// Functionality:  Input Fields & Submit Button for Login Page
import PropTypes from "prop-types";
import { authFields } from "../../constants/AuthConfig";
import Input from "../../components/Input";

// Refactor login Fields to be in correct format for Inputs
const fields = authFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function LoginFields({
  loginState,
  setLoginState,
  setLoginErrMsg,
}) {
  // Function: Handle State Change of Input Fields
  const handleChange = (e) => {
    e.preventDefault();
    setLoginErrMsg("");
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  // Const: Develop Login Input Fields for email & password
  const loginInputFields = fields?.map((field) => (
    <Input
      key={field.id}
      onChange={handleChange}
      value={loginState[field.id]}
      labelFor={field.labelFor}
      id={field.id}
      type={field.type}
      isRequired={field.isRequired}
      placeholder={field.placeholder}
      label={<p>{field?.labelText}</p>}
      divClassName="login-input-main-container"
      className="login-input-element font-small-medium"
    />
  ));

  return <>{loginInputFields}</>;
}

// Props
const authFieldsPropTypes = {
  loginState: PropTypes.object, // State of the login fields
  setLoginState: PropTypes.func, // Function to set the state of the login fields
  setLoginErrMsg: PropTypes.func, // Function to set the error message
};

authFields.propTypes = authFieldsPropTypes;
