// Functionality: Vehicle Vin Decoder Download Template Page, Dynamic Component For Downloading Template, Initial Page For Downloading Template and Failed Entries Download
import { TfiDownload } from "react-icons/tfi";
import Button from "../../../components/Button";
import { SiMicrosoftexcel } from "react-icons/si";
import { intialVinDecoderDownloadLabeling } from "../../../constants/SettingsConfig";

export default function VinDecoderDownloadFile({
  vehicleSettingsData,
  labeling = intialVinDecoderDownloadLabeling,
  containerClassName = "vin-decoder-download-template-group",
}) {
  // Function: Handle Download
  const handleDownloadDataClick = (tableData) => {
    // Get Data in Proper Formatting
    const headers = [["Vehicle", "VIN"]];
    const mappedData = tableData?.map((item) => [item.vehicle_id, item.vin]);

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent += headers.join(",") + "\n";
    mappedData?.forEach((rowArray) => {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${labeling?.fileName}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
  };
  const downloadButton = (
    <div>
      <Button
        buttonName={
          <>
            {labeling?.buttonName}
            <TfiDownload />
          </>
        }
        onClick={() => handleDownloadDataClick(vehicleSettingsData)}
        className="primary-white-button"
      />
    </div>
  );
  const downloadDiv = (
    <div className={containerClassName}>
      <div className="vin-decoder-download-template-header-container">
        <div className="vin-decoder-download-group-header">
          {labeling?.showIcon && <SiMicrosoftexcel />}
          <p>{labeling?.label}</p>
        </div>
        <p>{labeling?.subText}</p>
      </div>
      <div>{downloadButton}</div>
    </div>
  );
  return downloadDiv;
}
