// Functionality: Provide a basis for the application layout, wrap the application in the NavigationProvider context provider - render the NavigationBar component and the Outlet component

import { Outlet } from "react-router-dom";
import NavigationBar from "./NavigationBar";
import { NavigationProvider } from "../context/NavigationProvider";

export default function ApplicationLayout() {
  return (
    <NavigationProvider>
      <main>
        <NavigationBar />
        <div id="application-layout-container">
          <Outlet />
        </div>
      </main>
    </NavigationProvider>
  );
}
