// Functionality: This file exports the SustainableSolutionsNewEntryCard component which is used in the Reduction component. The component is a card input fields to add a new solution. This component will be shown within a modal.
import PropTypes from "prop-types";
import { useState } from "react";
import SelectDropdown from "../../../components/SelectDropdown";
import TextArea from "../../../components/TextArea";
import Button from "../../../components/Button";
import DatePicker from "../../../components/DatePicker";
import { sustainableSolutionsCategoryDropdown } from "../../../constants/ReductionConfig";
import { postSustainableSolutionsMarkerNewEntryAPI } from "../../../api/Reduction/SolutionsMaker/SustainableSolutionsMarkerNewEntryAPI";

export default function SustainableSolutionsNewEntryCard({
  setShowModal,
  sustainableSolutionsNewEntry,
  setSustainableSolutionsNewEntry,
  setSuccessMsg,
  notMockUser,
}) {
  // State: Initialize State - Sustainable Solutions Category Dropdown
  const [solutionsCategory, setSolutionsCategory] = useState([]);
  const [textAreaData, setTextAreaData] = useState("");

  // Function: Handle Submit Generating New Sustainable Solution Entry
  const handleSubmit = () => {
    setShowModal(false);
    if (notMockUser) {
      postSustainableSolutionsMarkerNewEntryAPI(
        sustainableSolutionsNewEntry,
        setSuccessMsg
      );
    }
    setTimeout(() => setSuccessMsg({ text: "", msg: "" }), 5000);
  };

  // Function: Handle Selected Date
  const handleSelectedDate = (event) => {
    setSustainableSolutionsNewEntry({
      ...sustainableSolutionsNewEntry,
      entryDate: event.target.value,
    });
  };

  // Function: Handle Input Change
  const handleInputChange = (event) => {
    if (event.target.value.length > 250) {
      return;
    } else {
      setSustainableSolutionsNewEntry({
        ...sustainableSolutionsNewEntry,
        description: event.target.value,
      });
      setTextAreaData(event.target.value);
    }
  };

  // Function: Handle Dropdown Change
  const handleDropdownChange = (event) => {
    setSustainableSolutionsNewEntry({
      ...sustainableSolutionsNewEntry,
      name: event?.value,
    });
    setSolutionsCategory([event]);
  };

  // Const: Submit Button Condition Handler - Only when all fields are filled out
  const submitConditionHandler =
    Object.values(sustainableSolutionsNewEntry).filter(
      (item) => item?.length > 0
    )?.length > 2;

  return (
    <div>
      <p className="card-title">New Sustainable Solution Entry</p>
      <div className="sustainable-solutions-new-entry-input-container">
        <div className="sustainable-solutions-new-entry-group">
          <p>Date:</p>
          <DatePicker
            selectedDate={sustainableSolutionsNewEntry?.entryDate}
            handleSelectedDate={(e) => handleSelectedDate(e)}
            dateType="date"
            restrictFuture={true}
          />
        </div>
        <div className="sustainable-solutions-new-entry-group">
          <p>Category:</p>
          <SelectDropdown
            divClassName="select-dropdown-main-container"
            headerClassName="select-dropdown-header-container"
            options={sustainableSolutionsCategoryDropdown || []}
            selectedOptions={solutionsCategory || []}
            setSelectedOptions={setSolutionsCategory}
            onChange={handleDropdownChange}
          />
        </div>
      </div>
      <div className="sustainable-solutions-new-entry-description-group">
        <p>Description:</p>
        <TextArea
          placeholder={"Input a description"}
          value={textAreaData}
          setValue={setTextAreaData}
          onChange={(e) => handleInputChange(e)}
        />
      </div>
      <div className="sustainable-solutions-submit-entry font-medium">
        <Button
          buttonName="Submit Entry"
          onClick={handleSubmit}
          disabled={!submitConditionHandler}
          className="submit-button"
        />
      </div>
    </div>
  );
}

// Props
const sustainableSolutionsNewEntryCardPropTypes = {
  setShowModal: PropTypes.func, // Function to set the state of the modal
  sustainableSolutionsNewEntry: PropTypes.object, // Object containing the new entry data
  setSustainableSolutionsNewEntry: PropTypes.func, // Function to set the new entry data
  setSuccessMsg: PropTypes.func, // Function to set the success message
  notMockUser: PropTypes.bool, // Boolean to determine if the user is a mock user
};

SustainableSolutionsNewEntryCard.propTypes =
  sustainableSolutionsNewEntryCardPropTypes;
