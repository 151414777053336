// Functionality: Select and set a specific datetime attribute
import PropTypes from "prop-types";

export default function DatePicker({
  divClassName = "", // Class name for the div container
  label = <></>, // Label component
  dateClassName = "primary-datepicker font-small font-color-gray", // Class name for the date input
  handleSelectedDate = () => {}, // Function: on change handler to change date
  selectedDate = "", // String: date value
  dateType = "month", // String: date type
  stepValue = "1", // String: step value
  restrictFuture = false, // Boolean: restrict future dates
  minDate = undefined, // String: min date value
  disabled = false,
}) {
  // Const: Get Today's date
  const today = new Date().toISOString().split("T")[0]; // Use this for dateType = "date"
  const todaySliced = today?.slice(0, 7); // Use this for dateType = "month"
  const todayToShow = dateType === "date" ? today : todaySliced;

  return (
    <div className={divClassName}>
      {label}
      <input
        className={`${dateClassName} ${disabled ? "disabled" : ""}`}
        type={dateType}
        step={stepValue}
        value={selectedDate}
        onChange={handleSelectedDate}
        max={restrictFuture ? todayToShow : undefined}
        min={minDate || undefined}
        placeholder="YYYY/MM"
        disabled={disabled}
      />
    </div>
  );
}

// Props
const datePickerProps = {
  divClassName: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object,
  ]),
  dateClassName: PropTypes.string,
  handleSelectedDate: PropTypes.func,
  selectedDate: PropTypes.string,
  dateType: PropTypes.string,
  stepValue: PropTypes.string,
  restrictFuture: PropTypes.bool,
  minDate: PropTypes.string,
};

DatePicker.propTypes = datePickerProps;
