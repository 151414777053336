import { createContext, useState } from "react";
import useQueryParams from "../hooks/use-queryParams";

const SignupContext = createContext({});

// Provides access to token across whole application
export const SignupProvider = ({ children }) => {
  // Get the query parameters from the URL for the username
  const query = useQueryParams();
  const queryEmail = query.get("email");

  // State: Initialize State - Full Application
  const [signupState, setSignupState] = useState({
    email: queryEmail || "",
    user_name: queryEmail || "",
    password: "",
    confirm_password: "",
  });

  // State: Initialize State - Developer Services Checkbox
  const [developerServicesCheckbox, setDeveloperServicesCheckbox] = useState(
    []
  );

  // State: Initialize State - Signup Agree Terms
  const [signupAgreeTerms, setSignupAgreeTerms] = useState([]);

  const value = {
    signupState,
    setSignupState,
    developerServicesCheckbox,
    setDeveloperServicesCheckbox,
    signupAgreeTerms,
    setSignupAgreeTerms,
  };

  return (
    <SignupContext.Provider value={value}>{children}</SignupContext.Provider>
  );
};

export default SignupContext;
