// Functionality: This is the VehicleProfile component of VehiclePerformancePage.
import { useEffect, useContext } from "react";
import VehiclePerformanceContext from "../../context/VehiclePerformanceProvider";
import DateContext from "../../context/DateProvider";
import useQueryParams from "../../hooks/use-queryParams";
import { PiTruckLight } from "react-icons/pi";
import { IoIosLock } from "react-icons/io";
import { FaArrowLeftLong } from "react-icons/fa6";
import GaugeChart from "../../visualizations/GaugeChart";
import useLabelCarbonRating from "../../hooks/use-labelCarbonRating";
import useNavHook from "../../hooks/use-NavHook";
import { vehiclePerformanceProfileDataLabels } from "../../constants/VehicleConfig";
import { getVehicleProfileAPI } from "../../api/VehiclePerformance/VehicleProfilesAPI";
import SwitchCarousel from "../../components/SwitchCarousel";
import { driveStatusVehicleProfile } from "../../constants/TileConfig";

export default function VehicleProfile({ setActiveTab, activeTab, mockData }) {
  // Context: Get Values from Vehicle Performance Context
  const { selectedVehicleData, setSelectedVehicleData, vehicleProfileData } =
    useContext(VehiclePerformanceContext);
  // Context: Get Values from Date Context
  const { dateValues, applicationDates, setApplicationDates } =
    useContext(DateContext);

  // Get the query parameters from the URL for the username
  const query = useQueryParams();
  const selectedVehicle = query.get("vehicle-profile") || null;

  // UE: Get Vehicle Profile Data For Selected Vehicle
  useEffect(() => {
    if (
      !selectedVehicle?.loaded &&
      !mockData &&
      applicationDates?.vehicleProfile
    ) {
      getVehicleProfileAPI(
        selectedVehicle,
        applicationDates,
        setSelectedVehicleData
      );
    }
  }, [applicationDates?.vehicleProfile]);

  // UE: Get Vehicle Profile Data For Selected Vehicle IN MOCK VERSIONING
  useEffect(() => {
    if (mockData && vehicleProfileData?.data?.length > 0) {
      const filteredData = vehicleProfileData?.data?.filter((item) => {
        return item.vehicle_id === selectedVehicle;
      });
      setSelectedVehicleData({ data: filteredData?.[0], loaded: true });
    }
  }, [vehicleProfileData]);

  // Deterine Label for Carbon Rating
  const carbonRatingLabel = useLabelCarbonRating(
    selectedVehicleData?.data?.rating
  );

  // Const: Initialize Hook - Navigation Hook to go to Vehicle Settings
  const { navigate, navTo } = useNavHook("/settings?activeTab=1");

  // Function: Handle Navigation to Vehicle Settings
  const handleNavVehicleSettings = () => {
    setActiveTab({ ...activeTab, "/settings": 1 });
    navigate(navTo, { state: { activeTab: 1 }, replace: true });
  };

  const vehInfoDesign = (data, dictionary, item, showLock = false) => {
    return (
      <>
        <div
          className={"vehicle-profile-info-group1"}
          key={dictionary?.[item]?.label}
        >
          <div className="vehicle-profile-info-group-label">
            {dictionary?.[item]?.label}:
          </div>
          <div className="vehicle-profile-info-group-value">
            {dictionary?.[item]?.unitBefore ? dictionary?.[item]?.unit : ""}{" "}
            {data?.data?.[item] || "-"}{" "}
            {dictionary?.[item]?.unitBefore ? "" : dictionary?.[item]?.unit}
          </div>
          {dictionary?.[item]?.blurb?.blurb || <> </>}
          {showLock && (
            <IoIosLock className="vehicle-profile-info-group-lock" />
          )}
        </div>
      </>
    );
  };

  // Set Selected Vehicle
  const handleBackVehicleGroupPage = () => {
    navigate("/vehicle-performance?activeTab=2", {
      state: { activeTab: 2 },
      replace: true,
    });
  };
  return (
    <>
      <div className="vehicle-profile-header-group">
        <div className="vehicle-profile-back-group">
          <FaArrowLeftLong
            className="back-arrow-icon"
            onClick={() => handleBackVehicleGroupPage()}
          />
          <p onClick={() => handleBackVehicleGroupPage()}>
            Back to Vehicle Selection
          </p>
        </div>
        <div className="vp-profile-icon-group">
          <div className="vp-profile-group-switch-carousel">
            <SwitchCarousel
              values={dateValues}
              applicationDates={applicationDates}
              setApplicationDates={setApplicationDates}
              applicationDatesLabel={"vehicleProfile"}
              fillArrowColor={"#66C743"}
              switchCarouselValueClassName="group-switch-carousel-value"
              changeVehicleProfile={true}
            />
          </div>
        </div>
      </div>
      <div className="vehicle-profile-page">
        {!selectedVehicleData?.loaded ? (
          <></>
        ) : (
          <>
            <div className="vehicle-profile-card1">
              <div className="vehicle-profile-card-title">
                Vehicle {selectedVehicle.toString()}
              </div>
              <PiTruckLight className="vehicle-profile-truck-icon" />
              <div className="vehicle-profile-card1-content">
                {Object.keys(vehiclePerformanceProfileDataLabels?.profile)?.map(
                  (item) => {
                    return vehInfoDesign(
                      selectedVehicleData,
                      vehiclePerformanceProfileDataLabels?.profile,
                      item,
                      true
                    );
                  }
                )}
                <div
                  onClick={() => handleNavVehicleSettings()}
                  className="vehicle-profile-nav-settings"
                >
                  To edit this information, please visit vehicle settings.
                </div>
              </div>
            </div>
            <div className="vehicle-profile-card2">
              <div className="vehicle-profile-card2-top">
                <div className="vehicle-profile-card-title">Trip Details</div>
                <div className="vehicle-profile-card1-content">
                  <div className="vehicle-profile-info-group1">
                    <div className="vehicle-profile-info-group-label">
                      Drive Status
                    </div>
                    <div
                      className={
                        selectedVehicleData?.data?.drive_status
                          ? "vehicle-profile-drive-status-icon"
                          : "vehicle-profile-drive-status-icon-inactive"
                      }
                    >
                      {selectedVehicleData?.data?.drive_status
                        ? "Active"
                        : "Inactive"}
                    </div>
                    {driveStatusVehicleProfile?.blurb}
                  </div>
                  {Object.keys(
                    vehiclePerformanceProfileDataLabels?.trip_details
                  )?.map((item) => {
                    return vehInfoDesign(
                      selectedVehicleData,
                      vehiclePerformanceProfileDataLabels?.trip_details,
                      item
                    );
                  })}
                </div>
              </div>
              <div className="vehicle-profile-card2-bottom">
                <div className="vehicle-profile-card-title">Fuel Details</div>
                <div className="vehicle-profile-card1-content">
                  {Object.keys(
                    vehiclePerformanceProfileDataLabels?.fuel_details
                  )?.map((item) => {
                    return vehInfoDesign(
                      selectedVehicleData,
                      vehiclePerformanceProfileDataLabels?.fuel_details,
                      item
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="vehicle-profile-card3">
              <div className="vehicle-profile-card-title">Analytics</div>
              <div className="vehicle-profile-card1-content">
                <p className="vehicle-profile-card-3-chart-label">
                  {carbonRatingLabel} Emissions Performance
                </p>
                <div className="vehicle-profile-card-3-chart">
                  <GaugeChart
                    ratingValue={selectedVehicleData?.data?.rating}
                    fontSize="1.125rem Arial"
                  />
                </div>
                {Object.keys(
                  vehiclePerformanceProfileDataLabels?.analytics
                )?.map((item) => {
                  return vehInfoDesign(
                    selectedVehicleData,
                    vehiclePerformanceProfileDataLabels?.analytics,
                    item
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
