// Functionality: Table component for the application
import PropTypes from "prop-types";
import { useState, useEffect, useRef, forwardRef } from "react";
import { useTable, useRowSelect, useSortBy } from "react-table";
import { GoTriangleUp, GoTriangleDown } from "react-icons/go";
import useSort from "../hooks/use-Sort";
import Pagination from "./Pagination";

export default function Table({
  tableData,
  config,
  tableClassName = "primary-table",
  tableHeadClassName = "primary-table-head card-text-min",
  tableRowClassName = "primary-table-row card-text",
  sortable = false,
  pagination = false,
  itemsPerPage = 10,
  minMaxFilters = false,
  dropdownFilters = false,
  download,
  downloadFunction,
  multiSelect = false,
  singleSelect = false,
  defaultSingleSelect = false,
  setSelectedRowIds = () => {},
}) {
  const [currentPage, setCurrentPage] = useState(1);
  // const [selectedRowIds, setSelectedRowIds] = useState({});

  const minMaxFilterData = minMaxFilters
    ? tableData?.filter((row) => {
        return minMaxFilters.every(({ column, data: [min, max] }) => {
          const value = parseFloat(row[column]);
          return value >= parseFloat(min) && value <= parseFloat(max);
        });
      })
    : tableData;

  const filteredData =
    dropdownFilters && dropdownFilters.data.length > 0
      ? minMaxFilterData.filter((row) => {
          const filterColumn = dropdownFilters.column;
          const filterValues = dropdownFilters.data.map(({ value }) => value);
          return filterValues.includes(row[filterColumn]);
        })
      : minMaxFilterData;

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const { sortOrder, sortBy, sortedData, setSortColumn } = useSort(
    filteredData,
    config
  );

  const currentData = pagination
    ? sortedData.slice(startIndex, endIndex)
    : sortedData;

  function getIcons(label, sortBy, sortOrder) {
    if (typeof label === "function") return <></>;
    const upIcon = <GoTriangleUp />;
    const downIcon = <GoTriangleDown />;
    if (label !== sortBy || sortOrder === null) {
      return (
        <div className="sort-table-icons">
          {upIcon}
          {downIcon}
        </div>
      );
    }
    if (sortOrder === "asc") {
      return <div>{upIcon}</div>;
    } else return <div>{downIcon}</div>;
  }

  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            className="multi-select-checkbox"
          />
        </>
      );
    }
  );

  const IndeterminateRadioItem = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);
      return (
        <>
          <input
            type="radio"
            ref={resolvedRef}
            {...rest}
            className="single-select-checkbox"
          />
        </>
      );
    }
  );

  const columns = config;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: currentData,
      initialState: { selectedRowIds: singleSelect && { 0: true } },
      manualSortBy: sortable,
      defaultCanSort: sortable,
      stateReducer: (newState, action, prevState) => {
        if (singleSelect && action.type === "toggleRowSelected") {
          if (action.value) {
            newState.selectedRowIds = { [action.id]: true };
          } else {
            newState.selectedRowIds = prevState.selectedRowIds;
          }
        }

        return newState;
      },
    },
    useSortBy,
    useRowSelect,

    (hooks) => {
      if (multiSelect) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      } else if (singleSelect) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "singleSelect",
            Cell: ({ row }) => (
              <div>
                <IndeterminateRadioItem {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  useEffect(() => {
    if (download) {
      downloadFunction(currentData, download);
    }
  }, [download]);

  // User Updates the selection via single or multi select check box
  useEffect(() => {
    if (multiSelect) {
      setSelectedRowIds(selectedFlatRows.map((item) => item.original));
    } else if (singleSelect && selectedFlatRows?.length > 0) {
      setSelectedRowIds(selectedFlatRows.map((item) => item.original));
    } else if (singleSelect && selectedFlatRows?.length === 0) {
      setSelectedRowIds([rows[0].original]);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (defaultSingleSelect) {
      setSelectedRowIds([rows[0].original]);
    }
  }, []);

  return (
    <>
      {pagination && (
        <Pagination
          pageCount={pageCount}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
      <table {...getTableProps()} className={tableClassName}>
        <thead className={tableHeadClassName}>
          {headerGroups?.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup?.headers?.map((column, index) => (
                <th
                  style={
                    sortable ? { cursor: "pointer" } : { cursor: "default" }
                  }
                  key={index}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  onClick={() =>
                    sortable ? (
                      column.id !== "selection" && setSortColumn(column.Header)
                    ) : (
                      <></>
                    )
                  }
                >
                  <div className="primary-table-sort-header">
                    <span>{column.render("Header")}</span>
                    {sortable && (
                      <span>{getIcons(column.Header, sortBy, sortOrder)}</span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, i) => {
            prepareRow(row);
            const isSelected = selectedRowIds[row.index];
            const rowClassName = tableRowClassName;
            return (
              <tr
                {...row.getRowProps()}
                className={rowClassName}
                style={
                  isSelected && multiSelect ? { fontWeight: "bold" } : null
                }
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

// Props
const TablePropTypes = {
  tableData: PropTypes.array, // Array of objects with the data to display in the table
  config: PropTypes.array, // Array of objects with the configuration for the table columns
  tableClassName: PropTypes.string, // String with the class name for the table
  tableHeadClassName: PropTypes.string, // String with the class name for the table head
  tableRowClassName: PropTypes.string, // String with the class name for the table rows
};
Table.propTypes = TablePropTypes;
