// Functionality: Doughnut chart component for the application
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

export default function DoughnutChart({
  groups = [],
  colorDict,
  labelMetricValue = "17,412",
  label = "MT CO2e",
}) {
  // Ref: Doughnut Chart
  const doughnutChartRef = useRef(null);
  const chartType = "doughnut";

  // UE: Generate the Doughnut Chart - Rerender when the datasets change and on initial load
  useEffect(() => {
    // Return if the chart ref is not defined or the groups are not defined
    if (!doughnutChartRef.current || !Array.isArray(groups)) {
      return;
    }
    if (groups) {
      // Const / Config: Determine if label is MT CO2e or something else
      const isMTCO2eConfig =
        label === "MT CO2e"
          ? {
              // Label Annotation Plugin
              afterDraw: (chart) => {
                const ctx = chart.ctx;
                const cw = chart.canvas.offsetWidth;
                const ch = chart.canvas.offsetHeight;
                const cx = cw / 2;
                const cy = ch / 1.8;
                ctx.save();
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.fillStyle = "#637E92";
                ctx.font = "0.75rem Arial";
                ctx.fillText("MT CO", cx, cy);
                ctx.save();
                const cx2 = cw / 1.68;
                const cy2 = ch / 1.75;
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.fillStyle = "#637E92";
                ctx.font = "0.5rem Arial";
                ctx.fillText("2", cx2, cy2);
                ctx.save();
                const cx3 = cw / 1.6;
                const cy3 = ch / 1.8;
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.fillStyle = "#637E92";
                ctx.font = "0.75rem Arial";
                ctx.fillText("e", cx3, cy3);
                ctx.restore();
              },
            }
          : {
              afterDraw: (chart) => {
                const ctx = chart.ctx;
                const cw = chart.canvas.offsetWidth;
                const ch = chart.canvas.offsetHeight;
                const cx = cw / 2;
                const cy = ch / 1.8;
                ctx.save();
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                ctx.fillStyle = "#637E92";
                ctx.font = "0.75rem Arial";
                ctx.fillText(label, cx, cy);
                ctx.save();
              },
            };
      // Const: Generate the data for the chart
      const labels = groups.map((group) => group.label);
      const values = groups.map((group) => group.percentageValue);

      // Const: Generate the background colors for the chart
      const backgroundColors = groups.map((el) => {
        const transformedLabel = el?.label?.toLowerCase().split(" ").join("");
        return colorDict?.[transformedLabel]?.color || "#36A2EB";
      });
      // Const: Generate the chart config
      const data = {
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: backgroundColors,
            borderWidth: 0,
          },
        ],
      };

      // Const: Configuration for the doughnut chart
      const doughnutChartConfig = {
        type: chartType,
        plugins: [
          {
            // Label Annotation Plugin
            afterDraw: (chart) => {
              const centerLabel = `${labelMetricValue}`;

              const ctx = chart.ctx;
              const cw = chart.canvas.offsetWidth;
              const ch = chart.canvas.offsetHeight;
              const cx = cw / 2;
              const cy = ch / 2.2;

              ctx.save();
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillStyle = "black";
              ctx.font = "1.375rem Arial";
              ctx.fillText(centerLabel, cx, cy);
              ctx.restore();
            },
          },
          isMTCO2eConfig,
        ],
        data: data,
        options: {
          cutout: "65%",
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
            },
            datalabels: {
              color: "#333",
              formatter: (value, context) => {
                const dataset =
                  context.chart.data.datasets[context.datasetIndex];
                const total = dataset.data.reduce((acc, curr) => acc + curr, 0);
                const percentage = Math.round((value / total) * 100) + "%";
                return percentage;
              },
              anchor: "center",
              align: "center",
              offset: -10,
              font: {
                size: 16,
                weight: "bold",
              },
            },
          },
        },
      };

      // Generate the Doughnut Chart Component
      const chart = new Chart(doughnutChartRef.current, doughnutChartConfig);

      // Cleanup function when component is unmounted
      return () => {
        chart.destroy();
      };
    }
  }, [groups, colorDict, label, labelMetricValue]);

  return (
    <canvas
      ref={doughnutChartRef}
      id="doughnut-chart-container"
      className="doughnut-chart"
    />
  );
}

const DoughnutChartPropTypes = {
  groups: PropTypes.array, // Array of objects containing label and value
  colorDict: PropTypes.object, // Dictionary of label-color mappings
  labelMetricValue: PropTypes.string, // Label for the metric value
  label: PropTypes.string, // Label for the metric
};

DoughnutChart.propTypes = DoughnutChartPropTypes;
