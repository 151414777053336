// Functionality: Navigation bar component for the application
import { useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";
import { NavigationContext } from "../context/NavigationProvider";
import {
  textNavigationConfig,
  iconNavigationConfig,
} from "../constants/NavigationConfig";
import logo from "../assets/logo-green-white.png";
import { useLocation } from "react-router-dom";

export default function NavigationBar() {
  // Context
  const { selectedNav, setSelectedNav } = useContext(NavigationContext); // Selected Navigation Link
  const { storedEmail } = useContext(AuthContext); // Stored Email For Help Page

  // Consts: Define the links to display in the navigation bar
  const links = textNavigationConfig; // Left Text Navigation
  const icons = iconNavigationConfig; // Right Icons Navigation

  // Const: Get the pathname
  const { pathname } = useLocation();

  // UE: Update the selectedNav state when the pathname changes
  useEffect(() => {
    setSelectedNav(pathname);
  }, [pathname, setSelectedNav]);

  // Function: Handle Signout - Clear the local storage and redirect to the login page
  const handleSignout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  // Component: Each of the link components to display in the navigation bar
  const linkComponent = (label, path, value, icon = false) => {
    // Const: Determine the class to apply to the navigation link (text or icon)
    const iconClass = icon
      ? "active-navigation-icon-underline"
      : "active-navigation-text-underline";

    // If the Help Page is Chosen, add the email to prepopulate the hubspot form
    const addEmailToPath = value === 6 ? `${path}?email=${storedEmail}` : path;

    return (
      <li key={`${path}-${value}`}>
        <NavLink
          to={addEmailToPath}
          activeclassname="active"
          className={selectedNav === path ? iconClass : ""}
        >
          {label}
        </NavLink>
      </li>
    );
  };

  return (
    <nav id="navigation-bar-container">
      <div>
        <ul id="navigation-link-container">
          {/* Navigation Logo  */}
          <NavLink to={"/"} activeclassname="active">
            <img src={logo} alt="Logo placeholder" />
          </NavLink>
          {/* Navigation Text */}
          {links.map(({ label, path, value }) =>
            linkComponent(label, path, value)
          )}
        </ul>
      </div>
      <div>
        {/* Navigation Icons */}
        <ul id="navigation-icon-link-container">
          {icons.map(({ label, path, value }) =>
            linkComponent(label, path, value, "icon")
          )}
          <p className="sign-out-link" onClick={handleSignout}>
            Sign Out
          </p>
        </ul>
      </div>
    </nav>
  );
}

// There are no props for this component
