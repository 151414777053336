// Functionality: This file exports the VinDecoderSettings component which allows the user to view and edit the vehicle settings data through uploading a csv.
import { useState, useEffect } from "react";
import Button from "../../components/Button";
import { vehicleSettingsDataColumnMapping } from "../../constants/SettingsConfig";
import VinDecoderSidebar from "./VinDecoderSettings/VinDecoderSidebar";
import VinDecoderUploadFile from "./VinDecoderSettings/VinDecoderUploadFile";
import VinDecoderCheckInitialUpload from "./VinDecoderSettings/VinDecoderCheckInitialUpload";
import VinDecoderFetch from "./VinDecoderSettings/VinDecoderFetch";
import VinDecoderOutputTable from "./VinDecoderSettings/VinDecoderOutput/VinDecoderOutputTable";
import { IoIosArrowBack } from "react-icons/io";
import { isFailedDecoderState } from "../../constants/initialDataConfig";
import { determineFailedVinDecoderEntries } from "../../constants/test";
import { failedVinDecoderReasonMapping } from "../../constants/SettingsConfig";

// TODO: A WAY TO ACCESS THE FULL TEMPLATE DATASET TO DOWNLOAD
export default function VinDecoderSettings({
  processStep,
  setProcessStep,
  vehicleSettingsData,
  setSelectedEdit,
  validVehicleID,
  successEditMsg,
  setSuccessEditMsg,
  vinFileUploadState,
  setVinFileUploadState,
  vinDecoderImportState,
  setVinDecoderImportState,
  yearValues,
  handlePostVehicleSettings,
  allVehiclesData,
  setAllVehiclesData,
}) {
  // Const: Discard All Records State
  const [discardAllRecords, setDiscardAllRecords] = useState(false);
  const [failedDecoderState, setFailedDecoderState] =
    useState(isFailedDecoderState); // Const: Failed Decoder State
  const [updatedDecoderState, setUpdatedDecoderState] =
    useState(isFailedDecoderState); // Const: Updated Decoder State
  const [dupDiscard, setDupDiscard] = useState({}); // Const: Dup Discard State
  const [vinDupDiscard, setVinDupDiscard] = useState({}); // Const: Vin Dup Discard State
  useEffect(
    () => {
      if (successEditMsg?.msg === "success") {
        setTimeout(() => setSuccessEditMsg({ text: "", msg: "" }), 5000);
      }
    }, // eslint-disable-next-line
    [successEditMsg]
  );

  // Functionality to read the uploaded file
  const fileReader = new FileReader();

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    const updatedArray = array.map((dict) =>
      Object.fromEntries(
        Object.entries(dict).map(([key, value]) => [
          vehicleSettingsDataColumnMapping[key?.replace(/[^\x20-\x7E]+/g, "")],
          value?.replace(/[^\x20-\x7E]+/g, ""),
        ])
      )
    );

    setVinFileUploadState({
      ...vinFileUploadState,
      originalFileData: updatedArray,
      fileData: updatedArray,
      updatedFailedEntries: {},
    });
    // // TODO: ADD A TON OF HANDLING HERE TO MAKE SURE THE CSV UPLOADED  IS VALID
  };

  const handleBackButtonClick = (step) => {
    if (step === 1) {
      if (
        failedDecoderState?.failed_length === 0 &&
        failedDecoderState?.upload_length !== 0
      ) {
        setVinFileUploadState({
          ...vinFileUploadState,
          originalFileData: vinFileUploadState?.originalFileData,
          fileData: vinFileUploadState?.originalFileData,
          updatedFailedEntries: vinFileUploadState?.updatedFailedEntries,
          triggerAPI: false,
        });
        const data = determineFailedVinDecoderEntries(
          validVehicleID,
          vinFileUploadState?.originalFileData,
          failedVinDecoderReasonMapping
        );
        setFailedDecoderState(data);
        setUpdatedDecoderState(vinFileUploadState?.updatedFailedEntries);
      } else {
        setProcessStep(step - 1);
      }
    } else {
      setProcessStep(step - 1);
    }
  };

  // Const: Back Button
  const backButton = (step) => {
    return (
      <div className="vin-decoder-sidebar-button">
        <Button
          onClick={() => handleBackButtonClick(step)}
          buttonName={
            <div className="flex-row-space-between">
              <IoIosArrowBack />
              <p>Back</p>
            </div>
          }
          className="secondary-blue-button"
        />
      </div>
    );
  };
  const determineButton = (step) => {
    if (step === 0) {
      return <div className="vin-decoder-sidebar-button"></div>;
    } else {
      return backButton(step);
    }
  };
  return (
    <div id="vin-decoder-page-container">
      <VinDecoderSidebar
        processStep={processStep}
        determineButton={determineButton}
      />
      <div id="vin-decoder-page-content">
        {processStep === 0 && (
          <>
            <VinDecoderUploadFile
              fileReader={fileReader}
              csvFileToArray={csvFileToArray}
              setProcessStep={setProcessStep}
              vehicleSettingsData={vehicleSettingsData}
              vinFileUploadState={vinFileUploadState}
              setVinFileUploadState={setVinFileUploadState}
              allVehiclesData={allVehiclesData}
              setAllVehiclesData={setAllVehiclesData}
            />
          </>
        )}
        {processStep === 1 && (
          <VinDecoderCheckInitialUpload
            updatedVehicleData={vinFileUploadState?.fileData}
            vinFileUploadState={vinFileUploadState}
            setVinFileUploadState={setVinFileUploadState}
            setProcessStep={setProcessStep}
            validVehicleID={validVehicleID}
            processStep={processStep}
            discardAllRecords={discardAllRecords}
            setDiscardAllRecords={setDiscardAllRecords}
            failedDecoderState={failedDecoderState}
            setFailedDecoderState={setFailedDecoderState}
            updatedDecoderState={updatedDecoderState}
            setUpdatedDecoderState={setUpdatedDecoderState}
            determineFailedVinDecoderEntries={determineFailedVinDecoderEntries}
            failedVinDecoderReasonMapping={failedVinDecoderReasonMapping}
            setVinDecoderImportState={setVinDecoderImportState}
            dupDiscard={dupDiscard}
            setDupDiscard={setDupDiscard}
            vinDupDiscard={vinDupDiscard}
            setVinDupDiscard={setVinDupDiscard}
          />
        )}
        {processStep === 2 && (
          <VinDecoderFetch
            vinDecoderImportState={vinDecoderImportState}
            setProcessStep={setProcessStep}
          />
        )}
        {processStep === 3 && (
          <VinDecoderOutputTable
            setVinDecoderImportState={setVinDecoderImportState}
            vinDecoderImportState={vinDecoderImportState}
            updatedVehicleData={vinFileUploadState?.fileData}
            yearValues={yearValues}
            handlePostVehicleSettings={handlePostVehicleSettings}
            setSelectedEdit={setSelectedEdit}
            setProcessStep={setProcessStep}
          />
        )}
      </div>
    </div>
  );
}
