// Functionality: Bar Chart component for the application
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

export default function BarChart({
  datasets,
  barType = "",
  barColor = "#0096FA",
  barChartStepSize = 100,
}) {
  // Refs
  const barChartRef = useRef(null); // Refs to Bar Chart
  const chartType = "bar"; // Type of chart is bar

  // UE: Generate the Bar Chart - Rerender when the datasets change and on initial load
  useEffect(() => {
    if (!barChartRef.current) return; // Ensure canvas is defined
    if (datasets) {
      // Format the datasets for the Bar Chart
      const formattedDatasets = () => {
        return datasets.map((dataset, index) => ({
          label: dataset.label,
          data: dataset.dataValues.map((item) => item.y),
          backgroundColor: dataset?.color || barColor,
          borderColor: dataset?.color || barColor,
          barPercentage: 0.5,
          type: dataset?.type || "bar",
          pointRadius: 0,
          borderWidth: dataset?.width || 1.5,
        }));
      };

      // Const: Configuration for the Bar Chart Scales
      let scalesConfig = {
        x: {
          grid: {
            display: false, // Disable the x-axis grid lines
          },
        },
        y: { beginAtZero: true },
      };

      // Const: Configuration for the Bar Chart Type (Stacked or Grouped)
      if (barType === "stacked") {
        scalesConfig.x.stacked = true;
        scalesConfig.y.stacked = true;
      } else if (barType === "grouped") {
        scalesConfig.x.grouped = true;
      }

      // Const: Configuration for Over Lay Bar Chart Type
      const options = {
        scales: {
          x: {
            stacked: true,
            categoryPercentage: 0.2,
            grid: {
              display: false, // Disable the x-axis grid lines
            },
          },
          y: {
            stacked: false,
            beginAtZero: true,
            ticks: {
              stepSize: barChartStepSize, // Set the y-axis step size (interval) to 3
            },
          },
        },
        plugins: {
          legend: {
            display: false, // Hide the legend
          },
        },
      };
      // Const: Configuration for the Bar Chart
      const barChartConfig = {
        type: chartType,
        data:
          barType === "overlay"
            ? datasets
            : {
                labels: datasets[0].dataValues.map((item) => item.x),
                datasets: formattedDatasets(),
              },
        options:
          barType === "overlay"
            ? options
            : {
                maintainAspectRatio: false,

                responsive: true,
                scales: scalesConfig,
                plugins: {
                  legend: {
                    position: "bottom", // Position the legend at the bottom
                    align: "start", // Align the legend to the start (left)
                  },
                },
              },
      };
      // Generate the Bar Chart Component
      const chart = new Chart(barChartRef.current, barChartConfig);

      // Cleanup function when component is unmounted
      return () => {
        chart.destroy();
      };
    }
  }, [datasets, barType, barColor, barChartStepSize]);

  return (
    <canvas ref={barChartRef} id="bar-chart-container" className="bar-chart" />
  );
}

// Props
const barChartPropTypes = {
  data: PropTypes.object, // Data to be displayed in the line chart, if array length > 0 then will be a multi-bar chart either stacked or grouped (default grouped)
  barType: PropTypes.string, // Type of bar chart to display (stacked or grouped)
  barColor: PropTypes.string, // Color of the bars in the chart
  barChartStepSize: PropTypes.number, // Step size of the y-axis
};

BarChart.propTypes = barChartPropTypes;
