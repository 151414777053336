// Functionality: Handles the API call to log in a user
import axios from "../loadBalancerAxios";
import { LOGIN_URL } from "../paths";
import { mockLoginData } from "../../constants/mockDataConfig";

export const postAuthenticateUser = async (
  loginState,
  navTo,
  navigate,
  setErrMsg
) => {
  // Check if the user logging in is the mock user
  if (
    loginState.email === mockLoginData?.email &&
    loginState.password === mockLoginData?.password
  ) {
    localStorage.setItem("greenToken", mockLoginData?.token);
    window.dispatchEvent(new StorageEvent("local-storage-clear"));
    navigate(navTo, { replace: true });
  } else {
    // Otherwise, normally log in the user
    await axios
      .post(LOGIN_URL, {
        ...loginState,
        "Content-Type": "application/json",
      })
      .then((response) => {
        // API Response - Set Err Msg if Incorrect Fields, Else Log In
        let loginResponse = response?.data;

        // Set Error Messgae
        if (loginResponse?.login !== "successful") {
          setErrMsg(loginResponse?.error);
        } else {
          // Clear Error Messgae
          setErrMsg("");
          // Store the access token in local storage
          const accessToken = loginResponse?.token;
          localStorage.setItem("greenToken", accessToken);
          window.dispatchEvent(new StorageEvent("local-storage-clear"));

          navigate(navTo, { replace: true });
        }
      })
      .catch((err) => {
        setErrMsg(err?.response?.data?.error);
      })
      .finally(() => {
        // If the user is already logged in, redirect to the home page
        if (localStorage.greenToken) {
          navigate(navTo, { replace: true });
        }
      });
  }
};
