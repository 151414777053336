// Functionality:  Pop Up Modal to reset password if the user forgot it
import { NavLink } from "react-router-dom";

export default function ForgotPasswordRedirect() {
  // Const: Forgot Password Button Name
  const forgotPWButtonName = "Forgot Password";

  // Component: Login Submit Button
  const forgotPasswordRedirectButton = (
    <li>
      <NavLink to={"/forgot-password"} activeclassname="active">
        {forgotPWButtonName}
      </NavLink>
    </li>
  );

  return (
    <>
      <div className="forgotpw-button-container">
        {forgotPasswordRedirectButton}
      </div>
    </>
  );
}
