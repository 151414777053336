// Functionality: This file exports the EmissionFootprintCard component which is used in the OverviewPage component. The component is a card including the total emissions footprint (monthly & yearly) and a breakdown of the emissions footprint.
import PropTypes from "prop-types";
import { useState, useContext } from "react";
import OverviewContext from "../../context/OverviewProvider";
import EmissionsFootprintBreakdownCard from "./EmissionFootprint/EmissionsFootprintBreakdownCard";
import MetricTileGroup from "../../components/MetricTileGroup";
import Modal from "../../components/Modal";
import SwitchCarousel from "../../components/SwitchCarousel";
import Skeleton from "react-loading-skeleton";

export default function EmissionFootprintCard({ ...props }) {
  // State
  const [showModal, setShowModal] = useState(false); // Modal for the breakdown of the emissions

  // Const: Initialize Context
  const { totalEmissionFootprint, emissionFootprintBreakdownData } =
    useContext(OverviewContext);

  // Const: Label Application Dates
  const applicationDatesLabel = "emissionFootprint";

  // Function: Show Modal
  const handleClick = () => {
    setShowModal(true);
  };
  // Function: Hide Modal
  const handleModalClose = () => {
    setShowModal(false);
  };

  // Const: Determine the body of the modal card
  const modalCardBody = (
    <Modal
      onClose={handleModalClose}
      children={
        <EmissionsFootprintBreakdownCard
          emissionFootprintBreakdownData={emissionFootprintBreakdownData}
          totalEmissionFootprint={totalEmissionFootprint}
        />
      }
    />
  );

  // Const: Modal Trigger Sub Header
  const modalTriggerSubHeader = (
    <div
      className={"metric-tile-group-subheader card-subtitle"}
      onClick={() => handleClick()}
    >
      See breakdown
    </div>
  );
  return (
    <>
      {!totalEmissionFootprint?.loaded ? (
        <Skeleton height={"100%"} />
      ) : (
        <MetricTileGroup
          svgConfig={totalEmissionFootprint}
          subHeader={modalTriggerSubHeader}
          metricClassName={
            "emission-footprint-metric-tile-group-metric font-metric-value-large"
          }
          svgChildren={
            <SwitchCarousel
              values={props.dateValues}
              applicationDates={props.applicationDates}
              setApplicationDates={props.setApplicationDates}
              applicationDatesLabel={applicationDatesLabel}
              fillArrowColor="black"
            />
          }
        />
      )}
      {showModal && modalCardBody}
    </>
  );
}

// Props
const emissionFootprintCardProptypes = {
  dateValues: PropTypes.array, // Array of dates to be shown in the date carousel
  applicationDates: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // String of application dates
  setApplicationDates: PropTypes.func, // Function to set the application dates
};

EmissionFootprintCard.propTypes = emissionFootprintCardProptypes;
