// Functionality: Accordion component for the application
import PropTypes from "prop-types";
import { useState } from "react";
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import ProgressBar from "./ProgressBar";
import { reportsAccordionConfig } from "../constants/ReportsConfig";

export default function Accordion({
  type,
  titleName,
  children,
  progressBarPercentage,
  titleClassName = "accordion-section-group",
  expandDict = reportsAccordionConfig,
}) {
  // State: isExpanded - Determine if the accordion is expanded
  const [isExpanded, setIsExpanded] = useState(expandDict);

  // Const: isIcon - Determine if the accordion is expanded
  const isIcon = (type) => {
    return (
      <span>
        {isExpanded[type] ? (
          <GoChevronDown className="accordion-icon" />
        ) : (
          <GoChevronRight className="accordion-icon" />
        )}
      </span>
    );
  };

  // Function: setIsExpandedHelper - Helper function to set the isExpanded state
  const setIsExpandedHelper = (item) => {
    setIsExpanded((isExpanded) => ({
      ...isExpanded,
      [item]: !isExpanded[item],
    }));
  };

  return (
    <>
      <div className={titleClassName} onClick={() => setIsExpandedHelper(type)}>
        <p>{titleName}</p>
        <div className="accordion-right-section-group">
          <div>
            {progressBarPercentage && (
              <ProgressBar
                percentageValue={progressBarPercentage}
                remainingProgressClassName={"remaining-progress-report"}
                circular={true}
              />
            )}
          </div>
          {isIcon(type)}
        </div>
      </div>
      {isExpanded[type] && children}
    </>
  );
}

// Props
const accordionPropTypes = {
  type: PropTypes.string, // Type of the accordion
  titleName: PropTypes.string, // Title of the accordion
  children: PropTypes.node, // Children of the accordion
  progressBarPercentage: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]), // Progress bar percentage
};

Accordion.propTypes = accordionPropTypes;
