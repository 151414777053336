// Functionality: API Call for Fuel Consumption Tile
import axios from "../loadBalancerAxios";
import { FUEL_CONSUMPTION_URL } from "../paths";
import { fuelConsumptionTileConfig } from "../../constants/EmissionsConfig";
import useNumberFormat from "../../hooks/use-NumberFormat";

export const postFuelConsumptionAPI = async (setData, selectedDate) => {
  await axios
    .post(FUEL_CONSUMPTION_URL, {
      "Content-Type": "application/json",
      date: selectedDate,
    })
    .then((response) => {
      // Const: Set Fuel Consumption Data
      const responseData = response?.data?.value || "-";
      setData({
        ...fuelConsumptionTileConfig,
        value: useNumberFormat(responseData),
        loaded: true,
      });
    })
    .catch(() => {
      // Const: Set Fuel Consumption Data to "-" when null
      const responseData = "-";
      setData({
        ...fuelConsumptionTileConfig,
        value: responseData,
        loaded: true,
      });
    });
};
