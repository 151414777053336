import { useContext, useRef } from "react";
import { createContext, useEffect, useState } from "react";
import DateContext from "./DateProvider";
import { postScope1API } from "../api/Emissions/Scope1API";
import { getScope1ChartDataAPI } from "../api/Emissions/Scope1ChartAPI";
import { getScope2API } from "../api/Emissions/Scope2API";
import { getScope2ManagerEntries } from "../api/Emissions/Scope2Manager/getScope2ManagerAPI";
import { getScope2ChartDataAPI } from "../api/Emissions/Scope2ChartCardAPI";
import { getSiteLocation } from "../api/Emissions/Scope2Manager/getSiteLocationAPI";
import { getLifeCycleEmissionsAPI } from "../api/Emissions/LifeCycleAPI";
import { getFuelTypeOptions } from "../api/Settings/FuelTypeOptionsAPI";

// INITIAL DATA TO IMPORT
import {
  isScope2ManagerNewEntry,
  isScope2ManagerNewAddress,
} from "../constants/initialDataConfig";

// Config Data to Import
import {
  scope1EmissionsSVGConfig,
  scope2EmissionsSVGConfig,
  scope1EmissionsData,
  scope2EmissionsData,
  scope1EmissionsTrendlineData,
  wellToTankTileConfig,
  tankToWheelTileConfig,
  wellToWheelTileConfig,
  wellToTankDataConfig,
  tankToWheelDataConfig,
  wellToWheelDataConfig,
} from "../constants/EmissionsConfig";

// MOCK Data to Import
import {
  mockScope1SVGData,
  mockScope2SVGData,
  mockScope1EmissionsData,
  mockScope2EmissionsData,
  mockScope2ManagerData,
  mockScope1BenchmarkEmissionsData,
  mockScope1AnnotationValueData,
  mockScope2ManagerLocationsData,
  mockScope2AnnotationValueData,
  mockLoginData,
  mockLifecycleAnnotationValueData,
  mockWellToTankLineChartData,
  mockTankToWheelLineChartData,
  mockLifecycleLineChartData,
  mockFuelTypeOptions,
} from "../constants/mockDataConfig";

const EmissionsContext = createContext({});

// Provides access to token across whole application
export const EmissionsProvider = ({ children }) => {
  // State: Initialize State - Full Page
  const [emissionsIsLoading, setEmissionsIsLoading] = useState(true);

  // State: Initialize State - Scope 1 Value
  const [scope1Value, setScope1Value] = useState({
    ...scope1EmissionsSVGConfig,
    value: "-",
    change: "-",
    increase: false,
    loaded: false,
  });

  // State: Initialize State - Scope 2 Value
  const [scope2Value, setScope2Value] = useState({
    ...scope2EmissionsSVGConfig,
    value: "-",
    change: "-",
    increase: false,
    loaded: false,
  });

  // State: Initialize State - Scope 1 Data Points
  const [scope1DataPoints, setScope1DataPoints] = useState({
    loaded: false,
    data: [],
  });

  // State: Initialize State - Scope 2 Data Points
  const [scope2DataPoints, setScope2DataPoints] = useState({
    loaded: false,
    data: { ...scope2EmissionsData },
  });

  const [scope2DataExists, setScope2DataExists] = useState({
    loaded: false,
    data: false,
  });

  // State: Initialize State - Scope 2 Manager Entries
  const [scope2ManagerEntries, setScope2ManagerEntries] = useState({
    loaded: false,
    data: {},
  });

  // State: Initialize State - Scope 2 Manager New Entry
  const [scope2ManagerNewEntry, setScope2ManagerNewEntry] = useState(
    isScope2ManagerNewEntry
  );

  // State: Initialize State - Scope 2 Manager Address
  const [scope2ManagerAddressOptions, setScope2ManagerAddressOptions] =
    useState({
      loaded: false,
      data: [],
    });

  // State: Initialize State - Scope 2 Manager New Address
  const [scope2ManagerNewAddress, setScope2ManagerNewAddress] = useState(
    isScope2ManagerNewAddress
  );

  // Life Cycle Tab -s---------------------------------------------------------------------------------------------------------------------------------------------
  // State: Initialize State - Well To Tank Value
  const [wellToTankData, setWellToTankData] = useState({
    ...wellToTankTileConfig,
    value: "-",
    loaded: false,
  });
  // State: Initialize State - Well To Tank Data Points
  const [glecLifeCycleChartData, setGlecLifeCycleChartData] = useState({
    loaded: false,
    data: [],
  });
  const [glecLifeCycleSidebarData, setGlecLifeCycleSidebarData] = useState({
    loaded: false,
    data: [],
  });
  // State: Initialize State - Tank to Wheel Value
  const [tankToWheelData, setTanktoWheelData] = useState({
    ...tankToWheelTileConfig,
    value: "-",
    loaded: false,
  });

  // State: Initialize State - Lifecycle Emissions
  const [wellToWheelData, setWellToWheelData] = useState({
    ...wellToWheelTileConfig,
    value: "-",
    loaded: false,
  });

  // State: Initialize State - Vehicle Type Options
  const [vehicleFuelTypeOptions, setVehicleFuelTypeOptions] = useState([]);
  const [selectedFuelType, setSelectedFuelType] = useState([]);

  // Const: Initialize Context - Application Dates
  const { applicationDates, selectedChartRadioItemType } =
    useContext(DateContext);

  // Const: Get Token & Make Sure Available For API Call
  const token = localStorage.getItem("greenToken");

  // UE: Get Emission Page Data For the Whole Application (Only Runs Once)
  useEffect(() => {
    const hasValues =
      Object.values(applicationDates).filter((date) => date !== "").length > 0;
    if (emissionsIsLoading && hasValues && token !== mockLoginData?.token) {
      const promises = [
        postScope1API(setScope1Value, applicationDates?.scope1Emissions),
        getScope1ChartDataAPI(
          setScope1DataPoints,
          selectedChartRadioItemType?.scope1,
          applicationDates?.scope1ChartEmissions
        ),
        getScope2ManagerEntries(setScope2ManagerEntries),
        getScope2ChartDataAPI(setScope2DataPoints, setScope2DataExists),
        getScope2API(setScope2Value, applicationDates?.scope2Emissions),
        getSiteLocation(setScope2ManagerAddressOptions),
        getLifeCycleEmissionsAPI(
          setGlecLifeCycleChartData,
          [],
          selectedChartRadioItemType?.lifecycle,
          applicationDates?.lifecycleChart
        ),
        getLifeCycleEmissionsAPI(setGlecLifeCycleSidebarData, [], "Monthly"),
        getFuelTypeOptions(setVehicleFuelTypeOptions, true),
      ];
      Promise.all(promises).then(() => {
        // Set Loading Component to False
        setEmissionsIsLoading(false);
      });
    } else if (
      emissionsIsLoading &&
      hasValues &&
      token === mockLoginData?.token
    ) {
      setScope1Value({
        ...scope1EmissionsSVGConfig,
        ...mockScope1SVGData?.[applicationDates?.scope1Emissions],
      });
      setScope2Value({
        ...scope2EmissionsSVGConfig,
        ...mockScope2SVGData?.[applicationDates?.scope2Emissions],
      });
      setScope1DataPoints({
        loaded: true,
        ...mockScope1AnnotationValueData,
        data: [
          { ...scope1EmissionsData, dataValues: mockScope1EmissionsData },
          {
            ...scope1EmissionsTrendlineData,
            dataValues: mockScope1BenchmarkEmissionsData,
          },
        ],
      });
      setScope2DataPoints({
        loaded: true,
        ...mockScope2AnnotationValueData,
        data: {
          ...scope2EmissionsData,
          dataValues: mockScope2EmissionsData,
        },
      });
      setScope2DataExists({ loaded: true, data: true });
      setScope2ManagerEntries(mockScope2ManagerData);
      setScope2ManagerAddressOptions(mockScope2ManagerLocationsData);
      setGlecLifeCycleChartData({
        loaded: true,
        ...mockLifecycleAnnotationValueData,
        data: [
          { ...wellToTankDataConfig, dataValues: mockWellToTankLineChartData },
          {
            ...tankToWheelDataConfig,
            dataValues: mockTankToWheelLineChartData,
          },
          {
            ...wellToWheelDataConfig,
            dataValues: mockLifecycleLineChartData,
          },
        ],
      });
      setEmissionsIsLoading(false);
      setVehicleFuelTypeOptions(mockFuelTypeOptions);
    } else if (
      !emissionsIsLoading &&
      hasValues &&
      token === mockLoginData?.token
    ) {
      setScope1Value({
        ...scope1EmissionsSVGConfig,
        ...mockScope1SVGData?.[applicationDates?.scope1Emissions],
      });
      setScope2Value({
        ...scope2EmissionsSVGConfig,
        ...mockScope2SVGData?.[applicationDates?.scope2Emissions],
      });
    }
  }, [emissionsIsLoading, applicationDates, token]);

  // Const: Get Previous Application Dates to See if Trigger API Call
  const prevApplicationDates = useRef(applicationDates);

  // Const: Get Previous Selected Chart Radio Item Type to See if Trigger API Call
  const prevSelectedChartRadioItemType = useRef(selectedChartRadioItemType);

  // UE: Get Emissions Data For Application(Trigger API Call) When applicationDates Changes
  useEffect(() => {
    const hasValues =
      Object.values(applicationDates).filter((date) => date !== "").length > 0;
    if (
      hasValues &&
      token &&
      token !== mockLoginData?.token &&
      !emissionsIsLoading &&
      prevApplicationDates.current
    ) {
      if (
        prevApplicationDates?.current?.scope1Emissions !==
        applicationDates.scope1Emissions
      ) {
        postScope1API(setScope1Value, applicationDates?.scope1Emissions);
      }
      if (
        prevApplicationDates?.current?.scope2Emissions !==
        applicationDates.scope2Emissions
      ) {
        getScope2API(setScope2Value, applicationDates?.scope2Emissions);
      }
      if (
        prevSelectedChartRadioItemType?.current?.scope1 !==
          selectedChartRadioItemType?.scope1 ||
        prevApplicationDates?.current?.scope1ChartEmissions !==
          applicationDates.scope1ChartEmissions
      ) {
        getScope1ChartDataAPI(
          setScope1DataPoints,
          selectedChartRadioItemType?.scope1,
          applicationDates?.scope1ChartEmissions
        );
      }
      if (
        prevSelectedChartRadioItemType?.current?.lifecycle !==
          selectedChartRadioItemType?.lifecycle ||
        prevApplicationDates?.current?.lifecycleChart !==
          applicationDates.lifecycleChart
      ) {
        getLifeCycleEmissionsAPI(
          setGlecLifeCycleChartData,
          selectedFuelType,
          selectedChartRadioItemType?.lifecycle,
          applicationDates?.lifecycleChart
        );
      }
    }
    prevApplicationDates.current = applicationDates;
    prevSelectedChartRadioItemType.current = selectedChartRadioItemType;
  }, [
    applicationDates,
    selectedChartRadioItemType?.scope1,
    selectedChartRadioItemType?.lifecycle,
    token,
    emissionsIsLoading,
  ]);

  // UE: Update LifeCyle Emissions by Fuel Type Filter
  useEffect(() => {
    let timer;

    // Set a timer to update the state after 5 seconds
    timer = setTimeout(() => {
      if (token !== mockLoginData?.token && glecLifeCycleChartData?.loaded) {
        getLifeCycleEmissionsAPI(
          setGlecLifeCycleChartData,
          selectedFuelType,
          selectedChartRadioItemType?.lifecycle,
          applicationDates?.lifecycleChart
        );
        getLifeCycleEmissionsAPI(
          setGlecLifeCycleSidebarData,
          selectedFuelType,
          "Monthly"
        );
      }
    }, 2500);

    return () => {
      clearTimeout(timer);
    };
  }, [selectedFuelType]);
  const value = {
    emissionsIsLoading,
    scope1Value,
    scope2Value,
    setScope2Value,
    scope1DataPoints,
    scope2DataPoints,
    scope2ManagerEntries,
    scope2ManagerNewEntry,
    setScope2ManagerNewEntry,
    setScope2DataPoints,
    setScope2DataExists,
    setScope2ManagerEntries,
    scope2ManagerAddressOptions,
    setScope2ManagerAddressOptions,
    scope2ManagerNewAddress,
    setScope2ManagerNewAddress,
    wellToTankData,
    tankToWheelData,
    wellToWheelData,
    glecLifeCycleChartData,
    setWellToWheelData,
    setTanktoWheelData,
    setWellToTankData,
    vehicleFuelTypeOptions,
    selectedFuelType,
    setSelectedFuelType,
    scope2DataExists,
    glecLifeCycleSidebarData,
  };

  return (
    <EmissionsContext.Provider value={value}>
      {children}
    </EmissionsContext.Provider>
  );
};

export default EmissionsContext;
