// Functionality:  Check Input Fields, Submit Button for Login Page, API Call
import PropTypes from "prop-types";
import Button from "../../components/Button";
import { postAuthenticateUser } from "../../api/Auth/LoginAPI";
import useNavHook from "../../hooks/use-NavHook";

export default function LoginSubmit({ loginState, setLoginErrMsg }) {
  // Const: Login Button Name
  const loginButtonName = "Log in";

  // Const: Navigation Properties to be used in the LoginSubmit component
  const { navigate, navTo } = useNavHook("/");

  // Function: Handle Submit of Login Form - Make API Call
  const handleSubmit = (e) => {
    e.preventDefault();
    postAuthenticateUser(loginState, navTo, navigate, setLoginErrMsg);
  };

  // Const - Check if Login Fields are filled
  const loginRequirements =
    loginState?.password?.length > 0 &&
    loginState?.email?.length > 0 &&
    loginState?.email?.includes("@");

  // Component: Login Submit Button
  const loginSubmitButton = (
    <Button
      onClick={handleSubmit}
      buttonName={loginButtonName}
      disabled={!loginRequirements}
      className={"font-medium"}
    />
  );
  return <div className="login-button-container">{loginSubmitButton}</div>;
}

// Props
const loginSubmitProps = {
  loginState: PropTypes.object, // State of the login fields
  setLoginErrMsg: PropTypes.func, // Function to set the error message
};

LoginSubmit.propTypes = loginSubmitProps;
