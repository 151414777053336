// Functionality: Single Insite Vehicle Edit Modal - used from the Vehicle Settings Page, only for editing a single vehicle in-site - cannot edit fields that exist, if the VIN exists, otherwise can enter new data for the vehicle. If the vin exists, can only fill in null entries.
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import SearchBar from "../../../components/SearchBar";
import { editVinFieldsConfig } from "../../../constants/SettingsConfig";

export default function SingleInsiteVehicleEditModal({
  handleReviewClick,
  setDisableInputs,
  disableInputs,
  handlePostVehicleSettings,
  handleModalClose,
  yearValues,
  vehicleFuelTypeOptions,
  handleEditFieldChange,
  singleVehicleEditFields,
  originalVehicleEditFields,
}) {
  // Const: Options Mapping For the Search Fields
  const searchBarOptionsMapping = {
    fuel_type: vehicleFuelTypeOptions,
    year: yearValues?.information,
  };

  // Const: Input Editable Components
  const editModalFieldsMapping = (config) => {
    return config.map((field, index) => {
      return (
        <div className="settings-update-vehicle-fields" key={index}>
          <p>{field.label}:</p>
          {field.type === "input" ? (
            <Input
              value={singleVehicleEditFields?.[field.valueName] || ""}
              onChange={(e) =>
                handleEditFieldChange(field.valueName, e, "insite")
              }
              placeholder={`Enter ${field.label}`}
              disabled={
                ((originalVehicleEditFields?.vin || false) &&
                  originalVehicleEditFields?.[field.valueName]?.length > 0) ||
                disableInputs
              }
            />
          ) : (
            <SearchBar
              options={searchBarOptionsMapping?.[field?.valueName] || []}
              selectedOptions={singleVehicleEditFields?.[field.valueName]}
              placeHolderText={`Select a ${field.label}`}
              onChange={(e) =>
                handleEditFieldChange(field.valueName, e, "insite", "search")
              }
              placeholder={"Search"}
              disabled={
                ((originalVehicleEditFields?.vin || false) &&
                  originalVehicleEditFields?.[field.valueName]?.length > 0) ||
                disableInputs
              }
            />
          )}
        </div>
      );
    });
  };

  // Dynamic Button Action Bar based on the disableInputs state
  const insightVehicleEdit = (
    <>
      <div className="settings-singular-update-vehicle-content">
        <div className="signup-password-note">
          Editing vehicle fields in-site is restricted for vehicles in which do
          not have a VIN. If you have a VIN, please use the VIN Decoder to
          update the vehicle information. You may only edit the engine
          manufacturer and model if a VIN is present.
        </div>
        <div className="settings-update-vehicle-fields-group-pair">
          {editModalFieldsMapping(editVinFieldsConfig)}
        </div>
      </div>
      {!disableInputs && (
        <div className="flex-end-button-group">
          <Button
            buttonName={"Review"}
            onClick={handleReviewClick}
            className={"primary-blue-button"}
          />
        </div>
      )}
      {disableInputs && (
        <div className="settings-update-vehicle-button-group">
          <div className="flex-end-button-group">
            <Button
              buttonName={"Cancel"}
              onClick={() => handleModalClose("single")}
              className={"cancel-red-button"}
            />
            <Button
              buttonName={"Edit"}
              onClick={() => setDisableInputs(false)}
              className={"secondary-blue-button"}
            />
          </div>
          <Button
            buttonName={"Save"}
            onClick={() => handlePostVehicleSettings("Single", "insite")}
            className={"primary-blue-button"}
          />
        </div>
      )}
    </>
  );
  return <>{insightVehicleEdit}</>;
}
