// Functionality: API Call for Vehicle Emissions Tile
import axios from "../loadBalancerAxios";
import { VEHICLE_EMISSIONS_URL } from "../paths";

export const getVehicleEmissionsAPI = async (
  setData,
  selectedDate,
  topN = 8
) => {
  await axios
    .get(`${VEHICLE_EMISSIONS_URL}?date=${selectedDate}&topN=${topN}`)
    .then((response) => {
      // Const: Set Vehicle Emissions Data
      const responseData = response?.data || [];

      // Const: Set Formatted Data
      const formattedData = responseData?.unit.map((label, index) => ({
        vehicle: `Vehicle ${parseInt(label) || label} `,
        emissions: responseData?.values[index]?.toFixed(2),
      }));

      setData({ loaded: true, data: formattedData });
    })
    .catch(() => {
      setData({ loaded: true, data: [] });
    });
};
