// Functionality: RangeSlider component for the application - frequently will be used within filters
import Slider from "react-slider";

export default function RangeSlider({
  values,
  setValues,
  stepValue = 0.01,
  inputRangeSliderClassName = "input-range-slider-group",
  disabled = false,
}) {
  // Function: Handle Values Change
  const handleValuesChange = (newValues) => {
    setValues({ ...values, data: newValues });
  };

  // Function: Handle Input Change
  const handleInputChange = (event, value) => {
    const newValue = event.target.value.trim();

    if (value === "min") {
      if (newValue === "") {
        setValues({ ...values, data: ["", values?.data[1]] });
      } else {
        setValues({ ...values, data: [newValue, values?.data[1]] });
      }
    }
    if (value === "max") {
      if (newValue === "") {
        setValues({ ...values, data: [values?.data[0], ""] });
      } else {
        setValues({ ...values, data: [values?.data[0], newValue] });
      }
    }
  };

  return (
    <>
      <Slider
        className={"range-slider"}
        value={values?.data}
        min={values?.minMax[0]}
        max={values?.minMax[1]}
        step={stepValue}
        onChange={handleValuesChange}
        disabled={disabled}
        orientation="horizontal"
        trackClassName="range-slider-track"
        renderThumb={(props, state) => (
          <div {...props} className="thumb">
            <div className="value-label">{state.valueNow.toLocaleString()}</div>
          </div>
        )}
      />
      <div className={inputRangeSliderClassName}>
        <input
          type="number"
          id="min-input-range-slider"
          value={values?.data[0]}
          onChange={(c) => handleInputChange(c, "min")}
        />
        <input
          type="number"
          id="max-input-range-slider"
          value={values?.data[1]}
          onChange={(c) => handleInputChange(c, "max")}
        />
      </div>
    </>
  );
}
