// Functionality: API Call for Scope 1 Emissions Data Points (Chart)
import axios from "../loadBalancerAxios";
import { SCOPE_2_DATA_POINTS_URL } from "../paths";
import { scope2EmissionsData } from "../../constants/EmissionsConfig";
import useDateFormat from "../../hooks/use-DateFormat";
// import useFindYearChange from "../../hooks/use-FindYearChange";

export const getScope2ChartDataAPI = async (setData, setScope2DataExists) => {
  await axios
    .get(SCOPE_2_DATA_POINTS_URL, {
      "Content-Type": "application/json",
    })
    .then((response) => {
      if (response.status === 204) {
        setData({
          loaded: true,
          data: { ...scope2EmissionsData },
          lineBreak: false,
          maxValue: false,
          annotationValue: false,
        });
        setScope2DataExists({
          loaded: true,
          data: false,
        });
      } else {
        // Const: Set Scope 2 Emissions Data
        const responseData = response?.data || [];

        const lengthData = responseData?.dates?.length;
        const nonEmptyData = lengthData > 0;

        const combinedValues = responseData?.values;

        const maxValue = Math.max(...combinedValues) + 50;

        // TODO: DETERMINE LOGIC FOR ANNOTAITON VALUE
        // const indexofLineBreak = useFindYearChange(responseData?.dates);
        // const annotationValue = {
        //   first: responseData?.dates
        //     ?.slice(indexofLineBreak - 1)?.[0]
        //     ?.slice(0, 4),
        //   second: responseData?.dates
        //     ?.slice(indexofLineBreak)?.[0]
        //     ?.slice(0, 4),
        // };

        // Const: Set Formatted Data
        const formattedData = responseData?.dates?.map((x, index) => ({
          x: index,
          y: responseData?.values[index],
          label:
            lengthData > 2 ? useDateFormat(x) : useDateFormat(x, "month-year"),
        }));
        setData({
          loaded: true,
          data: { ...scope2EmissionsData, dataValues: formattedData },
          lineBreak: false,
          maxValue: maxValue,
          annotationValue: false,
        });
        setScope2DataExists({
          loaded: true,
          data: nonEmptyData,
        });
      }
    })
    .catch(() => {
      setData({
        loaded: true,
        data: { ...scope2EmissionsData },
        lineBreak: false,
        maxValue: false,
        annotationValue: false,
      });
      setScope2DataExists({
        loaded: true,
        data: false,
      });
    });
};
