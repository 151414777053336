// Functionality: Tabbed Container component to switch which component to render on change.
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";

export default function TabbedContainer({
  tabs,
  activeTab,
  setActiveTab,
  containerClassName = "tabbed-container",
  tabListClassName = "tab-list",
  tabContentClassName = "tab-content",
  pageName,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabClick = (index) => {
    // Update the query parameter in the URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("activeTab", index);

    // Construct the new URL
    const newUrl = `${location.pathname}?${searchParams
      .toString()
      ?.split("&")?.[0]
      .toString()}`;

    // Update the active tab state
    setActiveTab({ ...activeTab, [pageName]: parseInt(index) });

    // Replace the current URL with the updated query parameter
    navigate(newUrl);
  };

  // Filter out tabs based on the condition
  const filteredTabs = tabs.filter((tab) => {
    // Filter out tabs that have hideTab set to true (default to false if not set)
    return tab?.hideTab === undefined || tab?.hideTab === false;
  });

  return (
    <div className={containerClassName}>
      <ul className={tabListClassName}>
        {filteredTabs.map((tab, index) => (
          <li
            key={index}
            className={`${
              index === parseInt(activeTab?.[pageName]) ? "active" : ""
            }`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      <div className={tabContentClassName}>
        {filteredTabs[activeTab?.[pageName]].content}
      </div>
    </div>
  );
}

// Props
const tabbedContainerProptypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.node,
    })
  ),
  activeTab: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  setActiveTab: PropTypes.func,
  containerClassName: PropTypes.string,
  tabListClassName: PropTypes.string,
  tabItemClassName: PropTypes.string,
  tabContentClassName: PropTypes.string,
};

TabbedContainer.propTypes = tabbedContainerProptypes;
