// Functionality: API Call for Editing Vehicle Settings
import axios from "../loadBalancerAxios";
import { VEHICLE_SETTINGS_URL } from "../paths";

export const postVehicleSettings = async (data, setMsg) => {
  await axios
    .post(VEHICLE_SETTINGS_URL, data)
    .then(() => {
      setMsg({ text: "Successfully edited vehicle entry.", msg: "success" });
    })
    .catch(() => {
      setMsg({ text: "Failed to edit vehicle entry.", msg: "warning" });
    });
};
