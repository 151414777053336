import { useContext } from "react";
import { AuthContext } from "../context/AuthProvider";
import DateContext from "../context/DateProvider";
import { mockLoginData } from "../constants/mockDataConfig";
import ApplicationHeader from "../layouts/ApplicationHeader";
import Input from "../components/Input";
import TabbedContainer from "../components/TabbedContainer";
import SettingsResetPasswordModal from "./SettingsPage/SettingsResetPasswordModal";
import VehicleSettings from "./SettingsPage/VehicleSettings";

export default function SettingsPage() {
  // Const: Initialize Auth Context
  const { carrierName, resetPWState, setResetPWState } =
    useContext(AuthContext);

  // Const: Date Context
  const { activeTab, setActiveTab } = useContext(DateContext);

  // Const: Determine if Mock User is Logged in
  const notMockUser =
    localStorage.getItem("greenToken") !== mockLoginData?.token;

  // Const: Company Domain Name Fields
  const companyDomainName = (
    <div className="setting-co-domain-name-group">
      <p>Company, Domain Name</p>
      <Input value={carrierName} disabled={true} />
    </div>
  );

  // Const: Account Set Up Fields
  const accountSetupFields = (
    <div>
      <p className="card-title">Account Set Up</p>
      <div className="setting-account-setup-group">
        <SettingsResetPasswordModal
          resetPWState={resetPWState}
          setResetPWState={setResetPWState}
        />
      </div>
    </div>
  );

  // Tabbed Container: User and Vehicle Settings
  const settingsTabs = [
    {
      label: "User Information",
      content: (
        <>
          <div id="settings-page-container">
            {companyDomainName}
            {accountSetupFields}
          </div>
        </>
      ),
    },
    {
      label: "Vehicle",
      content: (
        <>
          <VehicleSettings />
        </>
      ),
      // hideTab: notMockUser,
    },
  ];

  return (
    <>
      <ApplicationHeader headerName={"Account Settings"} />
      <TabbedContainer
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={settingsTabs}
        pageName={"/settings"}
      />
    </>
  );
}
