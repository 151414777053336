import { useContext } from "react";
import { AuthContext } from "../context/AuthProvider";
import AuthHeader from "../layouts/AuthHeader";
import ForgotPasswordFields from "./ForgotPassword/ForgotPasswordFields";
import ForgotPasswordSubmitEmail from "./ForgotPassword/ForgotPasswordSubmitEmail";
import BackLoginRedirect from "./ForgotPassword/BackLoginRedirect";
import ForgotPasswordResetFields from "./ForgotPassword/ForgotPasswordResetFields";
import ResetPasswordSubmit from "./ForgotPassword/ResetPasswordSubmit";

export default function ForgotPasswordPage() {
  // Const: Initialize Auth Context
  const {
    loginErrMsg,
    setLoginErrMsg,
    showForgotPW,
    setShowForgotPW,
    forgotPWState,
    setForgotPWState,
  } = useContext(AuthContext);

  return (
    <div>
      <div className="colored-bar-background-container"></div>
      <div className="colored-bar-main-container-content">
        <div id="forgotpw-container">
          <AuthHeader />
          <div className="forgotpw-content-container">
            <p className="forgotpw-content-container-title">
              Forgot Your Password?
            </p>
            <p className="forgotpw-content-container-subtitle">
              Please provide your email to receive a one-time code for password
              reset.
            </p>
            <div
              className={
                showForgotPW
                  ? "forgotpw-grid-container"
                  : "forgotpw-center-group-container"
              }
            >
              <div className={"forgotpw-grid-container-group"}>
                <ForgotPasswordFields
                  setLoginErrMsg={setLoginErrMsg}
                  forgotPWState={forgotPWState}
                  setForgotPWState={setForgotPWState}
                />
                <ForgotPasswordSubmitEmail
                  forgotPWState={forgotPWState}
                  setShowForgotPW={setShowForgotPW}
                  showForgotPW={showForgotPW}
                />
                <BackLoginRedirect />
              </div>
              <div className="forgotpw-grid-container-group">
                <p className={"forgotpw-err-msg"}>{loginErrMsg}</p>
                {showForgotPW && (
                  <>
                    <ForgotPasswordResetFields
                      setLoginErrMsg={setLoginErrMsg}
                      forgotPWState={forgotPWState}
                      setForgotPWState={setForgotPWState}
                    />
                    <ResetPasswordSubmit forgotPWState={forgotPWState} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
