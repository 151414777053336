// Functionality: Gather the dates that the user has data for in the system
import axios from "../loadBalancerAxios";
import { DATES_URL } from "../paths";
import useDateFormat from "../../hooks/use-DateFormat";
import {
  downloadReportsMonthDropdownOptions,
  monthToQuarterMap,
} from "../../constants/ReportsConfig";

export const getDatesAPI = async (
  setData,
  setYearValues,
  setSelectedChartRadioItemType,
  setMonthValues,
  setQuarterValues
) => {
  await axios
    .get(DATES_URL)
    .then((response) => {
      // Const: Date Data
      const dateData = response?.data?.dates;

      // Const: Historical Data - Check if there is more than one date, if so, it is monthly data, otherwise it is daily data
      const historicalData = dateData?.length > 1 ? "Monthly" : "Daily";
      const accessHistorialData = dateData?.length > 1;

      const todaysDate = new Date();
      let universalDate = new Date();

      const now = new Date();
      const utcNow = new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      );
      universalDate = utcNow;

      const showCurrentMonth =
        parseInt(
          todaysDate.toISOString().split("T")[0]?.split("-")?.slice(-1)?.[0]
        ) > 2;

      const slicedDateData = showCurrentMonth
        ? dateData
        : dateData?.slice(0, -1);

      // Const: Formatted Dictionary Date
      const dateFormat = slicedDateData?.map((item) => {
        return {
          label: useDateFormat(item, "month-year"),
          value: item,
        };
      });
      setData(dateFormat);

      //  Year Format & Drop Duplicates
      const yearFormat = dateData
        ?.map((item) => {
          return {
            label: useDateFormat(item, "year"),
            value: useDateFormat(item, "year"),
          };
        })
        ?.filter(
          (value, index, self) =>
            self.findIndex(
              (item) => item.label === value.label && item.value === value.value
            ) === index
        );

      // Find the most recent year
      const mostRecentYear = Math.max(
        ...yearFormat.map((item) => parseInt(item.label, 10))
      );

      // Up until 2050
      const projectionYearValue = Array.from(
        { length: 2050 - mostRecentYear },
        (_, index) => ({
          label: (mostRecentYear + index + 1).toString(),
          value: (mostRecentYear + index + 1).toString(),
        })
      );

      // Set Month Values
      function organizeDates(dates) {
        const dateDict = {};

        dates.forEach((date) => {
          const [year, month] = date.split("-");
          if (!dateDict[year]) {
            dateDict[year] = [];
          }
          const monthOption = downloadReportsMonthDropdownOptions.find(
            (option) => option.value === month
          );
          dateDict[year].push(monthOption);
        });

        return dateDict;
      }

      setMonthValues(organizeDates(dateData));

      // Set Quarter Values
      function organizeDatesByQuarter(dates) {
        const dateDict = {};

        dates.forEach((date) => {
          const [year, month] = date.split("-");
          if (!dateDict[year]) {
            dateDict[year] = {};
          }
          const quarter = monthToQuarterMap[month];
          if (!dateDict[year][quarter]) {
            dateDict[year][quarter] = { label: quarter, value: quarter };
          }
        });

        // Convert to desired format
        for (let year in dateDict) {
          dateDict[year] = Object.values(dateDict[year]);
        }

        return dateDict;
      }
      setQuarterValues(organizeDatesByQuarter(dateData));

      // Year Format - Projection Year
      setYearValues({
        information: yearFormat,
        projection: projectionYearValue,
      });

      // Set the selected radio item type
      setSelectedChartRadioItemType({
        accessHistorialData: accessHistorialData,
        scope1: historicalData,
        idle_emissions: historicalData,
        lifecycle: historicalData,
      });
    })
    .catch(() => {
      setData([]);
      setYearValues({ information: [], projection: [] });
    });
};
