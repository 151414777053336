// Functionality: API Call for Scope 2 Emissions Table  - Information used in the scope 2 manager table and within the scope 2 emissions visualization
import axios from "../../loadBalancerAxios";
import { SCOPE_2_MANAGER_URL } from "../../paths";

export const getScope2ManagerEntries = async (setData) => {
  await axios
    .get(SCOPE_2_MANAGER_URL)
    .then((response) => {
      if (response.status === 204) {
        setData({ loaded: true, data: {} });
      } else {
        // Const: Get Data From Response
        const responseData = response?.data;
        setData({ loaded: true, data: responseData });
      }
    })
    .catch(() => {
      setData({ loaded: true, data: {} });
    });
};
