// Functionality: This file exports the Scope2DeleteAddress component which is used in the Scope2Manager component. The component is a card including the form to delete an existing Scope 2 emission address
import Button from "../../../../components/Button";

export default function Scope2DeleteAddress({
  selectedLocationEditDelete,
  setSelectedLocationEditDelete,
  handleEditDeleteAddress,
  isScope2ManagerAddressManager,
}) {
  return (
    <>
      <div className="scope2-delete-container">
        <div>Are you sure you want to delete this site location?</div>
        <div className="scope2-delete-container-location">
          {selectedLocationEditDelete?.selectedLocationLabel}
        </div>
        <div>
          You may not delete a site location that has existing scope 2 entries.
        </div>
        <div className="flex-end-button-group">
          <Button
            buttonName="Cancel"
            className="cancel-red-button"
            onClick={() => {
              setSelectedLocationEditDelete(isScope2ManagerAddressManager);
            }}
          />
          <Button
            buttonName="Delete Location"
            className="cancel-solid-red-button"
            onClick={() => handleEditDeleteAddress("delete")}
          />
        </div>
      </div>
    </>
  );
}
