// Functionality:  Login Header for Not Logged In Users
import logo from "../assets/logo-green-black.png";

export default function AuthHeader() {
  return (
    <div className="auth-header-main-container">
      <img src={logo} alt="GreenIRR" />
    </div>
  );
}
