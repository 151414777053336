// Function: Generate Unique ID For All Entries in the Data
const generateUniqueIdData = (data) => {
  return data?.map((item, index) => {
    return {
      ...item,
      vin: item?.vin?.replaceAll(" ", ""),
      uniqueId: index,
    };
  });
};

// Function: Update Mapping Object
const updateFailedVinReasonMapping = (entries, reason, mapping) => {
  return {
    ...mapping?.[reason],
    count: entries?.length,
    unique_ids: entries?.map((val) => val?.uniqueId),
  };
};

// Function: Determine Individual Message to Display
const determineIndividualVehicleMessage = (mapping, item) => {
  // Find where the id is within the dictionary array failedVinDecoderReasonMapping or if valid, return the message
  const messages = [];

  // Iterate through the keys of the outer dictionary
  Object.keys(mapping).forEach((key) => {
    const entry = mapping[key];

    // Check if the unique_ids array includes the uniqueId
    if (entry?.unique_ids?.includes(item)) {
      messages?.push(entry.message); // Collect the message
    }
  });
  return messages;
};

// Function: Determine Failed Vins
export const determineFailedVinDecoderEntries = (
  validVehicleID,
  data,
  mapping,
  editEntry = false,
  dupDiscard = {},
  vinDupDiscard = {}
) => {
  // Const: Generate Unique ID For all Entries in the Data
  const uniqueIdData = editEntry ? data : generateUniqueIdData(data);

  // Const: Determine Duplicate Vehicle IDs
  const duplicateVehicleIds = uniqueIdData
    ?.map((item) => item?.vehicle_id)
    .filter(
      (value, index, self) => self.indexOf(value) !== index && value !== ""
    );
  // Const: Determine Duplicate Vehicle IDs, ignoring duplicates that have been discarded
  const duplicateVehicleIdsIgnoreDup =
    Object?.keys(dupDiscard)?.length > 0
      ? duplicateVehicleIds?.filter((item) =>
          Object?.keys(dupDiscard).includes(item)
        )
      : [];

  // Const: Determine Duplicate Entry Data
  const duplicateEntryData =
    Object?.keys(dupDiscard)?.length > 0
      ? uniqueIdData
          ?.filter((item) => duplicateVehicleIds?.includes(item?.vehicle_id))
          ?.filter(
            (ignoreDup) =>
              !duplicateVehicleIdsIgnoreDup.includes(ignoreDup?.vehicle_id)
          )
      : uniqueIdData?.filter((item) =>
          duplicateVehicleIds.includes(item?.vehicle_id)
        );

  // Const: Determine Duplicate Vehicle IDs
  const duplicateVins = uniqueIdData
    ?.map((item) => item?.vin)
    .filter(
      (value, index, self) => self.indexOf(value) !== index && value !== ""
    );
  // Const: Determine Duplicate Vehicle IDs, ignoring duplicates that have been discarded
  const duplicateVinsIgnoreDup =
    Object?.keys(vinDupDiscard)?.length > 0
      ? duplicateVins?.filter((item) =>
          Object?.keys(vinDupDiscard).includes(item)
        )
      : [];

  // Const: Determine Duplicate Entry Data
  const duplicateVinEntryData =
    Object?.keys(vinDupDiscard)?.length > 0
      ? uniqueIdData
          ?.filter((item) => duplicateVins?.includes(item?.vin))
          ?.filter(
            (ignoreDup) => !duplicateVinsIgnoreDup.includes(ignoreDup?.vin)
          )
      : uniqueIdData?.filter((item) => duplicateVins.includes(item?.vin));

  // Const: Determine Null Vehicle ID Data
  const nullVehicleIdData = uniqueIdData?.filter((item) => {
    return item?.vehicle_id === "";
  });
  const nullVehicleUniqueIds = nullVehicleIdData?.map((item) => {
    return item?.uniqueId;
  });
  // Const: Determine Invalid Vin Length Data
  const invalidVinLengthData = uniqueIdData?.filter(
    (item) => item?.vin?.length !== 17
  );

  // Const: Determine Invalid Vin Characters Data
  const invalidVinCharsData = uniqueIdData?.filter(
    (item) => /[^A-Za-z0-9]/.test(item?.vin) && item?.vin !== ""
  );

  // Const: Determine Invalid Vin Chars IOQ Data
  const invalidVinCharsIOQData = uniqueIdData?.filter(
    (item) => /[IOQioq]/.test(item?.vin) && item?.vin !== ""
  );

  // Const: Invalid Vehicle ID
  const invalidVehicleIdData = uniqueIdData?.filter(
    (item) =>
      !validVehicleID?.includes(item?.vehicle_id) && item?.vehicle_id !== ""
  );
  const invalidVehicleUniqueIds = invalidVehicleIdData?.map((item) => {
    return item?.uniqueId;
  });
  const dataMapping = {
    null_vehicle: nullVehicleIdData,
    duplicate_vehicle: duplicateEntryData,
    vin_length: invalidVinLengthData,
    vin_invalid_chars: invalidVinCharsData,
    vin_invalid_chars_ioq: invalidVinCharsIOQData,
    invalid_vehicle_id: invalidVehicleIdData,
    duplicate_vin: duplicateVinEntryData,
  };
  const updatedMapping = (updatedDataMapping) => {
    // Iterate over the keys of the updatedDataMapping object
    Object?.keys(updatedDataMapping)?.forEach((item) => {
      // Directly update the value at the given key in the original object
      updatedDataMapping[item] = updateFailedVinReasonMapping(
        updatedDataMapping[item],
        item,
        mapping
      );
    });

    // Return the updated object (though the object is updated in place)
    return updatedDataMapping;
  };

  const determineFailedVins = (updatedDataMapping, data) => {
    const failedIndexes = Object?.keys(updatedDataMapping)
      ?.map((item) => {
        return updatedDataMapping?.[item];
      })
      ?.map((val) => {
        return val?.unique_ids;
      })
      ?.flat();
    const failedVinDecoderEntries = editEntry
      ? data
      : data?.filter((item) => {
          return failedIndexes?.includes(item?.uniqueId);
        });
    const failedVinDecoderEntriesWithReasons = failedVinDecoderEntries?.map(
      (entry) => {
        return {
          ...entry,
          explanation: nullVehicleUniqueIds?.includes(entry?.uniqueId)
            ? [mapping?.null_vehicle?.message]
            : invalidVehicleUniqueIds?.includes(entry?.uniqueId)
            ? [mapping?.invalid_vehicle_id?.message]
            : entry?.discard
            ? ["Discarding Entry"]
            : determineIndividualVehicleMessage(
                updatedDataMapping,
                entry?.uniqueId
              ),
          discard: nullVehicleUniqueIds
            ?.concat(invalidVehicleUniqueIds)
            ?.includes(entry?.uniqueId)
            ? true
            : editEntry
            ? entry?.discard
            : false,
        };
      }
    );
    return failedVinDecoderEntriesWithReasons;
  };
  const updatedMappingValues = updatedMapping(dataMapping);
  const failedVinDeocderValues = determineFailedVins(
    updatedMappingValues,
    uniqueIdData
  );
  return {
    data: failedVinDeocderValues,
    upload_data: uniqueIdData,
    mapping: updatedMappingValues,
    failed_length: failedVinDeocderValues?.length,
    loaded: true,
    upload_length: uniqueIdData?.length,
  };
};

// Function: Determine Red Class Name on Table Row in Failed Vin Decoding
export const determineVinItemClassName = (val) => {
  if (typeof val === "number") {
    if (JSON?.stringify(val) === 0) {
      return "vin-decoder-import-entries-null-item";
    } else {
      return "";
    }
  } else if (val === null) {
    return "vin-decoder-import-entries-null-item";
  } else {
    if (val?.length === 0) {
      return "vin-decoder-import-entries-null-item";
    } else {
      return "";
    }
  }
};
