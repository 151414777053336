// Functionality: This file exports the Scope2NewEntry component which is used in the Scope2Manager component. The component is a card including the form to submit a new Scope 2 emission entry
import Input from "../../../../components/Input";
import SearchBar from "../../../../components/SearchBar";
import DatePicker from "../../../../components/DatePicker";
import Button from "../../../../components/Button";

export default function Scope2NewEntry({
  scope2ManagerNewEntry,
  scope2ManagerAddressOptions,
  handleInputChange,
  handleDropdownChange,
  handleSelectedDate,
  resetScope2ManagerFields,
  submitNewScope2Emission,
  submitConditionHandler,
}) {
  // Const: Input New Scope 2 Emission Container Group
  const inputNewScope2Emission = (
    <div className="scope2-manage-address-group font-color-gray font-small-medium">
      <p className="card-title">New Electricity Usage Entry</p>
      <div className="scope2-entry-form-group">
        <div className="scope2-manage-grid-group">
          <div className="scope2-manage-input-group-address">
            <p>Invoice ID:</p>
            <Input
              className="input-element scope2-manage-input-element"
              onChange={(e) => handleInputChange(e, "invoiceId")}
              value={scope2ManagerNewEntry?.invoiceId}
            />
          </div>
          <div className="scope2-manage-input-group-address">
            <p>Date:</p>
            <DatePicker
              selectedDate={scope2ManagerNewEntry?.billDate}
              handleSelectedDate={(e) => handleSelectedDate(e)}
              dateType={"date"}
              restrictFuture={true}
              dateClassName="secondary-datepicker font-small font-color-gray"
            />
          </div>
        </div>
        <div className="scope2-manage-input-group-address">
          <p>Site Location:</p>
          <SearchBar
            selectedOptions={scope2ManagerNewEntry?.siteLocation || []}
            options={scope2ManagerAddressOptions?.data}
            onChange={(e) => handleDropdownChange(e, "siteLocation")}
            placeholder="Search Site Location"
          />
          {/* TODO: ADD THIS */}
          {/* <p>Don't see a location? Visit Scope 2 Address Manager</p> */}
        </div>

        <div className="scope2-manage-input-group-address">
          <p>Electricity Purchased:</p>
          <div className="scope2-container-group">
            <Input
              onChange={(e) => handleInputChange(e, "wattage")}
              value={scope2ManagerNewEntry?.wattage}
            />
            <p>kWh</p>
          </div>
        </div>
      </div>
      <div className="scope2-submit-entry-button flex-end-button-group">
        <Button
          buttonName="Clear Entry"
          className="secondary-blue-button"
          onClick={() => resetScope2ManagerFields("new_entry")}
        />
        <Button
          buttonName="Submit Entry"
          onClick={() => submitNewScope2Emission()}
          disabled={!submitConditionHandler}
          className="primary-blue-button"
        />
      </div>
    </div>
  );
  return <> {inputNewScope2Emission}</>;
}
