// Functionality: This file exports the NewReportsFooterGroup component which is used in the New Reports Template component.
import { reportOptions } from "../../../constants/ReportsConfig";
import Button from "../../../components/Button";

export default function NewReportsFooterGroup({
  selectedReportSection,
  setSelectedReportSection,
}) {
  // Function: Handle Next & Back Buttons
  const handleNextBackButton = (e, type) => {
    if (type === "Next") {
      setSelectedReportSection(
        reportOptions?.[
          reportOptions?.findIndex((item) => item === selectedReportSection) + 1
        ]
      );
    } else if (type === "Back") {
      setSelectedReportSection(
        reportOptions?.[
          reportOptions?.findIndex((item) => item === selectedReportSection) - 1
        ]
      );
    }
  };

  return (
    <div className="report-header-background-div">
      <div className="report-subtitle-group">
        <div></div>
        <div className="flex-end-button-group">
          {selectedReportSection !== "Introduction" && (
            <Button
              buttonName={"Back"}
              className="secondary-blue-button"
              onClick={(e) => handleNextBackButton(e, "Back")}
            />
          )}
          {selectedReportSection !== "Challenges" && (
            <Button
              buttonName={"Next"}
              className="primary-blue-button"
              onClick={(e) => handleNextBackButton(e, "Next")}
            />
          )}
        </div>
      </div>
    </div>
  );
}
