// Functionality: API Call to generate a new site location for a user
import axios from "../../loadBalancerAxios";
import { SCOPE_2_MANAGER_LOCATION_URL } from "../../paths";

// INITIAL DATA TO RESET THE FORM AFTER SUBMISSION
import { isScope2ManagerNewAddress } from "../../../constants/initialDataConfig";

export const postNewSiteLocation = async (
  data,
  setData,
  setSelectedState,
  setMsg
) => {
  await axios
    .post(SCOPE_2_MANAGER_LOCATION_URL, {
      ...data,
    })
    .then((response) => {
      if (response?.data?.error) {
        setMsg({
          text: "Please enter a valid site location",
          msg: "warning",
        });
      } else {
        setMsg({
          text: "Successfully Added New Site Location",
          msg: "success",
        });
        setData(isScope2ManagerNewAddress);
        setSelectedState([]);
      }
    })
    .catch(() => {
      setMsg({
        text: "Please enter a valid site location",
        msg: "warning",
      });
    });
};
