// Functionality: This file exports the Scope1 Card component which is used in the Emissions Page component.
import PropTypes from "prop-types";
import { useContext } from "react";
import EmissionsContext from "../../context/EmissionsProvider";
import MetricTileGroup from "../../components/MetricTileGroup";
import SwitchCarousel from "../../components/SwitchCarousel";
import Skeleton from "react-loading-skeleton";

export default function Scope1Card({ ...props }) {
  // Const: Initialize Context
  const { scope1Value } = useContext(EmissionsContext);

  // Const: Label Application Dates
  const applicationDatesLabel = "scope1Emissions";

  // Const: Length of Scope 1 Emissions Value Determining css
  const scopeValueLengthCSS = scope1Value?.value?.length > 6 ? "-sm" : "";

  // Const: Length of Scope 1 Emissions Unit Determining css
  const scopeUnitLengthCSS = scope1Value?.value?.length > 8 ? "-sm" : "";

  return (
    <>
      {!scope1Value?.loaded ? (
        <Skeleton height={"100%"} />
      ) : (
        <div className="scope-card-contianer-group-scope1">
          <MetricTileGroup
            svgConfig={scope1Value}
            metricClassName={`scope-metric-tile-group-metric${scopeValueLengthCSS}`}
            unitClassName={`scope-metric-tile-group-unit${scopeUnitLengthCSS}`}
            svgChildren={
              <SwitchCarousel
                values={props.dateValues}
                applicationDates={props.applicationDates}
                setApplicationDates={props.setApplicationDates}
                applicationDatesLabel={applicationDatesLabel}
                fillArrowColor="black"
              />
            }
          />
        </div>
      )}
    </>
  );
}

// Props
const scope1CardPropTypes = {
  dateValues: PropTypes.array, // Array of date values
  applicationDates: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // String of application dates
  setApplicationDates: PropTypes.func, // Function to set application dates
};

Scope1Card.propTypes = scope1CardPropTypes;
