// Functionality: API Call for Total Idle Time Emmissions Tile
import axios from "../loadBalancerAxios.js";
import { TOTAL_IDLE_TIME_URL } from "../paths.js";
import { totalIdleTimeSVGConfig } from "../../constants/EmissionsConfig.js";

export const postTotalIdleTime = async (setData, selectedDate) => {
  await axios
    .post(TOTAL_IDLE_TIME_URL, {
      date: selectedDate,
    })
    .then((response) => {
      // Const: Get Data From Response
      const responseData = response?.data;

      // Const: Determine if its increase or decrease
      const increase = responseData?.change > 0;

      setData({
        loaded: true,
        data: {
          ...totalIdleTimeSVGConfig,
          value: responseData?.value.toFixed(0),
          percentageChange: Math.abs(responseData?.change).toFixed(0) || "-",
          increase: increase,
        },
      });
    })
    .catch(() => {
      setData({
        loaded: true,
        data: {
          ...totalIdleTimeSVGConfig,
          value: "-",
          percentageChange: "-",
          increase: false,
        },
      });
    });
};
