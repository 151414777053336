// Functionality: This file exports the NewReportsTitleGroup component which is used in the New Reports Template component.
import { useContext, useEffect } from "react";
import ReportContext from "../../../context/ReportsProvider";
import DatePicker from "../../../components/DatePicker";
import SelectDropdown from "../../../components/SelectDropdown";
import { downloadReportsRadioOptions } from "../../../constants/ReportsConfig";

export default function NewReportsTitleGroup({
  yearValues,
  selectedReportSection,
  monthValues,
  quarterValues,
  dateValues,
}) {
  // Const: Initialize Report Context
  const {
    radioValueDateType,
    setRadioValueDateType,
    selectedValueDateType,
    setSelectedValueDateType,
  } = useContext(ReportContext);

  useEffect(() => {
    // Get the Current Date / Year to See About Reseting, Generating a Report For Quarter & Month
    const selectedYear = selectedValueDateType?.year?.[0]?.value?.toString(); // Selected Year
    const selectedMonth = selectedValueDateType?.month?.[0]?.value?.toString(); // Selected Month
    const selectedQuarter = selectedValueDateType?.qtd?.[0]?.value?.toString(); // Selected Quarter

    const resetMonth =
      !monthValues?.[selectedYear]?.filter((item) => {
        return item?.value === selectedMonth;
      })?.length > 0;

    const resetQuarter =
      !quarterValues?.[selectedYear]?.filter((item) => {
        return item?.value === selectedQuarter;
      })?.length > 0;

    if (resetMonth && radioValueDateType === "Month") {
      setSelectedValueDateType({
        ...selectedValueDateType,
        month: [],
      });
    } else if (resetQuarter && radioValueDateType === "Quarter") {
      setSelectedValueDateType({
        ...selectedValueDateType,
        qtd: [],
      });
    }
  }, [selectedValueDateType?.year]);

  // Function: Handle Radio Change
  const handleRadioChange = (e) => {
    setRadioValueDateType(e?.value);
  };

  // Function: Handle Dropdown Change - Handling to Make Sure the Selected Date is not in the Future
  const handleDropdownChange = (e, type) => {
    setSelectedValueDateType({
      ...selectedValueDateType,
      [type]: [e],
    });
  };

  // Function: Handle Selected Date
  const handleSelectedDate = (e, type) => {
    setSelectedValueDateType({
      ...selectedValueDateType,
      custom: {
        ...selectedValueDateType?.custom,
        [type]: e.target.value,
      },
    });
  };

  // Const: Year Dropdown
  const yearInput = ["Year", "Month", "Quarter"].includes(
    radioValueDateType
  ) && (
    <SelectDropdown
      options={yearValues?.information || []}
      selectedOptions={selectedValueDateType?.year}
      divClassName={`select-dropdown-reports-date-container font-color-gray font-small`}
      placeHolderText={"Select a year"}
      onChange={(e) => handleDropdownChange(e, "year")}
    />
  );

  // Const: Quarter Dropdown
  const quarterInput = radioValueDateType === "Quarter" && (
    <SelectDropdown
      options={quarterValues[selectedValueDateType?.year?.[0]?.value] || []}
      selectedOptions={selectedValueDateType?.qtd}
      divClassName={`select-dropdown-reports-date-container${
        !selectedValueDateType?.year?.[0]?.value ? "-disabled" : ""
      } font-color-gray font-small`}
      headerClassName={`select-dropdown-header-container${
        !selectedValueDateType?.year?.[0]?.value ? "-disabled" : ""
      } font-color-gray`}
      placeHolderText={"Select a quarter"}
      onChange={(e) => handleDropdownChange(e, "qtd")}
      disabled={!selectedValueDateType?.year?.[0]?.value}
    />
  );

  // Const: Month Dropdown
  const monthInput = radioValueDateType === "Month" && (
    <SelectDropdown
      options={monthValues[selectedValueDateType?.year?.[0]?.value] || []}
      selectedOptions={selectedValueDateType?.month}
      divClassName={`select-dropdown-reports-date-container${
        !selectedValueDateType?.year?.[0]?.value ? "-disabled" : ""
      } font-color-gray font-small`}
      headerClassName={`select-dropdown-header-container${
        !selectedValueDateType?.year?.[0]?.value ? "-disabled" : ""
      } font-color-gray`}
      placeHolderText={"Select a month"}
      onChange={(e) => handleDropdownChange(e, "month")}
      disabled={!selectedValueDateType?.year?.[0]?.value}
    />
  );

  // Const: Custom Date Range
  const customDate = radioValueDateType === "Custom" && (
    <>
      <DatePicker
        dateType="month"
        restrictFuture={true}
        label={<div className="font-small">Start Date</div>}
        handleSelectedDate={(e) => handleSelectedDate(e, "start")}
        selectedDate={selectedValueDateType?.custom?.start}
        minDate={`${dateValues?.[0]?.value}`}
        divClassName="date-range-group font-color-gray"
      />
      <DatePicker
        dateType="month"
        restrictFuture={true}
        label={<div className="font-small">End Date</div>}
        handleSelectedDate={(e) => handleSelectedDate(e, "end")}
        selectedDate={selectedValueDateType?.custom?.end}
        minDate={selectedValueDateType?.custom?.start || null}
        divClassName="date-range-group font-color-gray"
        disabled={!selectedValueDateType?.custom?.start?.length > 0}
      />
    </>
  );
  // Const: YTD Date Range
  const ytdDate = radioValueDateType === "YTD" && (
    <>
      <div className="font-color-gray">Year to Date</div>
    </>
  );

  return (
    <div className="report-header-background-div">
      <div className="report-title-group">
        <p className="card-title">{`New Report`}</p>
        <SelectDropdown
          options={downloadReportsRadioOptions}
          placeHolderText={"Select a date range"}
          onChange={(e) => handleRadioChange(e)}
          selectedOptions={
            radioValueDateType
              ? [{ label: radioValueDateType, value: radioValueDateType }]
              : []
          }
        />
      </div>
      <div className="report-subtitle-group">
        <div className="report-subtitle-date-group">
          <p>{selectedReportSection}</p>
          <div className="reports-select-timeframe-group">
            {yearInput}
            {monthInput}
            {quarterInput}
            {customDate}
            {ytdDate}
          </div>
        </div>
      </div>
    </div>
  );
}
