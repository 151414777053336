// Functionality: MetricTileGroupMini component for the application, used for displaying information on the Lifecycle framework
import useSub from "../hooks/use-Sub";

export default function MetricTileGroupMini({
  divClassName = `mini-metric-tile-group-container`,
  titleClassName = `mini-metric-tile-group-title`,
  metricGroupClassName = `mini-metric-tile-group-metric-group`,
  metricClassName = `mini-metric-tile-group-metric`,
  unitClassName = `mini-metric-tile-group-unit`,
  contentClassName = "mini-metric-tile-group-content",
  childrenContainer,
  ...props
}) {
  // Const: Super script for the units
  const superscriptedFormula = useSub(props.svgConfig?.units);

  // Const: Determine the body of the card
  const cardBody = (
    <>
      <div className={metricGroupClassName}>
        <div className={metricClassName}>{props.svgConfig?.value || "000"}</div>
        <div className={unitClassName}>{superscriptedFormula || ""}</div>
      </div>
    </>
  );

  return (
    <>
      <div className={divClassName}>
        <div className={contentClassName}>
          <div className={titleClassName}>
            {props.svgConfig?.title || ""}
            {props.svgConfig?.blurb}
            {props.svgConfig?.legend}
          </div>
          {props.subHeader}
          {cardBody}
          {props.svgChildren && (
            <div className={childrenContainer}>{props.svgChildren}</div>
          )}
        </div>
        <div style={{ backgroundColor: props.svgConfig?.color }}></div>
      </div>
    </>
  );
}
