// Functionality: This file exports the VPSettingsTable component which is used in the Settings component.
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SettingsContext from "../../context/SettingsProvider";
import DateContext from "../../context/DateProvider";
import Table from "../../components/Table";
import Skeleton from "react-loading-skeleton";
import { yearOptions1965Today } from "../../constants/VehicleConfig";
import {
  vehicleSettingsDataConfig,
  vehicleSettingsEditConfig,
} from "../../constants/SettingsConfig";
import VehicleSettingsEditModal from "./VehicleSettings/VehicleSettingsEditModal";
import { CustomRadioItemGroup } from "../../components/RadioItemGroup";
import { postVehicleSettings } from "../../api/Settings/EditVehicleSettingsAPI";
import SnackBar from "../../components/SnackBar";
import Pagination from "../../components/Pagination";
import VinDecoderSettings from "./VinDecoderSettings";

// TODO: CLEAN THIS FILE SO SO SO MUCH
export default function VehicleSettings() {
  // Const: Initialize Context - Vehicle Performance Data
  const {
    vehicleSettingsData,
    singleVehicleEditFields,
    setSingleVehicleEditFields,
    successEditMsg,
    setSuccessEditMsg,
    vehicleSettingsDataPage,
    setVehicleSettingsDataPage,
    vehicleFuelTypeOptions,
    validVehicleID,
    vinFileUploadState,
    setVinFileUploadState,
    vinDecoderImportState,
    setVinDecoderImportState,
    allVehiclesData,
    setAllVehiclesData,
  } = useContext(SettingsContext);

  // Const: Initialize Context - Actie Tab
  const { activeTab, setActiveTab } = useContext(DateContext);

  // Const: Navigation Criteria
  const navigate = useNavigate();

  // State: Modal
  const [showModal, setShowModal] = useState({ single: false, multi: false }); // Modal for the breakdown of the emissions
  const [disableInputs, setDisableInputs] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState("Insite");
  const [disabledDecodeButton, setDisabledDecodeButton] = useState(true);
  const [singleVinDecoderProcessStep, setSingleVinDecoderProcessStep] =
    useState(1); // State: Vin Decoder Process Step
  // Vehicle to Edit

  // Process Step CSV Upload
  const [processStep, setProcessStep] = useState(0);

  // UE: Success Message
  useEffect(() => {
    if (successEditMsg?.msg === "success") {
      setTimeout(() => setSuccessEditMsg({ text: "", msg: "" }), 5000);
    }
  }, [successEditMsg]);

  // Function: Show Modal
  const handleClick = (type) => {
    setDisableInputs(false);
    setShowModal({ ...showModal, [type]: true });
  };

  // Function: Hide Modal
  const handleModalClose = (type) => {
    setShowModal({ ...showModal, [type]: false });
  };

  // Function: Handle Edit Click
  const handleEditClick = (selectedRow) => {
    handleClick("single");
    const selectedRowData = {
      ...selectedRow?.row?.original,
      fuel_type: [
        {
          label: selectedRow?.row?.original?.fuel_type,
          value: selectedRow?.row?.original?.fuel_type,
        },
      ],
      year: [
        {
          label: selectedRow?.row?.original?.year,
          value: selectedRow?.row?.original?.year,
        },
      ],
    };
    setSingleVehicleEditFields({
      vin_decode: selectedRowData,
      insite: selectedRowData,
      original: selectedRowData,
    });
    setDisabledDecodeButton(true);
    setSingleVinDecoderProcessStep(1);
  };

  // Function: Handle Review Click
  const handleReviewClick = () => {
    setDisableInputs(true);
  };

  // Function: Handle Click on Vehicle Route to Profile
  const handleRouteVehicleProfile = (data) => {
    setActiveTab({
      ...activeTab,
      "/vehicle-performance": 2,
    });

    // Update the query parameter in the URL
    const newUrl = `/vehicle-performance?activeTab=2&vehicle-profile=${data}`;
    navigate(newUrl);
  };

  // Function: Handle Edit Field Change
  const handleEditFieldChange = (
    col,
    e,
    type = "vin_decode",
    component = "input"
  ) => {
    const updatedValue = component === "input" ? e.target.value : [e];

    setSingleVehicleEditFields({
      ...singleVehicleEditFields,
      [type]: {
        ...singleVehicleEditFields[type],
        [col]: updatedValue,
      },
    });
  };

  // Function: Handle API Post
  const handlePostVehicleSettings = (type, singleType = "vin-decode") => {
    if (type === "Single") {
      // Determine the data to post (either insite or vin-decode)
      const singleTypeData = singleVehicleEditFields?.[singleType];
      const postData = {
        ...singleTypeData,
        fuel_type:
          singleTypeData?.fuel_type?.[0]?.value || singleTypeData?.fuel_type,
        year: singleTypeData?.year?.[0]?.value || singleTypeData?.year,
      };
      postVehicleSettings([postData], setSuccessEditMsg);
      setShowModal({
        single: false,
        multi: false,
      });
    } else if (type === "CSV") {
      postVehicleSettings(vinDecoderImportState?.data, setSuccessEditMsg);
      setVinFileUploadState({
        file: null,
        fileData: [],
        isFileSelected: false,
        originalFileData: [],
        updatedFailedEntries: {},
        triggerAPI: false,
      });
    }
  };

  const conditionToShowTable =
    selectedEdit === "Insite" || selectedEdit === "Multi";

  return (
    <div id="veh-settings-page-container">
      <CustomRadioItemGroup
        options={vehicleSettingsEditConfig}
        selectedOption={selectedEdit}
        setSelectedOption={setSelectedEdit}
        divClassName="custom-radio-item-container-linear"
        elementClassName="custom-radio-item-option-linear"
      />
      <div className="search-clear-button-container"></div>
      {selectedEdit === "CSV" && (
        <VinDecoderSettings
          processStep={processStep}
          setProcessStep={setProcessStep}
          vehicleSettingsData={vehicleSettingsData}
          setSelectedEdit={setSelectedEdit}
          validVehicleID={validVehicleID}
          successEditMsg={successEditMsg}
          setSuccessEditMsg={setSuccessEditMsg}
          vinFileUploadState={vinFileUploadState}
          setVinFileUploadState={setVinFileUploadState}
          vinDecoderImportState={vinDecoderImportState}
          setVinDecoderImportState={setVinDecoderImportState}
          yearValues={yearOptions1965Today}
          handlePostVehicleSettings={handlePostVehicleSettings}
          allVehiclesData={allVehiclesData}
          setAllVehiclesData={setAllVehiclesData}
        />
      )}
      {vehicleSettingsData?.loaded ? (
        vehicleSettingsData?.data?.length > 0 ? (
          <>
            <div className="settings-bulk-header-container">
              <div></div>
              {conditionToShowTable && (
                <Pagination
                  pageCount={false}
                  currentPage={vehicleSettingsDataPage}
                  onPageChange={setVehicleSettingsDataPage}
                  nextPage={vehicleSettingsData?.next_page}
                />
              )}
            </div>
            {conditionToShowTable && (
              <div className="vp-settings-table">
                <Table
                  tableData={vehicleSettingsData?.data}
                  config={vehicleSettingsDataConfig(
                    handleEditClick,
                    handleRouteVehicleProfile,
                    selectedEdit === "Insite"
                  )}
                  tableRowClassName="primary-table-row card-text-min"
                  sortable={true}
                  tableHeadClassName="primary-table-sort-header-group card-text-min"
                  multiSelect={false}
                />
                <VehicleSettingsEditModal
                  showModal={showModal}
                  handleModalClose={handleModalClose}
                  handlePostVehicleSettings={handlePostVehicleSettings}
                  disableInputs={disableInputs}
                  setDisableInputs={setDisableInputs}
                  handleReviewClick={handleReviewClick}
                  vehicleFuelTypeOptions={vehicleFuelTypeOptions}
                  yearValues={yearOptions1965Today}
                  disabledDecodeButton={disabledDecodeButton}
                  setDisabledDecodeButton={setDisabledDecodeButton}
                  singleVinDecoderProcessStep={singleVinDecoderProcessStep}
                  setSingleVinDecoderProcessStep={
                    setSingleVinDecoderProcessStep
                  }
                  handleEditFieldChange={handleEditFieldChange}
                  singleVehicleEditFields={singleVehicleEditFields}
                  setSingleVehicleEditFields={setSingleVehicleEditFields}
                />
              </div>
            )}
          </>
        ) : (
          <p>No vehicle settings data available.</p>
        )
      ) : (
        <Skeleton />
      )}
      <SnackBar
        message={successEditMsg?.text}
        visible={successEditMsg?.text?.length > 0}
        severity={successEditMsg?.msg}
      />
    </div>
  );
}
