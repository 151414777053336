import { createContext, useEffect, useState, useContext, useRef } from "react";
import DateContext from "./DateProvider";
import { getEmissionReductionTargetAPI } from "../api/Reduction/EmissionReductionTarget/EmissionReductionTargetAPI";
import { getDefaultEmissionReductionTargetAPI } from "../api/Reduction/EmissionReductionTarget/getDefaultEmissionReductionTargetAPI";
import { getEmissionsForecastingAPI } from "../api/Reduction/EmissionsForecastingAPI";
import { postSustainableSolutionsMarkerTableAPI } from "../api/Reduction/SolutionsMaker/SustainableSolutionsMarkerTableAPI";
import { emissionReductionTargetDataConfig } from "../constants/ReductionConfig";

// INITIAL DATA TO IMPORT
import {
  // isEmissionReductionProjectedYear,
  // isSelectedEmissionTargetPercentage,
  // isConstantEmissionReductionProjectedYear,
  isSustainableSolutionsNewEntry,
  isReductionTargetDefault,
} from "../constants/initialDataConfig";

// MOCK DATA TO IMPORT
import {
  mockEmissionReductionTargetSelectedYear,
  mockEmissionReductionTargetData,
  mockEmissionForecastingDataConfig,
  mockSolutionsMarkerData,
  mockLoginData,
} from "../constants/mockDataConfig";

const ReductionContext = createContext({});

// Provides access to token across whole application
export const ReductionProvider = ({ children }) => {
  // Const: Initialize Context - Application Dates
  const { applicationDates } = useContext(DateContext);

  // State: Initialize State - Full Page
  const [reductionIsLoading, setReductionIsLoading] = useState(true);

  // Emissions Reduction Target
  // State: Initialize State - Emissions Reduction Target
  const [emissionsReductionTargetData, setEmissionsReductionTargetData] =
    useState({ loaded: false, data: [] });

  // State: Initialize State - Default Emission Reduction Target
  const [reductionTargetDefault, setReductionTargetDefault] = useState(
    isReductionTargetDefault
  );

  // State: Initialize - State = Emission Reduction Target Projected Percentage
  const [
    selectedEmissionTargetPercentage,
    setSelectedEmissionTargetPercentage,
  ] = useState([]);

  // State: Initialize - State = Emission Reduction Target Projected Year
  const [emissionReductionProjectedYear, setEmissionReductionProjectedYear] =
    useState([]);

  // Emissions Forecasting
  // State: Initialize State - Emissions Forecasting
  const [emissionsForecastingData, setEmissionsForecastingData] = useState([]);

  // State: Initialize State - Sustainable Solutions Marker Data
  const [sustainableSolutionsMarkerData, setSustainableSolutionsMarkerData] =
    useState({ loaded: false, data: [] });

  const [
    sustainableSolutionsSelectedYear,
    setSustainableSolutionsSelectedYear,
  ] = useState([]); // Current date

  const [sustainableSolutionsNewEntry, setSustainableSolutionsNewEntry] =
    useState(isSustainableSolutionsNewEntry);

  // Const: Get Token & Make Sure Available For API Call
  const token = localStorage.getItem("greenToken");

  // UE: Get Reduction Page Data For the Whole Application (Only Runs Once)
  useEffect(
    () => {
      const hasValues =
        Object.values(applicationDates).filter((date) => date !== "").length >
        0;
      if (reductionIsLoading && hasValues && token !== mockLoginData?.token) {
        const promises = [
          getEmissionsForecastingAPI(setEmissionsForecastingData),
          postSustainableSolutionsMarkerTableAPI(
            setSustainableSolutionsMarkerData,
            new Date().toISOString().slice(0, 4)
          ),
          getDefaultEmissionReductionTargetAPI(
            setReductionTargetDefault,
            setEmissionReductionProjectedYear,
            setSelectedEmissionTargetPercentage
          ),
        ];
        Promise.all(promises).then(() => {
          // Set Loading Component to False
          setReductionIsLoading(false);
        });
        setSustainableSolutionsSelectedYear([
          {
            label: new Date().toISOString().slice(0, 4),
            value: new Date().toISOString().slice(0, 4),
          },
        ]);
      } else if (hasValues && token === mockLoginData?.token) {
        setEmissionsReductionTargetData({
          loaded: true,
          data: {
            ...emissionReductionTargetDataConfig,
            labels: mockEmissionReductionTargetData?.labels,
            datasets: [
              {
                ...emissionReductionTargetDataConfig.datasets[0],
                data: mockEmissionReductionTargetData?.actualData?.[
                  applicationDates?.emissionReductionTarget
                ],
              },
              {
                ...emissionReductionTargetDataConfig.datasets[1],
                data: mockEmissionReductionTargetData?.targetData,
              },
            ],
          },
        });
        setEmissionsForecastingData(mockEmissionForecastingDataConfig);
        setEmissionReductionProjectedYear(
          mockEmissionReductionTargetSelectedYear
        );
        setSustainableSolutionsMarkerData({
          loaded: true,
          data: mockSolutionsMarkerData,
        });
      }
    },
    // eslint-disable-next-line
    [applicationDates, token]
  );

  // Const: Get Previous Application Dates to See if Trigger API Call
  const prevApplicationDates = useRef(applicationDates);

  // Const: Get Previous Emission Reduction Selected Year to See if Trigger API Call
  const prevTargetSelectedYear = useRef(emissionReductionProjectedYear);

  // Const: Get Previous Emission Reduction % to See if Trigger API Call
  const prevTargetPercentage = useRef(selectedEmissionTargetPercentage);

  // Const: Get Previous Sustainable Solutions Selected Year to See if Trigger API Call
  const prevSustainableSolutionsSelectedYear = useRef(
    sustainableSolutionsSelectedYear
  );
  // UE: Get Emission Bar Plot Data For Application (Trigger API Call) When Any Input Changes
  useEffect(
    () => {
      const hasValues =
        Object.values(applicationDates).filter((date) => date !== "").length >
        0;
      const initialHasValues =
        hasValues &&
        token &&
        token !== mockLoginData?.token &&
        !reductionIsLoading;

      // Condition to run the API Call for Emission Reduction Target on Dropdown Change
      if (
        initialHasValues &&
        prevApplicationDates.current?.emissionReductionTarget?.length > 0 &&
        prevTargetPercentage.current?.length > 0 &&
        prevTargetSelectedYear.current?.length > 0 &&
        reductionTargetDefault?.loaded
      ) {
        if (
          prevApplicationDates?.current.emissionReductionTarget !==
            applicationDates.emissionReductionTarget ||
          prevTargetPercentage?.current !== selectedEmissionTargetPercentage ||
          prevTargetSelectedYear?.current !== emissionReductionProjectedYear
        ) {
          getEmissionReductionTargetAPI(
            setEmissionsReductionTargetData,
            applicationDates?.emissionReductionTarget,
            emissionReductionProjectedYear?.[0]?.value,
            selectedEmissionTargetPercentage,
            reductionTargetDefault?.base_year?.[0]?.value
          );
        }
      }
      // Condition to run the Initial API Call
      if (initialHasValues && !emissionsReductionTargetData?.loaded) {
        getEmissionReductionTargetAPI(
          setEmissionsReductionTargetData,
          applicationDates?.emissionReductionTarget,
          emissionReductionProjectedYear?.[0]?.value,
          selectedEmissionTargetPercentage,
          reductionTargetDefault?.base_year?.[0]?.value
        );
      }
      prevApplicationDates.current = applicationDates;
      prevTargetSelectedYear.current = emissionReductionProjectedYear;
      prevTargetPercentage.current = selectedEmissionTargetPercentage;
    },
    // eslint-disable-next-line
    [
      applicationDates,
      selectedEmissionTargetPercentage,
      emissionReductionProjectedYear,
      token,
      reductionIsLoading,
    ]
  );

  useEffect(() => {
    if (
      !reductionIsLoading &&
      prevSustainableSolutionsSelectedYear?.current?.length > 0 &&
      token &&
      token !== mockLoginData?.token
    ) {
      if (
        sustainableSolutionsSelectedYear !==
        prevSustainableSolutionsSelectedYear?.current
      ) {
        postSustainableSolutionsMarkerTableAPI(
          setSustainableSolutionsMarkerData,
          sustainableSolutionsSelectedYear?.[0]?.value
        );
      }
    }
    prevSustainableSolutionsSelectedYear.current =
      sustainableSolutionsSelectedYear;
  }, [reductionIsLoading, sustainableSolutionsSelectedYear, token]);

  const value = {
    emissionsReductionTargetData,
    emissionsForecastingData,
    sustainableSolutionsMarkerData,
    emissionReductionProjectedYear,
    setEmissionReductionProjectedYear,
    selectedEmissionTargetPercentage,
    setSelectedEmissionTargetPercentage,
    sustainableSolutionsSelectedYear,
    setSustainableSolutionsSelectedYear,
    sustainableSolutionsNewEntry,
    setSustainableSolutionsNewEntry,
    setSustainableSolutionsMarkerData,
    setReductionTargetDefault,
    reductionTargetDefault,
    reductionIsLoading,
  };

  return (
    <ReductionContext.Provider value={value}>
      {children}
    </ReductionContext.Provider>
  );
};

export default ReductionContext;
