// Functionality:  Demo Onboard Check for Signups Page
import { useState } from "react";
import CheckBoxGroup from "../../components/CheckBox";
import { agreeDemoTermsCheckboxOptions } from "../../constants/AuthConfig";
import { putCreatePassword } from "../../api/Auth/CreatePasswordAPI";
import useNavHook from "../../hooks/use-NavHook";
import { hubspotOnboardDemoForm } from "../../constants/AuthConfig";
import HubspotFormLayout from "../../layouts/HubspotFormLayout";
import DemoTermsModal from "./DemoTermsModal";
import Modal from "../../components/Modal";

export default function DemoOnboardCheck({
  demoSetupState,
  demoAgreeTerms,
  setDemoAgreeTerms,
  setErrMsg,
}) {
  // Modal State
  const [showModal, setShowModal] = useState(false);

  // Const: Length = 4 because there are 4 signup fields
  const isSignupFilledOut =
    Object.values(demoSetupState)?.filter((item) => item !== "")?.length === 4;

  // Const: Passwords Equal
  const isPasswordEqual =
    demoSetupState?.password === demoSetupState?.confirm_password;

  // Const: Signup Agree Terms Checked
  const isdemoAgreeTermsChecked = demoAgreeTerms?.length > 0;

  // Const: Navigation Properties to be used in the SignupSubmit component
  const { navigate, navTo } = useNavHook("/");

  // Function: Handle Submit of Login Form - Make API Call
  const handleSubmit = () => {
    putCreatePassword(
      { email: demoSetupState?.email, password: demoSetupState?.password },
      navigate,
      navTo,
      setErrMsg
    );
  };

  // Component: Submit Button
  const submitSignupButton = isSignupFilledOut && isPasswordEqual && (
    <HubspotFormLayout
      src={hubspotOnboardDemoForm?.src}
      region={hubspotOnboardDemoForm?.region}
      portalId={hubspotOnboardDemoForm?.portalId}
      formId={hubspotOnboardDemoForm?.formId}
      hubspotFormMiddleClassName={""}
      backgroundContainerClassName={""}
      mainContainerClassName={""}
      handleSubmit={handleSubmit}
    />
  );

  // Function: Show Modal
  const handleClick = () => {
    setShowModal(true);
  };
  // Function: Hide Modal
  const handleModalClose = () => {
    setShowModal(false);
  };

  // Component: Terms & Conditions & Privacy Policy
  const agreeCondition = (
    <CheckBoxGroup
      options={agreeDemoTermsCheckboxOptions(handleClick)}
      selectedOptions={demoAgreeTerms}
      setSelectedOptions={setDemoAgreeTerms}
      elementClassName="signup-checkbox-item-option"
    />
  );

  const modalCardBody = (
    <Modal onClose={handleModalClose} children={<DemoTermsModal />} />
  );

  return (
    <div>
      {agreeCondition}
      <div className="signup-create-account-container">
        {isdemoAgreeTermsChecked && submitSignupButton}
      </div>
      {showModal && modalCardBody}
    </div>
  );
}
