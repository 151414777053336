// Functionality: This file exports the Scope2EditAddress component which is used in the Scope2Manager component. The component is a card including the form to edit an existing Scope 2 emission address
import { useEffect } from "react";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import SearchBar from "../../../../components/SearchBar";

export default function Scope2EditAddress({
  selectedLocationEditDelete,
  setSelectedLocationEditDelete,
  handleEditDeleteAddress,
  setEditAddressFields,
  editAddressFields,
  stateOptionsConfig,
  selectedStateScopeEdit,
  setSelectedStateScopeEdit,
  isScope2ManagerAddressManager,
}) {
  // Const: Submit Button Condition Handler Address - Only when all fields are filled out
  const submitConditionHandlerAddress =
    Object?.values(editAddressFields)?.filter((item) => item?.length > 0)
      ?.length > 4;

  // UE: Set State to Scope 2 Manager New Address
  useEffect(() => {
    if (selectedStateScopeEdit?.length > 0) {
      setEditAddressFields({
        ...editAddressFields,
        state: selectedStateScopeEdit?.[0]?.value,
      });
    }
  }, [selectedStateScopeEdit]);

  // Function: Handle Scope 2 Manager Edit Address Entry
  const handleNewAddressEntry = (event, type) => {
    setEditAddressFields({
      ...editAddressFields,
      [type]: event.target.value,
    });
  };

  // Const: Input New Scope 2 Address Container Group
  const addressFields = (
    <div className="scope2-manage-address-group font-color-gray font-small-medium">
      <div className="scope2-entry-form-group">
        <div className="scope2-manage-input-group-address">
          <p>Site Name:</p>
          <Input
            className="input-element scope2-manage-input-element"
            onChange={(e) => handleNewAddressEntry(e, "siteName")}
            value={editAddressFields?.siteName}
          />
        </div>
        <div className="scope2-manage-input-group-address">
          <p>Street Address:</p>
          <Input
            className="input-element scope2-manage-input-element"
            onChange={(e) => handleNewAddressEntry(e, "streetAddress")}
            value={editAddressFields?.streetAddress}
          />
        </div>
        <div className="flex-row-space-between">
          <div className="scope2-manage-input-group-address">
            <p>City:</p>
            <Input
              className="input-element scope2-manage-input-element"
              onChange={(e) => handleNewAddressEntry(e, "city")}
              value={editAddressFields?.city}
            />
          </div>
          <div className="scope2-manage-input-group-address">
            <p>State:</p>
            <SearchBar
              options={stateOptionsConfig}
              selectedOptions={selectedStateScopeEdit}
              setSelectedOptions={setSelectedStateScopeEdit}
              isMultiSelect={false}
              placeholder="Select State"
              divClassName="search-bar-container-border font-color-gray"
            />
          </div>
          <div className="scope2-manage-input-group-address">
            <p>Zip Code:</p>
            <Input
              className="input-element scope2-manage-input-element"
              onChange={(e) => handleNewAddressEntry(e, "zipCode")}
              value={editAddressFields?.zipCode}
            />
          </div>
        </div>
      </div>
      <div className="scope2-submit-entry-button flex-end-button-group">
        <Button
          buttonName="Cancel"
          className="secondary-blue-button"
          onClick={() =>
            setSelectedLocationEditDelete(isScope2ManagerAddressManager)
          }
        />
        <Button
          buttonName="Edit Location"
          onClick={() => handleEditDeleteAddress("edit")}
          disabled={!submitConditionHandlerAddress}
          className="primary-blue-button"
        />
      </div>
    </div>
  );

  return (
    <>
      <div className="scope2-edit-container">
        <div>Editing the following site location:</div>
        <div className="scope2-delete-container-location">
          {selectedLocationEditDelete?.selectedLocationLabel}
        </div>
        <div>
          All scope 2 submissions associated with this location will be
          reallocated to the updated location.
        </div>
      </div>
      {addressFields}
    </>
  );
}
