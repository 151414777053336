// Functionality:  Check Input Fields, Submit Button for Forgot Password Page, API Call - Send Email
import PropTypes from "prop-types";
import { useState } from "react";
import Button from "../../components/Button";
import { postForgotPasswordEmail } from "../../api/Auth/ForgotPasswordEmailAPI";

export default function ForgotPasswordSubmitEmail({
  forgotPWState,
  setShowForgotPW,
  showForgotPW,
}) {
  // State: Forgot PW Button Name
  const [forgotPWButtonName, setForgotPWButtonName] = useState("Send Email");
  const [errMsg, setErrMsg] = useState("");

  // Function: Handle Submit of Forgot PW  Form - Change Hidden Fields
  const handleSubmit = (e) => {
    e.preventDefault();
    postForgotPasswordEmail(
      forgotPWState,
      setErrMsg,
      setShowForgotPW,
      setForgotPWButtonName
    );
  };

  // Const - Check if Forgot PW Fields are filled
  const forgotPWRequirements =
    forgotPWState?.email?.length > 0 && forgotPWState?.email?.includes("@");

  // Component: Forgot PW  Submit Button
  const forgotPWSubmitButton = (
    <Button
      onClick={handleSubmit}
      buttonName={forgotPWButtonName}
      disabled={!forgotPWRequirements}
      className={"font-medium"}
    />
  );

  return (
    <>
      <p className={"login-err-msg"}>{errMsg}</p>
      <div
        className={
          showForgotPW
            ? "forgotpw-button-container"
            : "forgotpw-button-container-center"
        }
      >
        {forgotPWSubmitButton}
      </div>
    </>
  );
}

// Props
const forgotPasswordSubmitEmailProps = {
  forgotPWState: PropTypes.object, // State of the forgot password fields
  setShowForgotPW: PropTypes.func, // Function to set the state of the forgot password form
  showForgotPW: PropTypes.bool, // Boolean to show the forgot password form
};

ForgotPasswordSubmitEmail.propTypes = forgotPasswordSubmitEmailProps;
