import { useContext, useState } from "react";
import SignupContext from "../context/SignupProvider";
import SignupHeader from "./SignupPage/SignupHeader";
import SignupFields from "./SignupPage/SignupFields";
import SignupSubmit from "./SignupPage/SignupSubmit";

export default function SignupPage() {
  // Initialize Context
  const {
    signupState,
    setSignupState,
    // developerServicesCheckbox,
    // setDeveloperServicesCheckbox,
    signupAgreeTerms,
    setSignupAgreeTerms,
  } = useContext(SignupContext);

  // Initialize State
  const [errMsg, setErrMsg] = useState(""); // State of the error message

  return (
    <>
      <div className="colored-bar-background-container"></div>
      <div className="colored-bar-main-container-content">
        <div id="signup-container">
          <SignupHeader />
          <div className="signup-content-container">
            <p className="signup-content-container-title">
              Create your account
            </p>
            <p className="signup-content-container-subtitle">
              Create an account to view your carbon accounting platform
            </p>
            <SignupFields
              signupState={signupState}
              setSignupState={setSignupState}
              setErrMsg={setErrMsg}
            />
            {/* For the Future to Subscribe to updates */}
            {/* <CheckBoxGroup
            options={signupCheckboxOptions}
            selectedOptions={developerServicesCheckbox}
            setSelectedOptions={setDeveloperServicesCheckbox}
          /> */}
            <SignupSubmit
              signupState={signupState}
              signupAgreeTerms={signupAgreeTerms}
              setSignupAgreeTerms={setSignupAgreeTerms}
              setErrMsg={setErrMsg}
            />
            <p className="signup-password-no-match">{errMsg}</p>
          </div>
        </div>
      </div>
    </>
  );
}
