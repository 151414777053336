// Functionality: LoadingSpinner component for the application, used for displaying that there is an action being executed and something is loading.

import PropTypes from "prop-types";
import { RotatingLines } from "react-loader-spinner";

export default function LoadingSpinner({
  isLoading,
  spinnerContainerClassName = "spinner-container",
  strokeColor = "grey",
  strokeWidth = "5",
  animationDuration = ".75",
}) {
  return (
    <div className={spinnerContainerClassName}>
      <RotatingLines
        strokeColor={strokeColor}
        strokeWidth={strokeWidth}
        animationDuration={animationDuration}
        visible={isLoading}
      />
    </div>
  );
}

// Props
const loadingSpinnerPropTypes = {
  isLoading: PropTypes.bool, // Tells us whether the spinner is loading or not
};
LoadingSpinner.propTypes = loadingSpinnerPropTypes;
