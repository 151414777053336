// Functionality: This file exports the Scope2PreviousSubmissions component which is used in the Scope2Manager component. The component is a card including the previous submissions for Scope 2 emissions
import { useState, useEffect } from "react";
import { scope2ManagerTableConfig } from "../../../constants/EmissionsConfig";
import { isScope2TableFilters } from "../../../constants/initialDataConfig";
import Scope2PreviousSubmissionsFilter from "./PreviousSubmissions/Scope2PreviousSubmissionsFilter";
import Scope2PreviousSubmissionsTable from "./PreviousSubmissions/Scope2PreviousSubmissionsTable.js";
import Scope2PreviousSubmissionsModal from "./PreviousSubmissions/Scope2PreviousSubmissionsModals.js";

// TODO: ADD DATE FILTER
// TODO: CLEAR & UPDATE TABLE ON SUBMIT FOR DATE

export default function Scope2PreviousSubmissions({
  scope2ManagerEntries,
  scope2ManagerAddressOptions,
  ...props
}) {
  // State: Copy of Scope 2 Manager Entries
  const [scope2ManagerEntriesTableData, setScope2ManagerEntriesTableData] =
    useState({ data: [], loaded: false });

  // State: Scope 2 Filter
  const [scope2TableFilters, setScope2TableFilters] =
    useState(isScope2TableFilters);

  const [scope2LocationFilters, setScope2LocationFilters] = useState([]); // State: Scope 2 Location Filters Options

  const [
    scope2ManagerElectricityPurchasedFilters,
    setScope2ManagerElectricityPurchasedFilters,
  ] = useState({ minMax: [], data: [] }); // State: Scope 2 Manager Electricity Purchased Filters

  // TODO: ADD THESE FILTERS
  // const [scope2ManagerDateFilters, setScope2ManagerDateFilters] = useState({
  //   min: "",
  //   max: "",
  // });
  // State: Scope 2 Manager Date Filters
  const [scope2ManagerInvoiceIdFilters, setScope2ManagerInvoiceIdFilters] =
    useState([]); // State: Scope 2 Manager Invoice ID Filters

  // Function: Reformat Data for Scope 2 Manager Entries Table
  function reformatData(data) {
    const reformattedData = [];

    for (const location in data) {
      const dates = data?.[location]?.dates;
      const values = data?.[location]?.values;
      const invoiceIds = data?.[location]?.invoices;
      const entryIds = data?.[location]?.entry_id;

      for (let i = 0; i < dates?.length; i++) {
        const [year, month, day] = dates[i]?.split("-");
        const newDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1);
        const fullMonth = newDate.toLocaleString("en-US", { month: "long" });
        reformattedData.push({
          dates: `${fullMonth} ${day} ${year}`,
          electricity_purchased_kwh: values[i],
          location: location,
          invoice_id: invoiceIds[i],
          entry_id: entryIds[i],
        });
      }
    }

    return reformattedData;
  }

  // Use Effect: Update Scope 2 Manager Entries Table Data
  useEffect(() => {
    if (
      scope2ManagerEntries?.loaded &&
      Object.entries(scope2ManagerEntries?.data)?.length > 0
    ) {
      setScope2ManagerEntriesTableData({
        data: reformatData(scope2ManagerEntries?.data),
        loaded: true,
      });
    }
  }, [scope2ManagerEntries]);

  // Use Effect: Update Scope 2 Manager Entries Table Data
  useEffect(() => {
    if (
      scope2ManagerEntries?.loaded &&
      Object.entries(scope2ManagerEntries?.data)?.length > 0
    ) {
      const locations = Object.keys(scope2ManagerEntries?.data)?.map((item) => {
        return {
          value: item,
          label: item?.substring(0, item?.lastIndexOf("-")),
        };
      });
      const electricityPurchased = Object.values(
        scope2ManagerEntries?.data
      )?.map((item) => item?.values);

      setScope2LocationFilters(locations);
      setScope2ManagerElectricityPurchasedFilters({
        minMax: [
          Math.min(...electricityPurchased.flat()),
          Math.max(...electricityPurchased.flat()),
        ],
        data: [
          Math.min(...electricityPurchased.flat()),
          Math.max(...electricityPurchased.flat()),
        ],
      });
      // const dates = Object.values(scope2ManagerEntries?.data)?.map(
      //   (item) => item?.date
      // );
      // setScope2ManagerDateFilters({
      //   min: Math.min(...dates.flat()),
      //   max: Math.max(...dates.flat()),
      // });
      const invoiceId = Object.values(scope2ManagerEntries?.data)?.flatMap(
        (item) => item?.invoices?.map((inv) => ({ value: inv, label: inv }))
      );
      setScope2ManagerInvoiceIdFilters(invoiceId);
    }
  }, [scope2ManagerEntries?.loaded]);

  return (
    <>
      <Scope2PreviousSubmissionsFilter
        setScope2ManagerEntriesTableData={setScope2ManagerEntriesTableData}
        originalScope2ManagerEntries={reformatData(scope2ManagerEntries?.data)}
        setScope2TableFilters={setScope2TableFilters}
        scope2TableFilters={scope2TableFilters}
        scope2LocationFilters={scope2LocationFilters}
        scope2ManagerElectricityPurchasedFilters={
          scope2ManagerElectricityPurchasedFilters
        }
        setScope2ManagerElectricityPurchasedFilters={
          setScope2ManagerElectricityPurchasedFilters
        }
        scope2ManagerInvoiceIdFilters={scope2ManagerInvoiceIdFilters}
        isScope2TableFilters={isScope2TableFilters}
      />
      <div className="scope2-table-container">
        <Scope2PreviousSubmissionsModal
          scope2ManagerAddressOptions={scope2ManagerAddressOptions}
          handleInputChange={props.handleInputChange}
          handleDropdownChange={props.handleDropdownChange}
          handleSelectedDate={props.handleSelectedDate}
          resetScope2ManagerFields={props.resetScope2ManagerFields}
          submitNewScope2Emission={props.submitNewScope2Emission}
          submitConditionHandler={props.submitConditionHandler}
          scope2ManagerNewAddress={props.scope2ManagerNewAddress}
          handleNewAddressEntry={props.handleNewAddressEntry}
          submitNewScope2Address={props.submitNewScope2Address}
          submitConditionHandlerAddress={props.submitConditionHandlerAddress}
          nonEmptyStateCondition={props.nonEmptyStateCondition}
          stateOptionsConfig={props.stateOptionsConfig}
          selectedStateScope={props.selectedStateScope}
          setSelectedStateScope={props.setSelectedStateScope}
          scope2ManagerNewEntry={props.scope2ManagerNewEntry}
          setSuccessMsg={props.setSuccessMsg}
        />
        <Scope2PreviousSubmissionsTable
          scope2ManagerEntriesTableData={scope2ManagerEntriesTableData}
          scope2ManagerTableConfig={scope2ManagerTableConfig}
          scope2ManagerAddressOptions={scope2ManagerAddressOptions}
          setSuccessMsg={props.setSuccessMsg}
        />
      </div>
    </>
  );
}
