// Functionality: This file exports the IdleTimeEmissionsCard component which is used in the Vehicle Performance Page component.
import { useContext } from "react";
import DateContext from "../../../context/DateProvider.js";
import VehiclePerformanceContext from "../../../context/VehiclePerformanceProvider.js";
import LineChart from "../../../visualizations/LineChart.js";
import useSub from "../../../hooks/use-Sub.js";
import Skeleton from "react-loading-skeleton";
import SwitchCarousel from "../../../components/SwitchCarousel.js";
import { totalIdleTimeEmissionsTile } from "../../../constants/TileConfig.js";
import { dailyMonthlyRadioItemOptions } from "../../../constants/AuthConfig.js";
import RadioItemGroup from "../../../components/RadioItemGroup.js";

export default function IdleTimeEmissionsCard({ ...props }) {
  // State: Selected Radio Item - Monthly v Daily
  const { selectedChartRadioItemType, setSelectedChartRadioItemType } =
    useContext(DateContext);

  // Const: Initialize Context
  const { idleTimeEmissionsData } = useContext(VehiclePerformanceContext);

  // Const: Super Scripted label for MT CO2e
  const superScriptedLabel = useSub("MT CO2");

  // Const: Label Application Dates
  const applicationDatesLabel = "idleChartEmissions";

  // Const: Show Date Carousel
  const showDateCarousel =
    selectedChartRadioItemType?.idle_emissions === "Daily";

  // Const: Monthly Disabled
  const monthlyDisabled =
    props.dateValues?.length > 1 ? [] : dailyMonthlyRadioItemOptions[1].value;

  const handleChangeRadioDate = (e) => {
    setSelectedChartRadioItemType((prevState) => ({
      ...prevState,
      idle_emissions: e?.target?.value,
    }));
  };

  // Const: Application Dates Switch Carousel
  const idleChartCardSwitchCarousel = showDateCarousel && (
    <div className="group-switch-carousel-no-margin">
      <SwitchCarousel
        values={props.dateValues}
        applicationDates={props.applicationDates}
        setApplicationDates={props.setApplicationDates}
        applicationDatesLabel={applicationDatesLabel}
        fillArrowColor={"#66C743"}
        switchCarouselValueClassName="group-switch-carousel-value"
      />
    </div>
  );

  // Const: Daily v Monthly Radio Item Group
  const dailyMonthlyRadioItemGroup = (
    <RadioItemGroup
      options={dailyMonthlyRadioItemOptions}
      value={selectedChartRadioItemType?.idle_emissions}
      onChange={(e) => handleChangeRadioDate(e)}
      disabledOptions={monthlyDisabled}
    />
  );

  return (
    <>
      {!idleTimeEmissionsData?.loaded ? (
        <Skeleton height={"350px"} />
      ) : (
        <div className="chart-card-container-group">
          <div className="chart-card-title-group">
            <div>
              <div className="card-title">
                Idle-time Emissions
                {totalIdleTimeEmissionsTile?.blurb}
              </div>
              <div className="card-subtitle">{superScriptedLabel}</div>
            </div>
            <div>
              {dailyMonthlyRadioItemGroup} {idleChartCardSwitchCarousel}
            </div>
          </div>
          <div className="vp-page-chart-container">
            <LineChart
              datasets={idleTimeEmissionsData?.data}
              lineBreak={idleTimeEmissionsData?.lineBreak}
              maxValue={idleTimeEmissionsData?.maxValue}
              annotationValue={idleTimeEmissionsData?.annotationValue}
            />
          </div>
        </div>
      )}
    </>
  );
}
