// Functionality: Handles the API call to update the password when the user is logged in
import axios from "../loadBalancerAxios";
import { UPDATE_PASSWORD_URL } from "../paths";
import { mockLoginData } from "../../constants/mockDataConfig";

export const putUpdatePassword = async (
  resetPWState,
  navigate,
  navTo,
  setErrMsg
) => {
  // Check if the user logging in is the mock user
  if (
    resetPWState.email === mockLoginData?.email &&
    resetPWState.password === mockLoginData?.password
  ) {
  } else {
    // Otherwise, normally log in the user
    await axios
      .put(UPDATE_PASSWORD_URL, {
        new_password: resetPWState?.password,
        "Content-Type": "application/json",
      })
      .then((response) => {
        // API Response - Set Err Msg if Incorrect Fields, Else Reroute to Log in
        let signupResponse = response?.data;

        // Set Error Messgae
        if (signupResponse?.success !== "password successfully updated.") {
          setErrMsg("Unable to update password.");
        } else {
          // Clear Error Messgae
          setErrMsg("");
          navigate(navTo, { replace: true });
        }
      })
      .catch((err) => {
        // Maybe show a snackbar here if the user is unable to update their password if they already have their account setup
        setErrMsg("Unable to update password.");
      });
  }
};
