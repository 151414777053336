// Functionality: React hook to generate a subscript to display a chemical formula in the application

export default function useSub(element) {
  const tokenize = (formula) => {
    const replaced = formula.replace(/CO2/g, "CO<sub>2</sub>");
    return <span dangerouslySetInnerHTML={{ __html: replaced }} />;
  };

  return tokenize(element);
}
