// Functionality: API Call for New Report Creation
import axios from "../loadBalancerAxios";
import { REPORTS_URL } from "../paths";

export const postNewReportAPI = async (data, setIsLoading, setMsg) => {
  await axios
    .post(REPORTS_URL, data)
    .then((response) => {
      const linkUrl = response.data.download_url;
      const anchor = document.createElement("a");
      anchor.setAttribute("href", linkUrl);
      anchor.setAttribute("download", `$report.pdf`); // Add the download attribute to force a download
      anchor.setAttribute("target", "_self"); // Set the target attribute to "_self" to download the file in the current window
      anchor.style.display = "none"; // Hide the anchor element from the page
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      setMsg({ text: "Successful report generation.", msg: "success" });
    })
    .catch((err) => {
      setMsg({ text: "Failed report generation.", msg: "warning" });
    })
    .finally(() => {
      setIsLoading(false);
    });
};
