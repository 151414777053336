// Functionality: Component to display when there are failed records in the initial upload.
import { useState } from "react";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoMdCheckmark } from "react-icons/io";
import FailedInitialRecordsModalVerify from "./FailedInitialRecordsModalVerify";

export default function FailedInitialRecordsTable({
  updatedDecoderState,
  setUpdatedDecoderState,
  determineFailedVinDecoderEntries,
  failedVinDecoderReasonMapping,
  discardAllRecords,
  setDiscardAllRecords,
  setArrayState,
  validVehicleID,
  dupDiscard,
  setDupDiscard,
  vinDupDiscard,
  setVinDupDiscard,
}) {
  const [showModal, setShowModal] = useState(false);

  // Function: Show Modal
  const handleClick = () => {
    setShowModal(true);
  };
  // Function: Hide Modal
  const handleModalClose = () => {
    setShowModal(false);
  };

  // Function: Determine Vehicle ID CSS Class
  const determineVehicleIDCSSClass = (item) => {
    if (item?.vehicle_id === "" || item?.discard) {
      return "strikethrough-text flex-row-space-between";
    } else {
      return "";
    }
  };

  //   TODO: HANLDE DUPLICATE ENTRY
  // Function: Handle Input Change
  const handleInputChange = (e, item) => {
    // Get the input
    const vinInput = e?.target?.value;
    const newVinInput = updatedDecoderState?.data?.map((val) => {
      if (val?.uniqueId === item?.uniqueId) {
        return {
          ...val,
          vin: vinInput,
        };
      }
      return val;
    });

    const newData = determineFailedVinDecoderEntries(
      validVehicleID,
      newVinInput,
      failedVinDecoderReasonMapping,
      true,
      dupDiscard,
      vinDupDiscard
    );
    setUpdatedDecoderState(newData);
  };

  // Function: Handle Input Change
  const handleDiscard = (item, isDup = false, type = "vehicle_id") => {
    const newDiscardData = updatedDecoderState?.data?.map((val) => {
      if (val.uniqueId === item.uniqueId) {
        return {
          ...val,
          discard: !val?.discard,
        };
      }
      return val;
    });

    // TODO: FIGURE OUT ISSUE WITH DUPLICATE VIN ENTRIES - CANNOT MATCH ON UNIQUE ID ON VEHICLE ID
    const dupVehicleIds = Object.keys(dupDiscard);
    let dupDiscardCopy = { ...dupDiscard };
    const dupVins = Object.keys(vinDupDiscard);
    let vinDupDiscardCopy = { ...vinDupDiscard };

    // Handle Duplicate Entries Discard, if the entry is a duplicate & discarded, clear the other duplicate entry's duplicate message
    if (type === "vehicle_id") {
      if (
        isDup &&
        !item?.discard &&
        !dupVehicleIds?.includes(item?.vehicle_id)
      ) {
        const updatedDupEntry = [
          ...(dupDiscard?.[item?.vehicle_id] || []),
          item?.uniqueId,
        ];
        setDupDiscard({
          ...dupDiscard,
          [item?.vehicle_id]: updatedDupEntry,
        });
        dupDiscardCopy = {
          ...dupDiscard,
          [item?.vehicle_id]: updatedDupEntry,
        };
      } else if (dupVehicleIds?.includes(item?.vehicle_id)) {
        if (dupDiscard?.[item?.vehicle_id].includes(item?.uniqueId)) {
          const updatedDupEntry = dupDiscard?.[item?.vehicle_id]?.filter(
            (val) => val !== item?.uniqueId
          );

          const valueOtherDup = newDiscardData
            ?.filter((val) => {
              return val?.vehicle_id === item?.vehicle_id;
            })
            ?.filter((id) => {
              return id?.uniqueId !== item?.uniqueId;
            })?.[0];

          // EQUALS 0 & NO DISCARD
          if (updatedDupEntry?.length === 0 && !valueOtherDup?.discard) {
            const newDupState = { ...dupDiscard };
            delete newDupState?.[item?.vehicle_id];
            setDupDiscard(newDupState);
            dupDiscardCopy = newDupState;
          }
          // EQUALS 0 & DISCARD
          else if (updatedDupEntry?.length === 0 && valueOtherDup?.discard) {
            setDupDiscard({
              ...dupDiscard,
              [item?.vehicle_id]: [valueOtherDup?.uniqueId],
            });
            dupDiscardCopy = {
              ...dupDiscard,
              [item?.vehicle_id]: [valueOtherDup?.uniqueId],
            };
          } else {
            setDupDiscard({
              ...dupDiscard,
              [item?.vehicle_id]: updatedDupEntry,
            });
            dupDiscardCopy = {
              ...dupDiscard,
              [item?.vehicle_id]: updatedDupEntry,
            };
          }
        }
      }
    } else if (type === "vin") {
      if (isDup && !item?.discard && !dupVins?.includes(item?.vin)) {
        const updatedDupEntry = [
          ...(vinDupDiscard?.[item?.vin] || []),
          item?.uniqueId,
        ];
        setVinDupDiscard({
          ...vinDupDiscard,
          [item?.vin]: updatedDupEntry,
        });
        vinDupDiscardCopy = {
          ...vinDupDiscard,
          [item?.vin]: updatedDupEntry,
        };
      } else if (dupVins?.includes(item?.vin)) {
        if (vinDupDiscard?.[item?.vin].includes(item?.uniqueId)) {
          const updatedDupEntry = vinDupDiscard?.[item?.vin]?.filter(
            (val) => val !== item?.uniqueId
          );

          const valueOtherDup = newDiscardData
            ?.filter((val) => {
              return val?.vin === item?.vin;
            })
            ?.filter((id) => {
              return id?.uniqueId !== item?.uniqueId;
            })?.[0];

          // EQUALS 0 & NO DISCARD
          if (updatedDupEntry?.length === 0 && !valueOtherDup?.discard) {
            const newDupState = { ...vinDupDiscard };
            delete newDupState?.[item?.vin];
            setVinDupDiscard(newDupState);
            vinDupDiscardCopy = newDupState;
          }
          // EQUALS 0 & DISCARD
          else if (updatedDupEntry?.length === 0 && valueOtherDup?.discard) {
            setVinDupDiscard({
              ...vinDupDiscard,
              [item?.vin]: [valueOtherDup?.uniqueId],
            });
            vinDupDiscardCopy = {
              ...vinDupDiscard,
              [item?.vin]: [valueOtherDup?.uniqueId],
            };
          } else {
            setVinDupDiscard({
              ...vinDupDiscard,
              [item?.vin]: updatedDupEntry,
            });
            vinDupDiscardCopy = {
              ...vinDupDiscard,
              [item?.vin]: updatedDupEntry,
            };
          }
        }
      }
    }
    const newData = determineFailedVinDecoderEntries(
      validVehicleID,
      newDiscardData,
      failedVinDecoderReasonMapping,
      true,
      dupDiscardCopy
    );
    setUpdatedDecoderState(newData);
  };

  const handleDiscardAllRecords = () => {
    const newDiscardData = updatedDecoderState?.data?.map((val) => {
      return {
        ...val,
        discard: !discardAllRecords,
      };
    });

    const newData = determineFailedVinDecoderEntries(
      validVehicleID,
      newDiscardData,
      failedVinDecoderReasonMapping,
      true,
      {},
      {}
    );

    setUpdatedDecoderState(newData);
    setDiscardAllRecords(!discardAllRecords);
    setDupDiscard({});
    setVinDupDiscard({});
  };

  // Function: Determine if All Records Have Been Handled
  const handleFailedRecordsDisabled = (data) => {
    // Filter Out Discarded Entries
    const filterDiscardEntries = data?.filter((item) => {
      return !item?.discard;
    });

    // Run Function to Determine Failed Records
    const failedRecords = determineFailedVinDecoderEntries(
      validVehicleID,
      filterDiscardEntries,
      failedVinDecoderReasonMapping
    );

    return failedRecords?.failed_length !== 0;
  };

  const determineItemDisabled = (item) => {
    const invalidExplanations = [
      "Automatically discarded - Invalid Vehicle ID.",
      "Automatically discarded - Null Vehicle ID.",
    ];

    return invalidExplanations.some((explanation) =>
      item?.explanation?.includes(explanation)
    );
  };

  // Const: Failed Records Table Header
  const failedRecordsTableHeader = (
    <div className="vin-decoder-failed-entries-container-header-group">
      <div className="vin-decoder-failed-entries-table-group">
        <div>Vehicle ID</div>
        <div>VIN</div>
        <div>Explanation</div>
        <div>Discard</div>
      </div>
    </div>
  );
  const failedRecordsTable = (
    <div className="vin-decoder-failed-entries-container">
      {failedRecordsTableHeader}
      <div className="vin-decoder-container-table-group">
        {updatedDecoderState?.data?.map((item, index) => {
          return (
            <div
              className={`vin-decoder-failed-entries-table-group${
                index % 2 === 0 ? "-white" : ""
              }`}
              key={item?.uniqueId}
            >
              <div className={determineVehicleIDCSSClass(item)}>
                {item?.vehicle_id?.length > 0 ? item?.vehicle_id : "N/A"}
              </div>
              <Input
                value={item?.vin}
                onChange={(e) => {
                  handleInputChange(e, item);
                }}
                placeholder="Enter VIN"
                disabled={item?.discard}
              />
              <div>
                {item?.explanation?.length > 0 ? (
                  item?.explanation?.map((exp, index) => {
                    return <div key={`${item?.uniqueId}${index}`}>{exp}</div>;
                  })
                ) : (
                  <IoMdCheckmark />
                )}
              </div>
              <FaRegTrashCan
                className={`vin-decoder-svg${
                  determineItemDisabled(item)
                    ? "-disabled"
                    : item?.discard
                    ? "-discard"
                    : ""
                }`}
                onClick={() => {
                  if (item?.vehicle_id === "") {
                    return;
                  }
                  // TODO: NEED TO HANDLE THE CASE OF DUPLICATE ENTRIES, WHEN ONE IS DELETED, ACCEPT THE OTHER
                  else if (
                    item?.explanation?.includes("Duplicate Vehicle ID.")
                  ) {
                    handleDiscard(item, true, "vehicle_id");
                  } else if (item?.explanation?.includes("Duplicate VIN.")) {
                    handleDiscard(item, true, "vin");
                  } else {
                    handleDiscard(item);
                  }
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="flex-end-button-group vin-decoder-failed-entries-container-footer-group">
        <Button
          buttonName={`${!discardAllRecords ? "Discard All" : "Include All"}`}
          className="secondary-blue-button"
          onClick={() => handleDiscardAllRecords()}
        />
        <FailedInitialRecordsModalVerify
          handleFailedRecordsDisabled={handleFailedRecordsDisabled}
          updatedDecoderState={updatedDecoderState}
          setArrayState={setArrayState}
          handleModalClose={handleModalClose}
          showModal={showModal}
          setShowModal={setShowModal}
        />
        <Button
          buttonName="Update Records"
          className="primary-blue-button"
          disabled={handleFailedRecordsDisabled(updatedDecoderState?.data)}
          onClick={handleClick}
        />
      </div>
    </div>
  );
  return <>{failedRecordsTable}</>;
}
