// Functionality:  Signup Header for Signups Page
import logo from "../../assets/logo-green-black.png";
import Button from "../../components/Button";
import useNavHook from "../../hooks/use-NavHook";

export default function SignupHeader() {
  // Const: Navigation Properties to be used in the Login Button component (reroute)
  const { navigate, navTo } = useNavHook("/");

  // Function: Handle Log in Button Click
  const handleClick = () => {
    navigate(navTo, { replace: true });
  };
  // Component: Button
  const redirectLoginButton = (
    <Button buttonName="Log in" onClick={handleClick} />
  );

  return (
    <div className="signup-header-main-container">
      <img src={logo} alt="GreenIRR" />
      <div className="redirect-login-container">
        {redirectLoginButton}
        <p>Already have an account?</p>
      </div>
    </div>
  );
}
