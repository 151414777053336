// Functionality: Searchbar for the entire application - searchbar is an input field with a search icon that provides a search functionality for the entire application given a json (front end search)
import { useState, useRef, useEffect } from "react";
import { MdOutlineSearch } from "react-icons/md";

export default function SearchBar({
  options = [],
  isMultiSelect = false,
  selectedOptions = [],
  setSelectedOptions = () => {},
  divClassName = "search-bar-main-container-100 font-color-gray",
  headerClassName = "search-bar-header-container font-color-gray",
  dropdownClassName = "search-bar-options-container",
  optionClassName = "search-bar-option",
  inputClassName = "search-bar-input",
  placeholder = "Search by vehicle",
  checkbox = true,
  disabled = false,
  ...props
}) {
  // State & Refs
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false); // Determine if dropdown is open
  const [searchTerm, setSearchTerm] = useState(""); // Search term
  const dropdownRef = useRef(null); // Ref to dropdown element

  // Function: Open / close the dropdown
  const toggleDropdown = () => {
    if (!disabled) {
      setDropdownIsOpen(!dropdownIsOpen);
    }
  };

  // Function: Close the dropdown on click outside
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownIsOpen(false);
    }
  };

  // Function: Update the state of selected options
  const handleSelectChange = (option) => {
    if (props.onChange) {
      props.onChange(option);
      toggleDropdown();
      if (!isMultiSelect) {
        setSearchTerm(""); // Clear the search term on open
      }
    } else {
      // Condition of Multi-Select options - don't close the dropdown after selecting
      if (isMultiSelect) {
        setSelectedOptions((prevSelectedOptions) => {
          const isSelected = prevSelectedOptions?.some(
            (item) => item.value === option.value
          );
          if (isSelected) {
            return prevSelectedOptions?.filter(
              (item) => item.value !== option.value
            );
          } else {
            return [...prevSelectedOptions, option];
          }
        });
      }
      // Condition of Single-Select options
      else {
        setSelectedOptions([option]);
        setDropdownIsOpen(false); // Close the dropdown after selecting in single-select mode
        setSearchTerm(""); // Clear the search term on open
      }
    }
  };

  const filterOptions = () => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // UE: Add event listener to close dropdown on click outside
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // Function: On Input Change
  const onInputChange = (e) => {
    setSearchTerm(e.target.value);
    setDropdownIsOpen(true);
  };

  return (
    <>
      <div className={divClassName} ref={dropdownRef}>
        <div className={headerClassName} onClick={toggleDropdown}>
          <div className="search-icon-container">
            <MdOutlineSearch />
          </div>
          <input
            type="text"
            placeholder={
              isMultiSelect
                ? placeholder
                : selectedOptions?.[0]?.label || placeholder
            }
            value={searchTerm}
            onChange={(e) => onInputChange(e)}
            className={`${inputClassName} ${disabled ? "disabled" : ""}`}
            onClick={toggleDropdown}
            disabled={disabled}
          />
        </div>
        {dropdownIsOpen && (
          <div>
            <ul className={dropdownClassName}>
              {filterOptions()?.map((option) => (
                <li
                  key={option.label}
                  className={`${optionClassName} ${
                    selectedOptions?.some(
                      (selectedOption) => selectedOption.value === option.value
                    )
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleSelectChange(option)}
                >
                  {option?.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {checkbox && isMultiSelect && selectedOptions.length > 0 && (
        <div className="selected-options-container">
          {selectedOptions.map((option) => (
            <div key={option.value} className="selected-option">
              <input
                type="checkbox"
                checked={true}
                onChange={() => handleSelectChange(option)}
              />
              <label>{option.label}</label>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
