// Functionality: Component that renders a colored line
export default function SolidLine({
  color = "black",
  height = 1.5,
  border = "none",
}) {
  return (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        border: border,
        height: height,
      }}
    />
  );
}
