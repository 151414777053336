// Functionality: Custom Radio Item Component - There are three different components in this file: (1)- RadioItemGroup, (2)- RadioItem, (3)- CustomRadioItemGroup. (1) & (2) use react's input component, while 3 uses a custom div component.

import PropTypes from "prop-types";

// Radio Group React Component
export default function RadioItemGroup({
  options,
  name,
  value,
  onChange,
  radioClassName,
  labelClassName,
  divClassName = "div-radio-item",
  splitTextByLine = false,
  disabledOptions = [],
  ...props
}) {
  return (
    <div className={divClassName}>
      {options.map((option) => (
        <RadioItem
          key={option.value}
          label={option.label}
          value={option.value}
          name={name}
          checked={value === option.value}
          onChange={onChange}
          radioClassName={radioClassName}
          labelClassName={labelClassName}
          splitTextByLine={splitTextByLine}
          disabled={disabledOptions.includes(option.value)}
          {...props}
        />
      ))}
    </div>
  );
}

// Singular Radio Item
export function RadioItem({
  label,
  value,
  name,
  checked,
  onChange,
  radioClassName = "radio-item-vertical-option",
  labelClassName = "radio-item-vertical-option",
  splitTextByLine = false,
  disabled = false,
  ...props
}) {
  // Split the label text into lines
  const lines = splitTextByLine ? label.split("\n") : label;
  return (
    <>
      <label
        className={`${labelClassName} ${checked ? "selected" : ""}`}
        key={value}
      >
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          className={radioClassName}
          disabled={disabled}
          {...props}
        />
        {splitTextByLine ? lines.length > 0 && lines[0] : lines}
        {splitTextByLine ? (
          lines.length > 1 && (
            <div
              style={{
                whiteSpace: "pre-wrap",
                marginLeft: "50px",
              }}
            >
              {lines.slice(1).map((line, index) => (
                <p key={index} style={{ marginTop: "10px" }}>
                  {line}
                </p>
              ))}
            </div>
          )
        ) : (
          <></>
        )}
      </label>
      {checked && <>{props.radiochildren}</>}
    </>
  );
}

// Functionality: Custom Radio Item Group Component
export function CustomRadioItemGroup({
  options = [],
  selectedOption = null,
  setSelectedOption = () => {},
  divClassName = "custom-radio-item-container",
  elementClassName = "custom-radio-item-option",
}) {
  // Function: Update the state of selected option
  const handleOptionChange = (option) => {
    setSelectedOption(option.value);
  };

  return (
    <div className={divClassName}>
      {options.map((option) => (
        <div
          key={option.value}
          className={`${elementClassName} ${
            selectedOption === option.value ? "selected" : ""
          }`}
          onClick={() => handleOptionChange(option)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
}

// Props
const customRadioItemGroupProps = {
  options: PropTypes.array, // Array of strings options to select
  selectedOption: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]), // Currently selected option
  setSelectedOption: PropTypes.func, // Function to update selected option
  divClassName: PropTypes.string, // Class name for the radio item container
  elementClassName: PropTypes.string, // Class name for the radio item element
};

CustomRadioItemGroup.propTypes = customRadioItemGroupProps;
