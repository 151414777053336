// Functionality: React hook to find the label for the carbon rating
import { performanceKeyLanguage } from "../constants/ICRConfig";

export default function useLabelCarbonRating(ratingValue) {
  // Get the intervals and labels from the config file
  const intervals = Object.keys(performanceKeyLanguage).map(Number); // Convert to numbers
  const labels = Object.values(performanceKeyLanguage);

  // Convert ratingValue to a number
  const numericRating = parseFloat(ratingValue);

  // Find the appropriate interval for the ratingValue
  let plainTextValue = null;
  for (let i = 0; i < intervals.length - 1; i++) {
    if (numericRating >= intervals[i] && numericRating <= intervals[i + 1]) {
      plainTextValue = intervals[i];
      break;
    }
  }

  // Return the corresponding label
  return plainTextValue !== null
    ? labels[intervals.indexOf(plainTextValue)]
    : "";
}
