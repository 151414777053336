// Functionality: This file exports the Scope1ChartCard Card component which is used in the Emissions Page component.
import { useContext } from "react";
import EmissionsContext from "../../context/EmissionsProvider";
import DateContext from "../../context/DateProvider.js";
import LineChart from "../../visualizations/LineChart";
import RadioItemGroup from "../../components/RadioItemGroup";
import SwitchCarousel from "../../components/SwitchCarousel";
import useSub from "../../hooks/use-Sub.js";
import Skeleton from "react-loading-skeleton";
import { scope1ChartEmissionsTile } from "../../constants/TileConfig.js";
import { dailyMonthlyRadioItemOptions } from "../../constants/AuthConfig.js";

export default function Scope1ChartCard({ ...props }) {
  // State: Selected Radio Item - Monthly v Daily
  const { selectedChartRadioItemType, setSelectedChartRadioItemType } =
    useContext(DateContext);

  // Context: Initialize Context
  const { scope1DataPoints } = useContext(EmissionsContext);

  // Const: Super Scripted label for MT CO2e
  const superScriptedLabel = useSub("MT CO2e");

  // Const: Label Application Dates
  const applicationDatesLabel = "scope1ChartEmissions";

  // Const: Show Date Carousel
  const showDateCarousel = selectedChartRadioItemType?.scope1 === "Daily";

  // Const: Monthly Disabled
  const monthlyDisabled =
    props.dateValues?.length > 1 ? [] : dailyMonthlyRadioItemOptions[1].value;

  // Function: Handle Change Radio Date
  const handleChangeRadioDate = (e) => {
    setSelectedChartRadioItemType((prevState) => ({
      ...prevState,
      scope1: e?.target?.value,
    }));
  };

  // Const: Application Dates Switch Carousel
  const scope1ChartCardSwitchCarousel = showDateCarousel && (
    <div className="group-switch-carousel-no-margin">
      <SwitchCarousel
        values={props.dateValues}
        applicationDates={props.applicationDates}
        setApplicationDates={props.setApplicationDates}
        applicationDatesLabel={applicationDatesLabel}
        fillArrowColor={"#66C743"}
        switchCarouselValueClassName="group-switch-carousel-value"
      />
    </div>
  );

  // Const: Daily v Monthly Radio Item Group
  const dailyMonthlyRadioItemGroup = (
    <RadioItemGroup
      options={dailyMonthlyRadioItemOptions}
      value={selectedChartRadioItemType?.scope1}
      onChange={(e) => handleChangeRadioDate(e)}
      disabledOptions={monthlyDisabled}
    />
  );
  return (
    <>
      {!scope1DataPoints?.loaded ? (
        <Skeleton height={"350px"} />
      ) : (
        <div className="chart-card-container-group">
          <div className="chart-card-title-group">
            <div>
              <div className="card-title">
                Scope 1 Emissions
                {scope1ChartEmissionsTile?.blurb}
                {scope1ChartEmissionsTile?.legend}
              </div>
              <div className="card-subtitle">{superScriptedLabel}</div>
            </div>
            <div>
              {dailyMonthlyRadioItemGroup} {scope1ChartCardSwitchCarousel}
            </div>
          </div>
          <div className="emissions-page-chart-container">
            <LineChart
              datasets={scope1DataPoints?.data}
              lineBreak={scope1DataPoints?.lineBreak}
              maxValue={scope1DataPoints?.maxValue}
              annotationValue={scope1DataPoints?.annotationValue}
            />
          </div>
        </div>
      )}
    </>
  );
}
