// Functionality: This file exports the AverageEmissionsPerVehicle component which is used in the Vehicle Performance Page component. This is also known as average emissions per vehicle.
import PropTypes from "prop-types";
import { useContext } from "react";
import VehiclePerformanceContext from "../../../context/VehiclePerformanceProvider.js";
import MetricTileGroup from "../../../components/MetricTileGroup.js";
import SwitchCarousel from "../../../components/SwitchCarousel.js";
import Skeleton from "react-loading-skeleton";

export default function AverageEmissionsPerVehicle({ ...props }) {
  // Const: Initialize Context
  const { averageEmissionsPerVehicle } = useContext(VehiclePerformanceContext);

  // Const: Label Application Dates
  const applicationDatesLabel = "avgEmissionsPerVehicle";

  return (
    <>
      {!averageEmissionsPerVehicle?.loaded ? (
        <Skeleton height={"300px"} />
      ) : (
        <div className="vp-card-contianer-group-vp1">
          <MetricTileGroup
            svgConfig={averageEmissionsPerVehicle?.value}
            svgChildren={
              <SwitchCarousel
                values={props.dateValues}
                applicationDates={props.applicationDates}
                setApplicationDates={props.setApplicationDates}
                applicationDatesLabel={applicationDatesLabel}
                fillArrowColor="black"
              />
            }
          />
        </div>
      )}
    </>
  );
}

// Props
const averageEmissionsPerVehicleProps = {
  dateValues: PropTypes.array, // Array of date values
  applicationDates: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // String of application dates
  setApplicationDates: PropTypes.func, // Function to set application dates
};

AverageEmissionsPerVehicle.propTypes = averageEmissionsPerVehicleProps;
