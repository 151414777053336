// Functionality: Singular vin Decoder Modal - Used to decode a single vin within the vehicle congifuration tab in settings
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import VinDecoderOutputTable from "../VinDecoderSettings/VinDecoderOutput/VinDecoderOutputTable";

export default function SingleVinDecoderModal({
  setVinDecoderImportData,
  vinDecoderImportData,
  disabledDecodeButton,
  handleVinDecoderButtonClick,
  singleVinDecoderProcessStep,
  setSingleVinDecoderProcessStep,
  handleModalClose,
  handlePostVehicleSettings,
  singleVehicleEditFields,
  handleEditFieldChange,
  disableInputs,
  setDisabledDecodeButton,
  yearValues,
  originalVehicleEditFields,
}) {
  const determineValidVinInput = (str) => {
    const strCondition =
      str?.length === 17 && /^[A-HJ-NP-Za-hj-np-z0-9]+$/?.test(str);

    setDisabledDecodeButton(!strCondition);
  };
  const vinDecoderSingleHeaderGroup = (
    <div className="settings-update-vehicle-fields">
      <p>
        Please enter vehicle {originalVehicleEditFields?.vehicle_id}'s VIN to
        continue:
      </p>
      <Input
        value={singleVehicleEditFields?.vin || ""}
        disabled={disableInputs || singleVinDecoderProcessStep === 2}
        onChange={(e) => {
          handleEditFieldChange("vin", e, "vin_decode");
          determineValidVinInput(e?.target.value);
        }}
        placeholder={disableInputs ? "" : "vin"}
      />
      <div></div>
      <div className="signup-password-note">
        Must be 17 characters long, not contain special characters, and not
        contain the letters I, O, or Q (case-insensitive).
      </div>
    </div>
  );
  const vinDecoderButtonGroup = (
    <div className="flex-end-button-group">
      {singleVinDecoderProcessStep === 1 && (
        <>
          <Button
            buttonName={"Cancel"}
            onClick={() => handleModalClose("single")}
            className={"cancel-red-button"}
          />
          <Button
            buttonName={"Decode"}
            onClick={handleVinDecoderButtonClick}
            className={"primary-blue-button"}
            disabled={disabledDecodeButton}
          />
        </>
      )}
      {singleVinDecoderProcessStep === 2 && (
        <>
          <Button
            buttonName={"Go Back"}
            onClick={() => setSingleVinDecoderProcessStep(1)}
            className={"secondary-blue-button"}
            disabled={disabledDecodeButton}
          />
          <Button
            buttonName={"Update Vehicle Records"}
            onClick={() => handlePostVehicleSettings("Single", "vin_decode")}
            className={"primary-blue-button"}
            disabled={disabledDecodeButton}
          />
        </>
      )}
    </div>
  );
  return (
    <>
      {vinDecoderSingleHeaderGroup}
      {singleVinDecoderProcessStep === 2 && (
        <VinDecoderOutputTable
          setVinDecoderImportState={setVinDecoderImportData}
          vinDecoderImportState={vinDecoderImportData}
          updatedVehicleData={vinDecoderImportData?.data}
          yearValues={yearValues}
          single={true}
        />
      )}
      {vinDecoderButtonGroup}
    </>
  );
}
