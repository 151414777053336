// Functionality: Switch Carousel component to switch which component to render on change. When you are at the first value, you cannot go back - when you are at the last value you cannot go forward.

import PropTypes from "prop-types";
import { useState, useEffect } from "react";

export default function SwitchCarousel({
  values = [],
  applicationDates,
  setApplicationDates,
  applicationDatesLabel,
  arrowGroupClassName = "switch-carousel-arrow",
  switchCarouselValueClassName = "switch-carousel-value",
  fillArrowColor = "white",
  changeVehicleProfile = false,
}) {
  // State
  const [currentIndex, setCurrentIndex] = useState(0); // Current index of the value to display

  // Function: Change the current index to the next one
  const handleNext = () => {
    if (currentIndex < values.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setApplicationDates({
        ...applicationDates,
        [applicationDatesLabel]: values[currentIndex + 1]?.value,
        vehicleProfile: changeVehicleProfile
          ? values[currentIndex + 1]?.value
          : applicationDates["vehicleProfile"],
      });
    }
  };

  // Function: Change the current index to the previous one
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      setApplicationDates({
        ...applicationDates,
        [applicationDatesLabel]: values[currentIndex - 1]?.value,
        vehicleProfile: changeVehicleProfile
          ? values[currentIndex - 1]?.value
          : applicationDates["vehicleProfile"],
      });
    }
  };

  // UE: Initialize currentIndex to the last element index when the component mounts
  useEffect(() => {
    if (values.length > 0) {
      const initialIndex = values.findIndex(
        (item) => item.value === applicationDates[applicationDatesLabel]
      );
      if (initialIndex !== -1) {
        setCurrentIndex(initialIndex);
      }
    }
  }, [values, applicationDates, applicationDatesLabel]);

  return (
    <>
      {currentIndex > 0 ? (
        <div className={arrowGroupClassName} onClick={handlePrev}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="4"
            height="9"
            viewBox="0 0 4 9"
            fill="none"
          >
            <path
              d="M3.95833 8.85449L4.72027e-08 4.89616L3.95833 0.937824L3.95833 8.85449Z"
              fill={fillArrowColor}
            />
          </svg>
        </div>
      ) : (
        <div></div>
      )}
      <div className={switchCarouselValueClassName}>
        <label>{values[currentIndex]?.label}</label>
      </div>
      {currentIndex < values.length - 1 ? (
        <div className={arrowGroupClassName} onClick={handleNext}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="4"
            height="9"
            viewBox="0 0 4 9"
            fill="none"
          >
            <path
              d="M4.1008e-05 8.85449L3.95837 4.89616L4.09136e-05 0.937824L4.1008e-05 8.85449Z"
              fill={fillArrowColor}
            />
          </svg>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

// Props
const switchCarouselPropTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string, // Label to display
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Value to display
    })
  ),
  arrowGroupClassName: PropTypes.string, // Class name for the arrow group
  switchCarouselValueClassName: PropTypes.string, // Class name for the value
  fillArrowColor: PropTypes.string, // Fill color for the arrows
};

SwitchCarousel.propTypes = switchCarouselPropTypes;
