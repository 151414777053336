// Functionality: Vehicle Vin Decoder Sidebar
import ProgressBar from "../../../components/ProgressBar";

export default function VinDecoderSidebar({ processStep, determineButton }) {
  // Function: Determine CSS Text Class
  const determineCSSText = (step) => {
    if (processStep === step) {
      return "vin-decoder-sidebar-subgroup-ip";
    } else if (processStep > step) {
      return "vin-decoder-sidebar-subgroup-comp";
    } else {
      return "vin-decoder-sidebar-subgroup-null";
    }
  };

  // Function: Determing SideBar Subgroup
  const sideBarSubGroup = (step, text) => {
    return (
      <div className={`vin-decoder-sidebar-subgroup ${determineCSSText(step)}`}>
        <ProgressBar
          percentageValue={processStep > step ? 100 : 0}
          remainingProgressClassName={"remaining-progress-report"}
          circular={true}
        />
        <p>{text}</p>
      </div>
    );
  };
  return (
    <>
      <div className="vin-decoder-sidebar">
        {determineButton(processStep)}
        {sideBarSubGroup(0, "Upload File")}
        {sideBarSubGroup(1, "Review Upload")}
        {sideBarSubGroup(2, "VIN Decoder")}
        {sideBarSubGroup(3, "Review Output")}
        {sideBarSubGroup(4, "Submit")}
      </div>
    </>
  );
}
