// Functionality: This file exports the CostofCarbonCard component which is used in the OverviewPage component. The component is a card including cost of carbonc emissions per $ fuel spend (monthly & yearly)
import MetricTileGroupSimple from "../../components/MetricTileGroupSimple";
import Skeleton from "react-loading-skeleton";

export default function CostofCarbonCard({ costOfCarbon }) {
  return (
    <>
      {!costOfCarbon?.loaded ? (
        <Skeleton height={"200px"} />
      ) : costOfCarbon?.value === "-" ? (
        <></>
      ) : (
        <MetricTileGroupSimple tileGroupConfig={costOfCarbon} />
      )}
    </>
  );
}
