// Functionality: Component to display when all records are successful in the initial upload.
import { useState } from "react";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table";
import VinDecoderDownloadFile from "../VinDecoderDownloadFile";
import {
  vsVinUploadTableConfig,
  vinDecoderDownloadPreviewLabeling,
} from "../../../../constants/SettingsConfig";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { postVinDecoderImportEntries } from "../../../../api/Settings/VinDecoderImportAPI";

export default function SuccessInitialRecords({
  failedDecoderState,
  updatedVehicleData,
  setProcessStep,
  setVinDecoderImportState,
}) {
  // State: Disable Button Click
  const [disableButton, setDisableButton] = useState(false);

  // Function: API Call to Run Vin Decoder
  const handleVinDecoderButtonClick = () => {
    setDisableButton(true);
    postVinDecoderImportEntries(
      updatedVehicleData,
      setVinDecoderImportState,
      setProcessStep,
      setDisableButton
    );
  };

  // Const: Success Records Summary
  const successRecordsSummary = (
    <>
      <div className="vin-decoder-success-entries-group">
        <div className="vin-decoder-success-entries-subgroup">
          <div className="vin-decoder-success-entries-group-header">
            <IoCheckmarkDoneOutline />
            <p>
              {failedDecoderState?.upload_length} records out of{" "}
              {failedDecoderState?.upload_length} have been successfully
              imported.
            </p>
          </div>
        </div>
        <div className="vin-decoder-success-entries-subgroup-content">
          <div className="flex-row-space-between">
            <p>You may now continue to the process of VIN Decoder.</p>
            <Button
              className="primary-blue-button"
              buttonName="Run Decoder"
              onClick={() => handleVinDecoderButtonClick()}
              disabled={disableButton}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="vin-decoder-success-entries-preview-table-header">
          <p className="card-title">Preview Upload</p>
          <VinDecoderDownloadFile
            vehicleSettingsData={updatedVehicleData}
            labeling={vinDecoderDownloadPreviewLabeling}
            containerClassName=""
          />
        </div>
        <div className="vin-decoder-success-entries-preview-table">
          <Table
            config={vsVinUploadTableConfig()}
            tableData={updatedVehicleData}
            tableRowClassName="primary-table-row"
            sortable={false}
            tableHeadClassName="primary-table-sort-header-group card-text-min"
          />
        </div>
      </div>
    </>
  );
  return <>{successRecordsSummary}</>;
}
