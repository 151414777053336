import { hubspotTicketForm } from "../constants/AuthConfig";
import HubspotFormLayout from "../layouts/HubspotFormLayout";
import ApplicationHeader from "../layouts/ApplicationHeader";

export default function HelpPage() {
  return (
    <>
      <ApplicationHeader
        headerName={"Submit a Ticket"}
        applicationDatesLabel={"overview"}
      />
      <p>
        Have a question? Fill out the form below and a member of our team will
        get back to you.
      </p>
      <HubspotFormLayout
        src={hubspotTicketForm?.src}
        region={hubspotTicketForm?.region}
        portalId={hubspotTicketForm?.portalId}
        formId={hubspotTicketForm?.formId}
        hubspotFormMiddleClassName={"hubspot-form-middle-help"}
        backgroundContainerClassName={"help-page-background-container"}
        mainContainerClassName={"help-page-main-container-content"}
      />
    </>
  );
}
