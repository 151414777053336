// Functionality: API Call for Vin Decoder Import Entries

import axios from "../loadBalancerAxios";
import { VIN_DECODER_URL } from "../paths";

export const postVinDecoderImportEntries = async (
  vehicleData,
  setVinDecoderImportData,
  setProcessStep,
  setDisableButton
) => {
  const updatedData = vehicleData?.map((item) => {
    return {
      vehicle_id: item?.vehicle_id,
      vin: item?.vin,
    };
  });
  const bodyData = vehicleData
    ?.map((item) => {
      return {
        [item?.vehicle_id]: item?.vin,
      };
    })
    ?.reduce((acc, obj) => {
      return { ...acc, ...obj };
    }, {});

  await axios
    .post(VIN_DECODER_URL, bodyData)
    .then((response) => {
      const responseData = response?.data;
      // Update the data to only include the vehicle_id and vin

      // TODO: Change this to use MAPPING (vinVehicleSettingsColumnMapping)
      // Rename columns to be consistent with the vehicle settings endpoint
      const updatedColumnNamingData = responseData?.map((item) => {
        return {
          vehicle_id: item?.VehicleId,
          vin: item?.Vin,
          manufacturer_name: item?.ManufacturerName,
          make: item?.Make,
          model: item?.Model,
          year: item?.ModelYear,
          engine_manufacturer: item?.EngineManufacturer,
          engine_type: item?.EngineModel,
          fuel_type: item?.FuelType,
          body_class: item?.BodyClass,
          gvwr: item?.["GVWR"],
        };
      });
      // Filter out the records where there are empty values
      const errorData = updatedColumnNamingData?.filter((item) => {
        return Object.values(item).some(
          (val) => val === "" || val === 0 || val === null
        );
      });

      setVinDecoderImportData({
        data: updatedColumnNamingData,
        originalData: updatedData,
        errorData: errorData,
        length_original_data: updatedData?.length,
        length_data: updatedColumnNamingData?.length,
        length_error_data: errorData?.length,
        loaded: true,
      });
      setProcessStep(2);
    })
    .catch(() => {
      setVinDecoderImportData({
        data: [],
        originalData: [],
        errorData: [],
        length_original_data: 0,
        length_data: 0,
        length_error_data: 0,
        loaded: true,
      });
    })
    ?.finally(() => {
      setDisableButton(false);
    });
};
