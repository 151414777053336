import { useContext, useState } from "react";
import DemoOnboardContext from "../context/DemoOnboardProvider";
import SignupFields from "./SignupPage/SignupFields";
import DemoOnboardCheck from "./DemoOnboardPage/DemoOnboardCheck";
import logo from "../assets/logo-green-black.png";

export default function DemoOnboardPage() {
  // Initialize Context
  const {
    demoSetupState,
    setDemoSetupState,
    demoAgreeTerms,
    setDemoAgreeTerms,
  } = useContext(DemoOnboardContext);

  // Initialize State
  const [errMsg, setErrMsg] = useState(""); // State of the error message

  return (
    <>
      <div className="colored-bar-background-container"></div>
      <div className="colored-bar-main-container-content">
        <div id="signup-container">
          <div className="signup-header-main-container">
            <img src={logo} alt="GreenIRR" />
          </div>
          <div className="signup-content-container">
            <p className="signup-content-container-title">
              Welcome to your Demo account
            </p>
            <p className="signup-content-container-subtitle">
              Please follow the steps below to view your demo carbon accounting
              platform
            </p>
            <SignupFields
              signupState={demoSetupState}
              setSignupState={setDemoSetupState}
              setErrMsg={setErrMsg}
            />
            <DemoOnboardCheck
              demoSetupState={demoSetupState}
              demoAgreeTerms={demoAgreeTerms}
              setDemoAgreeTerms={setDemoAgreeTerms}
              setErrMsg={setErrMsg}
            />
          </div>
        </div>
      </div>
    </>
  );
}
