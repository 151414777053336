// Functionality: This file exports the SettingsResetPasswordModal component which is used in the Settings page. The component is a modal that provides fields and an API call to generate a new password.
import { useState, useEffect } from "react";
import { IoMdCheckmark } from "react-icons/io";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Input from "../../components/Input";
import useNavHook from "../../hooks/use-NavHook";
import useValidatePassword from "../../hooks/use-ValidatePassword";
import { putUpdatePassword } from "../../api/Auth/UpdatePasswordAPI";
import {
  signupInputFields,
  passwordRequirementsText,
} from "../../constants/AuthConfig";

// Refactor Forgot PW Fields to be in correct format for Inputs
const fields = signupInputFields.slice(2);
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function SettingsResetPasswordModal({
  resetPWState,
  setResetPWState,
}) {
  // State
  const [showModal, setShowModal] = useState(false); // Modal for the breakdown of the emissions
  // State: Validation Message - Password
  const [pwValidationMessage, setPWValidationMessage] = useState("default");
  // State: Validation Message - Confirm Password
  const [cpwValidationMessage, setCPWValidationMessage] = useState("default");
  // State: ErrMsg
  const [errMsg, setErrMsg] = useState("");

  // Const: Reset PW Button Name
  const resetPWButtonName = "Reset Password";

  // Const: Length = 5 because there are 4 reset fields
  const isResetFilledOut =
    Object.values({
      password: resetPWState?.password,
      confirm_password: resetPWState?.confirm_password,
    })?.filter((item) => item !== "")?.length === 2;

  // Const: Navigation Properties to be used in the ResetPasswordSubmit component
  const { navigate, navTo } = useNavHook("/");

  // Function: Handle Submit of Reset PW Form - Make API Call
  const handleSubmit = (e) => {
    e.preventDefault();
    putUpdatePassword(resetPWState, navigate, navTo, setErrMsg);
  };
  // Function: Show Modal
  const handleClick = () => {
    setShowModal(true);
  };
  // Function: Hide Modal
  const handleModalClose = () => {
    setShowModal(false);
  };

  // Const: Passwords Equal
  const isPasswordEqual =
    resetPWState?.password === resetPWState?.confirm_password;

  const passwordValidation = useValidatePassword(resetPWState?.password);

  // UE: Set Password Validation Message
  useEffect(() => {
    let timer;

    const handleValidation = () => {
      // Add your validation logic here
      if (passwordValidation) {
        setPWValidationMessage("good");
      } else {
        setPWValidationMessage("bad");
      }
    };

    if (resetPWState.password.trim() !== "") {
      timer = setTimeout(handleValidation, 1500);
    } else {
      setPWValidationMessage("default");
      // Clear the validation message if the password is empty
    }

    return () => clearTimeout(timer);
  }, [resetPWState.password]);

  // UE: Set Confirm Password Validation Message
  useEffect(() => {
    let timer;

    const handleValidation = () => {
      // Add your validation logic here
      if (isPasswordEqual) {
        setCPWValidationMessage("good");
      } else {
        setCPWValidationMessage("bad");
      }
    };

    if (resetPWState.confirm_password.trim() !== "") {
      timer = setTimeout(handleValidation, 1500);
    } else {
      setCPWValidationMessage("default");
      // Clear the validation message if the password is empty
    }

    return () => clearTimeout(timer);
  }, [resetPWState.confirm_password]);

  // Function: Handle State Change of Input Fields
  const handleChange = (e) => {
    e.preventDefault();
    setResetPWState({ ...resetPWState, [e.target.id]: e.target.value });
  };

  // Const: Develop Login Input Fields for email & password
  const forgotPWInputFields = fields?.map((field) => {
    // Const: Label Text for Confirm Password
    const determineLabelText = () => {
      // Handle Case For Confirm Password Validation
      if (field?.labelFor === "confirm_password") {
        if (cpwValidationMessage === "bad") {
          return (
            <div>
              <p>{field?.labelText}</p>
              <div className="signup-password-no-match">
                Passwords do not match
              </div>
            </div>
          );
        } else if (cpwValidationMessage === "good") {
          return (
            <div className="signup-password-note-group">
              <p>{field?.labelText}</p>
              <IoMdCheckmark className="signup-password-note-checkmark" />
            </div>
          );
        } else {
          return <p>{field?.labelText}</p>;
        }
      }
      // Handle Case For Password Validation
      else if (field?.labelFor === "password") {
        if (pwValidationMessage === "default") {
          return (
            <div>
              <p>New {field?.labelText}</p>
              <div className="signup-password-note">
                {passwordRequirementsText}
              </div>
            </div>
          );
        } else if (pwValidationMessage === "good") {
          return (
            <div>
              <div className="signup-password-note-group">
                <p>New {field?.labelText}</p>
                <IoMdCheckmark className="signup-password-note-checkmark" />
              </div>
              <div className="signup-password-note">
                {passwordRequirementsText}
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <div className="signup-password-note-group">
                <p>New {field?.labelText}</p>
                <div className="signup-password-no-match">
                  Please enter a valid password
                </div>
              </div>
              <div className="signup-password-note">
                {passwordRequirementsText}
              </div>
            </div>
          );
        }
      } else {
        return <p>{field?.labelText}</p>;
      }
    };

    return (
      <Input
        key={field.id}
        onChange={handleChange}
        value={resetPWState[field.id]}
        labelFor={field.labelFor}
        id={field.id}
        type={field.type}
        isRequired={field.isRequired}
        disabled={field.disabled}
        placeholder={""}
        label={determineLabelText()}
        divClassName="forgotpw-input-main-container"
        className="signup-input-element font-small-medium"
      />
    );
  });

  // Component: Login Submit Button
  const resetPasswordSubmitButton = (
    <Button
      onClick={handleSubmit}
      buttonName={resetPWButtonName}
      disabled={!isResetFilledOut || !isPasswordEqual}
      className={"font-medium"}
    />
  );

  // Const: Determine the body of the modal card
  const modalCardBody = (
    <Modal
      onClose={handleModalClose}
      children={
        <div className="settings-resetpw-modal-group">
          {forgotPWInputFields}
          {resetPasswordSubmitButton}
        </div>
      }
    />
  );

  // Const: Modal Trigger Sub Header
  const modalButtonTrigger = (
    <div className="setting-account-setup-subgroup">
      <Button buttonName={"Reset Password"} onClick={handleClick} />
    </div>
  );
  return (
    <>
      {modalButtonTrigger}
      {showModal && modalCardBody}
    </>
  );
}
