// Functionality: This file exports the Scope2Manager component which is used in the Emissions Page component.
import { useState, useEffect } from "react";
import { useContext } from "react";
import EmissionsContext from "../../context/EmissionsProvider";
import DateContext from "../../context/DateProvider";
import Skeleton from "react-loading-skeleton";
import { stateOptionsConfig } from "../../constants/EmissionsConfig";
import SnackBar from "../../components/SnackBar";
import { postScope2ManagerEntries } from "../../api/Emissions/Scope2Manager/postScope2ManagerAPI";
import { getScope2ManagerEntries } from "../../api/Emissions/Scope2Manager/getScope2ManagerAPI";
import { postNewSiteLocation } from "../../api/Emissions/Scope2Manager/postNewSiteLocationAPI";
import { getSiteLocation } from "../../api/Emissions/Scope2Manager/getSiteLocationAPI";
import { getScope2API } from "../../api/Emissions/Scope2API";
import { getScope2ChartDataAPI } from "../../api/Emissions/Scope2ChartCardAPI";
import { mockLoginData } from "../../constants/mockDataConfig";
import Scope2PreviousSubmissions from "./Scope2Manager/Scope2PreviousSubmissions";
import Scope2EmptyState from "./Scope2Manager/Scope2EmptyState";

// INITIAL DATA TO CLEAR THE FORM ON BUTTON CLICK
import {
  isScope2ManagerNewEntry,
  isScope2ManagerNewAddress,
} from "../../constants/initialDataConfig";

export default function Scope2Manager() {
  // State
  const [successMsg, setSuccessMsg] = useState({ text: "", msg: "" });
  const [selectedStateScope, setSelectedStateScope] = useState([]);

  // Const: Determine if Mock User is Logged in
  const notMockUser =
    localStorage.getItem("greenToken") !== mockLoginData?.token;

  // Context: Get Emissions Context
  const {
    setScope2ManagerEntries,
    scope2ManagerEntries,
    scope2ManagerNewEntry,
    setScope2ManagerNewEntry,
    scope2ManagerNewAddress,
    setScope2ManagerNewAddress,
    scope2ManagerAddressOptions,
    setScope2ManagerAddressOptions,
    setScope2Value,
    setScope2DataPoints,
    setScope2DataExists,
  } = useContext(EmissionsContext);

  // Context: Get Date Context
  const { applicationDates } = useContext(DateContext);
  // UE: Get Scope 2 Manager Entries When New Entry Changes
  useEffect(() => {
    if (successMsg?.msg?.length > 0 && notMockUser) {
      setTimeout(
        () => getScope2ManagerEntries(setScope2ManagerEntries),
        getSiteLocation(setScope2ManagerAddressOptions),
        getScope2API(setScope2Value, applicationDates?.scope2Emissions),
        getScope2ChartDataAPI(setScope2DataPoints, setScope2DataExists),
        5000
      );
    }
  }, [
    successMsg,
    setScope2ManagerEntries,
    setScope2ManagerAddressOptions,
    notMockUser,
  ]);
  // Function: Submit New Scope 2 Emission Entry
  const submitNewScope2Emission = () => {
    if (notMockUser) {
      postScope2ManagerEntries(
        scope2ManagerNewEntry,
        setScope2ManagerNewEntry,
        setSuccessMsg
      );
      setTimeout(() => setSuccessMsg({ text: "", msg: "" }), 5000);
    }
  };

  // Function: Submit New Scope 2 Address Entry
  const submitNewScope2Address = () => {
    if (notMockUser) {
      postNewSiteLocation(
        scope2ManagerNewAddress,
        setScope2ManagerNewAddress,
        setSelectedStateScope,
        setSuccessMsg
      );
      setTimeout(() => setSuccessMsg({ text: "", msg: "" }), 5000);
    }
  };

  // Function: Handle Selected Date
  const handleSelectedDate = (event) => {
    setScope2ManagerNewEntry({
      ...scope2ManagerNewEntry,
      billDate: event.target.value,
    });
  };

  // Function: Handle Input Change
  const handleInputChange = (event, type) => {
    setScope2ManagerNewEntry({
      ...scope2ManagerNewEntry,
      [type]: event.target.value,
    });
  };

  // Function: Handle Dropdown Change
  const handleDropdownChange = (event, type) => {
    setScope2ManagerNewEntry({
      ...scope2ManagerNewEntry,
      [type]: [event],
    });
  };

  // Function: Handle Scope 2 Manager New Address Entry
  const handleNewAddressEntry = (event, type) => {
    setScope2ManagerNewAddress({
      ...scope2ManagerNewAddress,
      [type]: event.target.value,
    });
  };
  // UE: Set State to Scope 2 Manager New Address
  useEffect(() => {
    if (selectedStateScope?.length > 0) {
      setScope2ManagerNewAddress({
        ...scope2ManagerNewAddress,
        state: selectedStateScope?.[0]?.value,
      });
    }
  }, [selectedStateScope]);

  // Function: Reset Scope 2 Manager New Entry or Address Fields on Button Click
  const resetScope2ManagerFields = (type) => {
    if (type === "new_entry") {
      setScope2ManagerNewEntry(isScope2ManagerNewEntry);
    } else {
      setScope2ManagerNewAddress(isScope2ManagerNewAddress);
      setSelectedStateScope([]);
    }
  };

  // Const: Submit Button Condition Handler Scope2 - Only when all fields are filled out
  const submitConditionHandler =
    Object?.values(scope2ManagerNewEntry)?.filter((item) => item?.length > 0)
      ?.length > 4;

  // Const: Submit Button Condition Handler Address - Only when all fields are filled out
  const submitConditionHandlerAddress =
    Object?.values(scope2ManagerNewAddress)?.filter((item) => item?.length > 0)
      ?.length > 4;

  // Const: Non-Empty State Condition, Address Entries
  const nonEmptyStateCondition = scope2ManagerAddressOptions?.data?.length > 0;

  // Const: Non-Empty State Condition, Previous Submissions
  const nonEmptyStateConditionEntries =
    Object?.entries(scope2ManagerEntries?.data)?.length > 0;

  return (
    <>
      {scope2ManagerAddressOptions?.loaded ? (
        <>
          {!nonEmptyStateConditionEntries ? (
            <Scope2EmptyState
              scope2ManagerNewEntry={scope2ManagerNewEntry}
              scope2ManagerAddressOptions={scope2ManagerAddressOptions}
              handleInputChange={handleInputChange}
              handleDropdownChange={handleDropdownChange}
              handleSelectedDate={handleSelectedDate}
              resetScope2ManagerFields={resetScope2ManagerFields}
              submitNewScope2Emission={submitNewScope2Emission}
              submitConditionHandler={submitConditionHandler}
              handleNewAddressEntry={handleNewAddressEntry}
              submitNewScope2Address={submitNewScope2Address}
              submitConditionHandlerAddress={submitConditionHandlerAddress}
              nonEmptyStateCondition={nonEmptyStateCondition}
              stateOptionsConfig={stateOptionsConfig}
              selectedStateScope={selectedStateScope}
              setSelectedStateScope={setSelectedStateScope}
              scope2ManagerNewAddress={scope2ManagerNewAddress}
            />
          ) : (
            <div className="scope2-manage-previous-sub">
              {nonEmptyStateConditionEntries && (
                <Scope2PreviousSubmissions
                  scope2ManagerEntries={scope2ManagerEntries}
                  scope2ManagerAddressOptions={scope2ManagerAddressOptions}
                  handleInputChange={handleInputChange}
                  handleDropdownChange={handleDropdownChange}
                  handleSelectedDate={handleSelectedDate}
                  resetScope2ManagerFields={resetScope2ManagerFields}
                  submitNewScope2Emission={submitNewScope2Emission}
                  submitConditionHandler={submitConditionHandler}
                  scope2ManagerNewAddress={scope2ManagerNewAddress}
                  handleNewAddressEntry={handleNewAddressEntry}
                  submitNewScope2Address={submitNewScope2Address}
                  submitConditionHandlerAddress={submitConditionHandlerAddress}
                  nonEmptyStateCondition={nonEmptyStateCondition}
                  stateOptionsConfig={stateOptionsConfig}
                  selectedStateScope={selectedStateScope}
                  setSelectedStateScope={setSelectedStateScope}
                  setSuccessMsg={setSuccessMsg}
                  scope2ManagerNewEntry={scope2ManagerNewEntry}
                />
              )}
            </div>
          )}
        </>
      ) : (
        <Skeleton height={"300px"} />
      )}
      <SnackBar
        message={successMsg?.text}
        visible={successMsg?.text?.length > 0}
        severity={successMsg?.msg}
      />
    </>
  );
}
