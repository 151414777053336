// Functionality: API Call for Scope 1 Emissions Tile
import axios from "../loadBalancerAxios";
import { SCOPE_1_EMISSIONS_URL } from "../paths";
import { scope1EmissionsSVGConfig } from "../../constants/EmissionsConfig";
import useNumberFormat from "../../hooks/use-NumberFormat";

export const postScope1API = async (setData, selectedDate) => {
  await axios
    .post(SCOPE_1_EMISSIONS_URL, {
      "Content-Type": "application/json",
      date: selectedDate,
    })
    .then((response) => {
      // Const: Set Scope 1 Emissions Data
      const responseData = response?.data || "-";

      // Const: Determine if its increase or decrease
      const increase = responseData?.change > 0;

      setData({
        ...scope1EmissionsSVGConfig,
        value: useNumberFormat(responseData?.value || "-"),
        percentageChange: Math.abs(responseData?.change).toFixed(2) || "-",
        increase: increase,
        loaded: true,
      });
    })
    .catch(() => {
      setData({
        ...scope1EmissionsSVGConfig,
        value: "-",
        change: "-",
        increase: false,
        loaded: true,
      });
    });
};
