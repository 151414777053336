// Functionality: MetricTileGroup Simple component for the application, used for displaying the following: average emissions per vehicle, cost of carbon, and fuel consumption
import PropTypes from "prop-types";
import useSub from "../hooks/use-Sub.js";

export default function MetricTileGroupSimple({
  tileGroupConfig,
  containerClassName = "metric-tile-group-simple-container",
  valueClassName = "metric-tile-group-simple-value",
  ...props
}) {
  // Const: Super script for the units
  const superscriptedFormula = useSub(tileGroupConfig?.units);

  // Const: Title Group Component
  const titleGroup = (
    <div className="metric-tile-group-simple-title-group">
      <div className="card-title">
        {tileGroupConfig.title}
        {tileGroupConfig?.blurb}
      </div>
    </div>
  );

  return (
    <div className={containerClassName}>
      {titleGroup}
      <div className={valueClassName}>
        {tileGroupConfig?.dollar && "$"} {tileGroupConfig?.value || 0}
      </div>
      <div className="metric-title-group-simple-label">
        {superscriptedFormula}
      </div>
      {props.children}
    </div>
  );
}

// Props
const metricTileGroupSimplePropTypes = {
  tileGroupConfig: PropTypes.shape({
    title: PropTypes.string,
    units: PropTypes.string,
    color: PropTypes.string,
  }),
  dateCarouselData: PropTypes.array,
  applicationDatesLabel: PropTypes.string,
};

MetricTileGroupSimple.propTypes = metricTileGroupSimplePropTypes;
