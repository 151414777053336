// Functonality: This is the page for the registration onboarding form. It is a multi-step form that is used to collect user information, company information, invited users, and fleet management software. The form is powered by Hubspot and the
import { hubspotRegistrationFormConfig } from "../constants/AuthConfig";
import HubspotOnboardFormLayout from "../layouts/HubspotOnboardFormLayout";
import useQueryParams from "../hooks/use-queryParams";
import logo from "../assets/logo-green-black.png";
import ProgressBar from "../components/ProgressBar";
import SolidLine from "../components/SolidLine";

export default function RegistrationInvitedOnboardingFormPage() {
  const query = useQueryParams();
  const activeFormStep = query.get("step") || 1;

  const headerForm = (
    <div>
      <div className="hubspot-form-logo-header-group">
        <img src={logo} alt="GreenIRR" />
      </div>
      <div className="hubspot-form-header-group">
        <p>User Registration Form</p>
      </div>
    </div>
  );
  // TODO: GRAB THE USER EMAIL
  const progressContainer = (
    <div>
      <div className="registration-form-timeline-group">
        <div className="registration-form-timeline-subgroup">
          <ProgressBar
            circular={true}
            percentageValue={activeFormStep >= 1 ? 100 : 0}
          />
        </div>
        <SolidLine color={activeFormStep >= 2 ? "#00a63f" : "black"} />
        <div className="registration-form-timeline-subgroup">
          <ProgressBar
            circular={true}
            percentageValue={activeFormStep >= 2 ? 100 : 0}
          />
        </div>
        <SolidLine color={activeFormStep >= 3 ? "#00a63f" : "black"} />
        <div className="registration-form-timeline-subgroup">
          <ProgressBar
            circular={true}
            percentageValue={activeFormStep >= 3 ? 100 : 0}
          />
        </div>
        <SolidLine color={activeFormStep >= 4 ? "#00a63f" : "black"} />
        <div className="registration-form-timeline-subgroup">
          <ProgressBar
            circular={true}
            percentageValue={activeFormStep >= 4 ? 100 : 0}
          />
        </div>
      </div>
      <div className="registration-form-timeline-group">
        <div className="registration-form-timeline-subgroup">
          <p>User Information</p>
        </div>
        <div></div>
        <div className="registration-form-timeline-subgroup">
          <p>Company Information</p>
        </div>
        <div></div>
        <div className="registration-form-timeline-subgroup">
          <p>Invited Users</p>
        </div>
        <div></div>
        <div className="registration-form-timeline-subgroup">
          <p>Fleet Management Software</p>
        </div>
      </div>
    </div>
  );
  const headerChildren = (
    <>
      {headerForm}
      {progressContainer}
    </>
  );

  return (
    <HubspotOnboardFormLayout
      src={hubspotRegistrationFormConfig?.[activeFormStep]?.src}
      region={hubspotRegistrationFormConfig?.[activeFormStep]?.region}
      portalId={hubspotRegistrationFormConfig?.[activeFormStep]?.portalId}
      formId={hubspotRegistrationFormConfig?.[activeFormStep]?.formId}
      headerChildren={headerChildren}
      activeFormStep={activeFormStep}
    />
  );
}
