// Functionality: This file exports the ChallengesReport component which is used in the Report Page component.
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import ReportContext from "../../../context/ReportsProvider";
import TextArea from "../../../components/TextArea";
import RadioItemGroup from "../../../components/RadioItemGroup";
import {
  reportQuestionConfig,
  challengesMeasureEmissionsDropdownOptions,
} from "../../../constants/ReportsConfig";

export default function ChallengesReport({
  generateQuestionGroup,
  handleReportEventChange,
  reportInputClassName,
}) {
  // Const: Initialize Report Context
  const { challengesDataConfig, setChallengesDataConfig } =
    useContext(ReportContext);

  // UE: Clear Board Level Oversight textArea if dropdown is not "Other"
  useEffect(() => {
    if (
      challengesDataConfig?.challenges?.radio !== "Other" &&
      challengesDataConfig?.challenges?.textArea !== ""
    ) {
      setChallengesDataConfig((prevState) => ({
        ...prevState,
        challenges: {
          radio: prevState.challenges.radio,
          textArea: "",
        },
      }));
    }
  }, [
    challengesDataConfig?.challenges?.radio,
    challengesDataConfig?.challenges?.textArea,
    setChallengesDataConfig,
  ]);
  // Dictionary: UI/UX for each of the questions, in order
  const questionGroupConfig = {
    0: (
      <>
        <RadioItemGroup
          options={challengesMeasureEmissionsDropdownOptions}
          value={challengesDataConfig?.challenges?.radio}
          divClassName="div-radio-item-vertical"
          splitTextByLine={true}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "challenges",
              "radio",
              setChallengesDataConfig,
              challengesDataConfig
            )
          }
        />
        {challengesDataConfig?.challenges?.radio === "Other" && (
          <TextArea
            value={challengesDataConfig?.challenges?.textArea}
            placeholder={"Explain"}
            onChange={(e) =>
              handleReportEventChange(
                e,
                "challenges",
                "textArea",
                setChallengesDataConfig,
                challengesDataConfig
              )
            }
            className={reportInputClassName?.textArea}
          />
        )}
      </>
    ),
  };
  return (
    <>
      {generateQuestionGroup(
        reportQuestionConfig?.challenges,
        questionGroupConfig
      )}
    </>
  );
}

// Props
const challengesReportPropTypes = {
  generateQuestionGroup: PropTypes.func, // Function: Generates the UI/UX for each question
  handleReportEventChange: PropTypes.func, // Function: Handles the change of each question
};

ChallengesReport.propTypes = challengesReportPropTypes;
