// This file serves as a central location for all Mock Data That Will Be Used in the Demo Version of the Application

// Mock Data Login Information
export const mockLoginData = {
  email: "greenIRR@green.com",
  password: "1234",
  token: "mockData",
  customer_name: "ABC Company",
};

// Mock Data for the Dates API

// Year Data
export const mockYearData = {
  information: [
    { label: "2022", value: "3" },
    { label: "2023", value: "2" },
    { label: "2024", value: "1" },
  ],
  projection: [
    { label: "2025", value: "2025" },
    { label: "2030", value: "2030" },
    { label: "2035", value: "2035" },
  ],
};

// Overview Page
export const mockCostofCarbon = { 1: `$220.46`, 2: `$242.71`, 3: `$198.93` };
export const mockFuelConsumption = { 1: `48,197`, 2: `51,072`, 3: `38,018` };
export const mockEmissionFootprint = { 1: `17,412`, 2: `19,982`, 3: `15,235` };
export const mockIndustryCarbonRating = {
  loaded: true,
  value: { 1: `84.6`, 2: `72.4`, 3: `89.8` },
};
export const mockHEVData = {
  loaded: true,
  data: {
    1: [
      { vehicle: "Vehicle 432", emissions: 3.98 },
      { vehicle: "Vehicle 124", emissions: 3.73 },
      { vehicle: "Vehicle 158", emissions: 2.86 },
      { vehicle: "Vehicle 356", emissions: 2.83 },
      { vehicle: "Vehicle 201", emissions: 2.72 },
      { vehicle: "Vehicle 255", emissions: 2.66 },
      { vehicle: "Vehicle 333", emissions: 2.47 },
      { vehicle: "Vehicle 419", emissions: 2.25 },
    ],
    2: [
      { vehicle: "Vehicle 132", emissions: 4.98 },
      { vehicle: "Vehicle 424", emissions: 4.74 },
      { vehicle: "Vehicle 558", emissions: 3.86 },
      { vehicle: "Vehicle 756", emissions: 3.84 },
      { vehicle: "Vehicle 101", emissions: 2.62 },
      { vehicle: "Vehicle 295", emissions: 2.46 },
      { vehicle: "Vehicle 833", emissions: 2.27 },
      { vehicle: "Vehicle 219", emissions: 2.25 },
    ],
    3: [
      { vehicle: "Vehicle 232", emissions: 3.98 },
      { vehicle: "Vehicle 924", emissions: 3.74 },
      { vehicle: "Vehicle 358", emissions: 2.86 },
      { vehicle: "Vehicle 576", emissions: 2.84 },
      { vehicle: "Vehicle 601", emissions: 1.62 },
      { vehicle: "Vehicle 595", emissions: 1.46 },
      { vehicle: "Vehicle 233", emissions: 1.27 },
      { vehicle: "Vehicle 419", emissions: 1.25 },
    ],
  },
};
export const mockScope12Percentage = {
  1: { scope1: 0.6, scope2: 0.4 },
  2: {
    scope1: 0.5,
    scope2: 0.5,
  },
  3: {
    scope1: 0.4,
    scope2: 0.6,
  },
};

// Emissions Page
export const mockScope1SVGData = {
  1: {
    value: "754",
    percentageChange: "16.22",
    increase: false,
    loaded: true,
  },
  2: {
    value: "900",
    percentageChange: "13.92",
    increase: true,
    loaded: true,
  },
  3: {
    value: "790",
    percentageChange: "4.39",
    increase: false,
    loaded: true,
  },
};
export const mockScope2SVGData = {
  1: {
    value: "186",
    percentageChange: "5",
    increase: true,
    loaded: true,
  },
  2: {
    value: "177",
    percentageChange: "77",
    increase: true,
    loaded: true,
  },
  3: {
    value: "100",
    percentageChange: "77",
    increase: false,
    loaded: true,
  },
};
export const mockScope1EmissionsData = [
  { x: 1, label: "Mar", y: 980 },
  { x: 2, label: "Apr", y: 1086 },
  { x: 3, label: "may", y: 1500 },
  { x: 4, label: "Jun", y: 1300 },
  { x: 5, label: "Jul", y: 1300 },
  { x: 6, label: "Aug", y: 1200 },
  { x: 7, label: "Sep", y: 1100 },
  { x: 8, label: "Oct", y: 950 },
  { x: 9, label: "Nov", y: 673 },
  { x: 10, label: "Dec", y: 754 },
  { x: 11, label: "Jan", y: 950 },
  { x: 12, label: "Feb", y: 673 },
  { x: 13, label: "Mar", y: 754 },
];
export const mockScope1BenchmarkEmissionsData = [
  { x: 1, label: "Mar", y: 100 * 10.055 },
  { x: 2, label: "Apr", y: 100 * 10.055 },
  { x: 3, label: "May", y: 120 * 10.055 },
  { x: 4, label: "Jun", y: 100 * 10.055 },
  { x: 5, label: "Jul", y: 90 * 10.055 },
  { x: 6, label: "Aug", y: 100 * 10.055 },
  { x: 7, label: "Sep", y: 100 * 10.055 },
  { x: 8, label: "Oct", y: 90 * 10.055 },
  { x: 9, label: "Nov", y: 100 * 10.055 },
  { x: 10, label: "Dec", y: 100 * 10.055 },
  { x: 11, label: "Jan", y: 86 * 10.055 },
  { x: 12, label: "Feb", y: 100 * 10.055 },
  { x: 13, label: "Mar", y: 100 * 10.055 },
];
export const mockScope1AnnotationValueData = {
  lineBreak: 9,
  maxValue: 1500,
  annotationValue: { first: "2023", second: "2024" },
};
export const mockScope2EmissionsData = [
  { x: 1, label: "Mar", y: 55 },
  { x: 2, label: "Apr", y: 70 },
  { x: 3, label: "May", y: 100 },
  { x: 4, label: "Jun", y: 100 },
  { x: 5, label: "Jul", y: 85 },
  { x: 6, label: "Aug", y: 70 },
  { x: 7, label: "Sep", y: 50 },
  { x: 8, label: "Oct", y: 100 },
  { x: 9, label: "Nov", y: 177 },
  { x: 10, label: "Dec", y: 186 },
  { x: 11, label: "Jan", y: 100 },
  { x: 12, label: "Feb", y: 177 },
  { x: 13, label: "Mar", y: 186 },
];
export const mockScope2AnnotationValueData = {
  lineBreak: 9,
  maxValue: 200,
  annotationValue: { first: "2023", second: "2024" },
};
export const mockScope2ManagerData = {
  loaded: true,
  data: {
    "The Best Company (123 ABC Street Norwalk, CT 06824)": {
      dates: ["2023-9-04"],
      values: [8.0],
    },
    "companyE (55 Beautiful Street Bridgeport, CT 06605)": {
      dates: ["2023-10-11", "2023-11-17"],
      values: [21.31, 18.0],
    },
  },
};
// Mock Scope 2 Manager Locations Data
export const mockScope2ManagerLocationsData = {
  loaded: true,
  data: [
    {
      label: "Warehouse 1 - 123 ABC Street Fairfield, CT, 068254 - 18",
      value: "Warehouse 1 - 123 ABC Street Fairfield, CT, 068254 - 18",
    },
    {
      label: "Warehouse 2 - 745 Evergreen Terrace Springfield, IL 91010 - 2",
      value: "Warehouse 2 - 745 Evergreen Terrace Springfield, IL 91010 - 2",
    },
    {
      label: "Warehouse 3 - 123 Fake Street Springfield, CA 91702 - 1",
      value: "Warehouse 3 - 123 Fake Street Springfield, CA 91702 - 1",
    },
  ],
};

export const mockLifecycleAnnotationValueData = {
  lineBreak: null,
  maxValue: 1500,
  annotationValue: null,
};
export const mockWellToTankLineChartData = [
  { x: 1, label: "Mar", y: 120, date: "13" },
  { x: 2, label: "Apr", y: 186, date: "12" },
  { x: 3, label: "may", y: 150, date: "11" },
  { x: 4, label: "Jun", y: 130, date: "10" },
  { x: 5, label: "Jul", y: 130, date: "9" },
  { x: 6, label: "Aug", y: 120, date: "8" },
  { x: 7, label: "Sep", y: 110, date: "7" },
  { x: 8, label: "Oct", y: 250, date: "6" },
  { x: 9, label: "Nov", y: 373, date: "5" },
  { x: 10, label: "Dec", y: 454, date: "4" },
  { x: 11, label: "Jan", y: 350, date: "3" },
  { x: 12, label: "Feb", y: 473, date: "2" },
  { x: 13, label: "Mar", y: 554, date: "1" },
];
export const mockTankToWheelLineChartData = [
  { x: 1, label: "Mar", y: 580, date: "13" },
  { x: 2, label: "Apr", y: 306, date: "12" },
  { x: 3, label: "May", y: 500, date: "11" },
  { x: 4, label: "Jun", y: 200, date: "10" },
  { x: 5, label: "Jul", y: 400, date: "9" },
  { x: 6, label: "Aug", y: 210, date: "8" },
  { x: 7, label: "Sep", y: 390, date: "7" },
  { x: 8, label: "Oct", y: 320, date: "6" },
  { x: 9, label: "Nov", y: 533, date: "5" },
  { x: 10, label: "Dec", y: 644, date: "4" },
  { x: 11, label: "Jan", y: 570, date: "3" },
  { x: 12, label: "Feb", y: 632, date: "2" },
  { x: 13, label: "Mar", y: 774, date: "1" },
];
export const mockLifecycleLineChartData = mockWellToTankLineChartData.map(
  ({ x, label, date }) => ({
    x,
    label,
    y:
      (mockWellToTankLineChartData.find((entry) => entry.x === x)?.y || 0) +
      (mockTankToWheelLineChartData.find((entry) => entry.x === x)?.y || 0),
    date,
  })
);

// Reduction Page
export const mockEmissionReductionTargetSelectedYear = [
  {
    label: "2035",
    value: "2035",
  },
];
export const mockEmissionReductionTargetData = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
  ],
  actualData: {
    1: [742, 678, 400, 0, 0, 0, 0, 0, 0, 0, 0],
    2: [660, 630, 630, 609.5, 660, 650, 640.5, 604, 630.5, 690.75, 680],
    3: [660, 630, 630, 609.5, 660, 650, 640.5, 604, 630.5, 690.75, 680],
  },
  targetData: Array(11).fill(700),
};
export const mockEmissionForecastingDataConfig = {
  labels: [
    "Mar 01",
    "Apr 01",
    "May 01",
    "Jun 01",
    "Jul 01",
    "Aug 01",
    "Sep 01",
    "Oct 01",
    "Nov 01",
    "Dec 01",
    "Jan 01",
    "Feb 01",
    "Mar 01",
    "Apr 30",
    "May 31",
    "Jun 30",
    "Jul 31",
    "Aug 30",
  ],
  indexes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
  actualData: [600, 801, 870, 723, 800, 663, 500, 800, 830, 858, 730, 782, 675],
  forecastData: Array(12).fill(null).concat([650, 730, 840, 743, 679, 800]),
  lowerboundData: Array(13).fill(null)?.concat([690, 800, 703, 639, 760]),
  upperboundData: Array(13).fill(null)?.concat([770, 880, 783, 719, 840]),
  loaded: true,
  lineBreak: 12,
  maxValue: 880,
  annotationValue: { first: "Mar 01", second: "Apr 30" },
};
export const mockSolutionsMarkerData = [
  {
    date: "March 13",
    name: "Alternative Fuel",
    description: "Transitioned 15 vehicles to ethanol fuel",
    impact: 0,
  },
  {
    date: "February 02",
    name: "Alternative Fuel",
    description: "Transitioned 4 vehicles to ethanol fuel",
    impact: -2,
  },
  {
    date: "January 08",
    name: "EV Integration",
    description: "Purchased and replaced 20 vehicles with EVs",
    impact: -17,
  },
  {
    date: "December 30",
    name: "Impact",
    description: "Purchased and replaced 4 vehicles with new 2023 Volvo HDVs",
    impact: 9,
  },
];

// Vehicle Performance Page
export const mockAvgEmissionsPerVehicle = {
  1: "1250.8",
  2: "1251.2",
  3: "1250.5",
};
export const mockTotalIdleTimeEmissions = {
  1: {
    value: "1720",
    percentageChange: "4.44",
    increase: false,
  },
  2: {
    value: "1800",
    percentageChange: "9.09",
    increase: true,
  },
  3: {
    value: "1650",
    percentageChange: "5.14",
    increase: false,
  },
};
export const mockIdleTimeEmissionsDataValues = [
  { x: 1, label: "Mar", y: 23 },
  { x: 2, label: "Apr", y: 25 },
  { x: 3, label: "May", y: 21 },
  { x: 4, label: "Jun", y: 15 },
  { x: 5, label: "Jul", y: 10 },
  { x: 6, label: "Aug", y: 11 },
  { x: 7, label: "Sep", y: 31 },
  { x: 8, label: "Oct", y: 42 },
  { x: 9, label: "Nov", y: 40 },
  { x: 10, label: "Dec", y: 38 },
  { x: 11, label: "Jan", y: 40 },
  { x: 12, label: "Feb", y: 42 },
  { x: 13, label: "Mar", y: 23 },
];

export const mockIdleTimeAnnotationValueData = {
  lineBreak: 10,
  maxValue: 45,
  annotationValue: { first: "2023", second: "2024" },
};
const mockEmiPerVehicleDataValues1 = [
  { x: "432", y: 17 },
  { x: "717", y: 20 },
  { x: "865", y: 30 },
  { x: "465", y: 10 },
  { x: "135", y: 15 },
  { x: "435", y: 25 },
  { x: "333", y: 50 },
  { x: "419", y: 40 },
  { x: "422", y: 30 },
  { x: "158", y: 10 },
  { x: "397", y: 35 },
  { x: "928", y: 25 },
  { x: "260", y: 40 },
  { x: "757", y: 30 },
  { x: "124", y: 30 },
  { x: "115", y: 25 },
  { x: "890", y: 15 },
  { x: "874", y: 10 },
  { x: "882", y: 35 },
  { x: "373", y: 25 },
  { x: "432", y: 50 },
  { x: "717", y: 30 },
  { x: "865", y: 30 },
  { x: "465", y: 70 },
  { x: "135", y: 65 },
  { x: "435", y: 65 },
  { x: "333", y: 40 },
  { x: "419", y: 28 },
  { x: "422", y: 30 },
  { x: "158", y: 15 },
  { x: "397", y: 55 },
  { x: "928", y: 25 },
  { x: "260", y: 20 },
  { x: "757", y: 30 },
  { x: "124", y: 32 },
  { x: "115", y: 13 },
  { x: "890", y: 33 },
  { x: "874", y: 27 },
  { x: "882", y: 23 },
  { x: "373", y: 27 },
];
const mockEmiPerVehicleDataValues2 = mockEmiPerVehicleDataValues1?.map(
  (item) => {
    return {
      ...item,
      y: item.y + 20,
    };
  }
);
const mockEmiPerVehicleDataValues3 = mockEmiPerVehicleDataValues1?.map(
  (item) => {
    return {
      ...item,
      y: item.y + 4,
    };
  }
);

export const mockEmissionsPerVehicleData = {
  label: "CO2",
  dataValues: {
    1: mockEmiPerVehicleDataValues1,
    2: mockEmiPerVehicleDataValues2,
    3: mockEmiPerVehicleDataValues3,
  },
};

export const mockEmissionsPerVehicleDataBenchmark = {
  label: "Industry Benchmark",
  dataValues: [
    { x: "432", y: 10.055 },
    { x: "717", y: 10.055 },
    { x: "865", y: 10.055 },
    { x: "465", y: 10.055 },
    { x: "135", y: 10.055 },
    { x: "435", y: 10.055 },
    { x: "333", y: 10.055 },
    { x: "419", y: 10.055 },
    { x: "422", y: 10.055 },
    { x: "158", y: 10.055 },
    { x: "397", y: 10.055 },
    { x: "928", y: 10.055 },
    { x: "260", y: 10.055 },
    { x: "757", y: 10.055 },
    { x: "124", y: 10.055 },
    { x: "115", y: 10.055 },
    { x: "890", y: 10.055 },
    { x: "874", y: 10.055 },
    { x: "882", y: 10.055 },
    { x: "373", y: 10.055 },
    { x: "432", y: 10.055 },
    { x: "717", y: 10.055 },
    { x: "865", y: 10.055 },
    { x: "465", y: 10.055 },
    { x: "135", y: 10.055 },
    { x: "435", y: 10.055 },
    { x: "333", y: 10.055 },
    { x: "419", y: 10.055 },
    { x: "422", y: 10.055 },
    { x: "158", y: 10.055 },
    { x: "397", y: 10.055 },
    { x: "928", y: 10.055 },
    { x: "260", y: 10.055 },
    { x: "757", y: 10.055 },
    { x: "124", y: 10.055 },
    { x: "115", y: 10.055 },
    { x: "890", y: 10.055 },
    { x: "874", y: 10.055 },
    { x: "882", y: 10.055 },
    { x: "373", y: 10.055 },
  ],
  trendline: true,
  pointRadius: 0,
  color: "rgba(255, 31, 46, 1)",
  type: "line",
  width: 1,
};
// Vehicle Fuel Types
const vehicleFuelTypes = [
  "Diesel",
  "Biodiesel",
  "B10",
  "B12",
  "B13",
  "B15",
  "B20",
  "Ethanol",
  "LNG",
  "LPG",
  "Methanol",
  "B6",
  "B7",
];
// Vehicle Fuel Type Options
export const mockFuelTypeOptions = vehicleFuelTypes.map((fuelType) => {
  return {
    value: fuelType,
    label: fuelType,
  };
});
// Reports Page
export const mockReportsTableData = [
  {
    reportType: "Standard",
    dateDownload: "March 2023",
    timePeriod: "Month",
  },
  {
    reportType: "Standard",
    dateDownload: "June 2023",
    timePeriod: "Quarter",
  },
  {
    reportType: "Custom",
    dateDownload: "July 2023",
    timePeriod: "Year",
  },
  {
    reportType: "Standard",
    dateDownload: "July 2023",
    timePeriod: "Month",
  },
  {
    reportType: "Custom",
    dateDownload: "September 2023",
    timePeriod: "Year",
  },
  {
    reportType: "Standard",
    dateDownload: "October 2023",
    timePeriod: "Month",
  },
];

const mockVehiclePerformanceDataTable1 = [
  {
    vehicle_id: "124",
    idle_time_emissions_by_fuel_co2_mt: 300,
    total_idle_time_hrs: 12,
    total_emissions_mt: 309,
    vin: "1HGCM826",
    make: "Honda",
    model: "Accord",
    year: "2016",
    engine_type: "V2",
    vehicle_type: "Heavy Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 1,
    rating: 83.16,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.91,
    emissions_by_dist_ch4_mt: 0.7,
    total_idle_time_fuel_consumed: 9.53,
    average_speed: 84.07,
    total_estimated_mileage_miles: 148.09,
    total_drivetime: 64.63,
    co2_miles: 46.93,
    days_active: 942,
    total_fuel_consumed_gallons: 888.87,
    total_fuel_purchased_gallons: 959.56,
    total_amt_spent_fuel: 892.14,
    grams_mile_co2: 389.85,
    total_co2e_emission: 82.38,
    emissions_by_fuel_co2_mt: 5.75,
  },
  {
    vehicle_id: "432",
    idle_time_emissions_by_fuel_co2_mt: 298,
    total_idle_time_hrs: 21,
    total_emissions_mt: 213,
    vin: "2FMDK3GC",
    make: "Ford",
    model: "Edge",
    year: "2013",
    engine_type: "V6",
    vehicle_type: "Medium Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 2,
    rating: 82.86,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.69,
    emissions_by_dist_ch4_mt: 0.86,
    total_idle_time_fuel_consumed: 0.88,
    average_speed: 33.6,
    total_estimated_mileage_miles: 183.63,
    total_drivetime: 96.69,
    co2_miles: 274.12,
    days_active: 633,
    total_fuel_consumed_gallons: 170.01,
    total_fuel_purchased_gallons: 580.61,
    total_amt_spent_fuel: 767.27,
    grams_mile_co2: 343.1,
    total_co2e_emission: 81.44,
    emissions_by_fuel_co2_mt: 1.31,
  },
  {
    vehicle_id: "158",
    idle_time_emissions_by_fuel_co2_mt: 286,
    total_idle_time_hrs: 32,
    total_emissions_mt: 231,
    vin: "3HGCM826",
    make: "Toyota",
    model: "Camry",
    year: "2018",
    engine_type: "V4",
    vehicle_type: "Light Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 3,
    rating: 81.28,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.83,
    emissions_by_dist_ch4_mt: 0.68,
    total_idle_time_fuel_consumed: 6.62,
    average_speed: 91.32,
    total_estimated_mileage_miles: 197.58,
    total_drivetime: 50.91,
    co2_miles: 731.04,
    days_active: 259,
    total_fuel_consumed_gallons: 79.53,
    total_fuel_purchased_gallons: 992.58,
    total_amt_spent_fuel: 267.5,
    grams_mile_co2: 891.63,
    total_co2e_emission: 71.61,
    emissions_by_fuel_co2_mt: 6.29,
  },
  {
    vehicle_id: "356",
    idle_time_emissions_by_fuel_co2_mt: 283,
    total_idle_time_hrs: 87,
    total_emissions_mt: 954,
    vin: "4FMDK3GC",
    make: "Chevrolet",
    model: "Impala",
    year: "2019",
    engine_type: "V8",
    vehicle_type: "Heavy Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 4,
    rating: 80.19,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.11,
    emissions_by_dist_ch4_mt: 0.75,
    total_idle_time_fuel_consumed: 6.78,
    average_speed: 19.86,
    total_estimated_mileage_miles: 528.23,
    total_drivetime: 1.28,
    co2_miles: 734.52,
    days_active: 449,
    total_fuel_consumed_gallons: 669.19,
    total_fuel_purchased_gallons: 9.16,
    total_amt_spent_fuel: 728.54,
    grams_mile_co2: 13.74,
    total_co2e_emission: 28.11,
    emissions_by_fuel_co2_mt: 6.68,
  },
  {
    vehicle_id: "201",
    idle_time_emissions_by_fuel_co2_mt: 272,
    total_idle_time_hrs: 23,
    total_emissions_mt: 924,
    vin: "5HGCM827",
    make: "Nissan",
    model: "Altima",
    year: "2020",
    engine_type: "V4",
    vehicle_type: "Medium Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 5,
    rating: 78.16,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.44,
    emissions_by_dist_ch4_mt: 0.39,
    total_idle_time_fuel_consumed: 9.11,
    average_speed: 97.2,
    total_estimated_mileage_miles: 707.16,
    total_drivetime: 90.48,
    co2_miles: 150.74,
    days_active: 839,
    total_fuel_consumed_gallons: 244.14,
    total_fuel_purchased_gallons: 0.01,
    total_amt_spent_fuel: 32.87,
    grams_mile_co2: 800.18,
    total_co2e_emission: 23.69,
    emissions_by_fuel_co2_mt: 7.67,
  },
  {
    vehicle_id: "255",
    idle_time_emissions_by_fuel_co2_mt: 266,
    total_idle_time_hrs: 74,
    total_emissions_mt: 386,
    vin: "6FMDK3GC",
    make: "BMW",
    model: "X5",
    year: "2021",
    engine_type: "V6",
    vehicle_type: "Heavy Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 6,
    rating: 77.12,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.79,
    emissions_by_dist_ch4_mt: 0.53,
    total_idle_time_fuel_consumed: 5,
    average_speed: 61.6,
    total_estimated_mileage_miles: 214.17,
    total_drivetime: 84.38,
    co2_miles: 99.07,
    days_active: 712,
    total_fuel_consumed_gallons: 872.88,
    total_fuel_purchased_gallons: 928.61,
    total_amt_spent_fuel: 357.37,
    grams_mile_co2: 174.46,
    total_co2e_emission: 49.75,
    emissions_by_fuel_co2_mt: 7.43,
  },
  {
    vehicle_id: "333",
    idle_time_emissions_by_fuel_co2_mt: 247,
    total_idle_time_hrs: 85,
    total_emissions_mt: 845,
    vin: "7HGCM828",
    make: "Mercedes",
    model: "C Class",
    year: "2022",
    engine_type: "V8",
    vehicle_type: "Heavy Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 7,
    rating: 75.63,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.71,
    emissions_by_dist_ch4_mt: 0.51,
    total_idle_time_fuel_consumed: 0.91,
    average_speed: 68.28,
    total_estimated_mileage_miles: 5.64,
    total_drivetime: 68.62,
    co2_miles: 187.12,
    days_active: 738,
    total_fuel_consumed_gallons: 766.87,
    total_fuel_purchased_gallons: 167.5,
    total_amt_spent_fuel: 416.97,
    grams_mile_co2: 785.2,
    total_co2e_emission: 20.89,
    emissions_by_fuel_co2_mt: 7.64,
  },
  {
    vehicle_id: "419",
    idle_time_emissions_by_fuel_co2_mt: 225,
    total_idle_time_hrs: 23,
    total_emissions_mt: 723,
    vin: "8FMDK3GC",
    make: "Audi",
    model: "A6",
    year: "2021",
    engine_type: "V6",
    vehicle_type: "Medium Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 8,
    rating: 74.98,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.17,
    emissions_by_dist_ch4_mt: 0.89,
    total_idle_time_fuel_consumed: 7.89,
    average_speed: 0.35,
    total_estimated_mileage_miles: 508.74,
    total_drivetime: 23.75,
    co2_miles: 937.97,
    days_active: 570,
    total_fuel_consumed_gallons: 244.4,
    total_fuel_purchased_gallons: 450.45,
    total_amt_spent_fuel: 197.8,
    grams_mile_co2: 639.86,
    total_co2e_emission: 1.64,
    emissions_by_fuel_co2_mt: 1.71,
  },
  {
    vehicle_id: "224",
    idle_time_emissions_by_fuel_co2_mt: 302,
    total_idle_time_hrs: 97,
    total_emissions_mt: 935,
    vin: "9HGCM829",
    make: "Lexus",
    model: "RX",
    year: "2020",
    engine_type: "V6",
    vehicle_type: "Medium Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 9,
    rating: 73.12,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.91,
    emissions_by_dist_ch4_mt: 0.81,
    total_idle_time_fuel_consumed: 0.33,
    average_speed: 27.11,
    total_estimated_mileage_miles: 728.43,
    total_drivetime: 52.91,
    co2_miles: 191.21,
    days_active: 125,
    total_fuel_consumed_gallons: 839.78,
    total_fuel_purchased_gallons: 274.1,
    total_amt_spent_fuel: 515.5,
    grams_mile_co2: 542,
    total_co2e_emission: 43.51,
    emissions_by_fuel_co2_mt: 2.37,
  },
  {
    vehicle_id: "232",
    idle_time_emissions_by_fuel_co2_mt: 292,
    total_idle_time_hrs: 98,
    total_emissions_mt: 357,
    vin: "10HGCM830",
    make: "Porsche",
    model: "911",
    year: "2021",
    engine_type: "V6",
    vehicle_type: "Medium Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 10,
    rating: 72.16,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.57,
    emissions_by_dist_ch4_mt: 0.68,
    total_idle_time_fuel_consumed: 7.21,
    average_speed: 7.57,
    total_estimated_mileage_miles: 836.6,
    total_drivetime: 83.07,
    co2_miles: 249.1,
    days_active: 660,
    total_fuel_consumed_gallons: 227.35,
    total_fuel_purchased_gallons: 98.76,
    total_amt_spent_fuel: 903.57,
    grams_mile_co2: 918.56,
    total_co2e_emission: 31.85,
    emissions_by_fuel_co2_mt: 5.31,
  },
  {
    vehicle_id: "358",
    idle_time_emissions_by_fuel_co2_mt: 283,
    total_idle_time_hrs: 24,
    total_emissions_mt: 721,
    vin: "11HGCM831",
    make: "Tesla",
    model: "Model S",
    year: "2022",
    engine_type: "Electric",
    vehicle_type: "Light Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 11,
    rating: 71.76,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.52,
    emissions_by_dist_ch4_mt: 0.44,
    total_idle_time_fuel_consumed: 4.67,
    average_speed: 16.29,
    total_estimated_mileage_miles: 428.97,
    total_drivetime: 28.88,
    co2_miles: 537.79,
    days_active: 54,
    total_fuel_consumed_gallons: 179.22,
    total_fuel_purchased_gallons: 426.98,
    total_amt_spent_fuel: 350.3,
    grams_mile_co2: 541.28,
    total_co2e_emission: 57.44,
    emissions_by_fuel_co2_mt: 4.21,
  },
  {
    vehicle_id: "456",
    idle_time_emissions_by_fuel_co2_mt: 284,
    total_idle_time_hrs: 97,
    total_emissions_mt: 563,
    vin: "12HGCM832",
    make: "Ferrari",
    model: "488",
    year: "2022",
    engine_type: "V8",
    vehicle_type: "Heavy Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 12,
    rating: 70.81,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.83,
    emissions_by_dist_ch4_mt: 0.97,
    total_idle_time_fuel_consumed: 8.18,
    average_speed: 57.32,
    total_estimated_mileage_miles: 58.06,
    total_drivetime: 5.73,
    co2_miles: 963.41,
    days_active: 109,
    total_fuel_consumed_gallons: 722.95,
    total_fuel_purchased_gallons: 652.32,
    total_amt_spent_fuel: 193.53,
    grams_mile_co2: 520.14,
    total_co2e_emission: 3.15,
    emissions_by_fuel_co2_mt: 7.22,
  },
  {
    vehicle_id: "501",
    idle_time_emissions_by_fuel_co2_mt: 275,
    total_idle_time_hrs: 64,
    total_emissions_mt: 761,
    vin: "13HGCM833",
    make: "Lamborghini",
    model: "Huracan",
    year: "2022",
    engine_type: "V10",
    vehicle_type: "Heavy Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 13,
    rating: 69.86,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.24,
    emissions_by_dist_ch4_mt: 0.33,
    total_idle_time_fuel_consumed: 5.01,
    average_speed: 82.44,
    total_estimated_mileage_miles: 185.65,
    total_drivetime: 60.07,
    co2_miles: 821.54,
    days_active: 825,
    total_fuel_consumed_gallons: 227.72,
    total_fuel_purchased_gallons: 920.55,
    total_amt_spent_fuel: 977.47,
    grams_mile_co2: 226.41,
    total_co2e_emission: 67.07,
    emissions_by_fuel_co2_mt: 1.48,
  },
  {
    vehicle_id: "655",
    idle_time_emissions_by_fuel_co2_mt: 267,
    total_idle_time_hrs: 85,
    total_emissions_mt: 652,
    vin: "14HGCM834",
    make: "Bugatti",
    model: "Chiron",
    year: "2022",
    engine_type: "W16",
    vehicle_type: "Heavy Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 14,
    rating: 68.91,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.78,
    emissions_by_dist_ch4_mt: 0.2,
    total_idle_time_fuel_consumed: 4.1,
    average_speed: 84.84,
    total_estimated_mileage_miles: 958.17,
    total_drivetime: 93.86,
    co2_miles: 288.69,
    days_active: 476,
    total_fuel_consumed_gallons: 200.5,
    total_fuel_purchased_gallons: 375.98,
    total_amt_spent_fuel: 425.92,
    grams_mile_co2: 521.07,
    total_co2e_emission: 30.9,
    emissions_by_fuel_co2_mt: 2.97,
  },
  {
    vehicle_id: "733",
    idle_time_emissions_by_fuel_co2_mt: 246,
    total_idle_time_hrs: 67,
    total_emissions_mt: 963,
    vin: "15HGCM835",
    make: "Rolls-Royce",
    model: "Phantom",
    year: "2022",
    engine_type: "V12",
    vehicle_type: "Heavy Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 15,
    rating: 67.96,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.43,
    emissions_by_dist_ch4_mt: 0.78,
    total_idle_time_fuel_consumed: 0.39,
    average_speed: 68.02,
    total_estimated_mileage_miles: 814.02,
    total_drivetime: 12.33,
    co2_miles: 899.07,
    days_active: 260,
    total_fuel_consumed_gallons: 465.51,
    total_fuel_purchased_gallons: 760.33,
    total_amt_spent_fuel: 721.01,
    grams_mile_co2: 871.55,
    total_co2e_emission: 51.68,
    emissions_by_fuel_co2_mt: 4.46,
  },
  {
    vehicle_id: "819",
    idle_time_emissions_by_fuel_co2_mt: 228,
    total_idle_time_hrs: 28,
    total_emissions_mt: 812,
    vin: "16HGCM836",
    make: "McLaren",
    model: "720S",
    year: "2022",
    engine_type: "V8",
    vehicle_type: "Heavy Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 16,
    rating: 66.01,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.77,
    emissions_by_dist_ch4_mt: 0.71,
    total_idle_time_fuel_consumed: 8.31,
    average_speed: 83.4,
    total_estimated_mileage_miles: 951.43,
    total_drivetime: 22.19,
    co2_miles: 633.02,
    days_active: 850,
    total_fuel_consumed_gallons: 579.79,
    total_fuel_purchased_gallons: 530.89,
    total_amt_spent_fuel: 344.13,
    grams_mile_co2: 614.16,
    total_co2e_emission: 7.1,
    emissions_by_fuel_co2_mt: 8.98,
  },
  {
    vehicle_id: "1124",
    idle_time_emissions_by_fuel_co2_mt: 306,
    total_idle_time_hrs: 91,
    total_emissions_mt: 703,
    vin: "17HGCM837",
    make: "Aston Martin",
    model: "DB11",
    year: "2022",
    engine_type: "V12",
    vehicle_type: "Heavy Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 17,
    rating: 65.06,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.7,
    emissions_by_dist_ch4_mt: 0.74,
    total_idle_time_fuel_consumed: 9.23,
    average_speed: 51.09,
    total_estimated_mileage_miles: 846.97,
    total_drivetime: 36.71,
    co2_miles: 420.31,
    days_active: 718,
    total_fuel_consumed_gallons: 224.05,
    total_fuel_purchased_gallons: 186.21,
    total_amt_spent_fuel: 296.23,
    grams_mile_co2: 719,
    total_co2e_emission: 7.82,
    emissions_by_fuel_co2_mt: 9.1,
  },
  {
    vehicle_id: "4232",
    idle_time_emissions_by_fuel_co2_mt: 295,
    total_idle_time_hrs: 19,
    total_emissions_mt: 605,
    vin: "18HGCM838",
    make: "Bentley",
    model: "Continental GT",
    year: "2022",
    engine_type: "W12",
    vehicle_type: "Heavy Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 18,
    rating: 64.11,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.78,
    emissions_by_dist_ch4_mt: 0.72,
    total_idle_time_fuel_consumed: 8.83,
    average_speed: 25.25,
    total_estimated_mileage_miles: 314.43,
    total_drivetime: 57.02,
    co2_miles: 79.46,
    days_active: 781,
    total_fuel_consumed_gallons: 171.77,
    total_fuel_purchased_gallons: 535.86,
    total_amt_spent_fuel: 879.31,
    grams_mile_co2: 201.96,
    total_co2e_emission: 88.02,
    emissions_by_fuel_co2_mt: 6.56,
  },
  {
    vehicle_id: "1358",
    idle_time_emissions_by_fuel_co2_mt: 282,
    total_idle_time_hrs: 20,
    total_emissions_mt: 768,
    vin: "19HGCM839",
    make: "Maserati",
    model: "Ghibli",
    year: "2022",
    engine_type: "V6",
    vehicle_type: "Medium Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 19,
    rating: 63.16,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.97,
    emissions_by_dist_ch4_mt: 0.54,
    total_idle_time_fuel_consumed: 3.57,
    average_speed: 20.6,
    total_estimated_mileage_miles: 44.08,
    total_drivetime: 20.25,
    co2_miles: 725.89,
    days_active: 583,
    total_fuel_consumed_gallons: 97.16,
    total_fuel_purchased_gallons: 9.16,
    total_amt_spent_fuel: 5.15,
    grams_mile_co2: 167.53,
    total_co2e_emission: 62.04,
    emissions_by_fuel_co2_mt: 2.17,
  },
  {
    vehicle_id: "3456",
    idle_time_emissions_by_fuel_co2_mt: 281,
    total_idle_time_hrs: 90,
    total_emissions_mt: 469,
    vin: "20HGCM840",
    make: "Alfa Romeo",
    model: "Giulia",
    year: "2022",
    engine_type: "V6",
    vehicle_type: "Medium Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 20,
    rating: 62.21,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.13,
    emissions_by_dist_ch4_mt: 0.91,
    total_idle_time_fuel_consumed: 2.74,
    average_speed: 96.38,
    total_estimated_mileage_miles: 282.1,
    total_drivetime: 39.25,
    co2_miles: 675.86,
    days_active: 950,
    total_fuel_consumed_gallons: 71.97,
    total_fuel_purchased_gallons: 905.74,
    total_amt_spent_fuel: 627.65,
    grams_mile_co2: 674,
    total_co2e_emission: 60.28,
    emissions_by_fuel_co2_mt: 7.16,
  },
  {
    vehicle_id: "2501",
    idle_time_emissions_by_fuel_co2_mt: 279,
    total_idle_time_hrs: 98,
    total_emissions_mt: 482,
    vin: "21HGCM841",
    make: "Jaguar",
    model: "F-Type",
    year: "2022",
    engine_type: "V8",
    vehicle_type: "Medium Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 21,
    rating: 61.26,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.15,
    emissions_by_dist_ch4_mt: 0.21,
    total_idle_time_fuel_consumed: 3.99,
    average_speed: 33.75,
    total_estimated_mileage_miles: 856.88,
    total_drivetime: 98.85,
    co2_miles: 148.61,
    days_active: 794,
    total_fuel_consumed_gallons: 353.76,
    total_fuel_purchased_gallons: 63.15,
    total_amt_spent_fuel: 583.82,
    grams_mile_co2: 387.1,
    total_co2e_emission: 63.67,
    emissions_by_fuel_co2_mt: 3.14,
  },
  {
    vehicle_id: "2655",
    idle_time_emissions_by_fuel_co2_mt: 261,
    total_idle_time_hrs: 80,
    total_emissions_mt: 634,
    vin: "22HGCM842",
    make: "Land Rover",
    model: "Defender",
    year: "2022",
    engine_type: "V8",
    vehicle_type: "Heavy Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 22,
    rating: 60.31,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.47,
    emissions_by_dist_ch4_mt: 0.39,
    total_idle_time_fuel_consumed: 4.72,
    average_speed: 1.14,
    total_estimated_mileage_miles: 331.1,
    total_drivetime: 48.87,
    co2_miles: 955.11,
    days_active: 789,
    total_fuel_consumed_gallons: 746.31,
    total_fuel_purchased_gallons: 877.17,
    total_amt_spent_fuel: 891.07,
    grams_mile_co2: 17.13,
    total_co2e_emission: 14.3,
    emissions_by_fuel_co2_mt: 9.31,
  },
  {
    vehicle_id: "3733",
    idle_time_emissions_by_fuel_co2_mt: 241,
    total_idle_time_hrs: 10,
    total_emissions_mt: 734,
    vin: "23HGCM843",
    make: "Volvo",
    model: "XC90",
    year: "2022",
    engine_type: "V6",
    vehicle_type: "Medium Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 23,
    rating: 59.36,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 1,
    emissions_by_dist_ch4_mt: 0.31,
    total_idle_time_fuel_consumed: 3.19,
    average_speed: 90.32,
    total_estimated_mileage_miles: 948.6,
    total_drivetime: 25.68,
    co2_miles: 387.68,
    days_active: 299,
    total_fuel_consumed_gallons: 140.53,
    total_fuel_purchased_gallons: 150.16,
    total_amt_spent_fuel: 980.29,
    grams_mile_co2: 316.04,
    total_co2e_emission: 29.69,
    emissions_by_fuel_co2_mt: 0.09,
  },
  {
    vehicle_id: "5244",
    idle_time_emissions_by_fuel_co2_mt: 210,
    total_idle_time_hrs: 15,
    total_emissions_mt: 520,
    vin: "24HGCM844",
    make: "Subaru",
    model: "Outback",
    year: "2022",
    engine_type: "H4",
    vehicle_type: "Light Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 24,
    rating: 58.41,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.42,
    emissions_by_dist_ch4_mt: 0.3,
    total_idle_time_fuel_consumed: 7.72,
    average_speed: 65.51,
    total_estimated_mileage_miles: 47.89,
    total_drivetime: 12.4,
    co2_miles: 63.92,
    days_active: 242,
    total_fuel_consumed_gallons: 229.35,
    total_fuel_purchased_gallons: 358.24,
    total_amt_spent_fuel: 949.44,
    grams_mile_co2: 902.42,
    total_co2e_emission: 78.64,
    emissions_by_fuel_co2_mt: 2.42,
  },
  {
    vehicle_id: "4819",
    idle_time_emissions_by_fuel_co2_mt: 221,
    total_idle_time_hrs: 12,
    total_emissions_mt: 436,
    vin: "24HGCM814",
    make: "Subaru",
    model: "Outback",
    year: "2022",
    engine_type: "V4",
    vehicle_type: "Heavy Duty Vehicle",
    fuel_type: "Diesel",
    ranking: 25,
    rating: 53.16,
    drive_status: "active",
    emissions_by_dist_n2o_mt: 0.89,
    emissions_by_dist_ch4_mt: 0.54,
    total_idle_time_fuel_consumed: 0.56,
    average_speed: 34.4,
    total_estimated_mileage_miles: 427.47,
    total_drivetime: 97.32,
    co2_miles: 650.4,
    days_active: 745,
    total_fuel_consumed_gallons: 518.2,
    total_fuel_purchased_gallons: 995.94,
    total_amt_spent_fuel: 421.11,
    grams_mile_co2: 451.35,
    total_co2e_emission: 79.76,
    emissions_by_fuel_co2_mt: 6.46,
  },
];

const mockVehiclePerformanceDataTable2 = mockVehiclePerformanceDataTable1?.map(
  (item) => {
    return {
      ...item,
      total_emissions_mt: item.total_emissions_mt + 20,
      idle_time_emissions_by_fuel_co2_mt:
        item.idle_time_emissions_by_fuel_co2_mt + 20,
      total_idle_time_hrs: item.total_idle_time_hrs + 20,
      co2_miles: item.co2_miles + 20,
    };
  }
);
const mockVehiclePerformanceDataTable3 = mockVehiclePerformanceDataTable1?.map(
  (item) => {
    return {
      ...item,
      total_emissions_mt: item.total_emissions_mt + 4,
      idle_time_emissions_by_fuel_co2_mt:
        item.idle_time_emissions_by_fuel_co2_mt + 4,
      total_idle_time_hrs: item.total_idle_time_hrs + 4,
      co2_miles: item.co2_miles + 4,
    };
  }
);
export const mockVehiclePerformanceDataTable = {
  1: mockVehiclePerformanceDataTable1,
  2: mockVehiclePerformanceDataTable2,
  3: mockVehiclePerformanceDataTable3,
};
