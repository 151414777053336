// Functionality: API Call for Cost of Carbon Tile
import axios from "../loadBalancerAxios";
import { COST_OF_CARBON_URL } from "../paths";
import { costOfCarbonTileConfig } from "../../constants/EmissionsConfig";

export const getCostOfCarbonAPI = async (setData, selectedDate) => {
  await axios
    .get(`${COST_OF_CARBON_URL}?date=${selectedDate}`)
    .then((response) => {
      // Const: Set Cost of Carbon Data
      const responseData = response?.data?.data?.toFixed(2) || "-";
      setData({
        ...costOfCarbonTileConfig,
        value: responseData,
        dollar: true,
        loaded: true,
      });
    })
    .catch(() => {
      // Const: Set Cost of Carbon Data to "-" when null
      const responseData = "-";
      setData({
        ...costOfCarbonTileConfig,
        value: responseData,
        dollar: true,
        loaded: true,
      });
    });
};
