// Functionality: Handles the API call to trigger an email with verification code to reset the password
import axios from "../loadBalancerAxios";
import { FORGOT_PASSWORD_UPDATE_URL } from "../paths";
import { mockLoginData } from "../../constants/mockDataConfig";

export const putForgotPasswordVerify = async (
  forgotPWState,
  navigate,
  navTo,
  setErrMsg
) => {
  // Check if the user logging in is the mock user
  if (
    forgotPWState.email === mockLoginData?.email &&
    forgotPWState.password === mockLoginData?.password
  ) {
  } else {
    // Otherwise, normally log in the user
    await axios
      .put(FORGOT_PASSWORD_UPDATE_URL, {
        email: forgotPWState?.email,
        verification: forgotPWState?.code,
        new_password: forgotPWState?.confirm_password,
        "Content-Type": "application/json",
      })
      .then((response) => {
        // API Response - Set Err Msg if Incorrect Fields, Else Reroute to Log in
        let signupResponse = response?.data;

        // Set Error Messgae
        if (signupResponse?.success !== "Password successfully updated") {
          setErrMsg("Unable to update password.");
        } else {
          // Clear Error Messgae
          setErrMsg("");
          navigate(navTo, { replace: true });
        }
      })
      .catch((err) => {
        // Maybe show a snackbar here if the user is unable to create their password if they already have their account setup
        setErrMsg("Unable to create password.");
      });
  }
};
