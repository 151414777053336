// Functionality: React function to generate a table config given an array of column headers

// Const: Front end mapping converting the column names to the column headers
const frontEndColumnMapping = {
  vehicle_id: "Vehicle",
  vehicle: "Vehicle",
  emissions: "Emissions",
  date: "Date",
  name: "Category",
  description: "Description",
  impact: "Impact",
  dateDownload: "Date Downloaded",
  timePeriod: "Time Period",
  reportType: "Type",
  total_idle_time_hrs: "Idle Time (hours)",
  idle_time: "Idle Time",
  total_emissions_mt: "Total CO2e Emissions",
  idle_time_emissions_by_fuel_co2_mt: "Idle Time Emissions (MT CO2)",
  emissions_by_dist_ch4_mt: "CH4 Emissions (kg)",
  emissions_by_dist_n2o_mt: "N2O Emissions (kg)",
  emissions_by_fuel_co2_mt: "Carbon Emissions (MT)",
  electricity_purchased_kwh: "Electricity Purchased (kWh)",
  invoice_id: "Invoice ID",
  vin: "VIN",
  gvwr: "Class",
  engine_manufacturer: "Engine Manufacturer",
  engine_type: "Engine Model",
  manufacturer_name: "Manufacturer",
  last_updated: "Last Updated",
  // ** Vehicle Settings Columns ** May need to override in future
  year: "Model Year",
  fuel_type: "Primary Fuel Type",
};

// Function to generate the table configuration for the table component
export default function UniversalTableConfig(
  columns,
  handleRouteVehicleProfile = () => {}
) {
  // Const: Table config to return and use within the table component
  const tableConfig = columns?.map((column) => {
    if (["vehicle_id", "vehicle"].includes(column)) {
      return {
        Header: "Vehicle",
        accessor: (universalData) => (
          <div
            className="table-link-col-group"
            onClick={() => handleRouteVehicleProfile(universalData?.[column])}
          >
            {parseInt(universalData?.[column]) || universalData?.[column]}
          </div>
        ),
        sortValue: (universalData) => universalData?.[column],
      };
    } else {
      return {
        Header:
          frontEndColumnMapping?.[column] ||
          column?.slice(0, 1)?.toUpperCase() +
            column?.replaceAll("_", " ")?.slice(1),
        accessor: (universalData) => universalData?.[column],
        sortValue: (universalData) => universalData?.[column] || column,
      };
    }
  });
  return tableConfig;
}
