// Functionality: React hook to generate and apply a formatting for a date to display within the application

export default function useDateFormat(inputDate, typeFormat = "month") {
  // Parse the input date in yyyy-MM format
  const [year, month, day] = inputDate.split("-");
  const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1);

  let formattedDate = "";

  if (typeFormat === "month-year") {
    const fullMonth = date.toLocaleString("en-US", { month: "long" });
    formattedDate = `${fullMonth} ${year}`;
  } else if (typeFormat === "month") {
    const shortMonth = date.toLocaleString("en-US", { month: "short" });
    formattedDate = shortMonth;
  } else if (typeFormat === "year") {
    formattedDate = year;
  } else if (typeFormat === "month-day") {
    const shortMonth = date.toLocaleString("en-US", { month: "short" });
    formattedDate = `${shortMonth} ${day}`;
  } else if (typeFormat === "month-year-day") {
    const fullMonth = date.toLocaleString("en-US", { month: "long" });
    formattedDate = `${day} ${fullMonth} ${year}`;
  } else {
    throw new Error("Unsupported format");
  }

  return formattedDate;
}
