// Functionality: This file exports the StrategyReport component which is used in the Report Page component.
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import ReportContext from "../../../context/ReportsProvider";
import RadioItemGroup from "../../../components/RadioItemGroup";
import TextArea from "../../../components/TextArea";
import CheckBoxGroup from "../../../components/CheckBox";
import {
  reportQuestionConfig,
  climateTransitionPlanRadioOptions,
  climateRelatedScenarioAnalysisRadioOptions,
  yesNoOtherDropdownOptions,
  relevantScenariosCheckboxOptions,
  relevantParametersCheckboxOptions,
  relevantAssumptionsCheckboxOptions,
} from "../../../constants/ReportsConfig";

export default function StrategyReport({
  generateQuestionGroup,
  handleReportEventChange,
  reportInputClassName,
}) {
  // Const: Initialize Report Context
  const { strategyDataConfig, setStrategyDataConfig } =
    useContext(ReportContext);

  // UE: Clear Board Level Oversight Input if checkbox is not other
  useEffect(() => {
    if (
      strategyDataConfig?.climate_plan?.radio !== "Other" &&
      strategyDataConfig?.climate_plan?.textArea !== ""
    ) {
      setStrategyDataConfig((prevState) => ({
        ...prevState,
        climate_plan: {
          radio: prevState.climate_plan.radio,
          textArea: "",
        },
      }));
    } else if (
      strategyDataConfig?.scenario_analysis?.radio !== "Other" &&
      strategyDataConfig?.scenario_analysis?.textArea !== ""
    ) {
      setStrategyDataConfig((prevState) => ({
        ...prevState,
        scenario_analysis: {
          radio: prevState.scenario_analysis.radio,
          textArea: "",
        },
      }));
    } else if (
      !strategyDataConfig?.scenarios?.checkbox?.includes("Other") &&
      strategyDataConfig?.scenarios?.textArea !== ""
    ) {
      setStrategyDataConfig((prevState) => ({
        ...prevState,
        scenarios: {
          checkbox: prevState.scenarios.checkbox,
          textArea: "",
        },
      }));
    } else if (
      !strategyDataConfig?.parameters?.checkbox?.includes("Other") &&
      strategyDataConfig?.parameters?.textArea !== ""
    ) {
      setStrategyDataConfig((prevState) => ({
        ...prevState,
        parameters: {
          checkbox: prevState.parameters.checkbox,
          textArea: "",
        },
      }));
    } else if (
      !strategyDataConfig?.assumptions?.checkbox?.includes("Other") &&
      strategyDataConfig?.assumptions?.textArea !== ""
    ) {
      setStrategyDataConfig((prevState) => ({
        ...prevState,
        assumptions: {
          checkbox: prevState.assumptions.checkbox,
          textArea: "",
        },
      }));
    } else if (
      strategyDataConfig?.carbon_offsets?.radio !== "Yes" &&
      strategyDataConfig?.carbon_offsets?.textArea !== ""
    ) {
      setStrategyDataConfig((prevState) => ({
        ...prevState,
        carbon_offsets: {
          radio: prevState.carbon_offsets.radio,
          textArea: "",
        },
      }));
    } else if (
      strategyDataConfig?.carbon_offsets_certified?.radio !== "Yes" &&
      strategyDataConfig?.carbon_offsets_certified?.textArea !== ""
    ) {
      setStrategyDataConfig((prevState) => ({
        ...prevState,
        carbon_offsets_certified: {
          radio: prevState.carbon_offsets_certified.radio,
          textArea: "",
        },
      }));
    } else if (
      strategyDataConfig?.carbon_offsets_approved?.radio !== "Yes" &&
      strategyDataConfig?.carbon_offsets_approved?.textArea !== ""
    ) {
      setStrategyDataConfig((prevState) => ({
        ...prevState,
        carbon_offsets_approved: {
          radio: prevState.carbon_offsets_approved.radio,
          textArea: "",
        },
      }));
    } else if (
      strategyDataConfig?.contractual_instruments?.radio !== "Yes" &&
      strategyDataConfig?.contractual_instruments?.textArea !== ""
    ) {
      setStrategyDataConfig((prevState) => ({
        ...prevState,
        contractual_instruments: {
          radio: prevState.contractual_instruments.radio,
          textArea: "",
        },
      }));
    } else if (
      strategyDataConfig?.sold_transferred_offsets?.radio !== "Yes" &&
      strategyDataConfig?.sold_transferred_offsets?.textArea !== ""
    ) {
      setStrategyDataConfig((prevState) => ({
        ...prevState,
        sold_transferred_offsets: {
          radio: prevState.sold_transferred_offsets.radio,
          textArea: "",
        },
      }));
    }
  }, [
    strategyDataConfig?.climate_plan?.radio,
    strategyDataConfig?.climate_plan?.textArea,
    strategyDataConfig?.scenario_analysis?.radio,
    strategyDataConfig?.scenario_analysis?.textArea,
    strategyDataConfig?.scenarios?.checkbox,
    strategyDataConfig?.scenarios?.textArea,
    strategyDataConfig?.parameters?.checkbox,
    strategyDataConfig?.parameters?.textArea,
    strategyDataConfig?.assumptions?.checkbox,
    strategyDataConfig?.assumptions?.textArea,
    strategyDataConfig?.carbon_offsets?.radio,
    strategyDataConfig?.carbon_offsets?.textArea,
    strategyDataConfig?.carbon_offsets_certified?.radio,
    strategyDataConfig?.carbon_offsets_certified?.textArea,
    strategyDataConfig?.carbon_offsets_approved?.radio,
    strategyDataConfig?.carbon_offsets_approved?.textArea,
    strategyDataConfig?.contractual_instruments?.radio,
    strategyDataConfig?.contractual_instruments?.textArea,
    strategyDataConfig?.sold_transferred_offsets?.radio,
    strategyDataConfig?.sold_transferred_offsets?.textArea,
    setStrategyDataConfig,
  ]);

  // Dictionary: UI/UX for each of the questions, in order
  const questionGroupConfig = {
    0: (
      <>
        <RadioItemGroup
          options={climateTransitionPlanRadioOptions}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "climate_plan",
              "radio",
              setStrategyDataConfig,
              strategyDataConfig
            )
          }
          splitTextByLine={true}
          value={strategyDataConfig?.climate_plan?.radio}
          divClassName="div-radio-item-vertical"
        />
        {strategyDataConfig?.climate_plan?.radio === "Other" && (
          <TextArea
            value={strategyDataConfig?.climate_plan?.textArea}
            placeholder={"Explain"}
            onChange={(e) =>
              handleReportEventChange(
                e,
                "climate_plan",
                "textArea",
                setStrategyDataConfig,
                strategyDataConfig
              )
            }
            className={reportInputClassName?.textArea}
          />
        )}
      </>
    ),
    1: (
      <>
        <RadioItemGroup
          options={climateRelatedScenarioAnalysisRadioOptions}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "scenario_analysis",
              "radio",
              setStrategyDataConfig,
              strategyDataConfig
            )
          }
          value={strategyDataConfig?.scenario_analysis?.radio}
          divClassName="div-radio-item-vertical"
        />
        {strategyDataConfig?.scenario_analysis?.radio === "Other" && (
          <TextArea
            value={strategyDataConfig?.scenario_analysis?.textArea}
            placeholder={"Explain"}
            onChange={(e) =>
              handleReportEventChange(
                e,
                "scenario_analysis",
                "textArea",
                setStrategyDataConfig,
                strategyDataConfig
              )
            }
            className={reportInputClassName?.textArea}
          />
        )}
      </>
    ),
    2: (
      <>
        <p className="report-question-group-container-question">
          Relevant Scenarios
        </p>
        <>
          <CheckBoxGroup
            options={relevantScenariosCheckboxOptions || []}
            selectedOptions={strategyDataConfig?.scenarios?.checkbox}
            divClassName="div-radio-item-vertical"
            onChange={(e) =>
              handleReportEventChange(
                e,
                "scenarios",
                "checkbox",
                setStrategyDataConfig,
                strategyDataConfig
              )
            }
          />
          {strategyDataConfig?.scenarios?.checkbox?.includes("Other") && (
            <TextArea
              value={strategyDataConfig?.scenarios?.textArea}
              placeholder={"Explain"}
              onChange={(e) =>
                handleReportEventChange(
                  e,
                  "scenarios",
                  "textArea",
                  setStrategyDataConfig,
                  strategyDataConfig
                )
              }
            />
          )}
        </>
        <p className="report-question-group-container-question">
          Relevant Parameters
        </p>
        <>
          <CheckBoxGroup
            options={relevantParametersCheckboxOptions || []}
            selectedOptions={strategyDataConfig?.parameters?.checkbox}
            divClassName="div-radio-item-vertical"
            onChange={(e) =>
              handleReportEventChange(
                e,
                "parameters",
                "checkbox",
                setStrategyDataConfig,
                strategyDataConfig
              )
            }
          />
          {strategyDataConfig?.parameters?.checkbox?.includes("Other") && (
            <TextArea
              value={strategyDataConfig?.parameters?.textArea}
              placeholder={"Explain"}
              onChange={(e) =>
                handleReportEventChange(
                  e,
                  "parameters",
                  "textArea",
                  setStrategyDataConfig,
                  strategyDataConfig
                )
              }
            />
          )}
        </>
        <p className="report-question-group-container-question">
          Relevant Assumptions
        </p>
        <>
          <CheckBoxGroup
            options={relevantAssumptionsCheckboxOptions || []}
            selectedOptions={strategyDataConfig.assumptions?.checkbox}
            divClassName="div-radio-item-vertical"
            onChange={(e) =>
              handleReportEventChange(
                e,
                "assumptions",
                "checkbox",
                setStrategyDataConfig,
                strategyDataConfig
              )
            }
          />
          {strategyDataConfig?.assumptions?.checkbox?.includes("Other") && (
            <TextArea
              value={strategyDataConfig?.assumptions?.textArea}
              placeholder={"Explain"}
              onChange={(e) =>
                handleReportEventChange(
                  e,
                  "assumptions",
                  "textArea",
                  setStrategyDataConfig,
                  strategyDataConfig
                )
              }
            />
          )}
        </>
      </>
    ),
    3: (
      <>
        <RadioItemGroup
          options={yesNoOtherDropdownOptions?.slice(0, 2)}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "carbon_offsets",
              "radio",
              setStrategyDataConfig,
              strategyDataConfig
            )
          }
          value={strategyDataConfig?.carbon_offsets?.radio}
          divClassName="div-radio-item-vertical"
        />
        {strategyDataConfig?.carbon_offsets?.radio === "Yes" && (
          <>
            <p className="report-question-group-container-question">
              If yes, please specify the type of offset (e.g., GHG
              storage/removal or emissions reduction project).
            </p>
            <TextArea
              value={strategyDataConfig?.carbon_offsets?.textArea}
              placeholder={"Explain"}
              onChange={(e) =>
                handleReportEventChange(
                  e,
                  "carbon_offsets",
                  "textArea",
                  setStrategyDataConfig,
                  strategyDataConfig
                )
              }
              className={reportInputClassName?.textArea}
            />
          </>
        )}
      </>
    ),
    4: (
      <>
        <RadioItemGroup
          options={yesNoOtherDropdownOptions?.slice(0, 2)}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "carbon_offsets_certified",
              "radio",
              setStrategyDataConfig,
              strategyDataConfig
            )
          }
          value={strategyDataConfig?.carbon_offsets_certified?.radio}
          divClassName="div-radio-item-vertical"
        />
        {strategyDataConfig?.carbon_offsets_certified?.radio === "Yes" && (
          <>
            <p className="report-question-group-container-question">
              If yes, please specify the certifying body (e.g., Clean
              Development Mechanism, Joint Implementation, or other recognized
              GHG programs).
            </p>
            <TextArea
              value={strategyDataConfig?.carbon_offsets_certified?.textArea}
              placeholder={"Explain"}
              onChange={(e) =>
                handleReportEventChange(
                  e,
                  "carbon_offsets_certified",
                  "textArea",
                  setStrategyDataConfig,
                  strategyDataConfig
                )
              }
              className={reportInputClassName?.textArea}
            />
          </>
        )}
      </>
    ),
    5: (
      <>
        <RadioItemGroup
          options={yesNoOtherDropdownOptions?.slice(0, 2)}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "carbon_offsets_approved",
              "radio",
              setStrategyDataConfig,
              strategyDataConfig
            )
          }
          value={strategyDataConfig?.carbon_offsets_approved?.radio}
          divClassName="div-radio-item-vertical"
        />
        {strategyDataConfig?.carbon_offsets_approved?.radio === "Yes" && (
          <>
            <p className="report-question-group-container-question">
              If yes, please specify the program(s) (e.g., Clean Development
              Mechanism, Joint Implementation, or other approved programs).
            </p>
            <TextArea
              value={strategyDataConfig?.carbon_offsets_approved?.textArea}
              placeholder={"Explain"}
              onChange={(e) =>
                handleReportEventChange(
                  e,
                  "carbon_offsets_approved",
                  "textArea",
                  setStrategyDataConfig,
                  strategyDataConfig
                )
              }
              className={reportInputClassName?.textArea}
            />
          </>
        )}
      </>
    ),
    6: (
      <>
        <RadioItemGroup
          options={yesNoOtherDropdownOptions?.slice(0, 2)}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "contractual_instruments",
              "radio",
              setStrategyDataConfig,
              strategyDataConfig
            )
          }
          value={strategyDataConfig?.contractual_instruments?.radio}
          divClassName="div-radio-item-vertical"
        />
        {strategyDataConfig?.contractual_instruments?.radio === "Yes" && (
          <>
            <p className="report-question-group-container-question">
              If yes, please explain.
            </p>
            <TextArea
              value={strategyDataConfig?.contractual_instruments?.textArea}
              placeholder={"Explain"}
              onChange={(e) =>
                handleReportEventChange(
                  e,
                  "contractual_instruments",
                  "textArea",
                  setStrategyDataConfig,
                  strategyDataConfig
                )
              }
              className={reportInputClassName?.textArea}
            />
          </>
        )}
      </>
    ),
    7: (
      <>
        <RadioItemGroup
          options={yesNoOtherDropdownOptions?.slice(0, 2)}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "sold_transferred_offsets",
              "radio",
              setStrategyDataConfig,
              strategyDataConfig
            )
          }
          value={strategyDataConfig?.sold_transferred_offsets?.radio}
          divClassName="div-radio-item-vertical"
        />
        {strategyDataConfig?.sold_transferred_offsets?.radio === "Yes" && (
          <>
            <p className="report-question-group-container-question">
              If yes, please provide details on the nature of these reductions
              and indicate whether they have been verified or certified by an
              external GHG program.
            </p>
            <TextArea
              value={strategyDataConfig?.sold_transferred_offsets?.textArea}
              placeholder={"Explain"}
              onChange={(e) =>
                handleReportEventChange(
                  e,
                  "sold_transferred_offsets",
                  "textArea",
                  setStrategyDataConfig,
                  strategyDataConfig
                )
              }
              className={reportInputClassName?.textArea}
            />
          </>
        )}
      </>
    ),
  };

  return (
    <>
      {generateQuestionGroup(
        reportQuestionConfig?.strategy,
        questionGroupConfig
      )}
    </>
  );
}

// Props
const strategyReportPropTypes = {
  generateQuestionGroup: PropTypes.func, // Function: Generates the UI/UX for each question
  handleReportEventChange: PropTypes.func, // Function: Handles the change of each question
};

StrategyReport.propTypes = strategyReportPropTypes;
