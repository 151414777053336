// Functionality:  Input New Password Fields Forgot Password Page
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import {
  signupInputFields,
  resetPasswordCodeFields,
  passwordRequirementsText,
} from "../../constants/AuthConfig";
import Input from "../../components/Input";
import { IoMdCheckmark } from "react-icons/io";
import useValidatePassword from "../../hooks/use-ValidatePassword";
import VerificationCode from "../../components/VerificationCode";

// Refactor Forgot PW Fields to be in correct format for Inputs
const fields = signupInputFields.slice(2);
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function ForgotPasswordResetFields({
  setLoginErrMsg,
  forgotPWState,
  setForgotPWState,
}) {
  // State: Validation Message - Password
  const [pwValidationMessage, setPWValidationMessage] = useState("default");
  // State: Validation Message - Confirm Password
  const [cpwValidationMessage, setCPWValidationMessage] = useState("default");

  // Const: Passwords Equal
  const isPasswordEqual =
    forgotPWState?.password === forgotPWState?.confirm_password;

  const passwordValidation = useValidatePassword(forgotPWState?.password);

  // UE: Set Password Validation Message
  useEffect(() => {
    let timer;

    const handleValidation = () => {
      // Add your validation logic here
      if (passwordValidation) {
        setPWValidationMessage("good");
      } else {
        setPWValidationMessage("bad");
      }
    };

    if (forgotPWState.password.trim() !== "") {
      timer = setTimeout(handleValidation, 1500);
    } else {
      setPWValidationMessage("default");
      // Clear the validation message if the password is empty
    }

    return () => clearTimeout(timer);
  }, [forgotPWState.password]);

  // UE: Set Confirm Password Validation Message
  useEffect(() => {
    let timer;

    const handleValidation = () => {
      // Add your validation logic here
      if (isPasswordEqual) {
        setCPWValidationMessage("good");
      } else {
        setCPWValidationMessage("bad");
      }
    };

    if (forgotPWState.confirm_password.trim() !== "") {
      timer = setTimeout(handleValidation, 1500);
    } else {
      setCPWValidationMessage("default");
      // Clear the validation message if the password is empty
    }

    return () => clearTimeout(timer);
  }, [forgotPWState.confirm_password]);

  // Function: Handle State Change of Input Fields
  const handleChange = (e) => {
    e.preventDefault();
    setLoginErrMsg("");
    setForgotPWState({ ...forgotPWState, [e.target.id]: e.target.value });
  };

  // Const: Develop Login Input Fields for email & password
  const forgotPWInputFields = fields?.map((field) => {
    // Const: Label Text for Confirm Password
    const determineLabelText = () => {
      // Handle Case For Confirm Password Validation
      if (field?.labelFor === "confirm_password") {
        if (cpwValidationMessage === "bad") {
          return (
            <div>
              <p>{field?.labelText}</p>
              <div className="signup-password-no-match">
                Passwords do not match
              </div>
            </div>
          );
        } else if (cpwValidationMessage === "good") {
          return (
            <div className="signup-password-note-group">
              <p>{field?.labelText}</p>
              <IoMdCheckmark className="signup-password-note-checkmark" />
            </div>
          );
        } else {
          return <p>{field?.labelText}</p>;
        }
      }
      // Handle Case For Password Validation
      else if (field?.labelFor === "password") {
        if (pwValidationMessage === "default") {
          return (
            <div>
              <p>{field?.labelText}</p>
              <div className="signup-password-note">
                {passwordRequirementsText}
              </div>
            </div>
          );
        } else if (pwValidationMessage === "good") {
          return (
            <div>
              <div className="signup-password-note-group">
                <p>{field?.labelText}</p>
                <IoMdCheckmark className="signup-password-note-checkmark" />
              </div>
              <div className="signup-password-note">
                {passwordRequirementsText}
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <div className="signup-password-note-group">
                <p>{field?.labelText}</p>
                <div className="signup-password-no-match">
                  Please enter a valid password
                </div>
              </div>
              <div className="signup-password-note">
                {passwordRequirementsText}
              </div>
            </div>
          );
        }
      } else {
        return <p>{field?.labelText}</p>;
      }
    };

    return (
      <Input
        key={field.id}
        onChange={handleChange}
        value={forgotPWState[field.id]}
        labelFor={field.labelFor}
        id={field.id}
        type={field.type}
        isRequired={field.isRequired}
        disabled={field.disabled}
        placeholder={""}
        label={determineLabelText()}
        divClassName="forgotpw-input-main-container"
        className="signup-input-element font-small-medium"
      />
    );
  });

  return (
    <>
      <VerificationCode
        label={resetPasswordCodeFields?.labelText}
        setCode={setForgotPWState}
        code={forgotPWState}
        stateId={resetPasswordCodeFields?.id}
      />
      {forgotPWInputFields}
    </>
  );
}

// Props
const forgotPWResetFieldsPropTypes = {
  setLoginErrMsg: PropTypes.func, // Function to set the error message
  showForgotPW: PropTypes.bool, // State of the forgot password form
  forgotPWState: PropTypes.object, // State of the forgot password fields
  setForgotPWState: PropTypes.func, // Function to set the state of the forgot password fields
};

ForgotPasswordResetFields.propTypes = forgotPWResetFieldsPropTypes;
