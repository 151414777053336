// Functionality: CheckBox for the application
import PropTypes from "prop-types";

export default function CheckBoxGroup({
  options = [],
  selectedOptions = [],
  setSelectedOptions = () => {},
  divClassName = "checkbox-item-container",
  elementClassName = "checkbox-item-option",
  ...props
}) {
  // Function: Handle the state change in the checkbox group
  const handleOptionChange = (option) => {
    const updatedOptions = selectedOptions.includes(option.value)
      ? selectedOptions.filter(
          (selectedOption) => selectedOption !== option.value
        )
      : [...selectedOptions, option.value];
    setSelectedOptions(updatedOptions);
  };

  return (
    <div className={divClassName}>
      {options?.map((option) => (
        <div
          key={option.value}
          className={elementClassName}
          onClick={() =>
            (props.onChange && props.onChange(option)) ||
            handleOptionChange(option)
          }
        >
          <input
            type="checkbox"
            value={option.value}
            checked={selectedOptions.includes(option.value)}
            onChange={() => {}}
          />
          <label>{option.label}</label>
        </div>
      ))}
    </div>
  );
}

// Props
const checkBoxGroupPropTypes = {
  options: PropTypes.array, // Array of strings options to select
  selectedOption: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]), // Currently selected option
  setSelectedOption: PropTypes.func, // Function to update selected option
  divClassName: PropTypes.string, // Class name for the checkbox container
  elementClassName: PropTypes.string, // Class name for the checkbox element
};

CheckBoxGroup.propTypes = checkBoxGroupPropTypes;
