// Functionality: This file exports the EmissionsForecasting Card component which is used in the Reduction Page component.
import { useContext } from "react";
import ReductionContext from "../../context/ReductionProvider.js";
import useSub from "../../hooks/use-Sub";
import ForecastLineChart from "../../visualizations/ForecastChart";
import Skeleton from "react-loading-skeleton";
import { forecastingChartTile } from "../../constants/TileConfig";
export default function EmissionsForecasting() {
  // Const: Initialize Context
  const { emissionsForecastingData } = useContext(ReductionContext);

  // Const: Title Group for card
  const titleGroup = (
    <div>
      <div className="card-title">
        Emissions Forecasting
        {forecastingChartTile?.blurb}
      </div>
      <p className="card-subtitle">{useSub("MT CO2e")}</p>
    </div>
  );

  // Const: Forecasting Chart Card
  const forecastChartCard = (
    <div className="chart-card-container">
      {emissionsForecastingData?.lineBreak ? (
        <ForecastLineChart
          labels={emissionsForecastingData?.labels}
          actualData={emissionsForecastingData?.actualData}
          forecastData={emissionsForecastingData?.forecastData}
          lowerboundData={emissionsForecastingData?.lowerboundData}
          upperboundData={emissionsForecastingData?.upperboundData}
          indexes={emissionsForecastingData?.indexes}
          lineBreak={emissionsForecastingData?.lineBreak}
          maxValue={emissionsForecastingData?.maxValue}
          annotationValue={emissionsForecastingData?.annotationValue}
        />
      ) : (
        <div disabled={true} className={"forecast-disabled-container"}>
          <p>
            Please allow at least 30 days worth of data collection to populate
            before accessing the forecasting feature
          </p>
        </div>
      )}
    </div>
  );

  return (
    <>
      {!emissionsForecastingData?.loaded ? (
        <Skeleton height={400} />
      ) : (
        <div className="emission-forecasting-container">
          {titleGroup}
          {forecastChartCard}
        </div>
      )}
    </>
  );
}
