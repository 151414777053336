// Functionality: Handles the API call to create the password when the user first signs up
import axios from "../loadBalancerAxios";
import { CREATE_PASSWORD_URL } from "../paths";
import { mockLoginData } from "../../constants/mockDataConfig";

export const putCreatePassword = async (
  signupState,
  navigate,
  navTo,
  setErrMsg
) => {
  // Check if the user logging in is the mock user
  if (
    signupState.email === mockLoginData?.email &&
    signupState.password === mockLoginData?.password
  ) {
  } else {
    // Otherwise, normally log in the user
    await axios
      .put(CREATE_PASSWORD_URL, {
        ...signupState,
        "Content-Type": "application/json",
      })
      .then((response) => {
        // API Response - Set Err Msg if Incorrect Fields, Else Reroute to Log in
        let signupResponse = response?.data;

        // Set Error Messgae
        if (signupResponse?.success !== "password successfully created.") {
          setErrMsg("Unable to create password.");
        } else {
          // Clear Error Messgae
          setErrMsg("");
          navigate(navTo, { replace: true });
        }
      })
      .catch((err) => {
        const errMsg = err?.response?.data?.error;
        // Maybe show a snackbar here if the user is unable to create their password if they already have their account setup
        setErrMsg(errMsg);
      });
  }
};
