// Functionality:  Reroute the user back to login
import { NavLink } from "react-router-dom";

export default function BackLoginRedirect() {
  // Const: Forgot Password Button Name
  const backToLoginButtonName = "Back to log in";

  // Component: Login Submit Button
  const backToLoginButtonRedirect = (
    <li>
      <NavLink to={"/"} activeclassname="active">
        {backToLoginButtonName}
      </NavLink>
    </li>
  );

  return (
    <>
      <div className="forgotpw-button-container">
        {backToLoginButtonRedirect}
      </div>
    </>
  );
}
