// Functionality: Provide an application header - different from the navigation bar - this will be used within each of the pages. File generated to avoid repeated code
import PropTypes from "prop-types";
import { useContext } from "react";
import { AuthContext } from "../context/AuthProvider";

export default function ApplicationHeader({ headerName, ...props }) {
  const { carrierName } = useContext(AuthContext);

  return (
    <div className="page-header">
      <div>
        <p className="page-title">{headerName}</p>
        {props.children}
      </div>
      <div>
        <p className="company-name-title">{carrierName}</p>
      </div>
    </div>
  );
}

// Props
const applicationHeaderPropTypes = {
  headerName: PropTypes.string, // Page header name
  companyName: PropTypes.string, // Company name
};

ApplicationHeader.propTypes = applicationHeaderPropTypes;
