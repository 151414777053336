// Functionality: API Call for patching Scope 2 Emissions Table - Information used in the scope 2 manager table and within the scope 2 emissions visualization
import axios from "../../loadBalancerAxios";
import { SCOPE_2_MANAGER_URL } from "../../paths";

export const deleteScope2ManagerEntries = async (data, setMsg) => {
  await axios
    .delete(`${SCOPE_2_MANAGER_URL}?entry_id=${data}`)
    .then((response) => {
      if (response.status === 204) {
        setMsg({
          text: "Unable to delete entry",
          msg: "warning",
        });
      } else {
        setMsg({
          text: "Successfully deleted entry",
          msg: "success",
        });
      }
    })
    .catch(() => {
      setMsg({
        text: "Unable to delete entry",
        msg: "warning",
      });
    });
};
