import { useEffect } from "react";
import MetricTileGroupMini from "../../../components/MetricTileGroupMini";
import SwitchCarousel from "../../../components/SwitchCarousel";
import Skeleton from "react-loading-skeleton";

export default function GlecFrameworkCards({
  wellToTankData,
  tankToWheelData,
  wellToWheelData,
  dateValues,
  applicationDates,
  setApplicationDates,
  glecLifeCycleChartData,
  setWellToWheelData,
  setTanktoWheelData,
  setWellToTankData,
}) {
  //  UE: Get GLEC Life Cycle Data For Application(Trigger API Call) When applicationDates Changes
  useEffect(() => {
    if (glecLifeCycleChartData?.loaded) {
      const wellToTank = glecLifeCycleChartData?.data?.[0]?.dataValues;
      const tankToWheel = glecLifeCycleChartData?.data?.[1]?.dataValues;
      const wellToWheel = glecLifeCycleChartData?.data?.[2]?.dataValues;
      const result = wellToTank?.find(
        (dict) => dict.date === applicationDates?.lifecycle
      );
      const result2 = tankToWheel?.find(
        (dict) => dict?.date === applicationDates?.lifecycle
      );
      const result3 = wellToWheel?.find(
        (dict) => dict?.date === applicationDates?.lifecycle
      );
      setWellToWheelData({
        ...wellToWheelData,
        value:
          result3?.y !== undefined
            ? Math.round(result3?.y)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "-",
        loaded: true,
      });
      setTanktoWheelData({
        ...tankToWheelData,
        value:
          result2?.y !== undefined
            ? Math.round(result2?.y)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "-",
        loaded: true,
      });
      setWellToTankData({
        ...wellToTankData,
        value:
          result?.y !== undefined
            ? Math.round(result?.y)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "-",
        loaded: true,
      });
    }
  }, [applicationDates?.lifecycle, glecLifeCycleChartData]);

  const mainSwitchCarousel = (
    <div className="life-glec-group-switch-carousel">
      <SwitchCarousel
        values={dateValues}
        applicationDates={applicationDates}
        setApplicationDates={setApplicationDates}
        applicationDatesLabel={"lifecycle"}
        fillArrowColor={"#66C743"}
        switchCarouselValueClassName="group-switch-carousel-value"
      />
    </div>
  );
  return (
    <div className="life-glec-sidebar-group">
      {mainSwitchCarousel}
      <div className="life-glec-card-group">
        {wellToWheelData?.loaded ? (
          <MetricTileGroupMini svgConfig={wellToTankData} />
        ) : (
          <Skeleton height={100} />
        )}
        {tankToWheelData?.loaded ? (
          <MetricTileGroupMini svgConfig={tankToWheelData} />
        ) : (
          <Skeleton height={100} />
        )}
        {wellToWheelData?.loaded ? (
          <MetricTileGroupMini svgConfig={wellToWheelData} />
        ) : (
          <Skeleton height={100} />
        )}
      </div>
    </div>
  );
}
