// Functionality: Line Chart component for the application
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";

// Function: Calculate the new color with the given opacity & rgbaColor
function calculateNewColorWithOpacity(rgbaColor, opacityPercentage) {
  // Parse the RGBA color string and extract color components and opacity
  const rgbaValues = rgbaColor
    .substring(5, rgbaColor.length - 1)
    .split(",")
    .map((value) => parseFloat(value));

  const [r, g, b, a] = rgbaValues;

  // Ensure opacity percentage is between 0 and 100
  const opacity = Math.min(100, Math.max(0, opacityPercentage)) / 100;

  // Calculate the new RGBA values with the given opacity
  const newR = Math.round(r);
  const newG = Math.round(g);
  const newB = Math.round(b);
  const newA = opacity;

  // Construct the new RGBA color string
  const newColor = `rgba(${newR}, ${newG}, ${newB}, ${newA})`;

  return newColor;
}

export default function LineChart({
  datasets,
  lineBreak,
  maxValue,
  annotationValue,
  colorGradientStrength = 170,
}) {
  // Refs
  const lineChartCanvasRef = useRef(null); // Refs to Line Chart
  const chartType = "line"; // Type of chart is line

  // UE: Generate the Line Chart - Rerender when the datasets change and on initial load
  useEffect(() => {
    if (!lineChartCanvasRef.current) return; // Ensure canvas is defined
    if (datasets) {
      // Format the datasets for the Line Chart
      const formattedDatasets = datasets?.map((dataset) => {
        const ctx = lineChartCanvasRef.current.getContext("2d");
        const gradient = ctx.createLinearGradient(
          0,
          0,
          0,
          colorGradientStrength
        );
        gradient.addColorStop(
          0,
          `${calculateNewColorWithOpacity(dataset?.color, 1)}`
        ); // Starting color
        gradient.addColorStop(1, `rgba(255, 255, 255)`); // Ending color (transparent)

        return {
          label: dataset?.label,
          data: dataset?.dataValues?.map((item) => ({
            x: item?.x,
            y: item?.y,
          })),
          borderColor: dataset?.color,
          borderWidth: !dataset?.trendline ? 2.5 : 1,
          fill: !dataset?.trendline,
          backgroundColor: gradient,
          pointRadius: dataset?.pointRadius || 0,
          pointHoverRadius: 20, // Larger hover radius
          // pointHoverBorderColor: "transparent", // Set hover border color to transparent
        };
      });
      // Const: Configuration for Annotiations of Line Break if applicable
      const lineBreakAnnotation = lineBreak
        ? {
            line1: {
              type: "line",
              xMin: datasets?.[0]?.dataValues?.[lineBreak]?.x,
              xMax: datasets?.[0]?.dataValues?.[lineBreak]?.x,
              borderColor: "black",
              borderWidth: lineBreak ? 1 : 0,
              mode: "vertical",
              borderDash: [5, 5],
            },
            label1: {
              type: "label",
              xValue: datasets?.[0]?.dataValues?.[lineBreak - 1]?.x,
              yValue: maxValue,
              backgroundColor: "rgba(245,245,245)",
              content: [annotationValue?.first],
              font: {
                size: 12,
              },
            },
            label2: {
              type: "label",
              xValue: datasets?.[0]?.dataValues?.[lineBreak + 1]?.x,
              yValue: maxValue,
              backgroundColor: "rgba(245,245,245)",
              content: [annotationValue?.second],
              font: {
                size: 12,
              },
            },
          }
        : {};

      // Const: Configuration for the Line Chart
      const lineChartConfig = {
        type: chartType,

        data: {
          labels: formattedDatasets?.[0]?.data.map((item) => item?.x), // Using the first dataset's x values as labels
          datasets: formattedDatasets,
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
            },
            x: {
              stacked: true,
              grid: {
                display: false,
              },
              ticks: {
                callback: (index) => {
                  return datasets?.[0]?.dataValues?.[index]?.label;
                },
              },
            },
          },
          plugins: {
            legend: {
              position: "bottom", // Position the legend at the bottom
              align: "start", // Align the legend to the start (left)
              labels: {
                generateLabels: function (chart) {
                  const labels =
                    Chart.defaults.plugins.legend.labels.generateLabels(chart);

                  labels.forEach((label) => {
                    const dataset = chart.data.datasets.find(
                      (dataset) => dataset?.label === label.text
                    );
                    if (dataset) {
                      label.fillStyle = dataset?.borderColor; // Set fill color to match dataset's border color
                    }
                  });

                  return labels;
                },
              },
            },
            annotation: {
              clip: false,
              annotations: lineBreakAnnotation,
            },
            tooltip: {
              callbacks: {
                // This function changes the label for each data point when hovering
                title: function (tooltipItem, data) {
                  const dataIndexValue = tooltipItem?.[0]?.dataIndex;
                  return datasets?.[0]?.dataValues?.[dataIndexValue]?.label;
                },
              },
            },
          },
        },
      };

      // Generate the Line Chart Component
      const chart = new Chart(lineChartCanvasRef.current, lineChartConfig);

      // Cleanup function when component is unmounted
      return () => {
        chart.destroy(); // Cleanup the chart on component unmount
      };
    }
  }, [datasets]);

  return (
    <canvas
      ref={lineChartCanvasRef}
      id="line-chart-container"
      className="line-chart"
    />
  );
}

// Props
const lineChartPropTypes = {
  data: PropTypes.object, // Data to be displayed in the line chart, if array length > 0 then will be a multi-line chart
};

LineChart.propTypes = lineChartPropTypes;
