// Functionality: API Call to generate a new scope 2 emissions input (electricity usage in KwH or MwH)
import axios from "../../loadBalancerAxios";
import { SCOPE_2_MANAGER_URL } from "../../paths";

// INITIAL DATA TO RESET THE FORM AFTER SUBMISSION
import { isScope2ManagerNewEntry } from "../../../constants/initialDataConfig";

export const postScope2ManagerEntries = async (data, setData, setMsg) => {
  await axios
    .post(SCOPE_2_MANAGER_URL, {
      ...data,
      siteLocation: `${data?.siteLocation?.[0]?.label} - ${data?.siteLocation?.[0]?.value?.site_id}`,
      wattage: parseInt(data?.wattage.replace(/,/g, ""), 10),
    })
    .then(() => {
      setMsg({
        text: "Successfully Added Scope 2 Emission Entry",
        msg: "success",
      });
      setData(isScope2ManagerNewEntry);
    })
    .catch(() => {
      setMsg({
        text: "Please enter valid fields",
        msg: "warning",
      });
    });
};
