// Functionality: This is the VehicleProfileGroupPage component of VehiclePerformancePage.
import { useState, useContext, useEffect } from "react";
import VehiclePerformanceContext from "../../context/VehiclePerformanceProvider";
import VPDataFilter from "./Data/VPDataFilter";
import VPProfileGroupCards from "./ProfileGroup/VPProfileGroupCards";
import useQueryParams from "../../hooks/use-queryParams";
import VehicleProfile from "./VehicleProfile";
import Skeleton from "react-loading-skeleton";

export default function VehicleProfileGroupPage({ setActiveTab, activeTab }) {
  // Get the query parameters from the URL for the username
  const query = useQueryParams();
  const selectedVehicle = query.get("vehicle-profile") || null;
  const mockData = localStorage.getItem("greenToken") === "mockData";

  // Context: Get Values from Vehicle Performance Context
  const {
    vehicleProfileData,
    filtersIsLoading,
    vehicleProfileFilterEmissions,
    setVehicleProfileFilterEmissions,
    vehicleProfileFilterIdleTime,
    setVehicleProfileFilterIdleTime,
    vehicleProfileFilterIdleTimeEmissions,
    setVehicleProfileFilterIdleTimeEmissions,
    vehicleProfileFilterVehicle,
    setVehicleProfileFilterVehicle,
    clearButtonFilter,
    setClearButtonFilter,
    vehicleProfileFilterCarbonEmissions,
    setVehicleProfileFilterCarbonEmissions,
    vehicleProfileFilterCH4,
    setVehicleProfileFilterCH4,
    vehicleProfileFilterN2O,
    setVehicleProfileFilterN2O,
    setProfileFilterString,
    generateFilterString,
    vehicleProfileFilterEpaRating,
    setVehicleProfileFilterEpaRating,
    vehicleProfileDataPage,
    setVehicleProfileDataPage,
  } = useContext(VehiclePerformanceContext);

  // Set State: Filtered Vehicle Data for Profile Group
  const [filteredVehicleData, setFilteredVehicleData] = useState(
    vehicleProfileData?.data
  );
  // UE: Update Filtered Vehicle Data for Profile Group
  useEffect(() => {
    if (mockData && vehicleProfileData?.loaded && !filtersIsLoading?.profile) {
      // Apply min max filters to data
      const minMaxFilterData = [
        vehicleProfileFilterEmissions,
        vehicleProfileFilterIdleTime,
        vehicleProfileFilterIdleTimeEmissions,
      ]
        ? vehicleProfileData?.data?.filter((row) => {
            return [
              vehicleProfileFilterEmissions,
              vehicleProfileFilterIdleTime,
              vehicleProfileFilterIdleTimeEmissions,
            ].every(({ column, data: [min, max] }) => {
              const value = parseFloat(row[column]);
              return value >= parseFloat(min) && value <= parseFloat(max);
            });
          })
        : vehicleProfileData?.data;

      const filteredData =
        vehicleProfileFilterVehicle &&
        vehicleProfileFilterVehicle.data.length > 0
          ? minMaxFilterData.filter((row) => {
              const filterColumn = vehicleProfileFilterVehicle.column;
              const filterValues = vehicleProfileFilterVehicle.data.map(
                ({ value }) => value
              );
              return filterValues.includes(row[filterColumn]);
            })
          : minMaxFilterData;

      setFilteredVehicleData(filteredData);
    }
  }, [
    vehicleProfileFilterVehicle,
    vehicleProfileFilterEmissions,
    vehicleProfileFilterIdleTime,
    vehicleProfileFilterIdleTimeEmissions,
  ]);
  return (
    <>
      {selectedVehicle ? (
        <>
          <VehicleProfile
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            mockData={mockData}
          />
        </>
      ) : (
        <div className="vp-data-container">
          <VPDataFilter
            page={"profile"}
            filtersIsLoading={filtersIsLoading?.profile}
            filterEmissions={vehicleProfileFilterEmissions}
            setFilterEmissions={setVehicleProfileFilterEmissions}
            filterIdleTime={vehicleProfileFilterIdleTime}
            setFilterIdleTime={setVehicleProfileFilterIdleTime}
            filterIdleTimeEmissions={vehicleProfileFilterIdleTimeEmissions}
            setFilterIdleTimeEmissions={
              setVehicleProfileFilterIdleTimeEmissions
            }
            filterVehicle={vehicleProfileFilterVehicle}
            setFilterVehicle={setVehicleProfileFilterVehicle}
            filterCarbonEmissions={vehicleProfileFilterCarbonEmissions}
            setFilterCarbonEmissions={setVehicleProfileFilterCarbonEmissions}
            filterCH4={vehicleProfileFilterCH4}
            setFilterCH4={setVehicleProfileFilterCH4}
            filterN2O={vehicleProfileFilterN2O}
            setFilterN2O={setVehicleProfileFilterN2O}
            vehicleProfileFilterEpaRating={vehicleProfileFilterEpaRating}
            setVehicleProfileFilterEpaRating={setVehicleProfileFilterEpaRating}
            clearButtonFilter={clearButtonFilter}
            setClearButtonFilter={setClearButtonFilter}
            setFilterString={setProfileFilterString}
            generateFilterString={generateFilterString}
            mockData={mockData}
            setVehicleProfileDataPage={setVehicleProfileDataPage}
          />
          {!vehicleProfileData?.loaded ? (
            <Skeleton height={"100%"} />
          ) : (
            <VPProfileGroupCards
              vehicleProfileData={vehicleProfileData}
              vehicleProfileDataPage={vehicleProfileDataPage}
              setVehicleProfileDataPage={setVehicleProfileDataPage}
            />
          )}
        </div>
      )}
    </>
  );
}
