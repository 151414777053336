import { useContext } from "react";
import DateContext from "../../../context/DateProvider";
import RadioItemGroup from "../../../components/RadioItemGroup";
import useSub from "../../../hooks/use-Sub";
import LineChart from "../../../visualizations/LineChart";
import Skeleton from "react-loading-skeleton";
import SearchBar from "../../../components/SearchBar";
import SwitchCarousel from "../../../components/SwitchCarousel.js";
import { dailyMonthlyRadioItemOptions } from "../../../constants/AuthConfig.js";

export default function GlecFrameworkVisual({
  glecLifeCycleChartData,
  vehicleFuelTypeOptions,
  selectedFuelType,
  setSelectedFuelType,
  ...props
}) {
  // State: Selected Radio Item - Monthly v Daily
  const { selectedChartRadioItemType, setSelectedChartRadioItemType } =
    useContext(DateContext);

  // Const: Super Scripted label for MT CO2e
  const superScriptedLabel = useSub("Metric Tons CO2e");

  // Const: Label Application Dates
  const applicationDatesLabel = "lifecycleChart";

  // Const: Show Date Carousel
  const showDateCarousel = selectedChartRadioItemType?.lifecycle === "Daily";

  // Const: Monthly Disabled
  const monthlyDisabled =
    props.dateValues?.length > 1 ? [] : dailyMonthlyRadioItemOptions[1].value;

  // Function: Clear Selected Fuel Types
  const clearSelectedFuelTypes = () => {
    setSelectedFuelType([]);
  };

  const handleChangeRadioDate = (e) => {
    setSelectedChartRadioItemType((prevState) => ({
      ...prevState,
      lifecycle: e?.target?.value,
    }));
  };

  // Const: Application Dates Switch Carousel
  const lifecycleChartCardSwitchCarousel = showDateCarousel && (
    <div className="group-switch-carousel-no-margin">
      <SwitchCarousel
        values={props.dateValues}
        applicationDates={props.applicationDates}
        setApplicationDates={props.setApplicationDates}
        applicationDatesLabel={applicationDatesLabel}
        fillArrowColor={"#66C743"}
        switchCarouselValueClassName="group-switch-carousel-value"
      />
    </div>
  );

  // Const: Daily v Monthly Radio Item Group
  const dailyMonthlyRadioItemGroup = (
    <RadioItemGroup
      options={dailyMonthlyRadioItemOptions}
      value={selectedChartRadioItemType?.lifecycle}
      onChange={(e) => handleChangeRadioDate(e)}
      disabledOptions={monthlyDisabled}
    />
  );

  return (
    <>
      <div className="life-glec-chart-card-container-group">
        <div className="life-glec-chart-header-container">
          <div>
            <div className="card-title">Lifecycle Emissions</div>
            <div className="card-subtitle">{superScriptedLabel}</div>
          </div>
          <div>
            {dailyMonthlyRadioItemGroup}
            {lifecycleChartCardSwitchCarousel}
          </div>
          <div className="life-glec-chart-header-container">
            <SearchBar
              placeholder={"Filter by Fuel Type"}
              options={vehicleFuelTypeOptions}
              isMultiSelect={true}
              selectedOptions={selectedFuelType}
              setSelectedOptions={setSelectedFuelType}
              checkbox={false}
            />
            <div className="life-glec-selected-fuel-type-group">
              <div className="life-glec-selected-fuel-type">
                {selectedFuelType?.map((fuelType) => (
                  <p key={fuelType.value}>{fuelType.value}</p>
                ))}
              </div>
            </div>
            {selectedFuelType?.length > 0 && (
              <div
                className="life-glec-selected-fuel-clear"
                onClick={() => clearSelectedFuelTypes()}
              >
                x Clear Selection
              </div>
            )}
          </div>
        </div>
        <div className="life-glec-page-chart-container">
          {glecLifeCycleChartData?.loaded ? (
            glecLifeCycleChartData?.data?.slice(-1)?.[0]?.dataValues?.length >
            0 ? (
              <LineChart
                datasets={glecLifeCycleChartData?.data}
                lineBreak={glecLifeCycleChartData?.lineBreak}
                maxValue={glecLifeCycleChartData?.maxValue}
                annotationValue={glecLifeCycleChartData?.annotationValue}
                colorGradientStrength={300}
              />
            ) : (
              <div className="life-glec-error-container">
                {selectedFuelType?.length > 0 && (
                  <p>No datapoints available for selected fuel types: </p>
                )}
                <div className="life-glec-no-data-container">
                  {selectedFuelType?.map((fuelType) => (
                    <p key={fuelType.value}>{fuelType.value}</p>
                  ))}
                </div>
                {selectedFuelType?.length > 0 && (
                  <p>
                    {" "}
                    Please clear your selection or select another fuel type.
                  </p>
                )}
              </div>
            )
          ) : (
            <Skeleton height={400} />
          )}
        </div>
      </div>
    </>
  );
}
