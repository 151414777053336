// Functionality: Front End Pagination For a Table Component
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import Button from "./Button";

export default function Pagination({
  pageCount,
  currentPage,
  onPageChange,
  visiblePages = 3,
  nextPage = false,
}) {
  const totalVisiblePages = pageCount ? Math.min(pageCount, visiblePages) : 0;

  const pages = pageCount
    ? [...Array(totalVisiblePages).keys()]
        .map((i) => i + currentPage)
        .filter((page) => page <= pageCount)
    : [];

  const handlePageChange = (page) => {
    if (page >= 1 && page <= pageCount) {
      onPageChange(page);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < pageCount || nextPage) {
      onPageChange(currentPage + 1);
    }
  };
  return (
    <div className="pagination-container">
      {currentPage > 1 && (
        <Button
          onClick={handlePrev}
          className="pagination-arrow"
          buttonName={<GoChevronLeft />}
        />
      )}
      <div className="pagination-item">
        {pages.map((page) => (
          <Button
            key={page}
            onClick={() => handlePageChange(page)}
            className={currentPage === page ? "active" : ""}
            buttonName={page}
          />
        ))}
      </div>
      {(pageCount && currentPage < pageCount) ||
        (nextPage && (
          <Button
            onClick={handleNext}
            className="pagination-arrow"
            buttonName={<GoChevronRight />}
          />
        ))}
    </div>
  );
}
