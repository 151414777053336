import { createContext, useEffect, useState, useContext, useRef } from "react";
import DateContext from "./DateProvider";
import { getCostOfCarbonAPI } from "../api/Overview/CostOfCarbonAPI";
import { postTotalEmissionFootprintAPI } from "../api/Overview/EmissionsFootprintAPI";
import { getVehicleEmissionsAPI } from "../api/Overview/VehicleEmissionsAPI";
import { postFuelConsumptionAPI } from "../api/Overview/FuelConsumptionAPI";
import { postIndustryCarbonRatingAPI } from "../api/Overview/IndustryCarbonRatingAPI";

// INITIAL DATA TO IMPORT
import {
  costOfCarbonTileConfig,
  fuelConsumptionTileConfig,
  totalEmissionFootprintBreakdownConfig,
  totalEmissionFootprintSVGConfig,
} from "../constants/EmissionsConfig";

// MOCK DATA TO IMPORT
import {
  mockCostofCarbon,
  mockFuelConsumption,
  mockEmissionFootprint,
  mockIndustryCarbonRating,
  mockHEVData,
  mockScope12Percentage,
  mockLoginData,
} from "../constants/mockDataConfig";

const OverviewContext = createContext({});

// Provides access to token across whole application
export const OverviewProvider = ({ children }) => {
  // State: Initialize State - Full Page
  const [overviewIsLoading, setOverviewIsLoading] = useState(true);

  // State: Initialize State - Cost of Carbon
  const [costOfCarbon, setCostOfCarbon] = useState({});

  // State: Initialize State - Emission Footprint Breakdown
  const [emissionFootprintBreakdownData, setEmissionFootprintBreakdownData] =
    useState({});

  // State: Initialize State - Total Emission Footprint
  const [totalEmissionFootprint, setTotalEmissionFootprint] = useState({});

  // State: Initialize State - Industry Carbon Rating
  const [industryCarbonRating, setIndustryCarbonRating] = useState({
    value: 0,
    loaded: false,
  });

  // State: Initialize State - Vehicle Emissions
  const [vehicleEmissions, setVehicleEmissions] = useState({
    loaded: false,
    data: [],
  });

  // State: Initialize State - Fuel Consumption
  const [fuelConsumption, setFuelConsumption] = useState({});

  // Const: Initialize Context - Application Dates
  const { applicationDates } = useContext(DateContext);

  // Const: Get Token & Make Sure Available For API Call
  const token = localStorage.getItem("greenToken");

  // UE: Get Overview Data For the Whole Application (Only Runs Once)
  useEffect(() => {
    const hasValues =
      Object.values(applicationDates).filter((date) => date !== "").length > 0;

    if (overviewIsLoading && hasValues && token !== mockLoginData?.token) {
      const promises = [
        getCostOfCarbonAPI(
          setCostOfCarbon,
          applicationDates?.overviewRightTiles
        ),
        postFuelConsumptionAPI(
          setFuelConsumption,
          applicationDates?.overviewRightTiles
        ),
        postTotalEmissionFootprintAPI(
          setTotalEmissionFootprint,
          applicationDates?.emissionFootprint,
          setEmissionFootprintBreakdownData
        ),
        getVehicleEmissionsAPI(
          setVehicleEmissions,
          applicationDates?.vehicleEmissions,
          8
        ),
        postIndustryCarbonRatingAPI(
          setIndustryCarbonRating,
          applicationDates?.industryCarbonRating
        ),
      ];
      Promise.all(promises).then((response) => {
        // ENDPOINTS NOT READY MOCK DATA
        // totalEmissionFootprintBreakdownConfig.scope1.percentageValue = "1";
        // totalEmissionFootprintBreakdownConfig.scope2.percentageValue = "XX";
        // setEmissionFootprintBreakdownData({
        //   ...totalEmissionFootprintBreakdownConfig,
        //   dataAvailable: true,
        // });

        // Set Loading Component to False
        setOverviewIsLoading(false);
      });
    } else if (token === mockLoginData?.token && hasValues) {
      setCostOfCarbon({
        ...costOfCarbonTileConfig,
        value: mockCostofCarbon?.[applicationDates?.overviewRightTiles],
        loaded: true,
      });
      setFuelConsumption({
        ...fuelConsumptionTileConfig,
        value: mockFuelConsumption?.[applicationDates?.overviewRightTiles],
        loaded: true,
      });
      totalEmissionFootprintBreakdownConfig.scope1.percentageValue =
        mockScope12Percentage?.[applicationDates?.emissionFootprint]?.scope1;
      totalEmissionFootprintBreakdownConfig.scope2.percentageValue =
        mockScope12Percentage?.[applicationDates?.emissionFootprint]?.scope2;
      setEmissionFootprintBreakdownData({
        ...totalEmissionFootprintBreakdownConfig,
        dataAvailable: true,
      });
      setTotalEmissionFootprint({
        ...totalEmissionFootprintSVGConfig,
        value: mockEmissionFootprint?.[applicationDates?.emissionFootprint],
        loaded: true,
      });
      setVehicleEmissions({
        ...mockHEVData,
        data: mockHEVData?.data?.[applicationDates?.vehicleEmissions],
      });
      setIndustryCarbonRating({
        ...mockIndustryCarbonRating,
        value:
          mockIndustryCarbonRating?.value?.[
            applicationDates?.industryCarbonRating
          ],
      });
      setOverviewIsLoading(false);
    }
  }, [overviewIsLoading, applicationDates, token]);

  // Const: Get Previous Application Dates to See if Trigger API Call
  const prevApplicationDates = useRef(applicationDates);

  // UE: Get Overview Data For Application(Trigger API Call) When applicationDates Changes
  useEffect(() => {
    const hasValues =
      Object.values(applicationDates).filter((date) => date !== "").length > 0;
    if (
      hasValues &&
      token &&
      token !== mockLoginData?.token &&
      !overviewIsLoading &&
      prevApplicationDates.current
    ) {
      if (
        prevApplicationDates?.current?.industryCarbonRating !==
        applicationDates?.industryCarbonRating
      ) {
        postIndustryCarbonRatingAPI(
          setIndustryCarbonRating,
          applicationDates?.industryCarbonRating
        );
      }
      if (
        prevApplicationDates?.current?.overviewRightTiles !==
        applicationDates?.overviewRightTiles
      ) {
        getCostOfCarbonAPI(
          setCostOfCarbon,
          applicationDates?.overviewRightTiles
        );
        postFuelConsumptionAPI(
          setFuelConsumption,
          applicationDates?.overviewRightTiles
        );
      }
      if (
        prevApplicationDates?.current?.emissionFootprint !==
        applicationDates?.emissionFootprint
      ) {
        postTotalEmissionFootprintAPI(
          setTotalEmissionFootprint,
          applicationDates?.emissionFootprint,
          setEmissionFootprintBreakdownData
        );
      }
      if (
        prevApplicationDates?.current?.vehicleEmissions !==
        applicationDates?.vehicleEmissions
      ) {
        getVehicleEmissionsAPI(
          setVehicleEmissions,
          applicationDates?.vehicleEmissions
        );
      }
    }
    prevApplicationDates.current = applicationDates;
  }, [applicationDates, token, overviewIsLoading]);

  const value = {
    overviewIsLoading,
    costOfCarbon,
    emissionFootprintBreakdownData,
    totalEmissionFootprint,
    industryCarbonRating,
    vehicleEmissions,
    fuelConsumption,
  };

  return (
    <OverviewContext.Provider value={value}>
      {children}
    </OverviewContext.Provider>
  );
};

export default OverviewContext;
