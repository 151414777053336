// Functionality: Vehicle Vin Decoder Check Initial Upload
import { useEffect } from "react";
import { BiError } from "react-icons/bi";
import NoInitialRecords from "./VinDecoderCheckInitialUpload/NoInitialRecords";
import SuccessInitialRecords from "./VinDecoderCheckInitialUpload/SuccessInitialRecords";
import FailedInitialRecordsSummary from "./VinDecoderCheckInitialUpload/FailedInitialRecordsSummary";
import FailedInitialRecordsTable from "./VinDecoderCheckInitialUpload/FailedInitialRecordsTable";

// TODO: ADD UPLOAD FUNCTIONALITY TO REUPLOAD THE FILE AND FIX THE ERRORS
export default function VinDecoderCheckInitialUpload({
  updatedVehicleData,
  vinFileUploadState,
  setVinFileUploadState,
  setProcessStep,
  validVehicleID,
  discardAllRecords,
  setDiscardAllRecords,
  failedDecoderState,
  setFailedDecoderState,
  updatedDecoderState,
  setUpdatedDecoderState,
  determineFailedVinDecoderEntries,
  failedVinDecoderReasonMapping,
  setVinDecoderImportState,
  dupDiscard,
  setDupDiscard,
  vinDupDiscard,
  setVinDupDiscard,
}) {
  // UE: Set Failed Decoder State
  useEffect(() => {
    if (
      !failedDecoderState?.loaded &&
      updatedVehicleData?.length > 0 &&
      !updatedDecoderState?.loaded
    ) {
      const data = determineFailedVinDecoderEntries(
        validVehicleID,
        updatedVehicleData,
        failedVinDecoderReasonMapping
      );
      setFailedDecoderState(data);
      setUpdatedDecoderState(data);
    }
  }, [
    failedDecoderState?.loaded,
    updatedVehicleData,
    updatedDecoderState?.loaded,
  ]);

  // Function: Determine Reason Failed Vins
  const setArrayState = () => {
    const newUpdatedData = failedDecoderState?.upload_data
      .map((item) => {
        // Find the corresponding entry in dataState
        const failedRecord = updatedDecoderState?.data.find((val) => {
          return val?.uniqueId === item?.uniqueId;
        });

        // If failedRecord exists and discard is false, update the VIN
        if (failedRecord && !failedRecord.discard) {
          return {
            ...item,
            vin: failedRecord?.vin,
          };
        }
        // If failedRecord exists and discard is true, we will filter it out,
        // so we don't need to return anything here.
        return item;
      })
      // Filter out the records where discard is true
      ?.filter((item) => {
        const failedRecord = updatedDecoderState?.data.find(
          (val) => val?.uniqueId === item?.uniqueId
        );
        return !(failedRecord && failedRecord.discard);
      });

    // Reset & Rerun The Failed Decoder State
    const data = determineFailedVinDecoderEntries(
      validVehicleID,
      newUpdatedData,
      failedVinDecoderReasonMapping
    );

    setFailedDecoderState(data);
    setUpdatedDecoderState(data);
    setVinFileUploadState({
      ...vinFileUploadState,
      fileData: newUpdatedData,
      updatedFailedEntries: updatedDecoderState,
      triggerAPI: true,
    });
    setDiscardAllRecords(false);
  };
  return (
    <>
      {failedDecoderState?.loaded && (
        <>
          {failedDecoderState?.failed_length === 0 &&
            failedDecoderState?.upload_length !== 0 && (
              <SuccessInitialRecords
                failedDecoderState={failedDecoderState}
                updatedVehicleData={updatedVehicleData}
                setProcessStep={setProcessStep}
                setVinDecoderImportState={setVinDecoderImportState}
              />
            )}
          {failedDecoderState?.failed_length === 0 &&
            failedDecoderState?.upload_length === 0 && (
              <NoInitialRecords
                BiError={BiError}
                failedDecoderState={failedDecoderState}
              />
            )}

          {failedDecoderState?.failed_length !== 0 && (
            <FailedInitialRecordsSummary
              BiError={BiError}
              failedDecoderState={failedDecoderState}
            />
          )}

          {failedDecoderState?.failed_length !== 0 && (
            // FOR NOW DO THIS CSS CLASS AND THEN ADD THIS ONE: vin-decoder-failed-entries-edit-container
            <div className="vin-decoder-failed-entries-edit-container-1">
              <FailedInitialRecordsTable
                updatedDecoderState={updatedDecoderState}
                setUpdatedDecoderState={setUpdatedDecoderState}
                determineFailedVinDecoderEntries={
                  determineFailedVinDecoderEntries
                }
                failedVinDecoderReasonMapping={failedVinDecoderReasonMapping}
                discardAllRecords={discardAllRecords}
                setDiscardAllRecords={setDiscardAllRecords}
                setArrayState={setArrayState}
                validVehicleID={validVehicleID}
                dupDiscard={dupDiscard}
                setDupDiscard={setDupDiscard}
                vinDupDiscard={vinDupDiscard}
                setVinDupDiscard={setVinDupDiscard}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
