// Functionality: Snackbar for the entire application - snackbars provide the user with feedback after executing an action (e.g. "Successfully updated xyz")
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Button from "./Button";

export default function SnackBar({
  visible,
  onClose,
  severity,
  message,
  ...props
}) {
  // State
  const [snackbarVisible, setSnackbarVisible] = useState(visible); // Snackbar visibility state

  // UE: Add event listener for setting the snackbar visible
  useEffect(() => {
    setSnackbarVisible(visible);
  }, [visible]);

  const handleClose = () => {
    setSnackbarVisible(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <div className={`custom-snackbar ${snackbarVisible ? "open" : ""}`}>
        <div className={`alert ${severity}`}>
          <span>{message}</span>
          <Button buttonName="x" className="close" onClick={handleClose} />
        </div>
      </div>
    </>
  );
}

// Props
const snackBarPropTypes = {
  visible: PropTypes.bool, // Snackbar visibility
  onClose: PropTypes.func, // Snackbar close event
  severity: PropTypes.oneOf(["error", "warning", "success", ""]), // Snackbar severity
  message: PropTypes.string, // Snackbar message
};

SnackBar.propTypes = snackBarPropTypes;
