// Functionality: API Call for Emissions Forecasting Chart Tile
// FOR NOW: Commented out the labeling of the year, Jun 7 2024
import axios from "../loadBalancerAxios";
import { EMISSIONS_FORECASTING_URL } from "../paths";
import { emissionForecastingDataConfig } from "../../constants/ReductionConfig";
import useDateFormat from "../../hooks/use-DateFormat";

const removeIndexFromResponse = (responseData, indexToRemove) => {
  // Check if responseData and its arrays exist
  if (!responseData) return responseData;

  // Create a new object to avoid mutating the original responseData
  const newResponseData = { ...responseData };

  // Iterate over each key in the responseData object
  Object.keys(newResponseData).forEach((key) => {
    if (Array.isArray(newResponseData[key])) {
      // Remove the item at the specified index
      newResponseData[key] = newResponseData[key].filter(
        (_, index) => index !== indexToRemove
      );
    }
  });

  return newResponseData;
};

export const getEmissionsForecastingAPI = async (setData) => {
  await axios
    .get(EMISSIONS_FORECASTING_URL)
    .then((response) => {
      // Const: Emission Forecasting Data
      let responseData = response?.data;

      const lengthData = responseData?.dates?.length;
      const nonEmptyData = responseData?.dates?.length > 0;

      const indexActuals = responseData?.actuals.length;

      const dataToSlice = lengthData - indexActuals + 1;

      const combinedValues = responseData?.actuals
        ?.concat(responseData?.predictions)
        ?.concat(responseData?.lower_bound)
        ?.concat(responseData?.upper_bound);
      const maxValue = Math.max(...combinedValues);
      // TODO: ADD LOGIC FOR ANNOTATION VALUE
      // const annotationValue = {
      //   first: responseData?.dates?.slice(0)?.[0]?.slice(0, 4),
      //   second: responseData?.dates?.slice(indexActuals)?.[0]?.slice(0, 4),
      // };

      setData({
        ...emissionForecastingDataConfig,
        indexes: responseData?.dates?.map((item, index) => {
          return index;
        }),
        labels: responseData?.dates.map((item) => {
          return useDateFormat(item, "month-day");
        }),
        actualData: responseData?.actuals?.slice(0, indexActuals),
        forecastData: Array(indexActuals - 1)
          .fill(null)
          ?.concat(responseData?.actuals?.slice(-1))
          ?.concat(responseData?.predictions.slice(-dataToSlice + 1)),
        lowerboundData: Array(indexActuals)
          .fill(null)
          ?.concat(responseData?.lower_bound.slice(-dataToSlice + 1)),
        upperboundData: Array(indexActuals)
          .fill(null)
          ?.concat(responseData?.upper_bound.slice(-dataToSlice + 1)),
        lineBreak: nonEmptyData ? indexActuals - 1 : false,
        loaded: true,
        maxValue: maxValue,
        annotationValue:
          // removeLabel
          //   ?
          { first: false, second: false },
        // : annotationValue,
      });
    })
    .catch(() => {
      setData({
        ...emissionForecastingDataConfig,
        labels: [],
        actualData: [],
        forecastData: [],
        lowerboundData: [],
        upperboundData: [],
        loaded: true,
        maxValue: 0,
        annotationValue: { first: 0, second: 0 },
      });
    });
};
