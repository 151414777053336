// Functionality: API Call for Specific Vehicle Profiles
import axios from "../loadBalancerAxios";
import { RANKED_VEHICLE_PROFILE_GROUP_URL } from "../paths";

export const getRankedVehicleProfileGroup = async (
  setData,
  date,
  filterString,
  vehicleProfileDataPage
) => {
  await axios
    .get(
      `${RANKED_VEHICLE_PROFILE_GROUP_URL}?date=${date}&page=${vehicleProfileDataPage}&perPage=50${filterString}`
    )
    .then((response) => {
      const responseData = response?.data?.data;
      const updatedData = responseData?.map((item) => {
        return {
          ...item,
          vehicle_type: item.type,
        };
      });
      setData({
        data: updatedData,
        loaded: true,
        next_page: response?.data?.next_page,
      });
    })
    .catch(() => {
      setData({
        data: [],
        loaded: true,
        next_page: false,
      });
    });
};
