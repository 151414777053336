// Functionality: This file exports the RiskReport component which is used in the Report Page component.
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import ReportContext from "../../../context/ReportsProvider";
import CheckBoxGroup from "../../../components/CheckBox";
import RadioItemGroup from "../../../components/RadioItemGroup";
import TextArea from "../../../components/TextArea";
import {
  reportQuestionConfig,
  clientRiskRadioOptions,
  yesNoOtherDropdownOptions,
  exposedRiskRadioOptions,
  capitalizedCostsRadioOptions,
  expensesLossesRadioOptions,
  physicalTransitionRiskRadioOptions,
  financialPlanningImpactPandSChecklistOptions,
  financialPlanningImpactInvestmentChecklistOptions,
  financialPlanningImpactOperationsChecklistOptions,
  jointVenturesRadioOptions,
} from "../../../constants/ReportsConfig";

export default function RiskReport({
  generateQuestionGroup,
  handleReportEventChange,
  reportInputClassName,
}) {
  // Const: Initialize Report Context
  const { riskDataConfig, setRiskDataConfig } = useContext(ReportContext);

  // UE: Clear Board Level Oversight Input if Financial Impact is Yes or No
  useEffect(() => {
    if (
      riskDataConfig?.financial_impact?.radio !== "Yes" &&
      riskDataConfig?.financial_impact?.textArea !== ""
    ) {
      setRiskDataConfig((prevState) => ({
        ...prevState,
        financial_impact: {
          radio: prevState.financial_impact.radio,
          textArea: "",
        },
      }));
    } else if (
      riskDataConfig?.financial_impact?.radio !== "No" &&
      riskDataConfig?.no_financial_impact?.radio !== ""
    ) {
      setRiskDataConfig((prevState) => ({
        ...prevState,
        no_financial_impact: {
          radio: "",
        },
      }));
    } else if (
      !riskDataConfig?.products_and_services?.checkbox?.includes("Other") &&
      riskDataConfig?.products_and_services?.textArea !== ""
    ) {
      setRiskDataConfig((prevState) => ({
        ...prevState,
        products_and_services: {
          checkbox: prevState.products_and_services.checkbox,
          textArea: "",
        },
      }));
    } else if (
      !riskDataConfig?.investment?.checkbox?.includes("Other") &&
      riskDataConfig?.investment?.textArea !== ""
    ) {
      setRiskDataConfig((prevState) => ({
        ...prevState,
        investment: {
          checkbox: prevState.investment.checkbox,
          textArea: "",
        },
      }));
    } else if (
      !riskDataConfig?.operations?.checkbox?.includes("Other") &&
      riskDataConfig?.operations?.textArea !== ""
    ) {
      setRiskDataConfig((prevState) => ({
        ...prevState,
        operations: {
          checkbox: prevState.operations.checkbox,
          textArea: "",
        },
      }));
    } else if (
      riskDataConfig?.transition_plan?.radio !== "Yes" &&
      riskDataConfig?.transition_plan?.textArea !== ""
    ) {
      setRiskDataConfig((prevState) => ({
        ...prevState,
        transition_plan: {
          radio: prevState.transition_plan.radio,
          textArea: "",
        },
      }));
    } else if (
      riskDataConfig?.physical_transition_risks?.radio !== "Other" &&
      riskDataConfig?.physical_transition_risks?.textArea !== ""
    ) {
      setRiskDataConfig((prevState) => ({
        ...prevState,
        physical_transition_risks: {
          radio: prevState.physical_transition_risks.radio,
          textArea: "",
        },
      }));
    }
  }, [
    riskDataConfig?.financial_impact?.radio,
    riskDataConfig?.financial_impact?.textArea,
    riskDataConfig?.no_financial_impact?.radio,
    riskDataConfig?.transition_plan?.radio,
    riskDataConfig?.transition_plan?.textArea,
    riskDataConfig?.physical_transition_risks?.radio,
    riskDataConfig?.physical_transition_risks?.textArea,
    riskDataConfig?.products_and_services?.checkbox,
    riskDataConfig?.products_and_services?.textArea,
    riskDataConfig?.operations?.checkbox,
    riskDataConfig?.operations?.textArea,
    riskDataConfig?.investment?.checkbox,
    riskDataConfig?.investment?.textArea,
    setRiskDataConfig,
  ]);

  // Dictionary: UI/UX for each of the questions, in order
  const questionGroupConfig = {
    0: (
      <>
        <RadioItemGroup
          options={clientRiskRadioOptions || []}
          value={riskDataConfig?.identify_risk?.radio}
          splitTextByLine={true}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "identify_risk",
              "radio",
              setRiskDataConfig,
              riskDataConfig
            )
          }
          divClassName="div-radio-item-vertical"
        />
      </>
    ),
    1: (
      <>
        <RadioItemGroup
          options={yesNoOtherDropdownOptions?.slice(0, 2)}
          value={riskDataConfig?.financial_impact?.radio}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "financial_impact",
              "radio",
              setRiskDataConfig,
              riskDataConfig
            )
          }
          divClassName="div-radio-item-vertical"
        />
        {riskDataConfig?.financial_impact?.radio === "Yes" && (
          <TextArea
            value={riskDataConfig?.financial_impact?.textArea}
            placeholder={"Explain"}
            onChange={(e) =>
              handleReportEventChange(
                e,
                "financial_impact",
                "textArea",
                setRiskDataConfig,
                riskDataConfig
              )
            }
            className={reportInputClassName?.textArea}
          />
        )}
        {riskDataConfig?.financial_impact?.radio === "No" && (
          <>
            <div className="report-question-group-container">
              <p className="report-question-group-container-question">
                {reportQuestionConfig?.riskAndOpportunities?.slice(-1)}
              </p>
              <RadioItemGroup
                options={exposedRiskRadioOptions}
                value={riskDataConfig?.no_financial_impact?.radio}
                splitTextByLine={true}
                onChange={(e) =>
                  handleReportEventChange(
                    e,
                    "no_financial_impact",
                    "radio",
                    setRiskDataConfig,
                    riskDataConfig
                  )
                }
                divClassName="div-radio-item-vertical"
              />
            </div>
          </>
        )}
      </>
    ),
    2: (
      <>
        <RadioItemGroup
          options={capitalizedCostsRadioOptions}
          value={riskDataConfig?.capitalized_costs?.radio}
          splitTextByLine={true}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "capitalized_costs",
              "radio",
              setRiskDataConfig,
              riskDataConfig
            )
          }
          divClassName="div-radio-item-vertical"
        />
      </>
    ),
    3: (
      <>
        <RadioItemGroup
          options={expensesLossesRadioOptions}
          value={riskDataConfig?.expenses_losses?.radio}
          splitTextByLine={true}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "expenses_losses",
              "radio",
              setRiskDataConfig,
              riskDataConfig
            )
          }
          divClassName="div-radio-item-vertical"
        />
      </>
    ),
    4: (
      <>
        <RadioItemGroup
          options={yesNoOtherDropdownOptions?.slice(0, 2)}
          value={riskDataConfig?.transition_plan?.radio}
          splitTextByLine={true}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "transition_plan",
              "radio",
              setRiskDataConfig,
              riskDataConfig
            )
          }
          divClassName="div-radio-item-vertical"
        />
        {riskDataConfig?.transition_plan?.radio === "Yes" && (
          <TextArea
            value={riskDataConfig?.transition_plan?.textArea}
            placeholder={"Explain"}
            onChange={(e) =>
              handleReportEventChange(
                e,
                "transition_plan",
                "textArea",
                setRiskDataConfig,
                riskDataConfig
              )
            }
            className={reportInputClassName?.textArea}
          />
        )}
      </>
    ),
    5: (
      <>
        <RadioItemGroup
          options={physicalTransitionRiskRadioOptions}
          value={riskDataConfig?.physical_transition_risks?.radio}
          splitTextByLine={true}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "physical_transition_risks",
              "radio",
              setRiskDataConfig,
              riskDataConfig
            )
          }
          divClassName="div-radio-item-vertical"
        />
        {riskDataConfig?.physical_transition_risks?.radio === "Other" && (
          <TextArea
            value={riskDataConfig?.physical_transition_risks?.textArea}
            placeholder={"Explain"}
            onChange={(e) =>
              handleReportEventChange(
                e,
                "physical_transition_risks",
                "textArea",
                setRiskDataConfig,
                riskDataConfig
              )
            }
            className={reportInputClassName?.textArea}
          />
        )}
      </>
    ),
    6: (
      <>
        <>
          <p className="report-question-group-container-question">
            Products & Services
          </p>
          <CheckBoxGroup
            options={financialPlanningImpactPandSChecklistOptions || []}
            selectedOptions={riskDataConfig?.products_and_services?.checkbox}
            divClassName="div-radio-item-vertical"
            onChange={(e) =>
              handleReportEventChange(
                e,
                "products_and_services",
                "checkbox",
                setRiskDataConfig,
                riskDataConfig
              )
            }
          />
          {riskDataConfig?.products_and_services?.checkbox?.includes(
            "Other"
          ) && (
            <TextArea
              placeholder={"Explain"}
              value={riskDataConfig?.products_and_services?.textArea}
              onChange={(e) =>
                handleReportEventChange(
                  e,
                  "products_and_services",
                  "textArea",
                  setRiskDataConfig,
                  riskDataConfig
                )
              }
            />
          )}
        </>
        <>
          <p className="report-question-group-container-question">Investment</p>
          <CheckBoxGroup
            options={financialPlanningImpactInvestmentChecklistOptions || []}
            selectedOptions={riskDataConfig?.investment?.checkbox}
            divClassName="div-radio-item-vertical"
            onChange={(e) =>
              handleReportEventChange(
                e,
                "investment",
                "checkbox",
                setRiskDataConfig,
                riskDataConfig
              )
            }
          />
          {riskDataConfig?.investment?.checkbox?.includes("Other") && (
            <TextArea
              placeholder={"Explain"}
              value={riskDataConfig?.investment?.textArea}
              onChange={(e) =>
                handleReportEventChange(
                  e,
                  "investment",
                  "textArea",
                  setRiskDataConfig,
                  riskDataConfig
                )
              }
            />
          )}
        </>
        <>
          <p className="report-question-group-container-question">Operations</p>
          <CheckBoxGroup
            options={financialPlanningImpactOperationsChecklistOptions || []}
            selectedOptions={riskDataConfig?.operations?.checkbox}
            divClassName="div-radio-item-vertical"
            onChange={(e) =>
              handleReportEventChange(
                e,
                "operations",
                "checkbox",
                setRiskDataConfig,
                riskDataConfig
              )
            }
          />
          {riskDataConfig?.operations?.checkbox?.includes("Other") && (
            <TextArea
              placeholder={"Explain"}
              value={riskDataConfig?.operations?.textArea}
              onChange={(e) =>
                handleReportEventChange(
                  e,
                  "operations",
                  "textArea",
                  setRiskDataConfig,
                  riskDataConfig
                )
              }
            />
          )}
        </>
      </>
    ),
    7: (
      <>
        <RadioItemGroup
          options={jointVenturesRadioOptions}
          value={riskDataConfig?.joint_ventures?.radio}
          splitTextByLine={true}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "joint_ventures",
              "radio",
              setRiskDataConfig,
              riskDataConfig
            )
          }
          divClassName="div-radio-item-vertical"
        />
        {riskDataConfig?.joint_ventures?.radio?.includes("Yes") && (
          <>
            <p className="report-question-group-container-question">
              Explain how emissions have been or will be allocated across the
              various ownership entities
            </p>
            <TextArea
              value={riskDataConfig?.joint_ventures?.textArea}
              placeholder={"Explain"}
              onChange={(e) =>
                handleReportEventChange(
                  e,
                  "joint_ventures",
                  "textArea",
                  setRiskDataConfig,
                  riskDataConfig
                )
              }
              className={reportInputClassName?.textArea}
            />
          </>
        )}
      </>
    ),
  };
  return (
    <>
      {generateQuestionGroup(
        reportQuestionConfig?.riskAndOpportunities?.slice(0, 8),
        questionGroupConfig
      )}
    </>
  );
}
// Props
const riskReportPropTypes = {
  generateQuestionGroup: PropTypes.func, // Function: Generates the UI/UX for each question
  handleReportEventChange: PropTypes.func, // Function: Handles the change of each question
};

RiskReport.propTypes = riskReportPropTypes;
