// Functionality: Handles the API call to trigger an email with verification code to reset the password
import axios from "../loadBalancerAxios";
import { FORGOT_PASSWORD_EMAIL_URL } from "../paths";
import { mockLoginData } from "../../constants/mockDataConfig";

export const postForgotPasswordEmail = async (
  forgotPWState,
  setErrMsg,
  setShowForgotPW,
  setForgotPWButtonName
) => {
  // Check if the user logging in is the mock user
  if (
    forgotPWState.email === mockLoginData?.email &&
    forgotPWState.password === mockLoginData?.password
  ) {
  } else {
    // Otherwise, normally log in the user
    await axios
      .post(FORGOT_PASSWORD_EMAIL_URL, {
        email: forgotPWState?.email,
        "Content-Type": "application/json",
      })
      .then(() => {
        setErrMsg("");
        setShowForgotPW(true);
        setForgotPWButtonName("Resend Email");
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          // Maybe show a snackbar here if the user is unable to create their password if they already have their account setup
          setErrMsg(
            "Unable to send verification email. Make sure you are using the correct email and please try again."
          );
          setShowForgotPW(false);
        }
      });
  }
};
