// Functionality: API Call for Average Emissions Per Vehicle Tile
import axios from "../loadBalancerAxios.js";
import { AVERAGE_EMISSIONS_PER_VEHICLE_URL } from "../paths";
import { averageEmissionsperVehicleSVGConfig } from "../../constants/EmissionsConfig.js";

export const getAverageEmissionsPerVehicleAPI = async (
  setData,
  selectedDate
) => {
  await axios
    .get(`${AVERAGE_EMISSIONS_PER_VEHICLE_URL}?date=${selectedDate}`)
    .then((response) => {
      // Const: Get Data From Response
      const responseData = response?.data;

      setData({
        loaded: true,
        value: {
          ...averageEmissionsperVehicleSVGConfig,
          value: responseData?.avg_emissions_per_veh,
        },
      });
    })
    .catch(() => {
      setData({
        loaded: true,
        value: {
          ...averageEmissionsperVehicleSVGConfig,
          value: "-",
        },
      });
    });
};
