// This file serves as a central location for all configuration variables for Emissions related components
import UniversalTableConfig from "./UniversalTableConfig.js";
import {
  emissionFootprintTile,
  costOfCarbonTile,
  fuelConsumptionTile,
  scope1CardEmissionsTile,
  scope2CardEmissionsTile,
  avgEmissionsPerVehicleTile,
  totalIdleTimeTile,
} from "./TileConfig.js";
import { PiPencilLight } from "react-icons/pi";
import { BsFillTrashFill } from "react-icons/bs";

// Total Emissions
// Title for Emissions Plot
export const totalEmissionTitle = `Total Emission Footprint Breakdown`;

// Total Emission Footprint SVG Config
export const totalEmissionFootprintSVGConfig = {
  color: ["#0BCBB3", "#A1DD8C", "#0096FA"],
  opacity: [1, 1, 1],
  title: "Total Emission Footprint",
  blurb: emissionFootprintTile?.blurb,
  legend: emissionFootprintTile?.legend,
  subComment: "than last month",
  units: "Metric Tons CO2e",
  version: 2,
  loaded: false,
};

// Total Emission Footprint Breakdown Doughnut Chart
export const totalEmissionFootprintBreakdownConfig = {
  scope1: {
    label: `Scope 1`,
    legend: `Direct emissions from sources owned or controlled by a company`,
    color: `#A1DD8C`,
  },
  scope2: {
    label: `Scope 2`,
    legend: `Indirect emissions from the generation of purchased electricity consumed by a company`,
    color: `#0BCBB3`,
  },
  scope3: {
    label: `Scope 3`,
    legend: `All other indirect emissions that occur in a company's value chain`,
    color: `#0096FA`,
  },
  dataAvailable: false,
};

// Scope 1 Emissions
// Scope 1 Emissions Industry Benchmark
export const scope1EmissionsBenchmark = 120.66;

// Scope 1 Emissions Footprint SVG Config
export const scope1EmissionsSVGConfig = {
  color: ["#A1DD8C", "#A1DD8C", "#A1DD8C"],
  opacity: [0.295596, 0.85, 0.1],
  title: "Scope 1 Emissions",
  blurb: scope1CardEmissionsTile?.blurb,
  legend: scope1CardEmissionsTile?.legend,
  subComment: "than last month",
  units: "Metric Tons CO2e",
  version: 1,
  increase: false,
  percentageChange: 0,
  value: "00",
  loaded: false,
};

// Scope 2 Emissions
// Scope 2 Emissions Footprint SVG Config
export const scope2EmissionsSVGConfig = {
  color: ["#0BCBB3", "#0BCBB3", "#0BCBB3"],
  opacity: [0.295596, 0.85, 0.1],
  title: "Scope 2 Emissions",
  blurb: scope2CardEmissionsTile?.blurb,
  legend: scope2CardEmissionsTile?.legend,
  subComment: "than last entry",
  units: "Metric Tons CO2e",
  version: 1,
  loaded: false,
};

// Other
// Radio Item Emissions Options
export const radioItemEmissionOptions = [
  {
    label: "YTD",
    value: "year",
  },
  { label: "Month", value: "month" },
];

// Total Idle Time SVG Config
export const totalIdleTimeSVGConfig = {
  color: ["#00A63F", "#00A63F", "#00A63F"],
  opacity: [0.295596, 0.85, 0.1],
  title: "Total Idle Time",
  blurb: totalIdleTimeTile?.blurb,
  legend: totalIdleTimeTile?.legend,
  subComment: "than last month",
  units: "hours",
  version: 1,
};

// Average Emissions per Vehicle Tile
export const averageEmissionsperVehicleSVGConfig = {
  color: ["#0075D2", "#1A84D4", "#1A83D6"],
  opacity: [0.295596, 0.85, 0.1],
  title: "Average Emissions per Vehicle",
  blurb: avgEmissionsPerVehicleTile?.blurb,
  legend: avgEmissionsPerVehicleTile?.legend,
  subComment: "Grams CO2 / mile per vehicle",
  units: "Grams CO2 / mile per vehicle",
  version: 2,
};

// Cost of Carbon Tile
export const costOfCarbonTileConfig = {
  color: `-dg`,
  title: "Cost of Carbon",
  blurb: costOfCarbonTile?.blurb,
  legend: costOfCarbonTile?.legend,
  units: "per Metric Tons CO2 ",
  loaded: false,
};

// Fuel Consumption Tile
export const fuelConsumptionTileConfig = {
  color: `-dg`,
  title: "Fuel Consumption",
  blurb: fuelConsumptionTile?.blurb,
  legend: fuelConsumptionTile?.legend,
  units: "Total gallons consumed",
  loaded: false,
};

// Well to Tank Tile
export const wellToTankTileConfig = {
  color: "rgba(11, 203, 179, 1)",
  opacity: [0.295596, 0.85, 0.1],
  title: "Well-to-Tank Emissions",
  blurb: null,
  legend: null,
  units: "Metric Tons CO2e",
  value: "00",
  loaded: false,
};

// Const: Tank to Wheel Tile
export const tankToWheelTileConfig = {
  color: "rgba(148, 200, 235, 1)",
  opacity: [0.295596, 0.85, 0.1],
  title: "Tank-to-Wheel Emissions",
  blurb: null,
  legend: null,
  units: "Metric Tons CO2e",
  value: "00",
  loaded: false,
};

// Const: Well to Wheel Tile
export const wellToWheelTileConfig = {
  color: "rgba(24, 83, 122, 1)",
  opacity: [0.295596, 0.85, 0.1],
  title: "Well-To-Wheel Emissions",
  blurb: null,
  legend: null,
  units: "Metric Tons CO2e",
  value: "00",
  loaded: false,
};
// Const: Well to Tank Chart Data Config
export const wellToTankDataConfig = {
  label: "Well to Tank",
  dataValues: [],
  trendline: false,
  pointRadius: 2,
  color: "rgba(11, 203, 179, 1)",
};
// Const: Tank to Wheel Chart Data Config
export const tankToWheelDataConfig = {
  label: "Tank to Wheel",
  dataValues: [],
  trendline: false,
  pointRadius: 2,
  color: "rgba(148, 200, 235, 1)",
};

// Const: Well to Wheel Chart Data Config
export const wellToWheelDataConfig = {
  label: "Well to Wheel",
  dataValues: [],
  trendline: false,
  pointRadius: 2,
  color: "rgba(24, 83, 122, 1)",
};

// Const: Scope 1 Emissions Data
export const scope1EmissionsData = {
  label: "CO2",
  dataValues: [],
  trendline: false,
  pointRadius: 2,
  color: "rgba(161, 221, 140, 1)",
};
// Const: Scope 1 Emission Trendline Data
export const scope1EmissionsTrendlineData = {
  label: "Benchmark",
  dataValues: [],
  trendline: true,
  pointRadius: 0,
  color: "rgba(255, 31, 46, 1)",
};

// Const:  Scope 2 Emissions Data
export const scope2EmissionsData = {
  label: "CO2",
  dataValues: [],
  trendline: false,
  pointRadius: 2,
  color: "rgba(11, 203, 179, 1)",
};

// Const: Scope 2 Manager Metrics Measure
export const scope2ManagerMetricsMeasure = [
  {
    label: "kWh",
    value: "kWh",
  },
  {
    label: "MWh",
    value: "MWh",
  },
];

// // Table Config: Scope 2 Manager Table Config
// // Scope 2 Manager Columns
// export const scope2ManagerColumns = ["Date", "Scope 2 Emissions"];
// // Scope 2 Manager Table Config
// export const scope2ManagerTableConfig = () => {
//   // Add the rest of the columns
//   const columnsConfig = UniversalTableConfig(scope2ManagerColumns);
//   return columnsConfig;
// };

// Scope 2 Manager Site Locations Table Config
export const scope2SiteLocationColumns = ["label"];
// Scope 2 Manager Site Locations Table Config
export const scope2SiteLocationTableConfig = () => {
  // Add the rest of the columns
  const columnsConfig = [
    {
      Header: "Location",
      accessor: (universalData) => universalData?.["label"],
    },
  ];
  return columnsConfig;
};

// Const: State Options
const stateOptions = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "American Samoa",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Trust Territories",
  "Utah",
  "Vermont",
  "Virginia",
  "Virgin Islands",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
const stateAbbrev = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "AS",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "TT",
  "UT",
  "VT",
  "VA",
  "VI",
  "WA",
  "WV",
  "WI",
  "WY",
];
export const stateOptionsConfig = stateOptions.map((state, index) => {
  return {
    label: state,
    value: stateAbbrev[index],
  };
});

// Scope 2 Manager Table Columns
export const scope2ManagerTableColumns = [
  "invoice_id",
  "dates",
  "electricity_purchased_kwh",
];
//  Scope 2 Manager Table Config
export const scope2ManagerTableConfig = (handleEditClick = () => {}) => {
  // Add the rest of the columns
  const columnsConfig = UniversalTableConfig(scope2ManagerTableColumns);

  // Add the column to edit the vehicle settings
  const editDeleteColumns = [
    {
      Header: "Site Location",
      accessor: (universalData) =>
        universalData?.location?.substring(
          0,
          universalData?.location?.lastIndexOf("-")
        ),
      sortValue: (universalData) => universalData?.label,
    },
    {
      Header: "Edit",
      accessor: (universalData) => (
        <div className="table-edit-col-group">
          <PiPencilLight
            onClick={() => handleEditClick(universalData, "edit")}
          />
        </div>
      ),
      Cell: (universalData) => {
        return (
          <div className="table-edit-col-group">
            <PiPencilLight
              onClick={() => handleEditClick(universalData, "edit")}
            />
          </div>
        );
      },
      sortValue: (universalData) => universalData.vehicle_id,
    },
    {
      Header: "Delete",
      accessor: (universalData) => (
        <div className="table-edit-col-group">
          <BsFillTrashFill
            onClick={() => handleEditClick(universalData, "delete")}
          />
        </div>
      ),
      Cell: (universalData) => {
        return (
          <div className="table-edit-col-group">
            <BsFillTrashFill
              onClick={() => handleEditClick(universalData, "delete")}
            />
          </div>
        );
      },
      sortValue: (universalData) => universalData.vehicle_id,
    },
  ];

  return columnsConfig.concat(editDeleteColumns);
};

//  Scope 2 Manager Table Config
export const scope2AddressManagerTableColumns = (
  handleEditClick = () => {}
) => {
  const columnsConfig = [
    {
      Header: "Site Location",
      accessor: (universalData) => universalData?.label,
      sortValue: (universalData) => universalData?.label,
    },
    {
      Header: "Edit",
      accessor: (universalData) => (
        <div className="table-edit-col-group">
          <PiPencilLight
            onClick={() => handleEditClick(universalData?.value, "edit")}
          />
        </div>
      ),
      Cell: (universalData) => {
        return (
          <div className="table-edit-col-group">
            <PiPencilLight
              onClick={() => handleEditClick(universalData, "edit")}
            />
          </div>
        );
      },
      sortValue: (universalData) => universalData.vehicle_id,
    },
    {
      Header: "Delete",
      accessor: (universalData) => (
        <div className="table-edit-col-group">
          <BsFillTrashFill
            onClick={() => handleEditClick(universalData, "delete")}
          />
        </div>
      ),
      Cell: (universalData) => {
        return (
          <div className="table-edit-col-group">
            <BsFillTrashFill
              onClick={() => handleEditClick(universalData, "delete")}
            />
          </div>
        );
      },
      sortValue: (universalData) => universalData.vehicle_id,
    },
  ];

  return columnsConfig;
};
