// Functionality: API Call for changing default Emission Reduction Target Entry
import axios from "../../loadBalancerAxios";
import { EMISSION_REDUCTION_BASE_VALUES_URL } from "../../paths";

export const putEmissionReductionTargetAPI = async (
  selectedBaseYear,
  projectedYear,
  targetPercentage,
  setMsg
) => {
  await axios
    .put(EMISSION_REDUCTION_BASE_VALUES_URL, {
      base_year: selectedBaseYear?.[0]?.value,
      target_year: projectedYear?.[0]?.value,
      base_target: parseInt(targetPercentage?.[0]?.value) / 100 || 0.4,
    })
    .then(() => {
      setMsg({ text: "Emission Reduction Target Updated", msg: "success" });
    })
    .catch(() => {
      setMsg({
        text: "Unable to Update Emission Reduction Target",
        msg: "warning",
      });
    });
};
