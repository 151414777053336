// Functionality: API Call for Emissions Per Vehicle Line Chart w/ Benchmark
import axios from "../loadBalancerAxios";
import { EMISSIONS_PER_VEHICLE_URL } from "../paths";
import {
  emissionsPerVehicleDataConfig,
  emissionsPerVehicleBenchmarkConfig,
} from "../../constants/VehicleConfig";
export const getEmissionsPerVehicleAPI = async (
  setData,
  setEmissionsBarChartData,
  selectedDate
) => {
  await axios
    .get(`${EMISSIONS_PER_VEHICLE_URL}?date=${selectedDate}`)
    .then((response) => {
      // Const: Emissions per Vehicle Data
      const responseData = response?.data;
      // Const: Set Formatted Data
      const formattedData = responseData?.unit?.map((x, index) => ({
        x: x,
        y: responseData?.values[index],
      }));

      // Const: Set Formatted Benchmark Data (Sliced)
      const formattedSlicedData = responseData?.unit
        ?.slice(0, 30)
        ?.map((x, index) => ({
          x: x,
          y: responseData?.values[index],
        }));

      const formattedBenchmarkData = responseData?.benchmark?.map(
        (y, index) => ({
          x: responseData?.unit[index],
          y: y,
        })
      );

      // Const: Set Formatted Benchmark Data (Sliced)
      const formattedSlicedBenchmarkData = responseData?.benchmark
        ?.slice(0, 30)
        ?.map((y, index) => ({
          x: responseData?.unit[index],
          y: y,
        }));
      setData({
        loaded: true,
        data: [
          {
            ...emissionsPerVehicleDataConfig,
            dataValues: formattedData,
          },
          {
            ...emissionsPerVehicleBenchmarkConfig,
            dataValues: formattedBenchmarkData,
          },
        ],
      });
      setEmissionsBarChartData({
        loaded: true,
        data: [
          {
            ...emissionsPerVehicleDataConfig,
            dataValues: formattedSlicedData,
          },
          {
            ...emissionsPerVehicleBenchmarkConfig,
            dataValues: formattedSlicedBenchmarkData,
          },
        ],
      });
    })
    .catch(() => {
      setData({
        loaded: true,
        data: [],
      });
    });
};
