// Functionality: Input component for the application
import PropTypes from "prop-types";
import { useState } from "react";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";

export default function Input({
  divClassName = "input-main-container",
  label = <></>,
  value = "",
  setValue = () => {},
  id = "",
  type = "text",
  isRequired = false,
  className = "input-element",
  placeholder = "Enter a value",
  disabled = false,
  ...props
}) {
  // State: Input type for password fields
  const [inputType, setInputType] = useState(type);

  // Function: Set the value of the input on change
  const handleInputChange = (e) => {
    setValue(e?.target?.value);
  };

  // Function: Toggle the input type for password fields
  const toggleInputType = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };
  return (
    <>
      <div className={divClassName}>
        {label}
        <div style={{ position: "relative" }}>
          <input
            onChange={props.onChange || handleInputChange}
            value={value}
            id={id}
            type={inputType}
            required={isRequired}
            className={className}
            placeholder={placeholder}
            disabled={disabled}
            defaultValue={props.defaultValue}
            step={props.step}
          />
          {type === "password" && (
            <div className="input-password-icon-container">
              {inputType === "password" ? (
                <IoMdEyeOff
                  className="input-password-icon"
                  onClick={toggleInputType}
                />
              ) : (
                <IoMdEye
                  className="input-password-icon"
                  onClick={toggleInputType}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

// Props
const inputPropTypes = {
  divClassName: PropTypes.string, // Class name of the div container
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object,
  ]), // Label of the input
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Value of the input
  setValue: PropTypes.func, // Function to set the value of the input
  id: PropTypes.string, // Id of the input
  type: PropTypes.string, // Type of the input (text, number, etc)
  isRequired: PropTypes.bool, // If the input is required
  className: PropTypes.string, // Class name of the input
  placeholder: PropTypes.string, // Placeholder of the input
  disabled: PropTypes.bool, // If the input is disabled
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Default value of the input
  step: PropTypes.number, // Step of the input (for number inputs)
  onChange: PropTypes.func, // Function to handle the change of the input (if not using setValue)
};

Input.propTypes = inputPropTypes;
