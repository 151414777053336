// Functionality: API Call to generate a new site location for a user
import axios from "../../loadBalancerAxios";
import { SCOPE_2_MANAGER_LOCATION_URL } from "../../paths";

// INITIAL DATA TO RESET THE FORM AFTER SUBMISSION
import {
  isScope2ManagerNewAddress,
  isScope2ManagerAddressManager,
} from "../../../constants/initialDataConfig";

export const putSiteLocation = async (
  data,
  setData,
  setSelectedState,
  setMsg,
  setSelectedData
) => {
  await axios
    .put(SCOPE_2_MANAGER_LOCATION_URL, {
      ...data,
    })
    .then((response) => {
      if (response?.data?.error) {
        setMsg({
          text: "Please enter a valid site location",
          msg: "warning",
        });
      } else {
        setMsg({
          text: "Successfully Updated Site Location",
          msg: "success",
        });
        setData({ ...isScope2ManagerNewAddress, site_id: "" });
        setSelectedState([]);
        setSelectedData(isScope2ManagerAddressManager);
      }
    })
    .catch(() => {
      setMsg({
        text: "Please enter a valid site location",
        msg: "warning",
      });
    });
};
