// Functionality: React hook to easily navigate throughout the application

import { useLocation, useNavigate } from "react-router-dom";

export default function useNavHook(navigateTo) {
  // Const: React useNavigate from React Router Dom
  const navigate = useNavigate();
  // Const: React useLocation from React Router Dom (gets current location)
  const location = useLocation();
  // Const: Path to navigate to - should be in the form of a string
  const navTo = navigateTo || location.state?.from?.pathname;

  return { navigate, navTo, location };
}
