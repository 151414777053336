// Functionality: This file exports the Scope2 Card component which is used in the Emissions Page component.
import { useContext } from "react";
import EmissionsContext from "../../context/EmissionsProvider";
import MetricTileGroup from "../../components/MetricTileGroup";
import Skeleton from "react-loading-skeleton";
import SwitchCarousel from "../../components/SwitchCarousel";

export default function Scope2Card({ ...props }) {
  // Const: Initialize Context
  const { scope2Value } = useContext(EmissionsContext);

  // Const: Label Application Dates
  const applicationDatesLabel = "scope2Emissions";

  // Const: Length of Scope 2 Emissions Value Determining css
  const scopeValueLengthCSS = scope2Value?.value?.length > 6 ? "-sm" : "";

  // Const: Length of Scope 2 Emissions Unit Determining css
  const scopeUnitLengthCSS = scope2Value?.value?.length > 8 ? "-sm" : "";

  return (
    <>
      {!scope2Value?.loaded ? (
        <Skeleton height={"100%"} />
      ) : (
        <div className="scope-card-contianer-group-scope2">
          <MetricTileGroup
            svgConfig={scope2Value}
            metricClassName={`scope-metric-tile-group-metric${scopeValueLengthCSS}`}
            unitClassName={`scope-metric-tile-group-unit${scopeUnitLengthCSS}`}
            svgChildren={
              <SwitchCarousel
                values={props.dateValues}
                applicationDates={props.applicationDates}
                setApplicationDates={props.setApplicationDates}
                applicationDatesLabel={applicationDatesLabel}
                fillArrowColor="black"
              />
            }
          />
        </div>
      )}
    </>
  );
}
