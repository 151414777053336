// Functionality: Text Area component for the application
import PropTypes from "prop-types";

export default function TextArea({
  divClassName = "text-area-main-container",
  label = <></>,
  value = "",
  setValue = () => {},
  id = "",
  className = "text-area-element",
  placeholder = "Enter a value",
  maxTextArea = false,
  ...props
}) {
  // Function: Set the value of the text area on change
  const handleTextAreaChange = (e) => {
    if (maxTextArea && e.target.value.length > maxTextArea) {
      return;
    } else {
      setValue(e.target.value);
      if (props.onChange) {
        props.onChange(e.target.value);
      }
    }
  };

  // Const: Max character count
  const maxCharCountValueRemaining = maxTextArea && 250 - value.length;

  // Const: Max character count component
  const maxCharCount = (
    <div
      className={`max-char-count-container${
        maxTextArea && maxCharCountValueRemaining > 50 ? "" : "-red"
      }`}
    >
      {maxTextArea && maxCharCountValueRemaining}{" "}
      {maxTextArea && `${"characters remaining"}`}
    </div>
  );

  return (
    <>
      <div className={divClassName}>
        {label}
        <textarea
          onChange={handleTextAreaChange}
          value={value}
          id={id}
          className={className}
          placeholder={placeholder}
          {...props}
        />
        {maxCharCount}
      </div>
    </>
  );
}

// Props
const textAreaPropTypes = {
  divClassName: PropTypes.string, // Class name of the div container
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object,
  ]), // Label of the text area
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Value of the text area
  setValue: PropTypes.func, // Function to set the value of the text area
  id: PropTypes.string, // Id of the text area
  className: PropTypes.string, // Class name of the text area
  placeholder: PropTypes.string, // Placeholder of the text area
  maxTextArea: PropTypes.number, // Max length of the text area
};

TextArea.propTypes = textAreaPropTypes;
