// Functionality: API Call for Scope 1 Emissions Data Points (Chart)
import axios from "../loadBalancerAxios";
import { SCOPE_1_DATA_POINTS_URL } from "../paths";
import {
  scope1EmissionsData,
  scope1EmissionsTrendlineData,
} from "../../constants/EmissionsConfig";
import useDateFormat from "../../hooks/use-DateFormat";
import useFindYearChange from "../../hooks/use-FindYearChange";

export const getScope1ChartDataAPI = async (
  setData,
  selectedChartRadioItemType,
  applicationDates
) => {
  // Const: Determine if the data is daily or monthly
  const isDaily = selectedChartRadioItemType === "Daily";

  // Const: API Call Path
  const SCOPE_1_DATA_POINTS_DATE_URL = isDaily
    ? `${SCOPE_1_DATA_POINTS_URL}?format=Daily&date=${applicationDates}`
    : `${SCOPE_1_DATA_POINTS_URL}?format=Monthly`;

  await axios
    .get(SCOPE_1_DATA_POINTS_DATE_URL, {
      "Content-Type": "application/json",
    })
    .then((response) => {
      // Const: Set Scope 1 Emissions Data
      const responseData = response?.data || [];

      // Const: Length of Data, Check if Daily or Monthly Data
      const lengthData = responseData?.date?.length;
      const nonEmptyNonDailyData = lengthData > 1 && !isDaily;

      const indexofLineBreak = useFindYearChange(responseData?.date);
      const combinedValues = responseData?.values?.concat(
        responseData?.benchmarks
      );

      const maxValue = Math.max(...combinedValues) + 100;
      const annotationValue = {
        first: responseData?.date
          ?.slice(indexofLineBreak - 1)?.[0]
          ?.slice(0, 4),
        second: responseData?.date?.slice(indexofLineBreak)?.[0]?.slice(0, 4),
      };

      // Const: Set Formatted Data
      const formattedData = responseData?.date?.map((x, index) => ({
        x: index,
        y: responseData?.values[index],
        label: nonEmptyNonDailyData
          ? useDateFormat(x)
          : useDateFormat(x, "month-day"),
      }));

      // Const: Benchmark Data
      const benchmarkData = responseData?.date?.map((x, index) => ({
        x: index,
        y: responseData?.benchmarks[index],
        label: nonEmptyNonDailyData
          ? useDateFormat(x)
          : useDateFormat(x, "month-day"),
      }));
      setData({
        loaded: true,
        lineBreak:
          nonEmptyNonDailyData && indexofLineBreak !== -1
            ? indexofLineBreak - 1
            : false,
        maxValue: maxValue,
        annotationValue: annotationValue,
        data: [
          { ...scope1EmissionsTrendlineData, dataValues: benchmarkData },
          { ...scope1EmissionsData, dataValues: formattedData },
        ],
      });
    })
    .catch(() => {
      setData({
        loaded: true,
        data: [scope1EmissionsData],
        lineBreak: false,
        maxValue: false,
        annotationValue: false,
      });
    });
};
