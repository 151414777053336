// Functionality: Modal component for the application
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

export default function Modal({
  onClose,
  modalBackgroundClassName = "modal-background-class",
  modalClassName = "modal-class",
  modalDivClassName = "modal-div",
  modalChildrenClassName = "modal-children",
  children,
}) {
  // Reference to the DOM element
  const modalReference = "modal-container";

  return ReactDOM.createPortal(
    <div>
      <div onClick={onClose} className={modalBackgroundClassName}></div>
      <div
        onClick={onClose}
        className={modalClassName}
        id={modalReference}
      ></div>
      <div className={modalDivClassName}>
        <div className={modalChildrenClassName}>
          <div className="close-modal-x" onClick={onClose}>
            x
          </div>
          {children}
        </div>
      </div>
    </div>,
    document.querySelector(`.${modalReference}`)
  );
}

// Props
const modalPropTypes = {
  onClose: PropTypes.func, // Function to close / open the modal
  modalBackgroundClassName: PropTypes.string, // Class name for the modal background
  modalClassName: PropTypes.string, // Class name for the modal
  modalDivClassName: PropTypes.string, // Class name for the modal div
  modalChildrenClassName: PropTypes.string, // Class name for the modal children
  children: PropTypes.node, // Children to be rendered inside the modal
};

Modal.propTypes = modalPropTypes;
