import { useContext } from "react";
import { AuthContext } from "../context/AuthProvider";
import AuthHeader from "../layouts/AuthHeader";
import LoginFields from "./LoginPage/LoginFields";
import LoginSubmit from "./LoginPage/LoginSubmit";
import ForgotPasswordRedirect from "./LoginPage/ForgotPasswordRedirect";

export default function LoginPage() {
  // Const: Initialize Auth Context
  const { loginState, setLoginState, loginErrMsg, setLoginErrMsg } =
    useContext(AuthContext);

  return (
    <div id="login-container">
      <AuthHeader />
      <div className="login-content-container">
        <p className="login-content-container-title">Welcome Back!</p>
        <p className="login-content-container-subtitle">
          Log in to view your carbon accounting platform
        </p>
        <LoginFields
          loginState={loginState}
          setLoginState={setLoginState}
          setLoginErrMsg={setLoginErrMsg}
        />
        <p className={"login-err-msg"}>{loginErrMsg}</p>
        <LoginSubmit loginState={loginState} setLoginErrMsg={setLoginErrMsg} />
        <ForgotPasswordRedirect />
      </div>
      <div className="login-footer-image-container"></div>
    </div>
  );
}
