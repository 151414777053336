// Functionality: This file exports the TotalIdleTime component which is used in the Vehicle Performance Page component.
import { useContext } from "react";
import VehiclePerformanceContext from "../../../context/VehiclePerformanceProvider.js";
import MetricTileGroup from "../../../components/MetricTileGroup.js";
import SwitchCarousel from "../../../components/SwitchCarousel.js";
import Skeleton from "react-loading-skeleton";

export default function TotalIdleTimeCard({ ...props }) {
  // Const: Initialize Context
  const { totalIdleTime } = useContext(VehiclePerformanceContext);

  // Const: Label Application Dates
  const applicationDatesLabel = "totalIdleTime";

  return (
    <>
      {!totalIdleTime?.loaded ? (
        <Skeleton height={"300px"} />
      ) : (
        <div className="vp-card-contianer-group-vp2">
          <MetricTileGroup
            percentageChange={21}
            svgConfig={totalIdleTime?.data}
            svgChildren={
              <SwitchCarousel
                values={props.dateValues}
                applicationDates={props.applicationDates}
                setApplicationDates={props.setApplicationDates}
                applicationDatesLabel={applicationDatesLabel}
              />
            }
          />
        </div>
      )}
    </>
  );
}
