import { hubspotInvitedInformation } from "../constants/AuthConfig";
import HubspotOnboardFormLayout from "../layouts/HubspotOnboardFormLayout";
import logo from "../assets/logo-green-black.png";

export default function RegistrationInvitedOnboardingFormPage() {
  const headerForm = (
    <div className="hubspot-invited-user-form">
      <div className="hubspot-form-logo-header-group">
        <img src={logo} alt="GreenIRR" />
      </div>
      <div className="hubspot-form-header-group">
        <p>Invited User Registration Form</p>
      </div>
    </div>
  );

  const headerChildren = <>{headerForm}</>;
  return (
    <HubspotOnboardFormLayout
      src={hubspotInvitedInformation?.src}
      region={hubspotInvitedInformation?.region}
      portalId={hubspotInvitedInformation?.portalId}
      formId={hubspotInvitedInformation?.formId}
      headerChildren={headerChildren}
      activeFormStep={null}
    />
  );
}
