// Functionality: API Call for Emissions Per Vehicle Line Chart w/ Benchmark
import axios from "../loadBalancerAxios";
import { VEHICLE_SETTINGS_URL } from "../paths";

export const getVehicleSettings = async (setData, vehicleSettingsDataPage) => {
  await axios
    .get(`${VEHICLE_SETTINGS_URL}?page=${vehicleSettingsDataPage}&perPage=100`)
    .then((response) => {
      let data = response.data?.settings?.map((item) => {
        return {
          ...item,
          gvwr: item?.gross_vehicle_weight_rating_from_gsvwr?.toString(),
        };
      });
      setData({
        data: data,
        loaded: true,
        next_page: response?.data?.next_page,
      });
    })
    .catch(() => {
      setData({ data: [], loaded: true, next_page: null });
    });
};
