// Functionality: MetricTileGroup component for the application, used for displaying the following: total emission footprint, scope 1 emissions, scope 2 emissions, and total idle time
import PropTypes from "prop-types";
import useSub from "../hooks/use-Sub";

export default function MetricTileGroup({
  divClassName = `metric-tile-group-container`,
  titleClassName = `metric-tile-group-title card-title`,
  metricGroupClassName = `metric-tile-group-metric-group`,
  metricClassName = `metric-tile-group-metric`,
  unitClassName = `metric-tile-group-unit`,
  subCommentClassName = `metric-tile-group-sub-comment-group`,
  childrenContainer = `metric-tile-group-svg-children-container`,
  ...props
}) {
  // Const: Super script for the units
  const superscriptedFormula = useSub(props.svgConfig?.units);

  // Const: Determine the arrow increase / decrease for percentage change
  const arrow = props.svgConfig?.increase ? "↑" : "↓";
  const arrowGroupClassName = `${subCommentClassName}-1${
    props.svgConfig?.increase ? "-down" : ""
  }`;

  // Const: Determine the body of the card for Version 1 Component
  const cardBodyVersion1 = (
    <>
      <div className={metricGroupClassName}>
        <div className={metricClassName}>{props.svgConfig?.value || "000"}</div>
        <div className={unitClassName}>{superscriptedFormula || ""}</div>
      </div>
      {parseFloat(props.svgConfig?.percentageChange) === 0 ? (
        <> </>
      ) : (
        <div className={subCommentClassName}>
          <div className={arrowGroupClassName}>
            {props.svgConfig?.percentageChange}%{arrow}
          </div>
          <div className={`${subCommentClassName}-2`}>
            {props.svgConfig?.subComment || ""}
          </div>
        </div>
      )}
    </>
  );

  // Const: Determine the body of the card for Version 2 Component
  const cardBodyVersion2 = (
    <>
      <div className={metricGroupClassName}>
        <div className={metricClassName}>{props.svgConfig?.value || "000"}</div>
      </div>
      <div className={unitClassName}>{superscriptedFormula || ""}</div>
    </>
  );

  // Const: Dictionary for the version of the component to render within the card
  const versionDictionary = {
    1: cardBodyVersion1,
    2: cardBodyVersion2,
  };

  return (
    <>
      <div className={divClassName}>
        <div className={titleClassName}>
          {props.svgConfig?.title || ""}
          {props.svgConfig?.blurb}
          {props.svgConfig?.legend}
        </div>
        {props.subHeader}
        {versionDictionary[props.svgConfig?.version || 1]}
        <div className="svg-container">
          <svg
            className="svg-overlay"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 328 128"
            fill="none"
          >
            <path
              opacity={props.svgConfig?.opacity?.[0] || 0.295596}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-49.1938 57.2068C-49.1938 57.2068 70.0407 -21.7057 151.841 6.36561C233.641 34.437 332.855 22.1307 332.855 22.1307L333 316H-153L-146.906 80.9778L-49.1938 57.2068Z"
              fill={props.svgConfig?.color?.[0] || "#A1DD8C"}
            />
          </svg>
          <svg
            className="svg-overlay"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 328 128"
            fill="none"
          >
            <path
              opacity={props.svgConfig?.opacity?.[1] || 0.85}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-35 26.8498C-35 26.8498 61.4009 3.37935 143.159 31.3692C224.918 59.359 347 0 347 0V321H-17.5217L-35 26.8498Z"
              fill={props.svgConfig?.color?.[1] || "#A1DD8C"}
            />
          </svg>
          <svg
            className="svg-overlay"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 328 131"
            fill="none"
          >
            <path
              opacity={props.svgConfig?.opacity?.[2] || 0.1}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M365 17.1532C365 17.1532 316.229 -16.2126 234.302 11.7484C152.374 39.7093 -40 30.0094 -40 30.0094L-17.7909 311H347.486L365 17.1532Z"
              fill={props.svgConfig?.color?.[2] || "#A1DD8C"}
            />
          </svg>
          {props.svgChildren && (
            <div className="metric-tile-group-svg-children-container">
              {props.svgChildren}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

// Props
const metricTileGroupPropTypes = {
  divClassName: PropTypes.string, // class name for the div
  titleClassName: PropTypes.string, // class name for the title
  metricGroupClassName: PropTypes.string, // class name for the metricGroup
  metricClassName: PropTypes.string, // class name for the metric
  unitClassName: PropTypes.string, // class name for the label
  subCommentClassName: PropTypes.string, // class name for the subComment
  childrenContainer: PropTypes.string, // childrenContainer for the MetricTileGroup
  svgConfig: PropTypes.object, // svgConfig for the MetricTileGroup
  svgChildren: PropTypes.object, // svgChildren for the MetricTileGroup
  subHeader: PropTypes.object, // subHeader for the MetricTileGroup
};

MetricTileGroup.propTypes = metricTileGroupPropTypes;
