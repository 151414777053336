import { createContext, useEffect, useState } from "react";
import { reportsInitialStateFill } from "../constants/ReportsConfig";
import { getReportAutoFill } from "../api/Reports/ReportAutoFillAPI";
import { getDefaultEmissionReductionTargetAPI } from "../api/Reduction/EmissionReductionTarget/getDefaultEmissionReductionTargetAPI";

// INITIAL DATA TO IMPORT
import { isReductionTargetDefault } from "../constants/initialDataConfig";
// MOCK DATA TO IMPORT
import {
  mockReportsTableData,
  mockLoginData,
} from "../constants/mockDataConfig";

const ReportContext = createContext({});

// Provides access to token across whole application
export const ReportProvider = ({ children }) => {
  // State: Initialize State - Full Application
  const [reportIsLoading, setReportIsLoading] = useState(true);

  // State: Reports Table Data
  const [reportsTableData, setReportsTableData] = useState([]);

  // Report Header / Title Group
  const [radioValueDateType, setRadioValueDateType] = useState("");
  const [selectedValueDateType, setSelectedValueDateType] = useState(
    reportsInitialStateFill?.date
  );
  // State: Introduction
  const [introDataConfig, setIntroDataConfig] = useState(
    reportsInitialStateFill?.intro
  );

  // State: Risk Report
  const [riskDataConfig, setRiskDataConfig] = useState(
    reportsInitialStateFill?.risk
  );

  // State: Strategy Report
  const [strategyDataConfig, setStrategyDataConfig] = useState(
    reportsInitialStateFill?.strategy
  );

  // State: Target Report
  const [targetDataConfig, setTargetDataConfig] = useState(
    reportsInitialStateFill?.target
  );

  // State: Energy Report
  const [energyDataConfig, setEnergyDataConfig] = useState(
    reportsInitialStateFill?.energy
  );

  // State: Challenges Report
  const [challengesDataConfig, setChallengesDataConfig] = useState(
    reportsInitialStateFill?.challenges
  );

  // State: Selected Report Section Sidebar
  const [selectedReportSection, setSelectedReportSection] =
    useState("Introduction");

  const [selectedCountryOfOperation, setSelectedCountryOfOperation] = useState(
    introDataConfig?.countries_operation?.dropdown || []
  );

  // Input CSS Class
  const [reportInputClassName, setReportInputClassName] = useState({
    input: "input-element",
    textArea: "text-area-element",
  });

  // State: Initialize State - Default Emission Reduction Target
  const [reductionTargetDefault, setReductionTargetDefault] = useState(
    isReductionTargetDefault
  );

  const token = localStorage.getItem("greenToken");

  // UE: Get Dates For the Whole Application
  useEffect(() => {
    if (reportIsLoading && token && token !== mockLoginData?.token) {
      const promises = [];
      Promise.all(promises).then(() => {
        getReportAutoFill(
          setSelectedValueDateType,
          setRadioValueDateType,
          setIntroDataConfig,
          setRiskDataConfig,
          setStrategyDataConfig,
          setTargetDataConfig,
          setEnergyDataConfig,
          setChallengesDataConfig,
          setSelectedCountryOfOperation
        );
        getDefaultEmissionReductionTargetAPI(
          setReductionTargetDefault,
          () => {},
          () => {}
        );
        setReportIsLoading(false);
      });
    } else if (token === mockLoginData?.token) {
      setReportsTableData(mockReportsTableData);
      setReportIsLoading(false);
    }
  }, [reportIsLoading, token]);

  // Function: Generate the Date Data in Proper Format for Reporting
  const reportDateFormat = (dateData, selectedDateType) => {
    // Get Selected Date Type
    const selectedDate = selectedDateType.toLocaleLowerCase();
    if (selectedDate === "month") {
      return {
        month: dateData?.month?.[0]?.value,
        year: dateData?.year?.[0]?.value,
        qtd: false,
        ytd: false,
        custom: false,
      };
    } else if (selectedDate === "quarter") {
      return {
        month: false,
        year: dateData?.year?.[0]?.value,
        qtd: dateData?.qtd?.[0]?.value,
        ytd: false,
        custom: false,
      };
    } else if (selectedDate === "year") {
      return {
        month: false,
        year: dateData?.year?.[0]?.value,
        qtd: false,
        ytd: false,
        custom: false,
      };
    } else if (selectedDate === "ytd") {
      return {
        month: false,
        year: false,
        qtd: false,
        ytd: true,
        custom: false,
      };
    } else if (selectedDate === "custom") {
      return {
        month: false,
        year: false,
        qtd: false,
        ytd: false,
        custom: {
          start: dateData?.custom?.start,
          end: dateData?.custom?.end,
        },
      };
    }
  };
  // Const: Reformat the Data in Proper Format for Storing in BE API Call
  const reportDataFormat = {
    dateRanges: reportDateFormat(selectedValueDateType, radioValueDateType),
    ...introDataConfig,
    approval: {
      dropdown: introDataConfig?.approval?.dropdown,
      input:
        introDataConfig?.approval?.input2?.length > 0
          ? introDataConfig?.approval?.input +
            " - " +
            introDataConfig?.approval?.input2
          : introDataConfig?.approval?.input,
    },
    countries_operation: {
      textArea: introDataConfig?.countries_operation?.dropdown
        ?.map((item) => item?.value)
        ?.join(", "),
    },
    board_level_oversight: {
      dropdown: {
        label: introDataConfig?.board_level_oversight?.radio,
        value: introDataConfig?.board_level_oversight?.radio,
      },
      input: introDataConfig?.board_level_oversight?.textArea,
    },
    ...riskDataConfig,
    ...strategyDataConfig,
    ...targetDataConfig,
    ...energyDataConfig,
    ...challengesDataConfig,
  };

  const value = {
    reportIsLoading,
    reportsTableData,
    radioValueDateType,
    setRadioValueDateType,
    selectedValueDateType,
    setSelectedValueDateType,
    introDataConfig,
    setIntroDataConfig,
    riskDataConfig,
    setRiskDataConfig,
    strategyDataConfig,
    setStrategyDataConfig,
    targetDataConfig,
    setTargetDataConfig,
    energyDataConfig,
    setEnergyDataConfig,
    challengesDataConfig,
    setChallengesDataConfig,
    reportDataFormat,
    selectedReportSection,
    setSelectedReportSection,
    selectedCountryOfOperation,
    setSelectedCountryOfOperation,
    reportInputClassName,
    setReportInputClassName,
    reductionTargetDefault,
  };

  return (
    <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
  );
};

export default ReportContext;
