// Functionality: React hook to Validate Password Meets Requirements

export default function useValidatePassword(password) {
  // Define a regular expression for the password criteria
  const passwordRegex =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/;

  // Test the password against the regular expression
  const isValid = passwordRegex.test(password);

  // Return the validation result
  return isValid;
}
