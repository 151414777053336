// Functionality: Navigation Context Provider For the Application
import { useState, createContext, useEffect } from "react";

// Context: Initialize Navigation Context
const NavigationContext = createContext();

// Provider: Navigation Context Provider
const NavigationProvider = ({ children }) => {
  // State: Initialize Selected Navigation Link
  const [selectedNav, setSelectedNav] = useState(() => {
    // Use the value from sessionStorage if available, or default to an empty string
    return sessionStorage.getItem("selectedNav") || "";
  });

  // UE: Update sessionStorage when the selectedNav state changes
  useEffect(() => {
    sessionStorage.setItem("selectedNav", selectedNav);
  }, [selectedNav]);

  return (
    <NavigationContext.Provider value={{ selectedNav, setSelectedNav }}>
      {children}
    </NavigationContext.Provider>
  );
};

export { NavigationContext, NavigationProvider };
