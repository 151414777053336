// Functionality: Dropdown select component with multi-select and single-select modes
import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";

export default function SelectDropdown({
  options = [],
  isMultiSelect = false,
  selectedOptions = [],
  setSelectedOptions = () => {},
  placeHolderText = false,
  divClassName = "select-dropdown-main-container-100 font-color-gray",
  headerClassName = "select-dropdown-header-container font-color-gray",
  dropdownClassName = "select-dropdown-options-container",
  optionClassName = "select-dropdown-option",
  disabled = false,
  ...props
}) {
  // State & Refs
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false); // Determine if dropdown is open
  const dropdownRef = useRef(null); // Ref to dropdown element

  // Const: Determine the placeholder text
  const placeHolderValue = placeHolderText || "Select";

  const multiSelectPlaceholder =
    selectedOptions.length > 0 ? (
      <div className="multi-select-placeholder-container">
        {selectedOptions.map((opt) => (
          <div key={opt.value} className="multi-select-placeholder-value">
            {opt.label}
          </div>
        ))}
      </div>
    ) : (
      placeHolderText || placeHolderValue
    );

  // Const: Determine the text to display in the dropdown header
  const dropdownHeaderText = isMultiSelect
    ? multiSelectPlaceholder
    : selectedOptions.length > 0
    ? selectedOptions[0].label
    : placeHolderText || placeHolderValue;

  // Function: Open / close the dropdown
  const toggleDropdown = () => {
    if (!disabled) {
      setDropdownIsOpen(!dropdownIsOpen);
    }
  };

  // Function: Close the dropdown on click outside
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownIsOpen(false);
    }
  };

  // Function: Update the state of selected options
  const handleSelectChange = (option) => {
    if (props.onChange) {
      props.onChange(option);
      setDropdownIsOpen(false); // Close the dropdown after selecting in single-select mode
    } else {
      // Condition of Multi-Select options - don't close the dropdown after selecting
      if (isMultiSelect) {
        setSelectedOptions((prevSelectedOptions) => {
          const isSelected = prevSelectedOptions?.some(
            (item) => item?.value === option?.value
          );
          if (isSelected) {
            return prevSelectedOptions?.filter(
              (item) => item?.value !== option?.value
            );
          } else {
            return [...prevSelectedOptions, option];
          }
        });
      }
      // Condition of Single-Select options
      else {
        setSelectedOptions([option]);
        setDropdownIsOpen(false); // Close the dropdown after selecting in single-select mode
      }
    }
  };

  // UE: Add event listener to close dropdown on click outside
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className={divClassName} ref={dropdownRef}>
      <div className={headerClassName} onClick={toggleDropdown}>
        {dropdownHeaderText}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="19"
          viewBox="0 0 13 19"
          fill="none"
        >
          <path d="M9 8L5.5 12L2 8L9 8Z" fill="#66C743" />
        </svg>
      </div>
      {dropdownIsOpen && (
        <ul className={dropdownClassName}>
          {options?.map((option) => (
            <li
              key={option.value}
              className={`${optionClassName} ${
                selectedOptions?.some(
                  (selectedOption) => selectedOption.value === option.value
                )
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSelectChange(option)}
            >
              {option?.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

// Props
const selectDropdownPropTypes = {
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    PropTypes.object,
  ]), // Array of options to select from
  isMultiSelect: PropTypes.bool, // Indicate if the dropdown is multi-select or single-select
  selectedOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    PropTypes.number,
  ]), // Array of selected options
  setSelectedOptions: PropTypes.func, // Function: on change handler to select options
  placeHolderText: PropTypes.string, // Placeholder text for the dropdown or default text
  divClassName: PropTypes.string, // Class name for the dropdown container
  headerClassName: PropTypes.string, // Class name for the dropdown header
  dropdownClassName: PropTypes.string, // Class name for the dropdown options
  optionClassName: PropTypes.string, // Class name for the dropdown option
};

SelectDropdown.propTypes = selectDropdownPropTypes;
