// Functionality: This file exports the Scope2ChartCard Card component which is used in the Emissions Page component.
import { useContext } from "react";
import EmissionsContext from "../../context/EmissionsProvider";
import LineChart from "../../visualizations/LineChart";
import useSub from "../../hooks/use-Sub.js";
import Skeleton from "react-loading-skeleton";
import { scope2ChartEmissionsTile } from "../../constants/TileConfig.js";

export default function Scope2ChartCard() {
  // Const: Initialize Context
  const { scope2DataPoints } = useContext(EmissionsContext);

  // Const: Super Scripted label for MT CO2e
  const superScriptedLabel = useSub("MT CO2e");

  return (
    <>
      {!scope2DataPoints?.loaded ? (
        <Skeleton height={"100%"} />
      ) : (
        <div className="chart-card-container-group">
          <div className="card-title">
            Scope 2 Emissions
            {scope2ChartEmissionsTile?.blurb}
          </div>
          <div className="card-subtitle">{superScriptedLabel}</div>
          <div className="emissions-page-chart-container">
            <LineChart
              datasets={[scope2DataPoints?.data]}
              lineBreak={scope2DataPoints?.lineBreak}
              maxValue={scope2DataPoints?.maxValue}
              annotationValue={scope2DataPoints?.annotationValue}
            />
          </div>
        </div>
      )}
    </>
  );
}
