// Functionality: Component to display when there are failed records in the initial upload.
export default function FailedInitialRecordsSummary({
  BiError,
  failedDecoderState,
}) {
  const failedRecordsInformation = (type) => {
    const data = failedDecoderState?.mapping?.[type];
    if (data?.count === 0) {
      return <></>;
    } else {
      return (
        <div className="vin-decoder-failed-entries-info-content" key={type}>
          <BiError />
          <p>({data?.count}) </p>
          <p>{data?.summary?.split(":")?.[0]}</p>
          <p>{data?.summary?.split(":")?.[1]}</p>
        </div>
      );
    }
  };
  // Const: Failed Records Summary
  const failedRecordsSummary = (
    <>
      <div className="vin-decoder-failed-entries-group">
        <div className="vin-decoder-failed-entries-subgroup">
          <div className="vin-decoder-failed-entries-group-header">
            <BiError />
            <p>
              {failedDecoderState?.failed_length} vehicles out of{" "}
              {failedDecoderState?.upload_length} failed to meet the credentials
              to run vin decoder.
            </p>
          </div>
        </div>
        <div className="vin-decoder-failed-entries-subgroup-content">
          <p>
            Please resolve the following entry errors to import and continue to
            VIN Decoder:
          </p>
          <div>
            {Object.keys(failedDecoderState?.mapping).map((type) =>
              failedRecordsInformation(type)
            )}
          </div>
        </div>
      </div>
    </>
  );
  return <>{failedRecordsSummary}</>;
}
