// This file serves as a central location for all configuration variables for Authentication
// Input Auth Fields - Used in login and forgot password page
export const authFields = [
  {
    labelText: "Email",
    labelFor: "user_name",
    id: "email",
    name: "email",
    type: "text",
    autoComplete: "email",
    isRequired: true,
    placeholder: "email",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "password",
  },
];
// Reset Password Code Fields
export const resetPasswordCodeFields = {
  labelText: "Verification Code",
  labelFor: "code",
  id: "code",
  name: "code",
  type: "text",
  autoComplete: "code",
  isRequired: true,
  placeholder: "code",
};

// Input Signup Fields
export const signupInputFields = [
  {
    labelText: "Email",
    labelFor: "email",
    id: "email",
    name: "email",
    type: "text",
    autoComplete: "email",
    isRequired: true,
    placeholder: "email",
    disabled: true,
    divClassName: "signup-input-main-container",
  },
  {
    labelText: "Username",
    labelFor: "user_name",
    id: "user_name",
    name: "user_name",
    type: "text",
    autoComplete: "user_name",
    isRequired: true,
    placeholder: "user name",
    disabled: true,
    divClassName: "signup-input-main-container",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "password",
    disabled: false,
    divClassName: "signup-input-main-container",
  },
  {
    labelText: "Confirm Password",
    labelFor: "confirm_password",
    id: "confirm_password",
    name: "confirm_password",
    type: "password",
    isRequired: true,
    placeholder: "confirm password",
    disabled: false,
    divClassName: "signup-input-main-container",
  },
];

// Checkbox signup options
export const signupCheckboxOptions = [
  {
    label: "Enroll in Expo Developer Services",
    value: "enroll_in_expo_developer_services",
  },
];

// Checkbox agree terms options
export const agreeTermsCheckboxOptions = [
  {
    label: (
      <div>
        By creating an account you agree to our{" "}
        <a
          href="https://www.greenirr.com/terms-service.html"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href="https://www.greenirr.com/privacy-policy.html"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy.
        </a>
      </div>
    ),
    value: "agree_terms",
  },
];

// Checkbox agree terms options
export const agreeDemoTermsCheckboxOptions = (handleClick) => {
  return [
    {
      label: (
        <div>
          By creating an account you agree to our{" "}
          <a
            onClick={handleClick}
            target="_blank"
            rel="noreferrer"
            className="terms-services-link"
          >
            Demo Terms of Service
          </a>
        </div>
      ),
      value: "agree_terms",
    },
  ];
};

// Const: Signup Fields - Password Requirements Text
export const passwordRequirementsText =
  "Passwords should be at least 8 characters long and contain at least one number, one lowercase letter, one uppercase letter, and one special character.";

// Registration Form Fields
// Hubspot Information
export const hubspotRegistrationFormConfig = {
  1: {
    src: "//js.hsforms.net/forms/embed/v2.js",
    region: "na1",
    portalId: "44706451",
    formId: "11fa687b-8650-4420-b5e9-6ea6c11849c9",
  },
  2: {
    src: "//js.hsforms.net/forms/embed/v2.js",
    region: "na1",
    portalId: "44706451",
    formId: "eefb94ea-f34d-4ca4-8b57-6ca49cf27782",
  },
  3: {
    src: "//js.hsforms.net/forms/embed/v2.js",
    region: "na1",
    portalId: "44706451",
    formId: "be0234d5-5d32-42be-84db-4d73c02deea9",
  },
  4: {
    src: "//js.hsforms.net/forms/embed/v2.js",
    region: "na1",
    portalId: "44706451",
    formId: "27b9e7de-b917-45b9-b43f-d61cdd372dd5",
  },
};
export const hubspotInvitedInformation = {
  src: "//js.hsforms.net/forms/embed/v2.js",
  region: "na1",
  portalId: "44706451",
  formId: "48de07f9-21df-4986-aff8-fa7d467e480c",
};

export const hubspotTicketForm = {
  src: "//js.hsforms.net/forms/embed/v2.js",
  region: "na1",
  portalId: "44706451",
  formId: "2f6a7b2c-3f92-4e83-935f-2ab8ba504bc6",
};

export const hubspotOnboardDemoForm = {
  src: "//js.hsforms.net/forms/embed/v2.js",
  region: "na1",
  portalId: "44706451",
  formId: "f8f23144-4fad-422c-a7e2-b2b650bc966e",
};

export const husbpotDemoCTA = {
  src: "//js.hscta.net/cta/current.js",
  portalId: "44706451",
  ctaId: "170102490941",
};

// Hard Coded Email to reference
export const demoEmail = ["demo@nfi.com", "demo@jbh.com", "SKroese@werner.com"];

// Const: Daily v Monthly Radio Item
export const dailyMonthlyRadioItemOptions = [
  {
    label: "Monthly",
    value: "Daily",
  },
  {
    label: "Yearly",
    value: "Monthly",
  },
];
