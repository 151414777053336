// This file serves as a central location for all configuration variables related to Reduction Page
import UniversalTableConfig from "./UniversalTableConfig.js";
import { BsHourglassSplit } from "react-icons/bs";

// Solutions Marker (Solutions Marker)
// Solutions Marker Columns
export const solutionsMarkerColumns = ["date", "name", "description"];
// Solutions Marker Table Config
export const solutionsMarkerTableConfig = () => {
  // Add the rest of the columns
  const columnsConfig = UniversalTableConfig(solutionsMarkerColumns);

  // Add the impact output configuration
  const impactConfig = [
    {
      Header: "Impact",
      accessor: (smData) => smData["impact"],
      Cell: (row) => {
        // Get the value from the cell
        const { value } = row.cell;
        // Get the color of the value based on the sign (+/-)
        const isNegative = JSON.stringify(value)?.includes("-")
          ? "green"
          : "red";

        // Return the data based on the value of the cell
        const returnData =
          value === 0 ? (
            <BsHourglassSplit />
          ) : (
            <p className="table-impact-col-group-value">{Math.abs(value)}%</p>
          );

        return (
          <span
            className={`table-impact-col-group table-impact-col-group-${
              value === 0 ? "" : isNegative
            }`}
          >
            <p className="table-impact-col-group-label">
              {value !== 0 && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  transform={isNegative === "red" ? "" : "rotate(180 1 2)"}
                >
                  <path d="M9 5L6.00001 2L3 5" fill="#00A63F" />
                  <path
                    d="M9 5L6.00001 2L3 5H9Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M6 13L6 2"
                    stroke="white"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </p>
            {returnData}
          </span>
        );
      },
    },
  ];
  return columnsConfig.concat(impactConfig);
};

// Emission Reduction Target Component
// Const: Reduction Target % Dropdown Data
const reductionTargetPercentageDropdownData = [
  5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
  100,
];
export const reductionTargetPercentageDropdown =
  reductionTargetPercentageDropdownData.map((value) => {
    return {
      label: `${value}%`,
      value: value,
    };
  });

// Const: Emission Reduction Target Data Config
export const emissionReductionTargetDataConfig = {
  labels: [],
  datasets: [
    {
      label: "Actual",
      backgroundColor: "rgba(0, 117, 210, 1)",
      borderWidth: 1,
      data: [],
      barPercentage: 0.2,
    },
    {
      label: "Target",
      backgroundColor: "rgba(0, 117, 210, 0.2)",
      borderWidth: 1,
      data: [],
      barPercentage: 0.6,
    },
  ],
};

// Const: Emission Forecasting Data Config
export const emissionForecastingDataConfig = {
  labels: [],
  actualData: [],
  forecastData: [],
  loaded: false,
};

// Sustainabile Solutions Category Dropdown Options Component
const sustainableSolutionsCategoryDropdownData = [
  "Alternative Fuel",
  "EV Integration",
  "New Vehicle Models",
  "Route Behavior",
  "Other",
];
export const sustainableSolutionsCategoryDropdown =
  sustainableSolutionsCategoryDropdownData?.map((value) => {
    return {
      label: value,
      value: value,
    };
  });
