import { createContext, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { mockLoginData } from "../constants/mockDataConfig";
import { useState } from "react";

export const AUTH_CONTEXT_DEFAULT_VALUE = {
  logIn: async () => {
    return;
  },
};

export const AuthContext = createContext(AUTH_CONTEXT_DEFAULT_VALUE);

export const AuthProvider = ({ children }) => {
  // State of the login  email & password
  const [loginState, setLoginState] = useState({
    email: "",
    password: "",
  });

  // State: Store Username for email prepopulating
  const [storedEmail, setStoredEmail] = useState("");

  // State of the error message
  const [loginErrMsg, setLoginErrMsg] = useState("");

  // State - show reset password form
  const [showForgotPW, setShowForgotPW] = useState(false);

  // State: Reset Password State - Full Application
  const [forgotPWState, setForgotPWState] = useState({
    email: loginState?.email,
    password: "",
    confirm_password: "",
    code: "",
  });

  // State: Reset Password State
  const [resetPWState, setResetPWState] = useState({
    email: loginState?.email,
    password: "",
    confirm_password: "",
    code: "",
  });

  // UE: Update the storedEmail to reference throughout the application
  useEffect(() => {
    if (loginState?.email?.length > 0) {
      setStoredEmail(loginState?.email);
    }
  }, [loginState?.email]);

  const logIn = async (data) => {};

  const accessToken = localStorage.getItem("greenToken");
  const carrierName =
    accessToken !== mockLoginData?.token && accessToken !== null
      ? jwtDecode(JSON.stringify(accessToken))?.customer_name
      : mockLoginData?.customer_name;

  const value = {
    logIn,
    carrierName,
    loginState,
    setLoginState,
    loginErrMsg,
    setLoginErrMsg,
    showForgotPW,
    setShowForgotPW,
    forgotPWState,
    setForgotPWState,
    resetPWState,
    setResetPWState,
    storedEmail,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
