// Functionality: Provide a basis for the registration layout, which is a component imported from hubspot
import { useEffect } from "react";

export default function HubspotFormLayout({
  backgroundContainerClassName = "colored-bar-background-container",
  mainContainerClassName = "colored-bar-main-container-content",
  hubspotFormMiddleClassName = "hubspot-form-middle",
  hubspotFormId = "hubspotForm",
  hubspotActualFormClassName = "",
  ignoreCSS = false,
  handleSubmit = () => {},
  ...props
}) {
  // UE: Generate the Hubspot form that is embedded on the page
  useEffect(() => {
    const script = document.createElement("script");
    script.src = props?.src;
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          css: ignoreCSS,
          region: props?.region,
          portalId: props?.portalId,
          formId: props?.formId,
          target: "#hubspotForm",
          onBeforeFormSubmit: function (event) {
            props.handleBeforeSubmit();
          },
          onFormSubmitted: function (event) {
            handleSubmit();
          },
        });
      }
    });
  }, []);

  return (
    <div>
      <div className={backgroundContainerClassName}></div>
      <div className={mainContainerClassName}>
        <div className={hubspotFormMiddleClassName}>
          {props.headerChildren}
          <div id={hubspotFormId} className={hubspotActualFormClassName}></div>
        </div>
      </div>
    </div>
  );
}
