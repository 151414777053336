// Functionality: Modal Verification of Updating Failed Initial Records, POST Call to update Vehicles & VINs for decoder
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";

export default function FailedInitialRecordsModalVerify({
  handleFailedRecordsDisabled,
  updatedDecoderState,
  setArrayState,
  handleModalClose,
  showModal,
  setShowModal,
}) {
  const modalContent = (
    <div>
      <div className="card-title">
        Are you sure you want to continue to update VIN records for the uploaded
        Vehicles?
      </div>
      <div className="flex-end-button-group">
        <Button
          buttonName="Cancel"
          className="secondary-blue-button"
          disabled={handleFailedRecordsDisabled(updatedDecoderState?.data)}
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Button
          buttonName="Update Records"
          className="primary-blue-button"
          disabled={handleFailedRecordsDisabled(updatedDecoderState?.data)}
          onClick={() => {
            setArrayState();
          }}
        />
      </div>
    </div>
  );

  const verifyModal = (
    <Modal onClose={handleModalClose} children={modalContent} />
  );
  return <>{showModal && verifyModal}</>;
}
