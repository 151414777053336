import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

Chart.register(annotationPlugin);

export default function ForecastLineChart({
  indexes,
  labels,
  actualData,
  forecastData,
  lowerboundData,
  upperboundData,
  lineBreak,
  maxValue,
  annotationValue,
}) {
  // Refs
  const forecastChartRef = useRef(null); // Refs to Forecast Chart
  const chartType = "line"; // Type of chart is line

  const forecastShadowDataLower = lowerboundData
    ? {
        label: "Lower Bound",
        borderColor: "#0096FA1A",
        borderWidth: 2,
        data: lowerboundData?.map((item, index) => {
          return {
            x: index + 11,
            y: item,
          };
        }),
        fill: "#0096FA1A",
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: "#0096FA1A",
      }
    : {
        label: "Forecast Shadow",
        borderColor: "#0096FA1A",
        borderWidth: 90,
        data: forecastData?.map((item, index) => {
          return {
            x: index + 11,
            y: item,
          };
        }), // Include a null point before forecasted data
        fill: "-1",
        pointRadius: 0, // Set point radius to 0
        pointHoverRadius: 0, // Set hover point radius to 0
      };
  const forecastShadowDataUpper = upperboundData
    ? {
        label: "Upper Bound",
        borderColor: "#0096FA1A",
        borderWidth: 2,
        data: upperboundData?.map((item, index) => {
          return {
            x: index + 11,
            y: item,
          };
        }), // Include the upper bound projections
        fill: "-1", // This will fill the area above the line
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: "#0096FA1A",
      }
    : {};
  // UE: Generate the Forecast Chart - Rerender when the datasets change and on initial load
  useEffect(() => {
    if (!forecastChartRef.current) return; // Ensure canvas is defined

    if (actualData && forecastData) {
      // Create a combined dataset for actual and forecasted data
      const combinedData = {
        labels: indexes,
        datasets: [
          {
            label: "Actual Data",
            borderColor: "#232C33",
            borderWidth: 1.5,
            data: actualData?.map((item, index) => {
              return {
                x: index,
                y: item,
              };
            }),
            fill: false,
            pointRadius: 2, // Larger point radius
            pointBackgroundColor: "rgba(0, 0, 0, 0)", // Transparent color for the point
            pointHoverRadius: 20, // Larger hover radius
            // pointHoverBorderColor: "transparent", // Set hover border color to transparent
            pointHoverBackgroundColor: "rgba(0, 0, 0, 0)", // Transparent hover color
            pointHoverBorderWidth: 0, // Set hover border width to 0 for an invisible point
          },
          {
            label: "Forecast Data",
            borderColor: "#0096FA",
            borderWidth: 1.5,
            borderDash: [5, 5], // Make the forecasted line dashed
            data: forecastData?.map((item, index) => {
              return {
                x: index + 12,
                y: item,
              };
            }), // Include a null point before forecasted data
            fill: false,
            pointRadius: 2, // Larger point radius
            pointBackgroundColor: "rgba(0, 0, 0, 0)", // Transparent color for the point
            pointHoverRadius: 20, // Larger hover radius
            pointHoverBorderColor: "transparent", // Set hover border color to transparent
            pointHoverBackgroundColor: "rgba(0, 0, 0, 0)", // Transparent hover color
            pointHoverBorderWidth: 0, // Set hover border width to 0 for an invisible point
          },
          forecastShadowDataLower,
          forecastShadowDataUpper,
        ],
      };
      const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              callback: (index) => {
                return labels[index];
              },
            },
          },
          y: {
            stacked: false,
            beginAtZero: true,
            stepSize: 3000,
            grace: "20%",
          },
        },
        plugins: {
          legend: {
            display: true,
            labels: {
              filter: function (item, chart) {
                return (
                  item?.text != null &&
                  !["Lower Bound", "Upper Bound"]?.includes(item.text)
                );
              },
            },
          },
          annotation: {
            annotations: {
              line1: {
                type: "line",
                xMin: lineBreak,
                xMax: lineBreak,
                borderColor: "black",
                borderWidth: lineBreak ? 1 : 0,
                mode: "vertical",
                borderDash: [5, 5],
              },
              label2: annotationValue?.first && {
                type: "label",
                xValue: lineBreak - 1,
                yValue: maxValue + 100,
                backgroundColor: "rgba(245,245,245)",
                content: [annotationValue?.first],
                font: {
                  size: 12,
                },
              },
              label1: annotationValue?.second && {
                type: "label",
                xValue: lineBreak + 1,
                yValue: maxValue + 100,
                backgroundColor: "rgba(245,245,245)",
                content: [annotationValue?.second],
                font: {
                  size: 12,
                },
              },
            },
          },
          tooltip: {
            callbacks: {
              // This function changes the label for each data point when hovering
              title: function (tooltipItem, data) {
                const dataIndexValue = tooltipItem?.[0]?.dataIndex;
                return labels[dataIndexValue];
              },
            },
          },
        },
      };

      // Generate the Forecast Chart Component
      const chart = new Chart(forecastChartRef.current, {
        type: chartType,
        data: combinedData,
        options: options,
      });

      // Cleanup function when component is unmounted
      return () => {
        chart.destroy();
      };
    }
  }, [actualData, forecastData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <canvas
      ref={forecastChartRef}
      id="forecast-chart-container"
      className="forecast-chart"
    />
  );
}

// Props
const forecastLineChartPropTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  actualData: PropTypes.arrayOf(PropTypes.number),
  forecastData: PropTypes.arrayOf(PropTypes.number),
  lowerboundData: PropTypes.arrayOf(PropTypes.number),
  upperboundData: PropTypes.arrayOf(PropTypes.number),
};

ForecastLineChart.propTypes = forecastLineChartPropTypes;
