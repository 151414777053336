import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useQueryParams from "../hooks/use-queryParams";
import { getDatesAPI } from "../api/Auth/DatesAPI";
import { mockYearData, mockLoginData } from "../constants/mockDataConfig";

// Initial Data to import
import {
  isYearValues,
  isApplicationDates,
  isDailyMonthlyRadioItemOptions,
} from "../constants/initialDataConfig";

const DateContext = createContext({});

// Provides access to token across whole application
export const DateProvider = ({ children }) => {
  // Get the query parameters from the URL for the setting the active tab
  const location = useLocation();
  const query = useQueryParams();
  const activeTabURL = query.get("activeTab") || 0;

  // State: Initialize State - Full Application
  const [dateIsLoading, setDateIsLoading] = useState(true);

  // State: Initialize State - Date Values
  const [dateValues, setDateValues] = useState([]);

  // State: Year Values - Date Values
  const [yearValues, setYearValues] = useState(isYearValues);

  // State: Month Values - Date Values
  const [monthValues, setMonthValues] = useState({});

  // State: Quarter Values - Date Values
  const [quarterValues, setQuarterValues] = useState({});

  // State: Set Application Dates
  const [applicationDates, setApplicationDates] = useState(isApplicationDates);

  // State: Set Active Tabs - Application
  const [activeTab, setActiveTab] = useState({
    "/vehicle-performance":
      location?.pathname === "/vehicle-performance" ? activeTabURL : 0,
    "/settings": location?.pathname === "/settings" ? activeTabURL : 0,
    "/emissions": location?.pathname === "/emissions" ? activeTabURL : 0,
  });

  const [selectedChartRadioItemType, setSelectedChartRadioItemType] = useState(
    isDailyMonthlyRadioItemOptions
  );

  // Const: Get Token & Make Sure Available For API Call
  const token = localStorage.getItem("greenToken");

  // Function that Generates the mock data for the month and year
  const generateMonthYearData = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
    const currentYear = currentDate.getFullYear();

    const months = [];

    // Push three months ago
    const threeMonthsAgo = ((currentMonth - 3 + 12) % 12) + 1;
    const threeMonthsAgoYear =
      threeMonthsAgo === 12 ? currentYear - 1 : currentYear;
    months.push({
      label: `${new Date(threeMonthsAgoYear, threeMonthsAgo - 1).toLocaleString(
        "en-US",
        { month: "long", year: "numeric" }
      )}`,
      value: `3`,
    });

    // Push two months prior
    const twoMonthsAgo = ((currentMonth - 2 + 12) % 12) + 1;
    const twoMonthsAgoYear =
      twoMonthsAgo === 12 ? currentYear - 1 : currentYear;
    months.push({
      label: `${new Date(twoMonthsAgoYear, twoMonthsAgo - 1).toLocaleString(
        "en-US",
        { month: "long", year: "numeric" }
      )}`,
      value: `2`,
    });

    // Push current month
    months.push({
      label: `${new Date(currentYear, currentMonth - 1).toLocaleString(
        "en-US",
        { month: "long", year: "numeric" }
      )}`,
      value: `1`,
    });

    return months;
  };

  // UE: Get Dates For the Whole Application
  useEffect(() => {
    if (dateIsLoading && token && token !== mockLoginData?.token) {
      const promises = [
        getDatesAPI(
          setDateValues,
          setYearValues,
          setSelectedChartRadioItemType,
          setMonthValues,
          setQuarterValues
        ),
      ];
      Promise.all(promises).then(() => {
        setDateIsLoading(false);
      });
    } else if (token === mockLoginData?.token) {
      setDateIsLoading(false);
      setDateValues(generateMonthYearData());
      setYearValues(mockYearData);
    }
  }, [dateIsLoading, token]);

  // Initialize all date components to be the most recent date
  useEffect(() => {
    if (dateValues?.length > 0) {
      // Determine Which Date to Show
      let todaysDate = new Date();
      const now = new Date();
      const utcNow = new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      );

      todaysDate = utcNow;

      const showCurrentMonth =
        parseInt(
          todaysDate.toISOString().split("T")[0]?.split("-")?.slice(-1)?.[0]
        ) > 2;

      const showIndustryCarbon =
        parseInt(
          todaysDate.toISOString().split("T")[0]?.split("-")?.slice(-1)?.[0]
        ) > 15;

      const initialDate = dateValues?.slice(-1)?.[0]?.value;

      setApplicationDates({
        emissionFootprint: initialDate,
        overviewRightTiles: initialDate,
        vehicleEmissions: initialDate,
        industryCarbonRating: !showCurrentMonth
          ? dateValues?.slice(-1)?.[0]?.value
          : showIndustryCarbon
          ? dateValues?.slice(-1)?.[0]?.value
          : dateValues?.slice(-2)?.[0]?.value,
        scope1Emissions: initialDate,
        scope1ChartEmissions: initialDate,
        scope2Emissions: initialDate,
        emissionReductionTarget: initialDate.slice(0, 4), // Only the year is needed
        emissionsPerVehicle: initialDate,
        avgEmissionsPerVehicle: initialDate,
        totalIdleTime: initialDate,
        idleChartEmissions: initialDate,
        vpData: initialDate,
        vpProfile: initialDate,
        vehicleProfile: initialDate,
        lifecycle: initialDate,
        lifecycleChart: initialDate,
      });
    }
  }, [dateValues]);

  const value = {
    dateIsLoading,
    dateValues,
    setDateValues,
    applicationDates,
    setApplicationDates,
    yearValues,
    activeTab,
    setActiveTab,
    selectedChartRadioItemType,
    setSelectedChartRadioItemType,
    monthValues,
    quarterValues,
  };

  return <DateContext.Provider value={value}>{children}</DateContext.Provider>;
};

export default DateContext;
