// Functionality: React hook to generate and apply a formatting for a number to display within the application

export default function useNumberFormat(number) {
  // Round the number to the nearest whole number & format with commas
  const roundedData = Math.round(number)
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return roundedData;
}
