import { createContext, useEffect, useState, useContext, useRef } from "react";
import DateContext from "./DateProvider";
import { getEmissionsPerVehicleAPI } from "../api/VehiclePerformance/EmissionsPerVehicleAPI";
import { getAverageEmissionsPerVehicleAPI } from "../api/VehiclePerformance/AverageEmissionsPerVehicleAPI";
import { postTotalIdleTime } from "../api/VehiclePerformance/TotalIdleTimeAPI";
import { getIdleTimeEmissionsAPI } from "../api/VehiclePerformance/IdleTimeEmissionsAPI";
import { getVehiclePerformanceDataAPI } from "../api/VehiclePerformance/VehiclePerformanceDataAPI";
import { idleTimeEmissionsDataConfig } from "../constants/VehicleConfig";
import { getRankedVehicleProfileGroup } from "../api/VehiclePerformance/RankedVehicleProfileGroupAPI";

// INITIAL DATA TO IMPORT
import {
  averageEmissionsperVehicleSVGConfig,
  totalIdleTimeSVGConfig,
} from "../constants/EmissionsConfig";
import { emissionsPerVehicleSortDataConfig } from "../constants/VehicleConfig";

// MOCK DATA TO IMPORT
import {
  mockAvgEmissionsPerVehicle,
  mockTotalIdleTimeEmissions,
  mockIdleTimeEmissionsDataValues,
  mockIdleTimeAnnotationValueData,
  mockEmissionsPerVehicleData,
  mockEmissionsPerVehicleDataBenchmark,
  mockLoginData,
  mockVehiclePerformanceDataTable,
} from "../constants/mockDataConfig";

const VehiclePerformanceContext = createContext({});

// Provides access to token across whole application
export const VehiclePerformanceProvider = ({ children }) => {
  // State: Initialize State - Full Page
  const [vehiclePerformanceIsLoading, setVehiclePerformanceIsLoading] =
    useState(true);

  // State: Initialize State - Emissions Per Vehicle
  const [emissionsPerVehicleData, setEmissionsPerVehicleData] = useState({
    loaded: false,
    data: [],
  });

  // State: Initialize State - Emissions Per Vehicle Bar Chart Data
  const [emissionsBarChartData, setEmissionsBarChartData] = useState({
    loaded: false,
    data: [],
  });

  // State: Initialize State - Average Emissions Per Vehicle
  const [averageEmissionsPerVehicle, setAverageEmissionsPerVehicle] = useState({
    loaded: false,
    value: 0,
  });

  // State: Initialize State - Total Idle Time
  const [totalIdleTime, setTotalIdleTime] = useState({
    loaded: false,
    data: {},
  });
  const [idleTimeEmissionsData, setIdleTimeEmissionsData] = useState({
    loaded: false,
    data: [],
  });

  // DATA DATA --------------------------------------------------------------------------------
  // State: Initialize State - Vehicle Performance Data Table
  const [vehiclePerformanceDataTable, setVehiclePerformanceDataTable] =
    useState({
      loaded: false,
      data: [],
    });

  // State: Initialize State - Vehicle Performance Data Table (Filtering Purposes)
  const [
    filteredVehiclePerformanceDataTable,
    setFilteredVehiclePerformanceDataTable,
  ] = useState({
    loaded: false,
    data: [],
  });

  // State: Initialize State - Vehicle Performance Filter Emissions
  const [
    vehiclePerformanceFilterEmissions,
    setVehiclePerformanceFilterEmissions,
  ] = useState();

  // State: Initialize State - Vehicle Performance Filter Idle Time
  const [
    vehiclePerformanceFilterIdleTime,
    setVehiclePerformanceFilterIdleTime,
  ] = useState();

  // State: Initialize State - Vehicle Performance Filter Idle Time Emissions
  const [
    vehiclePerformanceFilterIdleTimeEmissions,
    setVehiclePerformanceFilterIdleTimeEmissions,
  ] = useState();

  // State: Initialize State - Vehicle Performance Filter Vehicle
  const [vehiclePerformanceFilterVehicle, setVehiclePerformanceFilterVehicle] =
    useState();

  // State: Initialize State - Vehicle Performance Carbon Emissions
  const [
    vehiclePerformanceFilterCarbonEmissions,
    setVehiclePerformanceFilterCarbonEmissions,
  ] = useState();

  // State: Initialize State - Vehicle Performance Filter CH4
  const [vehiclePerformanceFilterCH4, setVehiclePerformanceFilterCH4] =
    useState();

  // State: Initialize State - Vehicle Performance Filter N2O
  const [vehiclePerformanceFilterN2O, setVehiclePerformanceFilterN2O] =
    useState();

  // State: Initialize State - Filters is Loading
  const [filtersIsLoading, setFiltersIsLoading] = useState({
    data: true,
    profile: true,
  });

  // State: Initialize State - Clear Button Filter State
  const [clearButtonFilter, setClearButtonFilter] = useState({
    data: false,
    profile: false,
  });

  // State: Initialize State - Set Filter String
  const [filterString, setFilterString] = useState("");

  // State: Initialize State - Selected Sort Value
  const [selectedVehicleSortValue, setSelectedVehicleSortValue] = useState(
    emissionsPerVehicleSortDataConfig[2].value
  );

  // State: Initialize State - Emissions Per Vehicle Bar Chart Data
  const itemsPerPage = 30;
  const [sliceValue, setSliceValue] = useState([0, itemsPerPage]);

  // PROFILE DATA ------------------------------------------------------------------------------
  // State: Vehicle Profile Data
  const [vehicleProfileData, setVehicleProfileData] = useState({
    loaded: false,
    data: [],
    nextPage: false,
  });

  // State: Initialize State - Vehicle Profile Data Page
  const [vehicleProfileDataPage, setVehicleProfileDataPage] = useState(1);

  // State: Initialize State - Filters Vehicle Profile Data
  const [filtersVehicleProfileData, setFiltersVehicleProfileData] = useState({
    loaded: false,
    data: [],
  });

  // State: Initialize State - Vehicle Profile Filter Emissions
  const [vehicleProfileFilterEmissions, setVehicleProfileFilterEmissions] =
    useState();

  // State: Initialize State - Vehicle Profile Filter Idle Time
  const [vehicleProfileFilterIdleTime, setVehicleProfileFilterIdleTime] =
    useState();

  // State: Initialize State - Vehicle Profile Filter Idle Time Emissions
  const [
    vehicleProfileFilterIdleTimeEmissions,
    setVehicleProfileFilterIdleTimeEmissions,
  ] = useState();

  // State: Initialize State - Vehicle Profile Filter Vehicle
  const [vehicleProfileFilterVehicle, setVehicleProfileFilterVehicle] =
    useState();

  // State: Initialize State - Vehicle Profile Carbon Emissions
  const [
    vehicleProfileFilterCarbonEmissions,
    setVehicleProfileFilterCarbonEmissions,
  ] = useState();

  // State: Initialize State - Vehicle Profile Filter CH4
  const [vehicleProfileFilterCH4, setVehicleProfileFilterCH4] = useState();

  // State: Initialize State - Vehicle Profile Filter N2O
  const [vehicleProfileFilterN2O, setVehicleProfileFilterN2O] = useState();

  // State: Initailize State - Vehicle Profile Filter EPA Rating
  const [vehicleProfileFilterEpaRating, setVehicleProfileFilterEpaRating] =
    useState();

  // State: Initialize State - Set Filter String
  const [profileFilterString, setProfileFilterString] = useState("");

  // Set State: Selected Vehiclde Data for Profile
  const [selectedVehicleData, setSelectedVehicleData] = useState({
    loaded: false,
    data: [],
  });
  // USE EFFECT TO INITIALIZE CONTEXT & STATE ------------------------------------------------
  // Const: Initialize Context - Application Dates
  const { applicationDates, selectedChartRadioItemType } =
    useContext(DateContext);

  // Const: Get Token & Make Sure Available For API Call
  const token = localStorage.getItem("greenToken");

  // Const: Generate String Param For Vehicle Performance Data API Filering
  const generateFilterString = (filters, type = "data") => {
    const idleHoursFilter =
      filters?.length > 0
        ? `&min_total_idle_time_hrs=${
            filters?.[0]?.data?.[0]
          }&max_total_idle_time_hrs=${
            filters?.[0]?.data?.[1] === filters?.[0]?.minMax?.[1]
              ? filters?.[0]?.data?.[1] + 0.1
              : filters?.[0]?.data?.[1]
          }`
        : "";

    const emissionFilter =
      filters?.length > 0
        ? `&min_total_emissions_mt=${
            filters?.[1]?.data?.[0]
          }&max_total_emissions_mt=${
            filters?.[1]?.data?.[1] === filters?.[1]?.minMax?.[1]
              ? filters?.[1]?.data?.[1] + 0.1
              : filters?.[1]?.data?.[1]
          }`
        : "";

    const idleTimeEmissions =
      filters?.length > 0
        ? `&min_idle_time_emissions_by_fuel_co2_mt=${
            filters?.[2]?.data?.[0]
          }&max_idle_time_emissions_by_fuel_co2_mt=${
            filters?.[2]?.data?.[1] === filters?.[2]?.minMax?.[1]
              ? filters?.[2]?.data?.[1] + 0.1
              : filters?.[2]?.data?.[1]
          }`
        : "";

    const vehicleFilter =
      filters?.length > 0
        ? filters?.[3]?.data?.length > 0
          ? `&vehicleId=${filters?.[3]?.data
              ?.map((item) => item?.value)
              .join(",")}`
          : ""
        : "";

    const carbonEmissionsFilter =
      filters?.length > 0
        ? `&min_emissions_by_fuel_co2_mt=${
            filters?.[4]?.data?.[0]
          }&max_emissions_by_fuel_co2_mt=${
            filters?.[4]?.data?.[1] === filters?.[4]?.minMax?.[1]
              ? filters?.[4]?.data?.[1] + 0.1
              : filters?.[4]?.data?.[1]
          }`
        : "";

    // Divide by 1000 CH4 & N20
    const ch4Filter =
      filters?.length > 0
        ? `&min_emissions_by_dist_ch4_mt=${
            filters?.[5]?.data?.[0] / 1000
          }&max_emissions_by_dist_ch4_mt=${
            filters?.[5]?.data?.[1] === filters?.[5]?.minMax?.[1]
              ? filters?.[5]?.data?.[1] + 0.1 / 1000
              : filters?.[5]?.data?.[1] + 0.1 / 1000
          }`
        : "";

    const n20Filter =
      filters?.length > 0
        ? `&min_emissions_by_dist_n2o_mt=${
            filters?.[6]?.data?.[0] / 1000
          }&max_emissions_by_dist_n2o_mt=${
            filters?.[6]?.data?.[1] === filters?.[6]?.minMax?.[1]
              ? filters?.[6]?.data?.[1] + 0.1 / 1000
              : filters?.[6]?.data?.[1] + 0.1 / 1000
          }`
        : "";

    const epaRatingFilter =
      type === "profile"
        ? filters?.length > 0
          ? `&min_rating=${filters?.[7]?.data?.[0]}&max_rating=${
              filters?.[7]?.data?.[1] === filters?.[7]?.minMax?.[1]
                ? filters?.[7]?.data?.[1]
                : filters?.[7]?.data?.[1]
            }`
          : ""
        : "";

    return `${idleHoursFilter}${emissionFilter}${idleTimeEmissions}${vehicleFilter}${carbonEmissionsFilter}${ch4Filter}${n20Filter}${epaRatingFilter}`;
  };

  // UE: Initialize Filter High & Low Values when ApplicationDate Changes, Preserve Previous State
  useEffect(() => {
    if (
      filteredVehiclePerformanceDataTable?.data?.length > 0 &&
      filteredVehiclePerformanceDataTable?.loaded
    ) {
      const emissions = filteredVehiclePerformanceDataTable?.data?.map(
        (row) => row?.total_emissions_mt
      );
      const idleTime = filteredVehiclePerformanceDataTable?.data?.map(
        (row) => row?.total_idle_time_hrs
      );
      const idleTimeEmissions = filteredVehiclePerformanceDataTable?.data?.map(
        (row) => row?.idle_time_emissions_by_fuel_co2_mt
      );
      const vehicles = filteredVehiclePerformanceDataTable?.data?.map((row) => {
        return { label: row?.vehicle_id, value: row?.vehicle_id };
      });
      const carbonEmissions = filteredVehiclePerformanceDataTable?.data?.map(
        (row) => row?.emissions_by_fuel_co2_mt
      );
      const ch4Emissions = filteredVehiclePerformanceDataTable?.data?.map(
        (row) => row?.emissions_by_dist_ch4_mt
      );
      const n2oEmissions = filteredVehiclePerformanceDataTable?.data?.map(
        (row) => row?.emissions_by_dist_n2o_mt
      );
      setVehiclePerformanceFilterEmissions({
        column: "total_emissions_mt",
        data: [Math.min(...emissions), Math.max(...emissions)],
        minMax: [Math.min(...emissions), Math.max(...emissions)],
      });
      setVehiclePerformanceFilterIdleTime({
        column: "total_idle_time_hrs",
        data: [Math.min(...idleTime), Math.max(...idleTime)],
        minMax: [Math.min(...idleTime), Math.max(...idleTime)],
      });
      setVehiclePerformanceFilterIdleTimeEmissions({
        column: "idle_time_emissions_by_fuel_co2_mt",
        data: [Math.min(...idleTimeEmissions), Math.max(...idleTimeEmissions)],
        minMax: [
          Math.min(...idleTimeEmissions),
          Math.max(...idleTimeEmissions),
        ],
      });
      setVehiclePerformanceFilterVehicle({
        column: "vehicle_id",
        options: vehicles,
        data: [],
      });
      setVehiclePerformanceFilterCarbonEmissions({
        column: "emissions_by_fuel_co2_mt",
        data: [Math.min(...carbonEmissions), Math.max(...carbonEmissions)],
        minMax: [Math.min(...carbonEmissions), Math.max(...carbonEmissions)],
      });
      setVehiclePerformanceFilterCH4({
        column: "emissions_by_dist_ch4_mt",
        data: [Math.min(...ch4Emissions), Math.max(...ch4Emissions)],
        minMax: [Math.min(...ch4Emissions), Math.max(...ch4Emissions)],
      });
      setVehiclePerformanceFilterN2O({
        column: "emissions_by_dist_n2o_mt",
        data: [Math.min(...n2oEmissions), Math.max(...n2oEmissions)],
        minMax: [Math.min(...n2oEmissions), Math.max(...n2oEmissions)],
      });
    } else {
      setVehiclePerformanceFilterEmissions({
        column: "total_emissions_mt",
        data: [0, 0],
        minMax: [0, 0],
      });
      setVehiclePerformanceFilterIdleTime({
        column: "total_idle_time_hrs",
        data: [0, 0],
        minMax: [0, 0],
      });
      setVehiclePerformanceFilterIdleTimeEmissions({
        column: "idle_time_emissions_by_fuel_co2_mt",
        data: [0, 0],
        minMax: [0, 0],
      });
      setVehiclePerformanceFilterVehicle({
        column: "vehicle_id",
        options: [],
        data: [],
      });
      setVehiclePerformanceFilterCarbonEmissions({
        column: "emissions_by_fuel_co2_mt",
        data: [0, 0],
        minMax: [0, 0],
      });
      setVehiclePerformanceFilterCH4({
        column: "emissions_by_dist_ch4_mt",
        data: [0, 0],
        minMax: [0, 0],
      });
      setVehiclePerformanceFilterN2O({
        column: "emissions_by_dist_n2o_mt",
        data: [0, 0],
        minMax: [0, 0],
      });
    }
    setFiltersIsLoading((prevState) => ({
      ...prevState,
      data: false,
    }));
  }, [filteredVehiclePerformanceDataTable, clearButtonFilter?.data]);

  // UE: Get Vehicle Profile Data For Application(Trigger API Call) When applicationDates Changes
  useEffect(() => {
    if (
      filtersVehicleProfileData?.data?.length > 0 &&
      filtersVehicleProfileData?.loaded
    ) {
      const emissions = filtersVehicleProfileData?.data?.map(
        (row) => row?.total_emissions_mt
      );
      const idleTime = filtersVehicleProfileData?.data?.map(
        (row) => row?.total_idle_time_hrs
      );
      const idleTimeEmissions = filtersVehicleProfileData?.data?.map(
        (row) => row?.idle_time_emissions_by_fuel_co2_mt
      );
      const vehicles = filtersVehicleProfileData?.data?.map((row) => {
        return { label: row?.vehicle_id, value: row?.vehicle_id };
      });

      const carbonEmissions = filtersVehicleProfileData?.data?.map(
        (row) => row?.emissions_by_fuel_co2_mt
      );
      const ch4Emissions = filtersVehicleProfileData?.data?.map(
        (row) => row?.emissions_by_dist_ch4_mt
      );
      const n2oEmissions = filtersVehicleProfileData?.data?.map(
        (row) => row?.emissions_by_dist_n2o_mt
      );
      setVehicleProfileFilterEmissions({
        column: "emissions_by_fuel_co2_mt",
        data: [Math.min(...emissions), Math.max(...emissions)],
        minMax: [Math.min(...emissions), Math.max(...emissions)],
      });
      setVehicleProfileFilterIdleTime({
        column: "total_idle_time_hrs",
        data: [Math.min(...idleTime), Math.max(...idleTime)],
        minMax: [Math.min(...idleTime), Math.max(...idleTime)],
      });
      setVehicleProfileFilterIdleTimeEmissions({
        column: "idle_time_emissions_by_fuel_co2_mt",
        data: [Math.min(...idleTimeEmissions), Math.max(...idleTimeEmissions)],
        minMax: [
          Math.min(...idleTimeEmissions),
          Math.max(...idleTimeEmissions),
        ],
      });
      setVehicleProfileFilterVehicle({
        column: "vehicle_id",
        options: vehicles,
        data: [],
      });
      setVehicleProfileFilterCarbonEmissions({
        column: "emissions_by_fuel_co2_mt",
        data: [Math.min(...carbonEmissions), Math.max(...carbonEmissions)],
        minMax: [Math.min(...carbonEmissions), Math.max(...carbonEmissions)],
      });
      setVehicleProfileFilterCH4({
        column: "emissions_by_dist_ch4_mt",
        data: [Math.min(...ch4Emissions), Math.max(...ch4Emissions)],
        minMax: [Math.min(...ch4Emissions), Math.max(...ch4Emissions)],
      });
      setVehicleProfileFilterN2O({
        column: "emissions_by_dist_n2o_mt",
        data: [Math.min(...n2oEmissions), Math.max(...n2oEmissions)],
        minMax: [Math.min(...n2oEmissions), Math.max(...n2oEmissions)],
      });
      setVehicleProfileFilterEpaRating({
        column: "rating",
        data: [0, 100],
        minMax: [0, 100],
      });
    } else {
      setVehicleProfileFilterEmissions({
        column: "emissions_by_fuel_co2_mt",
        data: [0, 0],
        minMax: [0, 0],
      });
      setVehicleProfileFilterIdleTime({
        column: "total_idle_time_hrs",
        data: [0, 0],
        minMax: [0, 0],
      });
      setVehicleProfileFilterIdleTimeEmissions({
        column: "idle_time_emissions_by_fuel_co2_mt",
        data: [0, 0],
        minMax: [0, 0],
      });
      setVehicleProfileFilterVehicle({
        column: "vehicle_id",
        options: [],
        data: [],
      });
      setVehicleProfileFilterCarbonEmissions({
        column: "emissions_by_fuel_co2_mt",
        data: [0, 0],
        minMax: [0, 0],
      });
      setVehicleProfileFilterCH4({
        column: "emissions_by_dist_ch4_mt",
        data: [0, 0],
        minMax: [0, 0],
      });
      setVehicleProfileFilterN2O({
        column: "emissions_by_dist_n2o_mt",
        data: [0, 0],
        minMax: [0, 0],
      });
      setVehicleProfileFilterEpaRating({
        column: "rating",
        data: [0, 100],
        minMax: [0, 100],
      });
    }
    setFiltersIsLoading((prevState) => ({
      ...prevState,
      profile: false,
    }));
  }, [filtersVehicleProfileData, clearButtonFilter?.profile]);

  // UE: Get Vehicle Performance Data For Application(Trigger API Call) When applicationDates Changes
  useEffect(() => {
    const hasValues =
      Object.values(applicationDates).filter((date) => date !== "").length > 0;

    if (
      vehiclePerformanceIsLoading &&
      hasValues &&
      token !== mockLoginData?.token
    ) {
      const promises = [
        getEmissionsPerVehicleAPI(
          setEmissionsPerVehicleData,
          setEmissionsBarChartData,
          applicationDates?.emissionsPerVehicle
        ),
        getAverageEmissionsPerVehicleAPI(
          setAverageEmissionsPerVehicle,
          applicationDates?.avgEmissionsPerVehicle
        ),
        postTotalIdleTime(setTotalIdleTime, applicationDates?.totalIdleTime),
        getIdleTimeEmissionsAPI(
          setIdleTimeEmissionsData,
          selectedChartRadioItemType?.idle_emissions,
          applicationDates?.idleChartEmissions
        ),
        // Initial API Call for Vehicle Performance Data Table
        getVehiclePerformanceDataAPI(
          setVehiclePerformanceDataTable,
          setFilteredVehiclePerformanceDataTable,
          applicationDates?.vpData,
          "",
          setFiltersVehicleProfileData
        ),
        // Initial API Call for Vehicle Rank Data
        getRankedVehicleProfileGroup(
          setVehicleProfileData,
          applicationDates?.vpProfile,
          "",
          vehicleProfileDataPage
        ),
      ];
      Promise.all(promises).then(() => {
        // Set Loading Component to False
        setVehiclePerformanceIsLoading(false);
      });
    } else if (
      vehiclePerformanceIsLoading &&
      hasValues &&
      token === mockLoginData?.token
    ) {
      setEmissionsPerVehicleData({
        loaded: true,
        data: [
          {
            ...mockEmissionsPerVehicleData,
            dataValues:
              mockEmissionsPerVehicleData?.dataValues?.[
                applicationDates?.emissionsPerVehicle
              ],
          },
          mockEmissionsPerVehicleDataBenchmark,
        ],
      });
      setEmissionsBarChartData({
        loaded: true,
        data: [
          {
            ...mockEmissionsPerVehicleData,
            dataValues:
              mockEmissionsPerVehicleData?.dataValues?.[
                applicationDates?.emissionsPerVehicle
              ],
          },
          mockEmissionsPerVehicleDataBenchmark,
        ],
      });
      setAverageEmissionsPerVehicle({
        loaded: true,
        value: {
          ...averageEmissionsperVehicleSVGConfig,
          value:
            mockAvgEmissionsPerVehicle?.[
              applicationDates?.avgEmissionsPerVehicle
            ],
        },
      });
      setTotalIdleTime({
        loaded: true,
        data: {
          ...totalIdleTimeSVGConfig,
          ...mockTotalIdleTimeEmissions?.[applicationDates?.totalIdleTime],
        },
      });
      setIdleTimeEmissionsData({
        loaded: true,
        ...mockIdleTimeAnnotationValueData,
        data: [
          {
            ...idleTimeEmissionsDataConfig,
            dataValues: mockIdleTimeEmissionsDataValues,
          },
        ],
      });
      setVehiclePerformanceDataTable({
        loaded: true,
        data: mockVehiclePerformanceDataTable?.[applicationDates?.vpProfile],
      });
      setFilteredVehiclePerformanceDataTable({
        loaded: true,
        data: mockVehiclePerformanceDataTable?.[applicationDates?.vpProfile],
      });
      setVehicleProfileData({
        loaded: true,
        data: mockVehiclePerformanceDataTable?.[applicationDates?.vpData],
      });
      setVehiclePerformanceIsLoading(false);
    }
  }, [vehiclePerformanceIsLoading, applicationDates, token]);

  // Const: Get Previous Application Dates to See if Trigger API Call
  const prevApplicationDates = useRef(applicationDates);

  // Const: Get Previous Selected Chart Radio Item Type to See if Trigger API Call
  const prevSelectedChartRadioItemType = useRef(selectedChartRadioItemType);

  // UE: Get Vehicle Performance Data For Application(Trigger API Call) When applicationDates Changes
  useEffect(() => {
    const hasValues =
      Object.values(applicationDates).filter((date) => date !== "").length > 0;

    if (
      hasValues &&
      token &&
      token !== mockLoginData?.token &&
      !vehiclePerformanceIsLoading &&
      prevApplicationDates?.current
    ) {
      if (
        prevApplicationDates?.current?.emissionsPerVehicle !==
        applicationDates?.emissionsPerVehicle
      ) {
        setSelectedVehicleSortValue("default");
        setEmissionsPerVehicleData({
          loaded: false,
          data: [],
        });
        setSliceValue([0, itemsPerPage]);
        getEmissionsPerVehicleAPI(
          setEmissionsPerVehicleData,
          setEmissionsBarChartData,
          applicationDates?.emissionsPerVehicle
        );
      }
      if (
        prevApplicationDates?.current?.avgEmissionsPerVehicle !==
        applicationDates?.avgEmissionsPerVehicle
      ) {
        getAverageEmissionsPerVehicleAPI(
          setAverageEmissionsPerVehicle,
          applicationDates?.avgEmissionsPerVehicle
        );
      }
      if (
        prevApplicationDates?.current?.totalIdleTime !==
        applicationDates?.totalIdleTime
      ) {
        postTotalIdleTime(setTotalIdleTime, applicationDates?.totalIdleTime);
      }
      if (prevApplicationDates?.current?.vpData !== applicationDates?.vpData) {
        setFilteredVehiclePerformanceDataTable({
          loaded: false,
          data: [],
        });
        // API Call For Change in Application Dates for Vehicle Performance Data Table (Reset Filters)
        getVehiclePerformanceDataAPI(
          setVehiclePerformanceDataTable,
          setFilteredVehiclePerformanceDataTable,
          applicationDates?.vpData,
          ""
        );
        setClearButtonFilter({
          ...clearButtonFilter,
          data: !clearButtonFilter,
        });
        setFilterString("");
      }
      if (
        prevApplicationDates?.current?.vpProfile !== applicationDates?.vpProfile
      ) {
        setFiltersVehicleProfileData({
          loaded: false,
          data: [],
        });
        getVehiclePerformanceDataAPI(
          () => {},
          () => {},
          applicationDates?.vpProfile,
          "",
          setFiltersVehicleProfileData
        );
        // API Call For Change in Application Dates for Vehicle Performance Data Table (Reset Filters)
        getRankedVehicleProfileGroup(
          setVehicleProfileData,
          applicationDates?.vpProfile,
          "",
          vehicleProfileDataPage
        );
        setClearButtonFilter({
          ...clearButtonFilter,
          profile: !clearButtonFilter,
        });
        setProfileFilterString("");
      } else if (
        prevSelectedChartRadioItemType?.current?.idle_emissions !==
          selectedChartRadioItemType?.idle_emissions ||
        prevApplicationDates?.current?.idleChartEmissions !==
          applicationDates.idleChartEmissions
      ) {
        getIdleTimeEmissionsAPI(
          setIdleTimeEmissionsData,
          selectedChartRadioItemType?.idle_emissions,
          applicationDates?.idleChartEmissions
        );
      }
    } else if (
      hasValues &&
      token &&
      token === mockLoginData?.token &&
      !vehiclePerformanceIsLoading &&
      prevApplicationDates?.current
    ) {
      if (prevApplicationDates?.current?.vpData !== applicationDates?.vpData) {
        setVehiclePerformanceDataTable({
          loaded: true,
          data: mockVehiclePerformanceDataTable?.[applicationDates?.vpData],
        });
      }
      if (
        prevApplicationDates?.current?.vpProfile !== applicationDates?.vpProfile
      ) {
        setVehicleProfileData({
          loaded: true,
          data: mockVehiclePerformanceDataTable?.[applicationDates?.vpProfile],
        });
      }
      if (
        prevApplicationDates?.current?.totalIdleTime !==
        applicationDates?.totalIdleTime
      ) {
        setTotalIdleTime({
          loaded: true,
          data: {
            ...totalIdleTimeSVGConfig,
            ...mockTotalIdleTimeEmissions?.[applicationDates?.totalIdleTime],
          },
        });
      }
      if (
        prevApplicationDates?.current?.avgEmissionsPerVehicle !==
        applicationDates?.avgEmissionsPerVehicle
      ) {
        setAverageEmissionsPerVehicle({
          loaded: true,
          value: {
            ...averageEmissionsperVehicleSVGConfig,
            value:
              mockAvgEmissionsPerVehicle?.[
                applicationDates?.avgEmissionsPerVehicle
              ],
          },
        });
      }
      if (
        prevApplicationDates?.current?.emissionsPerVehicle !==
        applicationDates?.emissionsPerVehicle
      ) {
        setEmissionsPerVehicleData({
          loaded: true,
          data: [
            {
              ...mockEmissionsPerVehicleData,
              dataValues:
                mockEmissionsPerVehicleData?.dataValues?.[
                  applicationDates?.emissionsPerVehicle
                ],
            },
            mockEmissionsPerVehicleDataBenchmark,
          ],
        });
        setEmissionsBarChartData({
          loaded: true,
          data: [
            {
              ...mockEmissionsPerVehicleData,
              dataValues:
                mockEmissionsPerVehicleData?.dataValues?.[
                  applicationDates?.emissionsPerVehicle
                ],
            },
            mockEmissionsPerVehicleDataBenchmark,
          ],
        });
      }
    }
    prevApplicationDates.current = applicationDates;
    prevSelectedChartRadioItemType.current = selectedChartRadioItemType;
  }, [
    applicationDates,
    selectedChartRadioItemType?.idle_emissions,
    token,
    vehiclePerformanceIsLoading,
  ]);

  // UE: Handle API Call For Filtering Vehicle Performance Data Table
  useEffect(() => {
    if (filteredVehiclePerformanceDataTable?.loaded) {
      getVehiclePerformanceDataAPI(
        setVehiclePerformanceDataTable,
        () => {},
        applicationDates?.vpData,
        filterString
      );
    }
  }, [filterString]);

  // UE: Handle API Call For Filtering Vehicle Rank Profile Data
  useEffect(() => {
    if (filtersVehicleProfileData?.loaded) {
      getRankedVehicleProfileGroup(
        setVehicleProfileData,
        applicationDates?.vpProfile,
        profileFilterString,
        vehicleProfileDataPage
      );
    }
  }, [profileFilterString]);

  // UE: Handle API Call For Vehicle Profile Data w/ Pagination
  useEffect(() => {
    if (filtersVehicleProfileData?.loaded) {
      getRankedVehicleProfileGroup(
        setVehicleProfileData,
        applicationDates?.vpProfile,
        profileFilterString,
        vehicleProfileDataPage
      );
    }
  }, [vehicleProfileDataPage]);

  const value = {
    vehiclePerformanceIsLoading,
    emissionsPerVehicleData,
    averageEmissionsPerVehicle,
    totalIdleTime,
    idleTimeEmissionsData,
    vehiclePerformanceDataTable,
    vehiclePerformanceFilterEmissions,
    setVehiclePerformanceFilterEmissions,
    vehiclePerformanceFilterIdleTime,
    setVehiclePerformanceFilterIdleTime,
    vehiclePerformanceFilterIdleTimeEmissions,
    setVehiclePerformanceFilterIdleTimeEmissions,
    vehiclePerformanceFilterVehicle,
    setVehiclePerformanceFilterVehicle,
    filtersIsLoading,
    clearButtonFilter,
    setClearButtonFilter,
    selectedVehicleSortValue,
    setSelectedVehicleSortValue,
    emissionsBarChartData,
    setEmissionsBarChartData,
    sliceValue,
    setSliceValue,
    itemsPerPage,
    vehicleProfileData,
    setVehicleProfileData,
    vehicleProfileFilterEmissions,
    setVehicleProfileFilterEmissions,
    vehicleProfileFilterIdleTime,
    setVehicleProfileFilterIdleTime,
    vehicleProfileFilterIdleTimeEmissions,
    setVehicleProfileFilterIdleTimeEmissions,
    vehicleProfileFilterVehicle,
    setVehicleProfileFilterVehicle,
    setFilterString,
    generateFilterString,
    vehiclePerformanceFilterCarbonEmissions,
    setVehiclePerformanceFilterCarbonEmissions,
    vehiclePerformanceFilterCH4,
    setVehiclePerformanceFilterCH4,
    vehiclePerformanceFilterN2O,
    setVehiclePerformanceFilterN2O,
    vehicleProfileFilterCarbonEmissions,
    setVehicleProfileFilterCarbonEmissions,
    vehicleProfileFilterCH4,
    setVehicleProfileFilterCH4,
    vehicleProfileFilterN2O,
    setVehicleProfileFilterN2O,
    profileFilterString,
    setProfileFilterString,
    vehicleProfileFilterEpaRating,
    setVehicleProfileFilterEpaRating,
    selectedVehicleData,
    setSelectedVehicleData,
    vehicleProfileDataPage,
    setVehicleProfileDataPage,
  };

  return (
    <VehiclePerformanceContext.Provider value={value}>
      {children}
    </VehiclePerformanceContext.Provider>
  );
};

export default VehiclePerformanceContext;
