import { useContext } from "react";
import DateContext from "../context/DateProvider";
import ApplicationHeader from "../layouts/ApplicationHeader";
import TabbedContainer from "../components/TabbedContainer";
import { mockLoginData } from "../constants/mockDataConfig";
import VehiclePerformanceDataTab from "./VehiclePerformancePage/VehiclePerformanceDataTab";
import VehiclePerformanceOverviewTab from "./VehiclePerformancePage/VehiclePerformanceOverviewTab";
import VehicleProfileGroupPage from "./VehiclePerformancePage/VehicleProfileGroupPage";

export default function VehiclePerformancePage() {
  // Const: Initialize Context - Dates
  const {
    dateValues,
    applicationDates,
    setApplicationDates,
    activeTab,
    setActiveTab,
  } = useContext(DateContext);

  // Const: Determine if Mock User is Logged in
  const notMockUser =
    localStorage.getItem("greenToken") !== mockLoginData?.token;

  // Tabbed Container: Data & Vehicle Performance
  const vehiclePerformanceTabs = [
    {
      label: "Overview",
      content: (
        <>
          <VehiclePerformanceOverviewTab
            dateValues={dateValues}
            applicationDates={applicationDates}
            setApplicationDates={setApplicationDates}
            notMockUser={notMockUser}
          />
        </>
      ),
    },
    {
      label: "Data",
      content: (
        <>
          <VehiclePerformanceDataTab
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            pageName={"/vehicle-performance"}
          />
        </>
      ),
    },
    {
      label: "Profile",
      content: (
        <>
          <VehicleProfileGroupPage
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        </>
      ),
      // hideTab: notMockUser,
    },
  ];

  return (
    <>
      <ApplicationHeader headerName={"Vehicle Performance"} />
      <>
        <TabbedContainer
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={vehiclePerformanceTabs}
          pageName={"/vehicle-performance"}
        />
      </>
    </>
  );
}
