// Functionality: Display the output matches of the vin decoder
import Button from "../../../components/Button";
import { BiError } from "react-icons/bi";
import { IoCheckmarkDoneOutline } from "react-icons/io5";

export default function VinDecoderFetch({
  vinDecoderImportState,
  setProcessStep,
}) {
  // Const: Determine Length of Data Entries
  const lengthOfDataEntries = vinDecoderImportState?.length_original_data;
  const vinDecoderImportDataLength = vinDecoderImportState?.length_data;
  const emptyVinDecoderData = vinDecoderImportDataLength === 0;

  // Const: Failed Records Summary
  const emptyMessage = (
    <>
      <div className="vin-decoder-failed-entries-group">
        <div className="vin-decoder-failed-entries-subgroup">
          <div className="vin-decoder-failed-entries-group-header">
            <BiError />
            <p>
              {vinDecoderImportDataLength} records out of {lengthOfDataEntries}{" "}
              Vins failed to decode.
            </p>
          </div>
        </div>
        <div className="vin-decoder-failed-entries-subgroup-content">
          <p>
            Please make sure the VINs are correct and in the correct format, as
            well as the respective vehicle IDs.
          </p>
          <p>
            - If the issue persists, do not hesitate to submit a support ticket.
          </p>
        </div>
      </div>
    </>
  );

  // Const: Failed Records Summary
  const successMessage = (
    <>
      <div className="vin-decoder-success-entries-group">
        <div className="vin-decoder-success-entries-subgroup">
          <div className="vin-decoder-success-entries-group-header">
            <IoCheckmarkDoneOutline />
            <p>
              {vinDecoderImportDataLength} records out of {lengthOfDataEntries}{" "}
              have been successfully decoded.
            </p>
          </div>
        </div>
        <div className="vin-decoder-success-entries-subgroup-content">
          <div className="flex-row-space-between">
            <p>
              You may now continue to the process of reviewing, and editing, the
              output of the decoder.
            </p>
            <Button
              className="primary-blue-button"
              buttonName="Review Output"
              onClick={() => setProcessStep(3)}
            />
          </div>
        </div>
      </div>
    </>
  );
  return (
    <>
      {emptyVinDecoderData && emptyMessage}
      {!emptyVinDecoderData && successMessage}
    </>
  );
}
