// Functionality: This file exports the EmissionsPerVehicleCard component which is used in the Vehicle Performance Page component.
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import VehiclePerformanceContext from "../../../context/VehiclePerformanceProvider.js";
import SwitchCarousel from "../../../components/SwitchCarousel.js";
import BarChart from "../../../visualizations/BarChart.js";
import useSub from "../../../hooks/use-Sub.js";
import Skeleton from "react-loading-skeleton";
import { CustomRadioItemGroup } from "../../../components/RadioItemGroup.js";
import { emissionsPerVehicleSortDataConfig } from "../../../constants/VehicleConfig.js";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { emissionsPerVehicleTile } from "../../../constants/TileConfig.js";

// Function: Sort an array of objects by a property
function sortByProperty(arr, prop = "y", order = "asc") {
  // Const: Copy Array
  const sortedArray = [...arr];

  // Sort Array by either ascending or descending order
  if (order === "asc") {
    return sortedArray.sort((a, b) => {
      if (a[prop] < b[prop]) return -1;
      if (a[prop] > b[prop]) return 1;
      return 0;
    });
  } else if (order === "desc") {
    return sortedArray.sort((a, b) => {
      if (a[prop] > b[prop]) return -1;
      if (a[prop] < b[prop]) return 1;
      return 0;
    });
  } else {
    return arr;
  }
}

export default function EmissionsPerVehicleCard({ ...props }) {
  // Const: Initialize Context
  const {
    emissionsPerVehicleData,
    selectedVehicleSortValue,
    setSelectedVehicleSortValue,
    emissionsBarChartData,
    setEmissionsBarChartData,
    sliceValue,
    setSliceValue,
    itemsPerPage,
  } = useContext(VehiclePerformanceContext);

  // Const: Initialize Super Scripted Label
  const superScriptedLabel = useSub("MT CO2");

  // Const: Label Application Dates
  const applicationDatesLabel = "emissionsPerVehicle";

  // Length of Data: Emissions Per Vehicle
  const emissionsPerVehicleDataLength =
    emissionsPerVehicleData?.data?.[0]?.dataValues?.length;

  // UE: Emissions Per Vehicle Sortby Ascending / Descending
  useEffect(() => {
    if (emissionsPerVehicleData?.loaded) {
      setSliceValue([0, itemsPerPage]);
      const sortedData = sortByProperty(
        emissionsPerVehicleData?.data?.[0]?.dataValues,
        "y",
        selectedVehicleSortValue
      );
      setEmissionsBarChartData({
        ...emissionsBarChartData,
        data: [
          {
            ...emissionsPerVehicleData?.data?.[0],
            dataValues: sortedData?.slice(0, itemsPerPage),
          },
          {
            ...emissionsPerVehicleData?.data?.[1],
            dataValues: emissionsPerVehicleData?.data?.[1]?.dataValues?.slice(
              0,
              itemsPerPage
            ),
          },
        ],
      });
    }
  }, [selectedVehicleSortValue]);

  // Function: Handle Click Back
  const handleClickBack = () => {
    if (sliceValue[0] === 0) {
    } else {
      setSliceValue([
        sliceValue[0] - itemsPerPage,
        sliceValue[1] - itemsPerPage,
      ]);
      const sortedData = sortByProperty(
        emissionsPerVehicleData?.data?.[0]?.dataValues,
        "y",
        selectedVehicleSortValue
      );
      setEmissionsBarChartData({
        ...emissionsBarChartData,
        data: [
          {
            ...emissionsPerVehicleData?.data?.[0],
            dataValues: sortedData.slice(
              sliceValue[0] - itemsPerPage,
              sliceValue[1] - itemsPerPage
            ),
          },
          {
            ...emissionsPerVehicleData?.data?.[1],
            dataValues: emissionsPerVehicleData?.data?.[1]?.dataValues?.slice(
              sliceValue[0] - itemsPerPage,
              sliceValue[1] - itemsPerPage
            ),
          },
        ],
      });
    }
  };

  // Function: Handle Click Forward
  const handleClickForward = () => {
    if (sliceValue[1] === emissionsPerVehicleDataLength) {
    } else {
      const sortedData = sortByProperty(
        emissionsPerVehicleData?.data?.[0]?.dataValues,
        "y",
        selectedVehicleSortValue
      );
      setSliceValue([
        sliceValue[0] + itemsPerPage,
        sliceValue[1] + itemsPerPage,
      ]);
      setEmissionsBarChartData({
        ...emissionsBarChartData,
        data: [
          {
            ...emissionsPerVehicleData?.data?.[0],
            dataValues: sortedData?.slice(
              sliceValue[0] + itemsPerPage,
              sliceValue[1] + itemsPerPage
            ),
          },
          {
            ...emissionsPerVehicleData?.data?.[1],
            dataValues: emissionsPerVehicleData?.data?.[1]?.dataValues?.slice(
              sliceValue[0] + itemsPerPage,
              sliceValue[1] + itemsPerPage
            ),
          },
        ],
      });
    }
  };

  return (
    <>
      {!emissionsBarChartData?.loaded ? (
        <Skeleton height={"300px"} />
      ) : (
        <div className="chart-card-container-group">
          <div className="emissions-per-vehicle-title-group">
            <div>
              <div className="card-title">
                Emissions per Vehicle
                {emissionsPerVehicleTile?.blurb}
                {emissionsPerVehicleTile?.legend}
              </div>
              <div className="card-subtitle">{superScriptedLabel}</div>
            </div>
            <div className="emissions-per-vehicle-right-title-group">
              <div className="group-switch-carousel-no-margin">
                <SwitchCarousel
                  values={props.dateValues}
                  applicationDatesLabel={applicationDatesLabel}
                  applicationDates={props.applicationDates}
                  setApplicationDates={props.setApplicationDates}
                  fillArrowColor={"#66C743"}
                  switchCarouselValueClassName="group-switch-carousel-value"
                />
              </div>
              <CustomRadioItemGroup
                options={emissionsPerVehicleSortDataConfig}
                selectedOption={selectedVehicleSortValue}
                setSelectedOption={setSelectedVehicleSortValue}
              />
            </div>
          </div>
          <div className="vp-page-chart-container">
            {sliceValue?.[0] === 0 ? (
              <div></div>
            ) : (
              <GoChevronLeft onClick={() => handleClickBack()} />
            )}
            <div className="vp-barchart-div">
              <BarChart datasets={emissionsBarChartData?.data} />
            </div>
            {sliceValue?.[1] >= emissionsPerVehicleDataLength ? (
              <div></div>
            ) : (
              <GoChevronRight onClick={() => handleClickForward()} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

// Props
const emissionsPerVehicleCardProps = {
  dateValues: PropTypes.array, // Array of date values
  applicationDates: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // String of application dates
  setApplicationDates: PropTypes.func, // Function to set application dates
};

EmissionsPerVehicleCard.propTypes = emissionsPerVehicleCardProps;
