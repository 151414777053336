import { createContext, useEffect, useState, useContext } from "react";
import DateContext from "./DateProvider";
import { getVehicleSettings } from "../api/Settings/VehicleSettingsAPI";
import { getFuelTypeOptions } from "../api/Settings/FuelTypeOptionsAPI";
import { getAllVehicles } from "../api/Settings/GetAllVehiclesAPI";

// MOCK DATA TO IMPORT
import {
  mockVehiclePerformanceDataTable,
  mockLoginData,
} from "../constants/mockDataConfig";

const SettingsContext = createContext({});

// Provides access to token across whole application
export const SettingsProvider = ({ children }) => {
  // State: Initialize State - Full Page
  const [settingsIsLoading, setSettingsIsLoading] = useState(true);

  // State: Vehicle Settings Data
  const [vehicleSettingsData, setVehicleSettingsData] = useState({
    data: [],
    loaded: false,
  });
  const [singleVehicleEditFields, setSingleVehicleEditFields] = useState({
    vin_decode: {},
    insite: {},
    original: {},
  }); // State: Single Vehicle Edit Fields

  // State: Success Message for Editing a Vehicle
  const [successEditMsg, setSuccessEditMsg] = useState({ text: "", msg: "" }); // Success message for adding a new solutions marker

  // State: Initialize State - Vehicle Settings Data Page Number
  const [vehicleSettingsDataPage, setVehicleSettingsDataPage] = useState(1);

  // State: Initialize State - Vehicle Type Options
  const [vehicleFuelTypeOptions, setVehicleFuelTypeOptions] = useState([]);

  // State: Initialize State - Valid Vehicle ID
  const [validVehicleID, setValidVehicleID] = useState([]);
  const [vinFileUploadState, setVinFileUploadState] = useState({
    file: null,
    fileData: [],
    isFileSelected: false,
    originalFileData: [],
    updatedFailedEntries: {},
    triggerAPI: false,
  });

  const [vinDecoderImportState, setVinDecoderImportState] = useState({
    data: [],
    originalData: [],
    loaded: false,
    length_data: 0,
    length_original_data: 0,
  });

  // State: Download All Vehicles Data
  const [allVehiclesData, setAllVehiclesData] = useState({
    data: [],
    loaded: false,
  });

  // Const: Initialize Context - Application Dates
  const { applicationDates } = useContext(DateContext);

  // Const: Get Token & Make Sure Available For API Call
  const token = localStorage.getItem("greenToken");

  // UE: Get Initial Vehicle Settings Data
  // UE: Get Overview Data For the Whole Application (Only Runs Once)
  useEffect(() => {
    const hasValues =
      Object.values(applicationDates).filter((date) => date !== "").length > 0;

    if (settingsIsLoading && hasValues && token !== mockLoginData?.token) {
      const promises = [
        getVehicleSettings(setVehicleSettingsData, vehicleSettingsDataPage),
        getFuelTypeOptions(setVehicleFuelTypeOptions),
        getAllVehicles(setAllVehiclesData),
      ];
      Promise.all(promises).then((response) => {
        setSettingsIsLoading(false);
      });
    } else if (token === mockLoginData?.token && hasValues) {
      setVehicleSettingsData({
        data: mockVehiclePerformanceDataTable?.[1],
        loaded: true,
      });
      setSettingsIsLoading(false);
    }
  }, [settingsIsLoading, applicationDates, token]);

  // UE: Reload the page to get the new vehicle settings
  useEffect(() => {
    if (successEditMsg?.msg === "success") {
      getVehicleSettings(setVehicleSettingsData, vehicleSettingsDataPage);
    }
  }, [successEditMsg?.msg]);

  // UE: Handle API Call For Vehicle Profile Data w/ Pagination
  useEffect(() => {
    if (vehicleSettingsData?.loaded && token !== mockLoginData?.token) {
      getVehicleSettings(setVehicleSettingsData, vehicleSettingsDataPage);
    }
  }, [vehicleSettingsDataPage]);

  // UE: Handle Storage of Valid Vehicle ID
  useEffect(() => {
    if (allVehiclesData?.data?.length > 0 && allVehiclesData?.loaded) {
      const validVehicleID = allVehiclesData?.data?.map((item) => {
        return item?.vehicle_id;
      });
      setValidVehicleID(validVehicleID);
    }
  }, [allVehiclesData]);

  const value = {
    vehicleSettingsData,
    setVehicleSettingsData,
    singleVehicleEditFields,
    setSingleVehicleEditFields,
    successEditMsg,
    setSuccessEditMsg,
    vehicleSettingsDataPage,
    setVehicleSettingsDataPage,
    vehicleFuelTypeOptions,
    validVehicleID,
    vinFileUploadState,
    setVinFileUploadState,
    vinDecoderImportState,
    setVinDecoderImportState,
    allVehiclesData,
    setAllVehiclesData,
  };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
