// This file serves as a central location for all configuration variables related to navigation throughout the application
import settings from "../assets/icons/settings.png";
import help from "../assets/icons/help.png";

// Const: Navigation Text Values, Labels & Paths
export const textNavigationConfig = [
  {
    value: 0,
    label: "Overview",
    path: "/",
  },
  {
    value: 1,
    label: "Emissions",
    path: "/emissions",
  },
  {
    value: 2,
    label: "Reduction",
    path: "/reduction",
  },
  {
    value: 3,
    label: "Vehicle Performance",
    path: "/vehicle-performance",
  },
  {
    value: 4,
    label: "Reports",
    path: "/reports",
  },
];

// Const: Navigation Image / Icon Values, Labels & Paths
export const iconNavigationConfig = [
  {
    value: 5,
    label: <img src={settings} alt="Settings placeholder" />,
    path: "/settings",
  },
  {
    value: 6,
    label: <img src={help} alt="Help placeholder" />,
    path: "/help",
  },
];
