import logo from "../../assets/logo-green-black.png";

// Demo Terms and Conditions
export default function DemoTermsModal() {
  return (
    <div className="demo-trial-modal">
      <img src={logo} alt="GreenIRR" className="demo-trial-logo" />
      <h1>GREENIRR INC. TRIAL EVALUATION TERMS & CONDITIONS</h1>
      <div>
        <h2>1. Definitions.</h2>
        <p>
          a. “Effective Date” the date when these terms and conditions of this
          license (“License”) are approved or accepted by the person or entity
          providing such approval or acceptance (“Customer”).
        </p>
        <p>
          b. “Evaluation Period” shall be from the Effective Date until the
          earlier to occur of (i) 14 days following the date of onboard, defined
          as the day the user is given access to the platform, or (ii) the
          termination of this License as provided in Section 8 below.
        </p>
        <p>
          c. “Confidential Information” shall mean all information disclosed by
          either party to the other party, including, but not limited to: (i)
          the Service (defined below) and all related documentation; (ii) any
          information provided by either party marked with a proprietary,
          confidential or other similar notice or orally disclosed by either
          party or its partners; and (iii) the existence of this License and the
          evaluation performed under this License and its results. Confidential
          Information shall not include information which is (i) now, or
          becomes, through no act or failure to act by the recipient, generally
          known or available; (ii) is known by recipient at the time of
          receiving such information as evidenced by its records; (iii) is
          hereafter furnished to recipient by a third party, as a matter of
          right and without restriction on disclosure; or (iv) is the subject of
          a written permission to disclose provided by the disclosing party.
        </p>
        <p>
          d. “Feedback” shall mean any opinions, suggestions or improvements,
          whether tangible or intangible, provided by Customer to GREENIRR in
          connection with this License.
        </p>
        <p>
          d. “Service” shall mean the proprietary software owned by GREENIRR,
          and all related reports, manuals and other documentation. During the
          Evaluation Period, GREENIRR may, in its sole and absolute discretion,
          provide updates, fixes and/or patches to the Service, and the term
          “Service” shall include such updates, fixes and/or patches.
        </p>
      </div>
      <div>
        <h2> 2. Purpose of the Evaluation. </h2>
        <p>
          During the Evaluation Period, the Customer will be provided with
          access to the Service on a hosted environment provided by GREENIRR.
          Customer will be asked to use the features available through the
          Service in order to assess the functionality, usability, performance
          and overall experience of the Service. GREENIRR intends to use
          Customer’s feedback and data collected from the evaluation to improve
          and expand GREENIRR’s features and operational aspects of its Service.
        </p>
      </div>
      <div>
        <h2>3. Data.</h2>
        <p>
          The data collected by GREENIRR is used to provide, deliver, and
          support the services available through the Service. By using the
          Service and accepting the terms of this License, Customer consents and
          agrees to the collection, transfer, backup, and storage of Customer’s
          data by GREENIRR and GREENIRR’s service providers.
        </p>
      </div>
      <div>
        <h2>4. License Grant.</h2>
        <p>
          Subject to the terms and conditions hereof and during the Evaluation
          Period, GREENIRR grants to Customer a personal, non-transferrable,
          revocable, and non-exclusive license to use the Service format solely
          for the purpose of Customer’s internal evaluation of the Service.
        </p>
      </div>
      <div>
        <h2>5. Feedback</h2>
        <p>
          a. During the Evaluation Period and for a period of thirty (30) days
          thereafter, Customer shall provide GREENIRR with feedback and report
          on its use of the Service, including, but not limited to,
          participating in periodic meetings (in person, by telephone or other
          teleconferencing methods) (collectively, “Feedback Meetings”).
        </p>
        <p>
          b. Any Feedback shall (i) be and remain the sole, exclusive property
          of GREENIRR, and (ii) be deemed non- confidential as to Customer.
          GREENIRR shall have the right to use, disclose or incorporate any such
          Feedback in its sole and absolute discretion in any manner GREENIRR
          chooses in any future version of the Service or for any other purpose,
          without compensation to Customer and without Customer’s approval.
        </p>
      </div>
      <div>
        <h2>6. Fees.</h2>
        <p>
          During and with respect to the evaluation, (a) Customer shall not pay
          any license, subscription or other fees or compensation to GREENIRR;
          and (b) GREENIRR shall not pay any compensation to Customer.
        </p>
      </div>
      <div>
        <h2>7. No Obligation.</h2>
        <p>
          Customer acknowledges and agrees that (i) GREENIRR shall be under no
          obligation to provide Customer with any services or access to the
          Service; and (ii) GREENIRR is under no obligation to develop, maintain
          or market the Service or to release additional versions of the current
          Service. Future versions of the Service, if any, may not be compatible
          with the current Service.
        </p>
      </div>
      <div>
        <h2>8. Termination.</h2>
        <p>
          The license granted under Section 4 shall terminate on expiration of
          the Evaluation Period. Additionally, either party may terminate this
          License upon two (2) days’ prior written notice to the other party at
          any time for any or no cause or reason. Customer will cease accessing
          and using the Service upon the expiration or termination of this
          License. Sections 1, 3, and 5 through 17 shall survive termination of
          this License.
        </p>
      </div>
      <div>
        <h2>9. Proprietary Rights.</h2>
        <p>
          Customer recognizes and agrees that the Service is the property of
          GREENIRR and that all right, title and interest in the Service is
          reserved to and remains with GREENIRR. Customer does not acquire any
          rights, express or implied, in the Service, other than those expressly
          specified in this License. Customer agrees not to record, copy,
          distribute, sell, assign, sublicense or otherwise transfer the
          Service, or any portion thereof. Customer further agrees not to remove
          from view any copyright legend, trademark or confidentiality notice
          appearing on the Service or Service output, or adapt, translate,
          reverse engineer, decompile or otherwise derive the source code for
          the Service.
        </p>
      </div>
      <div>
        <h2>10. Confidential Information.</h2>
        <p>
          Customer understands that certain information disclosed to it by
          GREENIRR represents the proprietary trade secrets of GREENIRR.
          Customer therefore agrees to keep confidential and not disclose to any
          third party, and not to use except as specified in this License, any
          Confidential Information. Confidential information may be disclosed as
          required by court order, provided that Customer shall promptly notify
          GREENIRR to allow intervention and shall provide reasonable
          cooperation with GREENIRR to contest or minimize such disclosure.
          Customer shall protect the Confidential Information received with at
          least the same degree of care used to protect its own Confidential
          Information from unauthorized use or disclosure, but no less than a
          reasonable degree of care. Customer shall limit access to Confidential
          Information to those of its employees who need access to such
          Confidential Information to perform the evaluation required by this
          License. All Confidential Information (including all copies of such
          Confidential Information) shall remain the property of GREENIRR and
          shall be returned to GREENIRR upon: (a) request of GREENIRR; or (b)
          the expiration or termination of this License as set forth in Section
          8. Because of the unique and proprietary nature of the Service,
          Customer agrees that GREENIRR' remedies at law may be inadequate and
          that GREENIRR shall be entitled to equitable relief in specific
          performance in addition to the other remedies provided hereunder or
          available at law or in equity.
        </p>
        <p>
          GREENIRR understands the certain information disclosed to it by
          Customer represents proprietary information of Customer. All Customer
          data, which includes but is not limited to, Confidential Information,
          proprietary data from any mobile communications information and
          vehicle specific telematics made available to GREENIRR (collectively,
          “Customer Data”) shall be held by GREENIRR in confidence and, other
          than storage on GREENIRR’s platform maintained through its third-party
          service provider Amazon Web Services, shall not be disclosed to any
          third party and not to use except as specified in this License.
          Customer Data may be disclosed as required by court order, provided
          that GREENIRR shall promptly notify Customer to allow intervention and
          shall provide reasonable cooperation with Customer to contest or
          minimize such disclosure. GREENIRR shall protect the Customer Data
          received with at least the same degree of care used to protect its own
          Confidential Information from unauthorized use or disclosure, but no
          less than a reasonable degree of care. GreenIRR shall limit access to
          Customer Data to those of its employees who need access to such
          Customer Data to assist the Customer to perform the evaluation
          required by this License. All Customer Data (including all copies of
          such Customer Data) shall remain the property of Customer and shall be
          returned to Customer upon (a) request of Customer; or (b) the
          expiration or termination of this License as set forth in Section 8.
          Because of the unique and proprietary nature of the Service, GREENIRR
          agrees that Customer’s remedies at law may be inadequate and that
          Customer shall be entitled to equitable relief in specific performance
          in addition to the other remedies provided hereunder or available at
          law or in equity.
        </p>
      </div>
      <div>
        <h2> 11. Disclaimer of Warranties.</h2>
        <p>
          a. CUSTOMER ACKNOWLEDGES THAT THE SERVICE MAY NOT OPERATE AS INTENDED,
          OR MAY NOT BE COMPATIBLE WITH APPLICATIONS USED BY CUSTOMER. CUSTOMER
          HEREBY ACCEPTS ALL RISKS ASSOCIATED WITH CUSTOMER’S PARTICIPATION IN
          THIS EVALUATION, INCLUDING, BUT NOT LIMITED TO, LOSS OF DATA, AND/OR
          LOSS OF ACCESS TO APPLICATIONS USED BY CUSTOMER.
        </p>
        <p>
          b. GREENIRR MAKES NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR
          IMPLIED, AS TO THE SUITABILITY OR PERFORMANCE OF THE SERVICE AND
          SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES, INCLUDING IMPLIED
          WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE.
          THE SERVICE IS PROVIDED “AS IS.” GREENIRR DOES NOT WARRANT THAT THE
          FUNCTIONS CONTAINED IN THE SERVICE WILL MEET THE REQUIREMENTS OF
          CUSTOMER, THAT THE OPERATION OF THE SERVICE WILL BE UNINTERRUPTED OR
          ERROR FREE, OR THAT THE SERVICE WILL BE COMPATIBLE WITH CUSTOMER’S
          THIRD-PARTY APPLICATIONS OR SOFTWARE.
        </p>
      </div>
      <div>
        <h2>12. Limitation of Liability.</h2>
        <p>
          a. THE LIABILITY OF GREENIRR AND ITS LICENSORS TO CUSTOMER OR ANY
          THIRD PARTY ARISING FROM THE LICENSE OR USE OF THE SERVICE, OR THE
          PROVISION OF TECHNICAL SUPPORT, INSTALLATION, TRAINING OR OTHER
          SERVICES IN CONNECTION THEREWITH, HOWEVER CAUSED, AND ON ANY THEORY OF
          LIABILITY, INCLUDING CONTRACT, STRICT LIABILITY, NEGLIGENCE OR OTHER
          TORT, SHALL BE LIMITED TO DIRECT DAMAGES NOT TO EXCEED $100. IN NO
          EVENT WILL GREENIRR OR ITS LICENSORS BE LIABLE FOR ANY INDIRECT,
          INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING DAMAGES FOR
          LOSS OF PROFITS, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          THESE LIMITATIONS WILL APPLY NOTWITHSTANDING THE FAILURE OF THE
          ESSENTIAL PURPOSE OF ANY REMEDY.
        </p>
        <p>
          b. Customer acknowledges and agrees that (i) Customer is solely
          responsible for obtaining and complying with any third-party license
          agreements for the use of any third-party applications and/or
          software; and (ii) Customer is solely responsible for ensuring that
          Customer has the necessary rights to use the Service with such
          third-party applications and software.
        </p>
      </div>
      <div>
        <h2>13. Customer Remedies.</h2>
        <p>
          Except for liability for breach of Confidentiality Obligations set
          forth in Section 10, Company's entire liability and Customer’s
          exclusive remedy hereunder shall be Customer’s termination of this
          License pursuant to Section 8 above.
        </p>
      </div>
      <div>
        <h2>14. General.</h2>
        <p>
          a. The relationship of the parties hereto is one of contract only, and
          in no event shall the parties be construed as partners, joint
          venturers, agents or principals of each other. Customer may not assign
          or otherwise transfer, by operation of law or otherwise, any of its
          rights under this License without GREENIRR’ prior written consent in
          its sole and absolute discretion.
        </p>
        <p>
          b. This License is the complete and exclusive agreement between the
          parties with respect to the subject matter hereof, superseding and
          replacing any and all prior agreements, communications, and
          understandings (both written and oral) regarding such subject matter.
        </p>
        <p>
          c. The rights, duties, and obligations of the parties and the
          validity, interpretation, performance and legal effect of this License
          shall be governed by and interpreted in accordance with the internal
          laws of the State of Delaware, without regard to its conflicts of laws
          principles. Any legal action or proceeding related to this License
          shall be exclusively instituted in a state or federal court located in
          Wilmington, Delaware. GREENIRR and Customer agree to submit to the
          jurisdiction of, and agree that venue is proper in, these courts in
          any such legal action or proceeding.
        </p>
        <p>
          d. Customer will not export the Service or any direct product thereof,
          directly or indirectly, in violation of U.S. export control laws and
          other applicable export and import laws or use the Service for any
          purpose prohibited by these laws, or any other applicable laws.
        </p>
        <p>
          e. If any provision of this License is ruled invalid, such invalidity
          shall not affect the validity of the remaining portions of this
          License. No amendment to this License will be binding unless evidenced
          by a writing signed by the party against whom it is sought to be
          enforced. No waiver by either GREENIRR or Customer of any breach or
          default under this License shall be deemed to be a waiver of any of
          any other breach or default under this License.
        </p>
        <p>
          f. All notices required or permitted under this License shall be
          provided in writing by confirmed facsimile or by first class mail,
          postage- prepaid, to the respective parties at the addresses most
          recently provided to the other party.
        </p>
      </div>
    </div>
  );
}
