// Functionality: This file exports the Scope2PreviousSubmissionsTable component which is used in the Scope2PreviousSubmissions component. The component is a table for the previous submissions for Scope 2 emissions
import { useState } from "react";
import Modal from "../../../../components/Modal";
import Table from "../../../../components/Table";
import Scope2DeleteEntry from "../FormFields/Scope2DeleteEntry";
import Scope2EditEntry from "../FormFields/Scope2EditEntry";
import { deleteScope2ManagerEntries } from "../../../../api/Emissions/Scope2Manager/deleteScope2ManagerEntries";
import { putScope2ManagerEntries } from "../../../../api/Emissions/Scope2Manager/putScope2ManagerEntries";
import {
  isScope2ManagerEntryEdit,
  isScope2ManagerNewEntryEdit,
} from "../../../../constants/initialDataConfig";

export default function Scope2PreviousSubmissionsTable({
  scope2ManagerEntriesTableData,
  scope2ManagerTableConfig,
  scope2ManagerAddressOptions,
  setSuccessMsg,
}) {
  // State: Entry Fields
  const [scope2EditEntryFields, setScope2EditEntryFields] = useState(
    isScope2ManagerNewEntryEdit
  );

  // State: Selected Vehicle Edit Delete
  const [selectedEntriesEditDelete, setSelectedEntriesEditDelete] = useState(
    isScope2ManagerEntryEdit
  );

  const submitConditionHandler =
    Object?.values(scope2EditEntryFields)?.filter(
      (item) => item?.length > 0 || typeof item === "number"
    )?.length > 5;

  // Function: Gather Information on Selected Entry
  const handleEditDeleteEntryState = (data, type) => {
    // Const: Selected Entry
    const selectedEntry = data?.row?.original;

    // Const: Invoice ID
    const entryId = selectedEntry?.entry_id;

    // Const: Selected Entry Date - Reformat the date to be in the correct format
    const selectedEntryDate = selectedEntry?.dates;
    const date = new Date(selectedEntryDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11, so add 1 and pad with 0 if necessary
    const day = String(date.getDate()).padStart(2, "0"); // pad with 0 if necessary

    // Const: Selected Entry Location - Reformat the location to be in the correct format matched on site id
    const selectedLocation = selectedEntry?.location;
    const selectedEntryLocationId = parseInt(
      selectedLocation.substring(selectedLocation.lastIndexOf("-") + 1)
    );
    const selectedEntryLocation = scope2ManagerAddressOptions?.data?.filter(
      (item) => {
        return item?.value?.site_id === selectedEntryLocationId;
      }
    );

    if (type === "edit") {
      setSelectedEntriesEditDelete({
        selectedEntryLabel: selectedEntry,
        selectedEntryId: entryId,
        type: "edit",
      });
      setScope2EditEntryFields({
        entry_id: selectedEntry?.entry_id,
        invoice_id: selectedEntry?.invoice_id,
        bill_date: `${year}-${month}-${day}`,
        wattage: selectedEntry?.electricity_purchased_kwh,
        siteLocation: selectedEntryLocation,
        site_id: selectedEntryLocationId,
      });
    } else if (type === "delete") {
      setSelectedEntriesEditDelete({
        selectedEntryLabel: selectedEntry,
        selectedEntryId: entryId,
        type: "delete",
      });
    }
  };

  // Function: Handle Modal Close
  const handleModalClose = () => {
    setSelectedEntriesEditDelete(isScope2ManagerEntryEdit);
  };

  const handleEditDeleteEntry = (type) => {
    if (type === "delete") {
      deleteScope2ManagerEntries(
        selectedEntriesEditDelete?.selectedEntryId,
        setSuccessMsg
      );
    }
    if (type === "edit") {
      putScope2ManagerEntries(
        {
          entry_id: scope2EditEntryFields?.entry_id,
          invoice_id: scope2EditEntryFields?.invoice_id,
          bill_date: scope2EditEntryFields?.bill_date,
          wattage: scope2EditEntryFields?.wattage,
          site_id: scope2EditEntryFields?.site_id,
        },
        setSuccessMsg
      );
    }
    handleModalClose();
  };
  // Const: Scope 2 Entries Table
  const scope2EntriesTable = scope2ManagerEntriesTableData?.loaded && (
    <Table
      tableData={scope2ManagerEntriesTableData?.data}
      config={scope2ManagerTableConfig(handleEditDeleteEntryState)}
      tableRowClassName="primary-table-row solution-marker-table-row card-text-min"
      sortable={false}
      tableHeadClassName="primary-table-sort-header-group card-text-min"
    />
  );

  // Const: Previous Submissions
  const previousSubmissionEntries = (
    <div className="scope2-data-table">{scope2EntriesTable}</div>
  );

  const scope2DeleteEntryModal = (
    <Modal
      onClose={() => handleModalClose()}
      modalDivClassName="large-modal-div"
      children={
        <Scope2DeleteEntry
          selectedEntriesEditDelete={selectedEntriesEditDelete}
          handleEditDeleteEntry={handleEditDeleteEntry}
          handleModalClose={handleModalClose}
        />
      }
    />
  );

  const scope2EditEntryModal = (
    <Modal
      onClose={() => handleModalClose()}
      modalDivClassName="large-modal-div"
      children={
        <Scope2EditEntry
          setScope2EditEntryFields={setScope2EditEntryFields}
          scope2EditEntryFields={scope2EditEntryFields}
          handleEditDeleteEntry={handleEditDeleteEntry}
          scope2ManagerAddressOptions={scope2ManagerAddressOptions}
          handleModalClose={handleModalClose}
          submitConditionHandler={submitConditionHandler}
        />
      }
    />
  );

  return (
    <>
      {previousSubmissionEntries}
      {selectedEntriesEditDelete?.type === "edit" && scope2EditEntryModal}
      {selectedEntriesEditDelete?.type === "delete" && scope2DeleteEntryModal}
    </>
  );
}
