// This file serves as a central location for defining axios interceptors, headers, etc. for the application
import axios from "axios";
import { LOGIN_URL } from "./paths";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("greenToken");
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (
      ["Invalid token", "Token has expired", "Token is missing"].includes(
        response.data.error
      )
    ) {
      // Handle token expired response here
      localStorage.clear();
      window.location.href = "/";
    }
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== LOGIN_URL
    ) {
      // Handle 401 error here (clear local storage and redirect)
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default instance;
