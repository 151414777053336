// Functionality: Verification Code Component for the whole application
import { useRef } from "react";
import { FaTimes } from "react-icons/fa";
import Button from "./Button";

export default function VerificationCode({
  code,
  setCode,
  label = "Verification Code",
  stateId,
}) {
  // Refs to control each digit input element
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  // Function: Reset all inputs and clear state
  const resetCode = () => {
    inputRefs.forEach((ref) => {
      ref.current.value = "";
    });
    inputRefs[0].current.focus();
    setCode({
      ...code,
      [stateId]: "",
    });
  };

  // Function: Handle input into the verification component
  const handleInput = (e, index) => {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    // Update code state with single digit
    const newCode = [...code?.[stateId]];
    // Convert lowercase letters to uppercase
    if (/^[a-z]+$/.test(input.value)) {
      const uc = input.value.toUpperCase();
      newCode[index] = uc;
      inputRefs[index].current.value = uc;
    } else {
      newCode[index] = input.value;
    }
    setCode({ ...code, [stateId]: newCode.join("") });

    input.select();

    if (input.value === "") {
      // If the value is deleted, select previous input, if exists
      if (previousInput) {
        previousInput.current.focus();
      }
    } else if (nextInput) {
      // Select next input on entry, if exists
      nextInput.current.select();
    }
  };

  // Function: Select the contents on focus
  const handleFocus = (e) => {
    e.target.select();
  };

  // Function: Handle backspace key
  const handleKeyDown = (e, index) => {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    if ((e.keyCode === 8 || e.keyCode === 46) && input.value === "") {
      e.preventDefault();
      setCode({
        ...code,
        [stateId]:
          code?.[stateId]?.slice(0, index) + code?.[stateId]?.slice(index + 1),
      });
      if (previousInput) {
        previousInput.current.focus();
      }
    }
  };

  // Function: Capture pasted characters
  const handlePaste = (e) => {
    const pastedCode = e.clipboardData.getData("text");
    if (pastedCode.length === 6) {
      setCode({
        ...code,
        [stateId]: pastedCode,
      });
      inputRefs.forEach((inputRef, index) => {
        inputRef.current.value = pastedCode.charAt(index);
      });
    }
  };

  // Clear button deletes all inputs and selects the first input for entry
  const clearButton = (
    <Button
      onClick={resetCode}
      className="verification-code-reset-button"
      buttonName={<FaTimes />}
    />
  );
  return (
    <div className="verification-code-component">
      <p>{label}</p>
      <div className="verification-code-input-group">
        {[0, 1, 2, 3, 4, 5].map((index) => (
          <input
            className="individual-verification-code-input"
            key={index}
            type="text"
            maxLength={1}
            onChange={(e) => handleInput(e, index)}
            ref={inputRefs[index]}
            autoFocus={index === 0}
            onFocus={handleFocus}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={handlePaste}
          />
        ))}
        {code?.[stateId]?.length ? clearButton : <></>}
      </div>
    </div>
  );
}
