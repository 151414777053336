// Functionality: API Call for Idle Time Emissions Line Chart w/ Benchmark
import axios from "../loadBalancerAxios";
import { IDLE_TIME_EMISSIONS_URL } from "../paths";
import { idleTimeEmissionsDataConfig } from "../../constants/VehicleConfig.js";
import useDateFormat from "../../hooks/use-DateFormat";
import useFindYearChange from "../../hooks/use-FindYearChange";

export const getIdleTimeEmissionsAPI = async (
  setData,
  selectedChartRadioItemType,
  applicationDates
) => {
  // Const: Determine if the data is daily or monthly
  const isDaily = selectedChartRadioItemType === "Daily";

  // Const: API Call Path
  const IDLE_TIME_EMISSIONS_DATE_URL = isDaily
    ? `${IDLE_TIME_EMISSIONS_URL}?format=Daily&date=${applicationDates}`
    : `${IDLE_TIME_EMISSIONS_URL}?format=Monthly`;

  await axios
    .get(IDLE_TIME_EMISSIONS_DATE_URL)
    .then((response) => {
      // Const: Get Data From Response
      const responseData = response?.data || [];

      // Const: Length of Data, Check if Daily or Monthly Data
      const lengthData = responseData?.dates?.length;
      const nonEmptyNonDailyData = lengthData > 1 && !isDaily;

      const indexofLineBreak = useFindYearChange(responseData?.dates);
      const combinedValues = responseData?.emissions;

      const maxValue = Math.max(...combinedValues) + 25;
      const annotationValue = {
        first: responseData?.dates
          ?.slice(indexofLineBreak - 1)?.[0]
          ?.slice(0, 4),
        second: responseData?.dates?.slice(indexofLineBreak)?.[0]?.slice(0, 4),
      };

      // Const: Set Formatted Data
      const formattedData =
        responseData?.error?.length > 0
          ? []
          : responseData?.dates?.map((x, index) => ({
              x: index,
              y: responseData?.emissions[index],
              label: nonEmptyNonDailyData
                ? useDateFormat(x)
                : useDateFormat(x, "month-day"),
            }));

      setData({
        loaded: true,
        data: [{ ...idleTimeEmissionsDataConfig, dataValues: formattedData }],
        lineBreak:
          nonEmptyNonDailyData && indexofLineBreak !== -1
            ? indexofLineBreak - 1
            : false,
        maxValue: maxValue,
        annotationValue: annotationValue,
      });
    })
    .catch(() => {
      setData({
        loaded: true,
        data: [{ ...idleTimeEmissionsDataConfig, dataValues: [] }],
        lineBreak: false,
        maxValue: 0,
        annotationValue: { first: "", second: "" },
      });
    });
};
