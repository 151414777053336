// Functionality: This file exports the EmissionReductionTarget Card component which is used in the Reduction Page component.
import { useContext } from "react";
import ReductionContext from "../../context/ReductionProvider";
import DateContext from "../../context/DateProvider";
import SelectDropdown from "../../components/SelectDropdown";
import { reductionTargetPercentageDropdown } from "../../constants/ReductionConfig";
import BarChart from "../../visualizations/BarChart";
import SwitchCarousel from "../../components/SwitchCarousel";
import Skeleton from "react-loading-skeleton";
import { emissionReductionTargetTile } from "../../constants/TileConfig";
import EmissionReductionTargetSettingsModal from "./EmissionReductionTargetSettingsModal";

export default function EmissionReductionTargetCard() {
  // Const: Initialize Context
  const {
    emissionsReductionTargetData,
    emissionReductionProjectedYear,
    setEmissionReductionProjectedYear,
    selectedEmissionTargetPercentage,
    setSelectedEmissionTargetPercentage,
    reductionTargetDefault,
    setReductionTargetDefault,
  } = useContext(ReductionContext);

  // Const: Initialize Date Context
  const { yearValues, applicationDates, setApplicationDates } =
    useContext(DateContext);

  // Const: Label Application Dates
  const applicationDatesLabel = "emissionReductionTarget";

  // Const: Base Year Handler - logic: only show years AFTER the base year, if there is only one year, show that year
  const yearHandler = () => {
    if (yearValues?.information?.length === 1) {
      return yearValues?.information;
    } else {
      const startIndex = yearValues?.information
        ?.map((item, index) => {
          if (item.value === reductionTargetDefault?.base_year?.[0]?.value) {
            return index;
          }
        })
        ?.filter((item) => item !== undefined)?.[0];
      return yearValues?.information.slice(startIndex + 1);
    }
  };

  // Const: Title Group for card
  const titleGroup = (
    <>
      <div className="emission-reduction-target-container-title-group">
        <div className="card-title">
          Emission Reduction Target
          {emissionReductionTargetTile?.blurb}
          <EmissionReductionTargetSettingsModal
            reductionTargetPercentageDropdown={
              reductionTargetPercentageDropdown
            }
            yearValues={yearValues}
            reductionTargetDefault={reductionTargetDefault}
            setEmissionReductionProjectedYear={
              setEmissionReductionProjectedYear
            }
            setSelectedEmissionTargetPercentage={
              setSelectedEmissionTargetPercentage
            }
            setReductionTargetDefault={setReductionTargetDefault}
          />
        </div>
        <div>
          <div className="group-switch-carousel">
            <SwitchCarousel
              values={yearHandler() || []}
              applicationDates={applicationDates}
              setApplicationDates={setApplicationDates}
              applicationDatesLabel={applicationDatesLabel}
              fillArrowColor={"#66C743"}
              switchCarouselValueClassName="group-switch-carousel-value"
            />
          </div>
        </div>
      </div>
      <p className="card-subtitle">Greenhouse Gas Emissions (GHGs)</p>
    </>
  );

  // Const target dropdown selection for card
  const targetDropdownSelection = (
    <>
      <div className="emission-reduction-target-dropdown-group">
        <p className="card-subtext">Target:</p>
        <SelectDropdown
          divClassName="select-dropdown-main-container font-color-blue"
          headerClassName="select-dropdown-header-container font-color-blue"
          options={reductionTargetPercentageDropdown}
          selectedOptions={selectedEmissionTargetPercentage}
          setSelectedOptions={setSelectedEmissionTargetPercentage}
        />
        <p className="card-subtext">emission reduction by</p>
        <SelectDropdown
          divClassName="select-dropdown-main-container font-color-blue"
          headerClassName="select-dropdown-header-container font-color-blue"
          options={yearValues?.projection || []}
          selectedOptions={emissionReductionProjectedYear}
          setSelectedOptions={setEmissionReductionProjectedYear}
        />
        <p className="card-subtext">
          assuming base year: {reductionTargetDefault?.base_year?.[0]?.label}
        </p>
      </div>
    </>
  );

  // Const: Bar Chart Card
  const barChartCard = (
    <div className="chart-card-container">
      <BarChart
        datasets={emissionsReductionTargetData?.data}
        barType="overlay"
        barChartStepSize={3}
      />
    </div>
  );

  return (
    <>
      {!emissionsReductionTargetData?.loaded ? (
        <Skeleton height={400} />
      ) : (
        <div className="emission-reduction-target-container">
          {titleGroup}
          {targetDropdownSelection}
          {barChartCard}
        </div>
      )}
    </>
  );
}
