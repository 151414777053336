// Functionality: This file exports the IntroReport component which is used in the Report Page component.
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthProvider";
import ReportContext from "../../../context/ReportsProvider";
import { BsFillTrashFill } from "react-icons/bs";
import Input from "../../../components/Input";
import SelectDropdown from "../../../components/SelectDropdown";
import TextArea from "../../../components/TextArea";
import RadioItemGroup from "../../../components/RadioItemGroup";
import CheckBoxGroup from "../../../components/CheckBox";
import {
  jobTitleReportDropdownOptions,
  reportQuestionConfig,
  reportingBoundaryRadioItemOptions,
  transportModesChecklistOptions,
  ISINcodeDropdownOptions,
  boardLevelOversightRadioOptions,
  countryOfOperationsDropdownOptions,
} from "../../../constants/ReportsConfig";

export default function IntroReport({
  generateQuestionGroup,
  handleReportEventChange,
  yearValues,
  reportInputClassName,
}) {
  // Const: Initialize Report Context
  const {
    radioValueDateType,
    selectedValueDateType,
    introDataConfig,
    setIntroDataConfig,
    selectedCountryOfOperation,
    setSelectedCountryOfOperation,
  } = useContext(ReportContext);

  // Context: Company Name
  const { carrierName } = useContext(AuthContext);

  // UE: Set the default value for the dropdown for the Countries of Operation
  useEffect(() => {
    if (selectedCountryOfOperation?.length > 0) {
      setIntroDataConfig((prevState) => ({
        ...prevState,
        countries_operation: { dropdown: selectedCountryOfOperation },
      }));
    }
  }, [selectedCountryOfOperation]);

  // Function: Date to Show
  const dateToShow = () => {
    if (radioValueDateType === "Year") {
      return <p>{selectedValueDateType?.["year"]?.[0]?.label}</p>;
    } else if (radioValueDateType === "Custom") {
      return (
        <>
          <p>{selectedValueDateType?.["custom"]?.start}</p>
          <p>-</p>
          <p>{selectedValueDateType?.["custom"]?.end}</p>
        </>
      );
    } else if (radioValueDateType === "YTD") {
      return (
        <>
          <p>{yearValues?.information?.slice(-1)?.[0]?.label}</p>
        </>
      );
    } else {
      return (
        <>
          <p>
            {
              selectedValueDateType?.[radioValueDateType.toLowerCase()]?.[0]
                ?.label
            }
          </p>
          <p>{selectedValueDateType?.["year"]?.[0]?.label}</p>
        </>
      );
    }
  };

  // Function to handle adding a new dropdown-input pair to organizational_ids
  const addDropdownInputPair = () => {
    setIntroDataConfig((prevState) => ({
      ...prevState,
      organizational_ids: [
        ...prevState.organizational_ids,
        { dropdown: [], input: "" },
      ],
    }));
  };

  // Function to handle deleting a dropdown-input pair from organizational_ids
  const deleteDropdownInputPair = (index) => {
    setIntroDataConfig((prevState) => ({
      ...prevState,
      organizational_ids: prevState.organizational_ids.filter(
        (_, i) => i !== index
      ),
    }));
  };

  // Function to handle changes in organizational_ids
  const handleNestedEventChange = (e, type, index, label) => {
    setIntroDataConfig((prevState) => {
      const updatedorganizational_ids = [...prevState.organizational_ids];
      if (label === "dropdown") {
        updatedorganizational_ids[index].dropdown = e;
      } else if (label === "input") {
        updatedorganizational_ids[index].input = e.target.value;
      }
      return {
        ...prevState,
        [type]: updatedorganizational_ids,
      };
    });
  };

  // UE: Clear Board Level Oversight Text Area if Radio is not "Other"
  useEffect(() => {
    if (
      introDataConfig?.board_level_oversight?.radio !== "Other" &&
      introDataConfig?.board_level_oversight?.textArea !== ""
    ) {
      setIntroDataConfig((prevState) => ({
        ...prevState,
        board_level_oversight: {
          radio: prevState.board_level_oversight.radio,
          textArea: "",
        },
      }));
    }
    if (
      introDataConfig?.approval?.dropdown?.value !== "Other" &&
      introDataConfig?.approval?.input2 !== ""
    ) {
      setIntroDataConfig((prevState) => ({
        ...prevState,
        approval: {
          dropdown: prevState.approval.dropdown,
          input: prevState.approval.input,
          input2: "",
        },
      }));
    }
  }, [
    introDataConfig?.approval?.dropdown,
    introDataConfig?.board_level_oversight?.radio,
    introDataConfig?.board_level_oversight?.textArea,
    setIntroDataConfig,
  ]);

  // Dictionary: UI/UX for each of the questions, in order
  const questionGroupConfig = {
    0: <p className="company-name-title-reports">{carrierName}</p>,
    1: (
      <div className="reporting-flex-group">
        <SelectDropdown
          options={jobTitleReportDropdownOptions || []}
          selectedOptions={[introDataConfig?.approval?.dropdown]}
          placeHolderText="Select job title"
          divClassName="select-dropdown-reports-main-container font-color-gray font-small"
          onChange={(e) =>
            handleReportEventChange(
              e,
              "approval",
              "dropdown",
              setIntroDataConfig,
              introDataConfig
            )
          }
        />
        {introDataConfig?.approval?.dropdown?.value === "Other" && (
          <Input
            value={introDataConfig?.approval?.input2}
            placeholder={"Role"}
            onChange={(e) =>
              handleReportEventChange(
                e,
                "approval",
                "input2",
                setIntroDataConfig,
                introDataConfig
              )
            }
            className={reportInputClassName?.input}
          />
        )}
        <Input
          value={introDataConfig?.approval?.input}
          placeholder={"Name"}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "approval",
              "input",
              setIntroDataConfig,
              introDataConfig
            )
          }
          className={reportInputClassName?.input}
        />
        <p className="font-cursive" style={{ marginLeft: "20px" }}>
          {introDataConfig?.approval?.input}
        </p>
      </div>
    ),
    2: (
      <TextArea
        value={introDataConfig?.company_description?.textArea}
        placeholder={"General description"}
        onChange={(e) =>
          handleReportEventChange(
            e,
            "company_description",
            "textArea",
            setIntroDataConfig,
            introDataConfig
          )
        }
        className={reportInputClassName?.textArea}
      />
    ),
    3: (
      <div className="reporting-intro-selected-date-group">
        <p>{radioValueDateType}:</p>
        {dateToShow()}
      </div>
    ),
    4: (
      <div className="reporting-intro-selected-date-group">
        <SelectDropdown
          options={countryOfOperationsDropdownOptions}
          placeHolderText="Countries"
          isMultiSelect={true}
          selectedOptions={selectedCountryOfOperation}
          setSelectedOptions={setSelectedCountryOfOperation}
          divClassName="select-dropdown-reports-main-container font-color-gray font-small"
        />
      </div>
    ),
    5: (
      <RadioItemGroup
        options={reportingBoundaryRadioItemOptions}
        value={introDataConfig?.reporting_boundary?.radio}
        divClassName="div-radio-item-vertical"
        splitTextByLine={true}
        onChange={(e) =>
          handleReportEventChange(
            e,
            "reporting_boundary",
            "radio",
            setIntroDataConfig,
            introDataConfig
          )
        }
      />
    ),
    6: (
      <CheckBoxGroup
        options={transportModesChecklistOptions || []}
        selectedOptions={introDataConfig?.transport_modes?.checkbox}
        divClassName="div-radio-item-vertical"
        onChange={(e) =>
          handleReportEventChange(
            e,
            "transport_modes",
            "checkbox",
            setIntroDataConfig,
            introDataConfig
          )
        }
      />
    ),
    7: (
      <>
        {introDataConfig.organizational_ids.map((pair, index) => (
          <div key={index} className="reporting-flex-group">
            <SelectDropdown
              options={ISINcodeDropdownOptions || []}
              selectedOptions={[pair.dropdown]}
              placeHolderText="Select unique Identifier"
              divClassName="select-dropdown-reports-main-container font-color-gray font-small"
              onChange={(e) =>
                handleNestedEventChange(
                  e,
                  `organizational_ids`,
                  index,
                  "dropdown"
                )
              }
            />
            <Input
              value={pair.input}
              placeholder={"Entry"}
              onChange={(e) =>
                handleNestedEventChange(e, `organizational_ids`, index, "input")
              }
            />
            {index === introDataConfig.organizational_ids.length - 1 && (
              <div className="report-new-entry-container font-medium-large">
                <p onClick={addDropdownInputPair}>+</p>
              </div>
            )}
            {introDataConfig.organizational_ids.length > 1 && (
              <div
                className="report-delete-entry-container"
                onClick={() => deleteDropdownInputPair(index)}
              >
                <BsFillTrashFill />
              </div>
            )}
          </div>
        ))}
      </>
    ),
    8: (
      <div>
        <RadioItemGroup
          options={boardLevelOversightRadioOptions || []}
          value={introDataConfig?.board_level_oversight?.radio}
          splitTextByLine={true}
          divClassName="div-radio-item-vertical-no-gap"
          onChange={(e) =>
            handleReportEventChange(
              e,
              "board_level_oversight",
              "radio",
              setIntroDataConfig,
              introDataConfig
            )
          }
        />
        {introDataConfig?.board_level_oversight?.radio === "Other" && (
          <TextArea
            value={introDataConfig?.board_level_oversight?.textArea}
            divClassName=""
            placeholder={"Oversight"}
            onChange={(e) =>
              handleReportEventChange(
                e,
                "board_level_oversight",
                "textArea",
                setIntroDataConfig,
                introDataConfig
              )
            }
            className={reportInputClassName?.textArea}
          />
        )}
      </div>
    ),
  };
  return (
    <>
      {generateQuestionGroup(
        reportQuestionConfig?.introduction,
        questionGroupConfig
      )}
    </>
  );
}

// Props
const introReportPropTypes = {
  generateQuestionGroup: PropTypes.func, // Function: Generates the UI/UX for each question
  handleReportEventChange: PropTypes.func, // Function: Handles the change of each question
};

IntroReport.propTypes = introReportPropTypes;
