// Functionality: Provide a basis for the registration layout, which is a component imported from hubspot
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function HubspotOnboardFormLayout({
  backgroundContainerClassName = "colored-bar-background-container",
  mainContainerClassName = "colored-bar-main-container-content",
  hubspotFormMiddleClassName = "hubspot-form-middle-padding",
  hubspotActualFormClassName = "hubspot-actual-form",
  hubspotFormId = "hubspotForm",
  activeFormStep,
  ...props
}) {
  const location = useLocation();
  const navigate = useNavigate();

  // UE: Generate the Hubspot form that is embedded on the page
  useEffect(() => {
    const script = document.createElement("script");
    script.src = props?.src;
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          css: "",
          region: props?.region,
          portalId: props?.portalId,
          formId: props?.formId,
          target: "#hubspotForm",
          onFormSubmit: function () {
            // Capture the email value on form submission
            const email = document.getElementById(
              `email-${props?.formId}`
            )?.value;

            if (email && activeFormStep === 1) {
              // Update the query parameter in the URL
              const searchParams = new URLSearchParams(location.search);
              searchParams.set("email", email);

              // Construct the new URL
              const newUrl = `${location.pathname}?${searchParams
                .toString()
                ?.split("&")?.[0]
                .toString()}&step=${parseInt(activeFormStep) + 1}`;

              // Replace the current URL with the updated query parameter
              navigate(newUrl);
            }
            // Maybe break these up and store company across
            else if (
              parseInt(activeFormStep) > 1 &&
              parseInt(activeFormStep) < 4
            ) {
              // Update the query parameter in the URL
              const searchParams = new URLSearchParams(location.search);
              searchParams.set("step", parseInt(activeFormStep) + 1);
              // Construct the new URL
              const newUrl = `${location.pathname}?${searchParams.toString()}`;
              // Replace the current URL with the updated query parameter
              navigate(newUrl);
            }
          },
        });
      }
    });

    // return () => {
    //   document.body.removeChild(script); // Clean up when component unmounts
    // };
  }, [activeFormStep]);

  return (
    <div>
      <div className={backgroundContainerClassName}></div>
      <div className={mainContainerClassName}>
        <div className={hubspotFormMiddleClassName}>
          {props.headerChildren}
          <div id={hubspotFormId} className={hubspotActualFormClassName}></div>
        </div>
      </div>
    </div>
  );
}
