import { useContext } from "react";
import EmissionsContext from "../context/EmissionsProvider";
import DateContext from "../context/DateProvider";
import ApplicationHeader from "../layouts/ApplicationHeader";
import Scope1Card from "./EmissionsPage/Scope1Card";
import Scope2Card from "./EmissionsPage/Scope2Card";
import Scope1ChartCard from "./EmissionsPage/Scope1ChartCard";
import Scope2ChartCard from "./EmissionsPage/Scope2ChartCard";
import Scope2Manager from "./EmissionsPage/Scope2Manager";
import TabbedContainer from "../components/TabbedContainer";
import useNavHook from "../hooks/use-NavHook";
import LifeCyclePage from "./EmissionsPage/LifeCyclePage";
import Skeleton from "react-loading-skeleton";

export default function EmissionsPage() {
  // Const: Initialize Context
  const { scope2DataExists } = useContext(EmissionsContext);

  // Const: Initialize Context - Dates
  const {
    dateValues,
    applicationDates,
    setApplicationDates,
    activeTab,
    setActiveTab,
  } = useContext(DateContext);

  // Const: Initialize Hook - Navigation Hook to go to Emissions Page
  const { navigate, navTo } = useNavHook("/emissions?activeTab=1");

  // Function: Handle Navigation Scope 2 Manager
  const handleNavScope2Manager = () => {
    setActiveTab({ ...activeTab, "/emissions": 1 });
    navigate(navTo, { state: { activeTab: 1 }, replace: true });
  };

  // Const: Empty Card For Scope 2
  const scope2EmptyCard = (
    <div
      id="emission-page-container-scope2-empty"
      className="chart-card-container-group"
      onClick={handleNavScope2Manager}
    >
      No Data Available - Please Visit Scope 2 Emissions Manager
    </div>
  );

  // Const: Card For Scope 2
  const scope2Card = (
    <div id="emission-page-container-scope2">
      <Scope2Card
        dateValues={dateValues}
        applicationDates={applicationDates}
        setApplicationDates={setApplicationDates}
      />
      <Scope2ChartCard />
    </div>
  );

  // Tabbed Container: Data & Scope 2 Manager
  const emissionsTabs = [
    {
      label: "Data",
      content: (
        <>
          <div id="emission-page-container-scope1">
            <Scope1ChartCard
              dateValues={dateValues}
              applicationDates={applicationDates}
              setApplicationDates={setApplicationDates}
            />
            <Scope1Card
              dateValues={dateValues}
              applicationDates={applicationDates}
              setApplicationDates={setApplicationDates}
            />
          </div>
          {scope2DataExists?.loaded ? (
            scope2DataExists?.data ? (
              scope2Card
            ) : (
              scope2EmptyCard
            )
          ) : (
            <Skeleton />
          )}
        </>
      ),
    },
    {
      label: "Scope 2 Manager",
      content: (
        <>
          <Scope2Manager />
        </>
      ),
    },
    {
      label: "Lifecycle",
      content: <LifeCyclePage />,
    },
  ];
  return (
    <>
      <ApplicationHeader headerName={"Emissions"} />
      <>
        <TabbedContainer
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={emissionsTabs}
          pageName="/emissions"
        />
      </>
    </>
  );
}
