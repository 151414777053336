// Functionality: Singular Vehicle Edit Modal - component is used for both singular Vin Decoder Import and Single Vehicle Insite Editing
import { useState, useEffect } from "react";
import Modal from "../../../components/Modal";
import { CustomRadioItemGroup } from "../../../components/RadioItemGroup";
import { postVinDecoderImportEntries } from "../../../api/Settings/VinDecoderImportAPI";
import SingleVinDecoderModal from "./SingleVinDecoderModal";
import SingleInsiteVehicleEditModal from "./SingleInsiteVehicleEditModal";
import { singularVehicleEditConfig } from "../../../constants/SettingsConfig";

export default function VehicleSettingsEditModal({
  showModal,
  handleModalClose,
  handlePostVehicleSettings,
  disableInputs,
  setDisableInputs,
  handleReviewClick,
  vehicleFuelTypeOptions,
  yearValues,
  disabledDecodeButton,
  setDisabledDecodeButton,
  singleVinDecoderProcessStep,
  setSingleVinDecoderProcessStep,
  handleEditFieldChange,
  singleVehicleEditFields,
  setSingleVehicleEditFields,
}) {
  // State
  const [selectedSingleEditType, setSelectedSingleEditType] =
    useState("decoder"); // State: Selection Radio Item of Editor Type
  const [vinDecoderImportData, setVinDecoderImportData] = useState({});

  // UE: Update the Edit Fields to support that of vin decode when it is used
  useEffect(() => {
    if (singleVehicleEditFields && vinDecoderImportData?.loaded) {
      setSingleVehicleEditFields({
        ...singleVehicleEditFields,
        vin_decode: vinDecoderImportData?.data[0],
      });
    }
  }, [vinDecoderImportData?.data]);

  // Function: API Call to Run Vin Decoder
  const handleVinDecoderButtonClick = () => {
    setDisabledDecodeButton(true);
    postVinDecoderImportEntries(
      [
        {
          vehicle_id: singleVehicleEditFields?.vin_decode?.vehicle_id,
          vin: singleVehicleEditFields?.vin_decode?.vin,
        },
      ],
      setVinDecoderImportData,
      setSingleVinDecoderProcessStep,
      setDisabledDecodeButton
    );
  };

  // Radio Group For Insite Editing vs Vin Decoder
  const editVehicleRadioItemGroup = (
    <CustomRadioItemGroup
      options={singularVehicleEditConfig}
      selectedOption={selectedSingleEditType}
      setSelectedOption={setSelectedSingleEditType}
      divClassName="custom-radio-item-container-linear"
      elementClassName="custom-radio-item-option-linear"
    />
  );
  // Header Group for Editing Vehicle
  const editVehicleHeaderGroup = (
    <div className="settings-update-vehicle-modal-group">
      <div>
        Editing Vehicle {singleVehicleEditFields?.original?.vehicle_id}{" "}
        Configuration
      </div>
      <div className="setting-vehicle-warning">
        {!disableInputs && (
          <>
            <p>
              You are currently editing these fields - If you close before
              finishing, your changes will be lost.
            </p>
          </>
        )}
        {disableInputs && (
          <p>
            Please review your changes to Vehicle{" "}
            {singleVehicleEditFields?.original?.vehicle_id} before submitting.
          </p>
        )}
      </div>
    </div>
  );

  const vinDecoderVehicleEdit = (
    <div className="settings-update-vehicle-fields-group">
      <SingleVinDecoderModal
        setVinDecoderImportData={setVinDecoderImportData}
        vinDecoderImportData={vinDecoderImportData}
        disabledDecodeButton={disabledDecodeButton}
        handleVinDecoderButtonClick={handleVinDecoderButtonClick}
        singleVinDecoderProcessStep={singleVinDecoderProcessStep}
        setSingleVinDecoderProcessStep={setSingleVinDecoderProcessStep}
        handleModalClose={handleModalClose}
        handlePostVehicleSettings={handlePostVehicleSettings}
        singleVehicleEditFields={singleVehicleEditFields?.vin_decode}
        handleEditFieldChange={handleEditFieldChange}
        disableInputs={disableInputs}
        setDisabledDecodeButton={setDisabledDecodeButton}
        updatedVehicleData={vinDecoderImportData?.data}
        yearValues={yearValues}
        originalVehicleEditFields={singleVehicleEditFields?.original}
      />
    </div>
  );
  // Const: Determine the body of the modal card
  const modalCardBody = (
    <Modal
      onClose={() => handleModalClose("single")}
      modalDivClassName="large-modal-div"
      children={
        <div>
          {editVehicleHeaderGroup}
          {editVehicleRadioItemGroup}
          {selectedSingleEditType === "insite" && (
            <SingleInsiteVehicleEditModal
              handleReviewClick={handleReviewClick}
              setDisableInputs={setDisableInputs}
              disableInputs={disableInputs}
              handlePostVehicleSettings={handlePostVehicleSettings}
              handleModalClose={handleModalClose}
              yearValues={yearValues}
              vehicleFuelTypeOptions={vehicleFuelTypeOptions}
              handleEditFieldChange={handleEditFieldChange}
              singleVehicleEditFields={singleVehicleEditFields?.insite}
              originalVehicleEditFields={singleVehicleEditFields?.original}
            />
          )}
          {selectedSingleEditType === "decoder" && vinDecoderVehicleEdit}
        </div>
      }
    />
  );
  return <>{showModal?.single && modalCardBody}</>;
}
