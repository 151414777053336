// Functionality: API Call for Emission Reduction Target Bar Chart Tile
import axios from "../../loadBalancerAxios";
import { EMISSION_REDUCTION_TARGET_URL } from "../../paths";
import { emissionReductionTargetDataConfig } from "../../../constants/ReductionConfig";
import useDateFormat from "../../../hooks/use-DateFormat";

export const getEmissionReductionTargetAPI = async (
  setData,
  selectedYear,
  projectedYear,
  targetPercentage,
  baseYear
) => {
  // URL: Emission Reduction Target
  const emissionsReductionURL = `${EMISSION_REDUCTION_TARGET_URL}?target=${
    parseInt(targetPercentage?.[0]?.value) / 100 || 0.4
  }&curr_year=${selectedYear}&base_year=${baseYear}&expected_year=${projectedYear}`;
  await axios
    .get(emissionsReductionURL)
    .then((response) => {
      // Const: Emission Reduction Data
      const responseData = response?.data;

      // Labels
      const emissionLabels = responseData?.date?.map((item) =>
        useDateFormat(item)
      );

      // Generate the Data Structure
      const dataStructure = {
        ...emissionReductionTargetDataConfig,
        labels: emissionLabels,
      };
      dataStructure.datasets[0].data = responseData?.actual;
      dataStructure.datasets[1].data = responseData?.expected;
      setData({ loaded: true, data: dataStructure });
    })
    .catch(() => {
      setData({ loaded: true, data: [] });
    });
};
