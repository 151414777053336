// Functionality: Vehicle CSV Uploaded Settings Table
import { useState, useEffect } from "react";
import Button from "../../../../components/Button";
import ToggleSwitch from "../../../../components/ToggleSwitch";
import VinDecoderOutputEditModal from "./VinDecoderOutputEditModal";
import VinDecoderImportModalVerify from "./VinDecoderImportModalVerify";
import { PiPencilLight } from "react-icons/pi";
import { BiError } from "react-icons/bi";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import {
  vinDecoderLabelNames,
  vehSettingsOutputCols,
} from "../../../../constants/SettingsConfig";
import { determineVinItemClassName } from "../../../../constants/test";

export default function VinDecoderOutputTable({
  setVinDecoderImportState,
  vinDecoderImportState,
  updatedVehicleData,
  yearValues,
  handlePostVehicleSettings,
  single = false,
  setSelectedEdit = () => {},
  setProcessStep = () => {},
}) {
  // State: Toggle Show Errors
  const [showErrors, setShowErrors] = useState(false);
  const [showModal, setShowModal] = useState({ edit: false, verify: false });
  const [selectedVehicleEdit, setSelectedVehicleEdit] = useState({
    original: {},
    edit: {},
  });

  // Function: Show Modal
  const handleClick = (item) => {
    setSelectedVehicleEdit({ original: item, edit: item });
    setShowModal({
      edit: true,
      verify: false,
    });
  };
  // Function: Hide Modal
  const handleModalClose = () => {
    setShowModal({
      edit: false,
      verify: false,
    });
  };

  const lengthErrorData = vinDecoderImportState?.length_error_data;

  // UE: If Error Data Does Not Exist, Set Show Errors to False
  useEffect(() => {
    if (lengthErrorData === 0) {
      setShowErrors(false);
    }
  }, [lengthErrorData]);

  const vinOutputHeader = (
    <div>
      <div className="vin-decoder-import-header-group">
        <div>
          <p className="card-title">Review output</p>
          <p>
            Our system checks the values of each row. If any issues are found,
            you can easily edit it by clicking on the cell.
          </p>
        </div>
      </div>
      <div className="vin-decoder-import-header-group">
        <div
          className={`vin-decoder-import-${
            lengthErrorData > 0 ? "warning" : "success"
          }-msg`}
        >
          {lengthErrorData > 0 ? <BiError /> : <IoCheckmarkDoneOutline />}
          {lengthErrorData > 0 ? (
            <div>
              There are {lengthErrorData} vehicles that contain missing values.
              Please update these values, or choose to ignore the empty entries.
            </div>
          ) : (
            <div>
              All vehicles have been successfully imported from vin decoder.
            </div>
          )}
        </div>
        <div className="flex-row-space-between">
          <ToggleSwitch
            labelLeft=""
            labelRight=""
            isToggled={showErrors}
            onToggle={() => setShowErrors(!showErrors)}
            disabled={lengthErrorData === 0}
          />
          <div>Display missing values</div>
        </div>
      </div>
    </div>
  );

  const dataToShow = (data) => {
    return (
      <>
        {data?.map((item, index) => {
          return (
            <div
              className={`vin-decoder-import-entries-table-group${
                index % 2 === 0 ? "-white" : ""
              }`}
              key={item?.uniqueId}
            >
              {vehSettingsOutputCols?.map((key, index) => {
                return (
                  <div
                    className={determineVinItemClassName(item[key])}
                    key={`${item?.uniqueId}-${index}`}
                  >
                    {item[key] || "N/A"}
                  </div>
                );
              })}
              <PiPencilLight onClick={() => handleClick(item)} />
            </div>
          );
        })}
      </>
    );
  };

  // Const: Button Group
  const importDataButtonGroup = (
    <div className="flex-end-button-group">
      <Button
        buttonName="Import Data"
        className="primary-blue-button"
        onClick={() => {
          setShowModal({
            edit: false,
            verify: true,
          });
        }}
      />
    </div>
  );

  return (
    <>
      {!single && vinOutputHeader}
      {updatedVehicleData?.length > 0 && (
        <div>
          {vinDecoderImportState?.loaded &&
          vinDecoderImportState?.length_data > 0 ? (
            <div className="vin-decoder-import-container-group">
              <div className="vin-decoder-import-entries-table-group">
                {vinDecoderLabelNames?.map((label, index) => {
                  return (
                    <p
                      className="vin-decoder-import-entries-table-label"
                      key={`${label}=${index}`}
                    >
                      {label}
                    </p>
                  );
                })}
              </div>
              <div className="vin-decoder-import-container-table-group">
                {dataToShow(
                  showErrors
                    ? vinDecoderImportState?.errorData
                    : vinDecoderImportState?.data
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {!single && importDataButtonGroup}
      <VinDecoderOutputEditModal
        handleModalClose={handleModalClose}
        selectedVehicleEdit={selectedVehicleEdit}
        setSelectedVehicleEdit={setSelectedVehicleEdit}
        showModal={showModal?.edit}
        yearValues={yearValues}
        setVinDecoderImportState={setVinDecoderImportState}
        vinDecoderImportState={vinDecoderImportState}
      />
      {!single && (
        <VinDecoderImportModalVerify
          handleModalClose={handleModalClose}
          showModal={showModal?.verify}
          handlePostVehicleSettings={handlePostVehicleSettings}
          setSelectedEdit={setSelectedEdit}
          setProcessStep={setProcessStep}
        />
      )}
    </>
  );
}
