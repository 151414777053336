// Functionality: API Call for Average Emissions Per Vehicle Tile
import axios from "../loadBalancerAxios";
import { VEHICLE_PERFORMANCE_DATA_URL } from "../paths";

export const getVehiclePerformanceDataAPI = async (
  setData,
  setFilterData = () => {},
  dateData,
  filterString,
  setOtherFilterData = () => {}
) => {
  await axios
    .get(`${VEHICLE_PERFORMANCE_DATA_URL}?date=${dateData}${filterString}`)
    .then((response) => {
      // Const: Post Data From Response
      const responseData = response?.data;

      const fillZero = 0;
      const reformattedData = responseData?.map((data) => {
        return {
          ...data,
          total_idle_time_hrs:
            data?.total_idle_time_hrs?.toFixed(2) || fillZero?.toFixed(2),
          total_emissions_mt:
            data?.total_emissions_mt?.toFixed(2) || fillZero?.toFixed(2),
          idle_time_emissions_by_fuel_co2_mt:
            data?.idle_time_emissions_by_fuel_co2_mt?.toFixed(2) ||
            fillZero?.toFixed(2),
          emissions_by_dist_ch4_mt:
            data?.emissions_by_dist_ch4_mt?.toFixed(3) || fillZero?.toFixed(2),
          emissions_by_dist_n2o_mt:
            data?.emissions_by_dist_n2o_mt?.toFixed(3) || fillZero?.toFixed(2),
          emissions_by_fuel_co2_mt:
            data?.emissions_by_fuel_co2_mt?.toFixed(2) || fillZero?.toFixed(2),
        };
      });

      setData({
        loaded: true,
        data: reformattedData,
      });
      setFilterData({
        loaded: true,
        data: reformattedData,
      });
      setOtherFilterData({
        loaded: true,
        data: reformattedData,
      });
    })
    .catch(() => {
      setData({
        loaded: true,
        data: [],
      });
    });
};
