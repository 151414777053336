// Functionality: API Call for Getting All Vehicle IDs for a specific company
import axios from "../loadBalancerAxios";
import { ALL_VEHICLES_URL } from "../paths";

export const getAllVehicles = async (setData) => {
  await axios
    .get(ALL_VEHICLES_URL)
    .then((response) => {
      // Get the response data
      const responseData = response?.data;

      // Reformat Data into correct styling
      const formattedData = responseData?.map((item) => {
        return {
          vehicle_id: item,
          vin: "",
        };
      });
      // Set the data
      setData({ data: formattedData, loaded: true });
    })
    .catch(() => {
      setData({ data: [], loaded: true });
    });
};
