// Functionality: API Call for Creating New Entry in Sustainable Solutions Marker
import axios from "../../axios";
import { SOLUTIONS_MARKER_NEW_ENTRY_URL } from "../../paths";

export const postSustainableSolutionsMarkerNewEntryAPI = async (
  data,
  setMsg
) => {
  await axios
    .post(SOLUTIONS_MARKER_NEW_ENTRY_URL, data)
    .then(() => {
      setMsg({ text: "Successfully submitted new entry.", msg: "success" });
    })
    .catch(() => {
      setMsg({ text: "Failed to submit new entry.", msg: "warning" });
    });
};
