// Functionality: This file exports the EnergyReport component which is used in the Page component.
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import ReportContext from "../../../context/ReportsProvider";
import SelectDropdown from "../../../components/SelectDropdown";
import {
  reportQuestionConfig,
  energyRelatedActivitiesDropdownQuestions,
  yesNoOtherDropdownOptions,
  energyOptions,
} from "../../../constants/ReportsConfig";

export default function EnergyReport({ generateQuestionGroup }) {
  // Const: Initialize Report Context
  const { energyDataConfig, setEnergyDataConfig } = useContext(ReportContext);
  const [energyClicked, setEnergyClicked] = useState(false);

  // Function: Handle Dropdown Change
  const handleDropdownChange = (index, value) => {
    const newEnergyDropdown = { ...energyDataConfig };
    newEnergyDropdown[energyOptions[index]] = [value];
    setEnergyDataConfig(newEnergyDropdown);
    setEnergyClicked(false);
  };

  // Const: Energy Dropdown Group
  const energyDropdownGroup = energyRelatedActivitiesDropdownQuestions?.map(
    (item, index) => {
      return (
        <div key={index} className="energy-dropdown-container-group">
          <p>{item}</p>
          <SelectDropdown
            options={yesNoOtherDropdownOptions?.slice(0, 2)}
            selectedOptions={energyDataConfig[energyOptions[index]]}
            onChange={(e) => handleDropdownChange(index, e)}
          />
        </div>
      );
    }
  );

  // Dictionary: UI/UX for each of the questions, in order
  const questionGroupConfig = {
    0: <>{energyDropdownGroup}</>,
  };

  return (
    <>
      {generateQuestionGroup(reportQuestionConfig?.energy, questionGroupConfig)}
    </>
  );
}

// Props
const energyReportPropTypes = {
  generateQuestionGroup: PropTypes.func, // Function: Generates the UI/UX for each question
};

EnergyReport.propTypes = energyReportPropTypes;
