// Functionality: Vehicle Vin Decoder Upload File, Dynamic Component For Initial Upload and Failed Entries Re-Upload
import Button from "../../../components/Button";
import { IoCloudUploadOutline } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";
import VinDecoderDownloadFile from "./VinDecoderDownloadFile";
import { fileUploadLineDict } from "../../../constants/SettingsConfig";

export default function VinDecoderUploadFile({
  fileReader,
  csvFileToArray,
  setProcessStep,
  vehicleSettingsData,
  reUpload = false,
  vinFileUploadState,
  setVinFileUploadState,
  allVehiclesData,
  setAllVehiclesData,
  ...props
}) {
  // Const: File Reader
  const handleOnChange = (e) => {
    // Display the File Name
    const selectedFile = e.target.files[0];

    // Update file selection status
    setVinFileUploadState({
      ...vinFileUploadState,
      file: selectedFile,
      isFileSelected: !!selectedFile,
    });
  };

  //  Const: Initialize File Reader
  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (vinFileUploadState?.file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(vinFileUploadState?.file);
      setProcessStep(1);
    } else {
      alert("No file selected.");
    }
  };

  const buttonNextGroup = (
    <div className="flex-end-button-group">
      <Button
        onClick={(e) => {
          handleOnSubmit(e);
        }}
        buttonName="Continue to Review Upload"
        className="primary-blue-button"
        disabled={!vinFileUploadState?.isFileSelected}
      />
    </div>
  );
  return (
    <>
      {!reUpload && (
        <div className="flex-row-space-between vin-decoder-download-template-header-container">
          <p>
            This tool enables you to upload a CSV file of vehicle data (vehicle
            id and vin) to decode the VINs and populate the vehicle data in the
            system.
          </p>
          {buttonNextGroup}
        </div>
      )}
      <div className="vin-decoder-upload-file-page-content">
        <VinDecoderDownloadFile
          vehicleSettingsData={allVehiclesData?.data}
          labeling={props.labeling}
        />
        <div className="vin-decoder-upload-csv-container ">
          <IoCloudUploadOutline />
          <p>Drag and Drop</p>
          <div className="vin-decoder-upload-message-group">
            <hr style={fileUploadLineDict} />
            <div>OR</div>
            <hr style={fileUploadLineDict} />
          </div>
          <label htmlFor="csvFileInput" className="primary-blue-button">
            Choose File
          </label>
          <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
            className="primary-blue-button"
          />
        </div>
        <p className="vin-decoder-selected-file-note">Supported format: CSV</p>
        <span
          id="fileName"
          className={
            vinFileUploadState?.isFileSelected
              ? "vin-decoder-selected-file file-selected"
              : "vin-decoder-selected-file file-not-selected"
          }
        >
          <SiMicrosoftexcel />
          {vinFileUploadState?.isFileSelected
            ? vinFileUploadState?.file.name
            : "No file chosen"}
        </span>
      </div>
    </>
  );
}
