// Functionality: This file exports the Scope2EmptyState component which is used in the Scope2Manager component. The component is a placeholder for the empty state view for Scope 2 emissions manager.

import greenDesktop from "../../../assets/greenirr_desktop.png";
import Scope2PreviousSubmissionsModals from "./PreviousSubmissions/Scope2PreviousSubmissionsModals";

export default function Scope2EmptyState({
  scope2ManagerNewEntry,
  scope2ManagerAddressOptions,
  handleInputChange,
  handleDropdownChange,
  handleSelectedDate,
  resetScope2ManagerFields,
  submitNewScope2Emission,
  submitConditionHandler,
  handleNewAddressEntry,
  submitNewScope2Address,
  submitConditionHandlerAddress,
  nonEmptyStateCondition,
  stateOptionsConfig,
  selectedStateScope,
  setSelectedStateScope,
  scope2ManagerNewAddress,
}) {
  const completeEmptyState = (
    <div className="scope2-empty-state">
      <img src={greenDesktop} alt="desktop" />
      <div className="scope2-empty-state-header">
        Start tracking your Scope 2 Emissions!
      </div>
      <p>
        Before we can record any entries, we'll need you to enter some site
        location details.
      </p>
      <Scope2PreviousSubmissionsModals
        scope2ManagerAddressOptions={scope2ManagerAddressOptions}
        handleInputChange={handleInputChange}
        handleDropdownChange={handleDropdownChange}
        handleSelectedDate={handleSelectedDate}
        resetScope2ManagerFields={resetScope2ManagerFields}
        submitNewScope2Emission={submitNewScope2Emission}
        submitConditionHandler={submitConditionHandler}
        scope2ManagerNewAddress={scope2ManagerNewAddress}
        handleNewAddressEntry={handleNewAddressEntry}
        submitNewScope2Address={submitNewScope2Address}
        submitConditionHandlerAddress={submitConditionHandlerAddress}
        nonEmptyStateCondition={nonEmptyStateCondition}
        stateOptionsConfig={stateOptionsConfig}
        selectedStateScope={selectedStateScope}
        setSelectedStateScope={setSelectedStateScope}
        scope2ManagerNewEntry={scope2ManagerNewEntry}
        showEntry={false}
      />
    </div>
  );
  const partialEmptyState = (
    <div className="scope2-empty-state">
      <img src={greenDesktop} alt="desktop" />
      <div className="scope2-empty-state-header">
        Start tracking your Scope 2 Emissions!
      </div>
      <p>
        Begin entering electricity usage and manage your site locations below,
        to get started.
      </p>
      <Scope2PreviousSubmissionsModals
        scope2ManagerAddressOptions={scope2ManagerAddressOptions}
        handleInputChange={handleInputChange}
        handleDropdownChange={handleDropdownChange}
        handleSelectedDate={handleSelectedDate}
        resetScope2ManagerFields={resetScope2ManagerFields}
        submitNewScope2Emission={submitNewScope2Emission}
        submitConditionHandler={submitConditionHandler}
        scope2ManagerNewAddress={scope2ManagerNewAddress}
        handleNewAddressEntry={handleNewAddressEntry}
        submitNewScope2Address={submitNewScope2Address}
        submitConditionHandlerAddress={submitConditionHandlerAddress}
        nonEmptyStateCondition={nonEmptyStateCondition}
        stateOptionsConfig={stateOptionsConfig}
        selectedStateScope={selectedStateScope}
        setSelectedStateScope={setSelectedStateScope}
        scope2ManagerNewEntry={scope2ManagerNewEntry}
      />
    </div>
  );
  return (
    <>
      {!nonEmptyStateCondition && completeEmptyState}
      {nonEmptyStateCondition && partialEmptyState}
    </>
  );
}
