// Functionality: React hook to find the year change in an array of dates

export default function useFindYearChange(dateArray) {
  for (let i = 0; i < dateArray.length - 1; i++) {
    const currentYear = dateArray[i].substring(0, 4); // Extracting the year from the date
    const nextYear = dateArray[i + 1].substring(0, 4); // Extracting the year from the next date
    if (currentYear !== nextYear) {
      return i + 1; // Returning the index where the year changes
    }
  }
  return -1; // If no year change found
}
