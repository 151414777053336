// Functionality: This file exports the Scope2PreviousSubmissionsModal component which is used in the Scope2Manager component. The component is a modal for the previous submissions for Scope 2 emissions manager, including new entry and new address modals (address Manager).
import { useState } from "react";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import Scope2NewAddress from "../FormFields/Scope2NewAddress";
import Scope2NewEntry from "../FormFields/Scope2NewEntry";
import { isScope2ManagerAddressManager } from "../../../../constants/initialDataConfig";

export default function Scope2PreviousSubmissionsModal({
  showEntry = true,
  ...props
}) {
  // State: New Entry Modal
  const [showModal, setShowModal] = useState({
    new_entry: false,
    site_location_manager: false,
  });

  // State: Selected Vehicle Edit Delete
  const [selectedLocationEditDelete, setSelectedLocationEditDelete] = useState(
    isScope2ManagerAddressManager
  );

  // Function: Handle Click to Open Modal
  const handleClick = (type) => {
    setShowModal({ ...showModal, [type]: true });
  };
  // Function: Handle Modal Close
  const handleModalClose = (type) => {
    setShowModal({ ...showModal, [type]: false });
    setSelectedLocationEditDelete(isScope2ManagerAddressManager);
  };

  // Const Scope 2 New Address Modal
  const scope2NewAddressModal = (
    <Modal
      onClose={() => handleModalClose("site_location_manager")}
      modalDivClassName="large-modal-div"
      children={
        <Scope2NewAddress
          resetScope2ManagerFields={props.resetScope2ManagerFields}
          scope2ManagerNewAddress={props.scope2ManagerNewAddress}
          handleNewAddressEntry={props.handleNewAddressEntry}
          submitNewScope2Address={props.submitNewScope2Address}
          submitConditionHandlerAddress={props.submitConditionHandlerAddress}
          nonEmptyStateCondition={props.nonEmptyStateCondition}
          stateOptionsConfig={props.stateOptionsConfig}
          selectedStateScope={props.selectedStateScope}
          setSelectedStateScope={props.setSelectedStateScope}
          scope2ManagerAddressOptions={props.scope2ManagerAddressOptions}
          selectedLocationEditDelete={selectedLocationEditDelete}
          setSelectedLocationEditDelete={setSelectedLocationEditDelete}
          setSuccessMsg={props.setSuccessMsg}
          handleModalClose={handleModalClose}
        />
      }
    />
  );
  // Const Scope 2 New Entry Modal
  const scope2NewEntryModal = (
    <Modal
      onClose={() => handleModalClose("new_entry")}
      modalDivClassName="large-modal-div"
      children={
        <Scope2NewEntry
          scope2ManagerNewEntry={props.scope2ManagerNewEntry}
          scope2ManagerAddressOptions={props.scope2ManagerAddressOptions}
          handleInputChange={props.handleInputChange}
          handleDropdownChange={props.handleDropdownChange}
          handleSelectedDate={props.handleSelectedDate}
          resetScope2ManagerFields={props.resetScope2ManagerFields}
          submitNewScope2Emission={props.submitNewScope2Emission}
          submitConditionHandler={props.submitConditionHandler}
        />
      }
    />
  );
  return (
    <>
      <div className="flex-end-button-group">
        <Button
          buttonName={
            showEntry ? "+ Site Location Manager" : "Add Site Location"
          }
          className="secondary-blue-button"
          onClick={() => handleClick("site_location_manager")}
        />
        {showEntry && (
          <Button
            buttonName="+ New Entry"
            className="primary-blue-button"
            onClick={() => handleClick("new_entry")}
          />
        )}
      </div>
      {showModal?.new_entry && scope2NewEntryModal}
      {showModal?.site_location_manager && scope2NewAddressModal}
    </>
  );
}
