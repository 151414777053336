// Functionality: Component to display when no records are found in the initial upload.
export default function NoInitialRecords({ BiError, failedDecoderState }) {
  // Const: Failed Records Summary
  const noRecordsSummary = (
    <>
      <div className="vin-decoder-failed-entries-group">
        <div className="vin-decoder-failed-entries-subgroup">
          <div className="vin-decoder-failed-entries-group-header">
            <BiError />
            <p>
              You are attempting to import {failedDecoderState?.failed_length}{" "}
              records. To run VIN Decoder, you must have at least one record to
              import.
            </p>
          </div>
        </div>
        <div className="vin-decoder-failed-entries-subgroup-content">
          <p>
            Please go back to the previous step and upload a file with at least
            one record.
          </p>
        </div>
      </div>
    </>
  );
  return <>{noRecordsSummary}</>;
}
