// This file serves as a central location for all configuration variables for the Industry Carbon Rating

// Industry Carbon Rating Title
export const industryCarbonRatingTitle = "Industry Carbon Rating";

// The following variables are used to configure the text displayed below the ICR Gauge Visualization
export const performanceKeyLanguage = {
  0: `Significantly Poor`,
  20: `Poor`,
  40: `Average`,
  60: `Above Average`,
  80: `Significantly Above Average`,
  100: `Significantly Above`,
};

// Color Gradient For Gauge
export const colorGradientICR = [
  "rgba(255, 31, 46, 1)", // Red
  "rgba(249, 127, 61, 1)", // Orange
  "rgba(244, 224, 77, 1)", // Yellow
  "rgba(11, 203, 179, 1)", // Yellow-green-blue
  "rgba(0, 166, 63, 1)", // Green
];
