// Functionality: API Call for gathering inputted Site Locations by user
import axios from "../../loadBalancerAxios";
import { SCOPE_2_MANAGER_LOCATION_URL } from "../../paths";

export const getSiteLocation = async (setData) => {
  await axios
    .get(SCOPE_2_MANAGER_LOCATION_URL)
    .then((response) => {
      // Response Data
      const responseData = response?.data;

      // Reformat Data
      const reformattedData = responseData?.map((item) => {
        return {
          // Label: Site Name - Street Address City, State Zip Code
          label: `${item?.site_name} - ${item?.street_address} ${item?.city}, ${item?.state} ${item?.zip_code}`,
          value: item,
        };
      });

      setData({ loaded: true, data: reformattedData });
    })
    .catch(() => {
      setData({ loaded: true, data: [] });
    });
};
