// Functionality: API Call for Average Emissions Per Vehicle Tile
import axios from "../loadBalancerAxios";
import { REPORTS_URL } from "../paths";
import {
  financialPlanningImpactPandSOptions,
  financialPlanningImpactInvestmentOptions,
  financialPlanningImpactOperationsOptions,
  relevantScenariosParametersAssumptionsOptions,
  downloadReportsMonthDropdownOptions,
  reportAutoFillAPISectioning,
  reportsInitialStateFill,
} from "../../constants/ReportsConfig";

export const getReportAutoFill = async (
  setDateTypeData,
  setDateData,
  setIntroData,
  setRiskData,
  setStrategyData,
  setTargetData,
  setEnergyData,
  setChallengesData,
  setSelectedCountryOfOperation
) => {
  await axios
    .get(REPORTS_URL)
    .then((response) => {
      // Const: Response Data
      const responseData = response?.data;

      // Set Date Type Data
      const newDateTypeData = responseData?.["dateRanges"];
      const outputObj = reportsInitialStateFill?.date;
      Object.keys(newDateTypeData).forEach((key) => {
        if (newDateTypeData[key] !== false && key !== "custom") {
          if (["year", "qtd"]?.includes(key)) {
            outputObj[key].push({
              label: newDateTypeData[key],
              value:
                key === "year"
                  ? parseInt(newDateTypeData[key])
                  : newDateTypeData[key],
            });
          } else if (["month"]?.includes(key)) {
            const monthValueToCheck = newDateTypeData[key];
            const monthString =
              typeof newDateTypeData[key] === "number"
                ? JSON?.stringify(monthValueToCheck)
                : monthValueToCheck;
            const monthStringCheck =
              monthString?.length === 2 ? monthString : `0${monthString}`;
            const monthMapping = downloadReportsMonthDropdownOptions.filter(
              (month) => month?.value === monthStringCheck
            );
            outputObj[key].push(monthMapping?.[0]);
          } else {
            outputObj[key] = [];
          }
        } else if (key === "custom" && newDateTypeData[key] !== false) {
          outputObj[key] = newDateTypeData[key];
        }
      });
      setDateTypeData(outputObj);

      // Const: Reverse Date Type Data to get the Selected Date Types in Array
      const reverseDateTypeData = Object?.keys(
        Object.fromEntries(
          Object.entries(newDateTypeData).filter(
            ([key, value]) => value !== false
          )
        )
      );
      // Function: Function to get the actual date type from the selected date type
      const getDateType = (dateType) => {
        if (dateType?.length > 1) {
          if (dateType?.includes("month") && dateType?.includes("year")) {
            return "Month";
          } else if (dateType?.includes("qtd") && dateType?.includes("year")) {
            return "Quarter";
          }
        } else {
          if (dateType?.[0] === "ytd") {
            return "YTD";
          } else if (dateType?.[0] === "custom") {
            return "Custom";
          } else if (dateType?.[0] === "year") {
            return "Year";
          }
        }
      };
      setDateData(getDateType(reverseDateTypeData));

      // Set Intro Data
      const introKeys = reportAutoFillAPISectioning?.intro;

      const newIntroData = introKeys.reduce((obj, key) => {
        if (key === "board_level_oversight") {
          obj[key] = {
            radio: responseData[key]?.dropdown?.value,
            textArea: responseData[key]?.textArea,
          };
        } else if (key === "countries_operation") {
          const countries = responseData[key]?.textArea
            ?.split(", ")
            ?.map((item) => {
              return {
                label: item,
                value: item,
              };
            });
          obj[key] = {
            dropdown: countries,
          };
          setSelectedCountryOfOperation(countries);
        } else if (key === "approval") {
          const approvalLabels = responseData[key]?.input?.split(" - ");
          obj[key] = {
            dropdown: responseData[key]?.dropdown,
            input: approvalLabels?.[0] || "",
            input2: approvalLabels?.[1] || "",
          };
        } else if (responseData.hasOwnProperty(key)) {
          obj[key] = responseData[key];
        } else if (!responseData.hasOwnProperty(key)) {
          obj[key] = reportsInitialStateFill?.intro[key];
        }
        return obj;
      }, {});

      setIntroData(newIntroData);

      // Set Risk Data
      const riskKeys = reportAutoFillAPISectioning?.risk;

      // Risk Checklist Data Needs to be Refactored, Remove Values that not a checklist, and place as textArea and check Other
      // Risk Checklist Options
      const riskChecklistOptions = financialPlanningImpactPandSOptions
        ?.concat(financialPlanningImpactInvestmentOptions)
        ?.concat(financialPlanningImpactOperationsOptions);

      const newRiskData = riskKeys.reduce((obj, key) => {
        // Checklist in Sections: products_and_services, investment, operations
        if (riskKeys?.slice(-3)?.includes(key)) {
          // Filter the options based on the checklist
          const filteredOptions = responseData[key]?.checkbox.filter((item) =>
            riskChecklistOptions.includes(item)
          );
          // Filter the options that are not in the checklist
          const textAreaOptions = responseData[key]?.checkbox.filter(
            (item) => !riskChecklistOptions.includes(item)
          );
          // Add Other to the filtered options if there are textAreaOptions
          const addOtherFilteredOptions =
            textAreaOptions?.length > 0
              ? filteredOptions?.concat(["Other"])
              : filteredOptions;

          obj[key] = {
            checkbox: addOtherFilteredOptions,
            textArea: textAreaOptions?.[0] || "",
          };
        } else if (responseData.hasOwnProperty(key)) {
          obj[key] = responseData[key];
        } else if (!responseData.hasOwnProperty(key)) {
          obj[key] = reportsInitialStateFill?.risk[key];
        }
        return obj;
      }, {});
      setRiskData(newRiskData);

      // Set Strategy Data
      const strategyKeys = reportAutoFillAPISectioning?.strategy;
      // Strategy Checklist Data Needs to be Refactored, Remove Values that not a checklist, and place as textArea and check Other
      // Strategy Options
      const strategyChecklistOptions =
        relevantScenariosParametersAssumptionsOptions?.scenarios
          ?.concat(relevantScenariosParametersAssumptionsOptions?.parameters)
          ?.concat(relevantScenariosParametersAssumptionsOptions?.assumptions);
      const newStrategyData = strategyKeys.reduce((obj, key) => {
        if (strategyKeys?.slice(-3)?.includes(key)) {
          // Filter the options based on the checklist
          const filteredOptions = responseData[key]?.checkbox.filter((item) =>
            strategyChecklistOptions?.includes(item)
          );
          // Filter the options that are not in the checklist
          const textAreaOptions = responseData?.[key]?.checkbox.filter(
            (item) => !strategyChecklistOptions?.includes(item)
          );
          // Add Other to the filtered options if there are textAreaOptions
          const addOtherFilteredOptions =
            textAreaOptions?.length > 0
              ? filteredOptions?.concat(["Other"])
              : filteredOptions;

          obj[key] = {
            checkbox: addOtherFilteredOptions,
            textArea: textAreaOptions?.[0] || "",
          };
        } else if (responseData.hasOwnProperty(key)) {
          obj[key] = responseData[key];
        } else if (!responseData.hasOwnProperty(key)) {
          obj[key] = reportsInitialStateFill?.strategy[key];
        }
        return obj;
      }, {});
      setStrategyData(newStrategyData);

      // Set Target Data
      const targetKeys = reportAutoFillAPISectioning?.target;
      const newTargetData = targetKeys.reduce((obj, key) => {
        if (responseData.hasOwnProperty(key)) {
          obj[key] = responseData[key];
        } else if (!responseData.hasOwnProperty(key)) {
          obj[key] = reportsInitialStateFill?.target[key];
        }
        return obj;
      }, {});
      setTargetData(newTargetData);
      // Set Energy Data
      const energyKeys = reportAutoFillAPISectioning?.energy;

      const newEnergyData = energyKeys.reduce((obj, key) => {
        if (responseData.hasOwnProperty(key)) {
          obj[key] = responseData[key];
        } else if (!responseData.hasOwnProperty(key)) {
          obj[key] = reportsInitialStateFill?.energy[key];
        }
        return obj;
      }, {});
      setEnergyData(newEnergyData);

      // Set Challenges Data
      const challengesKeys = reportAutoFillAPISectioning?.challenges;
      const newChallengesData = challengesKeys.reduce((obj, key) => {
        if (responseData.hasOwnProperty(key)) {
          obj[key] = responseData[key];
        } else if (!responseData.hasOwnProperty(key)) {
          obj[key] = reportsInitialStateFill?.challenges[key];
        }
        return obj;
      }, {});
      setChallengesData(newChallengesData);

      // Set Custom Data
    })
    .catch(() => {});
};
