// Functionality: This is the VehiclePerformanceOverviewTab component of VehiclePerformancePage.
import AverageEmissionsPerVehicle from "./Overview/AverageEmissionsPerVehicleCard";
import EmissionsPerVehicleCard from "./Overview/EmissionsPerVehicleCard";
import IdleTimeEmissionsCard from "./Overview/IdleTimeEmissionsCard";
import TotalIdleTimeCard from "./Overview/TotalIdleTimeCard";

export default function VehiclePerformanceOverviewTab({
  dateValues,
  applicationDates,
  setApplicationDates,
  notMockUser,
}) {
  return (
    <>
      <div id="vp-page-container-group1">
        <EmissionsPerVehicleCard
          dateValues={dateValues}
          applicationDates={applicationDates}
          setApplicationDates={setApplicationDates}
        />
        <AverageEmissionsPerVehicle
          dateValues={dateValues}
          applicationDates={applicationDates}
          setApplicationDates={setApplicationDates}
        />
      </div>
      {notMockUser ? (
        <>
          <div id="vp-page-container-group2">
            <TotalIdleTimeCard
              dateValues={dateValues}
              applicationDates={applicationDates}
              setApplicationDates={setApplicationDates}
            />

            <IdleTimeEmissionsCard
              dateValues={dateValues}
              applicationDates={applicationDates}
              setApplicationDates={setApplicationDates}
            />
          </div>
        </>
      ) : (
        <div id="vp-page-container-group2">
          <TotalIdleTimeCard
            dateValues={dateValues}
            applicationDates={applicationDates}
            setApplicationDates={setApplicationDates}
          />
          <IdleTimeEmissionsCard />
        </div>
      )}
    </>
  );
}
