// Functionality: This file exports the NewReportComponent page component which is used in the Report Page component.
import { useEffect, useContext, useState, useRef } from "react";
import ReportContext from "../../context/ReportsProvider";
import DateContext from "../../context/DateProvider";
import PropTypes from "prop-types";
import NewReportsTitleGroup from "./ReportBreakdown/NewReportsTitleGroup";
import NewReportsFooterGroup from "./ReportBreakdown/NewReportsFooterGroup";
import ReportsSideBar from "./ReportsSideBar";
import Button from "../../components/Button";
import { TfiDownload } from "react-icons/tfi";
import IntroReport from "./ReportBreakdown/IntroReport";
import RiskReport from "./ReportBreakdown/RiskReport";
import TargetReport from "./ReportBreakdown/TargetReport";
import StrategyReport from "./ReportBreakdown/StrategyReport";
import EnergyReport from "./ReportBreakdown/EnergyReport";
import ChallengesReport from "./ReportBreakdown/ChallengesReport";
import { postNewReportAPI } from "../../api/Reports/NewReportAPI";
import LoadingSpinner from "../../components/LoadingSpinner";
import SnackBar from "../../components/SnackBar";

export default function NewReportComponent({
  generateQuestionGroup,
  notMockUser,
}) {
  // Context: Report Context
  const {
    radioValueDateType,
    introDataConfig,
    riskDataConfig,
    targetDataConfig,
    strategyDataConfig,
    energyDataConfig,
    challengesDataConfig,
    selectedValueDateType,
    reportDataFormat,
    selectedReportSection,
    setSelectedReportSection,
    reportInputClassName,
    setReportInputClassName,
  } = useContext(ReportContext);

  // Stat
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState({ text: "", msg: "" });

  // Const: Initialize Date Context
  const { yearValues, monthValues, quarterValues, dateValues } =
    useContext(DateContext);

  // Ref: Reference to the Scrollable Component to Reset the scrollbar
  const scrollableContainerRef = useRef(null);

  // UE: Reset Scroll Position to Top Whenever the Active Tab Changes
  useEffect(() => {
    // Reset scroll position to top whenever the active tab changes
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTo(0, 0);
    }
  }, [selectedReportSection]);

  // UE: Reset the Success Message After 5 Seconds
  useEffect(() => {
    if (successMsg?.text?.length > 0) {
      setTimeout(() => {
        setSuccessMsg({ text: "", msg: "" });
      }, 5000);
    }
  }, [successMsg]);

  // Function: Handle Change For any UI/UX Components
  const handleReportEventChange = (e, type, label, setData, currentData) => {
    const dataType = (event, label) => {
      if (label === "input") {
        return event?.target?.value;
      } else if (label === "input2") {
        return event?.target?.value;
      } else if (label === "dropdown") {
        return event;
      } else if (label === "textArea") {
        return event?.target?.value;
      } else if (label === "radio") {
        return event?.target?.value;
      } else if (label === "checkbox") {
        const updatedOptions = currentData?.[type]?.checkbox.includes(
          event.value
        )
          ? currentData?.[type]?.[label].filter(
              (selectedOption) => selectedOption !== event.value
            )
          : [...currentData?.[type]?.[label], event.value];
        return updatedOptions;
      }
      return dataType(e);
    };
    setData({
      ...currentData,
      [type]: {
        ...currentData?.[type],
        [label]: dataType(e, label),
      },
    });
  };

  // Function: Calculate Completion Percentage For Each Section
  const calculateCompletionPercentage = (
    obj,
    energy = false,
    add = 0,
    isStrategy = false
  ) => {
    let totalQuestions = 0;
    let completeQuestions = 0;

    for (let key in obj) {
      const question = obj[key];
      let isComplete = false;

      // Check if at least one answer for the question is complete
      for (let answerKey in question) {
        const answerValue = question[answerKey];
        if (Array.isArray(answerValue) && answerValue.length > 0) {
          isComplete = true;
          break;
        } else if (
          typeof answerValue === "string" &&
          answerValue.trim() !== ""
        ) {
          if (["Other", "Yes"]?.includes(answerValue)) {
            if (answerValue === "Yes" && isStrategy) {
              isComplete = true;
              break;
            }
            if (question?.textArea?.length > 0) {
              isComplete = true;
              break;
            }
            break;
          } else {
            isComplete = true;
            break;
          }
        } else if (
          typeof answerValue === "object" &&
          Object.keys(answerValue)?.length > 1
        ) {
          if (answerValue?.dropdown?.value?.length > 0) {
            isComplete = true;
            break;
          } else if (answerValue?.value?.length > 0 && !energy) {
            if (answerValue?.value === "Other") {
              if (question?.input?.length > 0) {
                if (question?.input2?.length > 0) {
                  isComplete = true;
                  break;
                }
              }
              break;
            } else {
              isComplete = true;
              break;
            }
          }
        }
      }

      // If the question has a complete answer, increment completeQuestions counter
      if (isComplete) {
        completeQuestions++;
      }

      // Increment totalQuestions counter for each question
      totalQuestions++;
    }

    // Calculate completion percentage
    const addOne = completeQuestions === 0 ? 0 : add;

    const completionPercentage =
      ((completeQuestions + addOne) / totalQuestions) * 100;

    return completionPercentage.toFixed(2) > 100
      ? 100
      : completionPercentage.toFixed(2); // Return percentage with two decimal places
  };

  // Const: Download Report Submit Button Name
  const downloadReportButtonName = (
    <div className="download-reports-icon-group">
      {!downloadIsLoading ? (
        <>
          <p>Download Report</p>
          <TfiDownload />
        </>
      ) : (
        <div>
          <LoadingSpinner
            spinnerContainerClassName="download-reports-loading-spinner-container"
            strokeColor="white"
            strokeWidth="3"
            animationDuration="1"
          />
        </div>
      )}
    </div>
  );

  // Const: Handle Download Report Submit Button
  const handleDownloadClick = (e) => {
    const sumFields =
      parseInt(calculateCompletionPercentage(introDataConfig)) +
      parseInt(calculateCompletionPercentage(riskDataConfig, false, 1)) +
      parseInt(calculateCompletionPercentage(targetDataConfig, false, 1)) +
      parseInt(
        calculateCompletionPercentage(strategyDataConfig, false, 0, true)
      ) +
      parseInt(calculateCompletionPercentage(energyDataConfig, false)) +
      parseInt(calculateCompletionPercentage(challengesDataConfig)) +
      parseInt(
        Object.values(selectedValueDateType).filter((item) => {
          return item?.length > 0;
        })?.length
      );
    setReportInputClassName({
      input: "input-report-element",
      textArea: "text-area-report-element",
    });
    if (
      radioValueDateType === "Year" &&
      selectedValueDateType?.year?.length < 1
    ) {
      e.preventDefault();
      alert("Please enter a valid date before downloading the report.");
    } else if (
      radioValueDateType === "Month" &&
      (selectedValueDateType?.year?.length < 1 ||
        selectedValueDateType?.month?.length < 1)
    ) {
      e.preventDefault();
      alert("Please enter a valid date before downloading the report.");
    } else if (
      radioValueDateType === "Quarter" &&
      (selectedValueDateType?.year?.length < 1 ||
        selectedValueDateType?.qtd?.length < 1)
    ) {
      e.preventDefault();
      alert("Please enter a valid date before downloading the report.");
    } else if (
      radioValueDateType === "Custom" &&
      Object.values(selectedValueDateType?.custom)?.length < 2
    ) {
      e.preventDefault();
      alert("Please enter a valid date before downloading the report.");
    } else if (sumFields < 600) {
      e.preventDefault();
      alert("Please complete all fields before downloading the report.");
    } else {
      if (notMockUser) {
        setDownloadIsLoading(true);
        postNewReportAPI(reportDataFormat, setDownloadIsLoading, setSuccessMsg);
      }
    }
  };

  // Const: Download Report Submit Button
  const downloadReportSubmitButton = (
    <div className="sidebar-button-center">
      <Button
        buttonName={downloadReportButtonName}
        className={"submit-button"}
        onClick={handleDownloadClick}
        disabled={downloadIsLoading}
      />
    </div>
  );
  return (
    <>
      <div className="report-page-container">
        <div className="reports-sidebar-container">
          <ReportsSideBar
            calculateCompletionPercentage={calculateCompletionPercentage}
            downloadReportSubmitButton={downloadReportSubmitButton}
          />
        </div>
        <div className="reports-container-group">
          <NewReportsTitleGroup
            yearValues={yearValues}
            selectedReportSection={selectedReportSection}
            monthValues={monthValues}
            quarterValues={quarterValues}
            dateValues={dateValues}
          />
          <div
            className="standard-reports-container-group"
            ref={scrollableContainerRef}
          >
            {selectedReportSection === "Introduction" && (
              <IntroReport
                generateQuestionGroup={generateQuestionGroup}
                handleReportEventChange={handleReportEventChange}
                yearValues={yearValues}
                reportInputClassName={reportInputClassName}
              />
            )}
            {selectedReportSection === "Risks & Opportunities" && (
              <RiskReport
                generateQuestionGroup={generateQuestionGroup}
                handleReportEventChange={handleReportEventChange}
                reportInputClassName={reportInputClassName}
              />
            )}
            {selectedReportSection === "Strategy" && (
              <StrategyReport
                generateQuestionGroup={generateQuestionGroup}
                handleReportEventChange={handleReportEventChange}
                reportInputClassName={reportInputClassName}
              />
            )}
            {selectedReportSection === "Targets & Reduction" && (
              <TargetReport
                generateQuestionGroup={generateQuestionGroup}
                handleReportEventChange={handleReportEventChange}
                reportInputClassName={reportInputClassName}
              />
            )}
            {selectedReportSection === "Energy" && (
              <EnergyReport generateQuestionGroup={generateQuestionGroup} />
            )}
            {selectedReportSection === "Challenges" && (
              <ChallengesReport
                generateQuestionGroup={generateQuestionGroup}
                handleReportEventChange={handleReportEventChange}
                reportInputClassName={reportInputClassName}
              />
            )}
            {/* {selectedReportSection === "Additional Features" && (
            <CustomFeaturesReport />
          )} */}
          </div>
          <NewReportsFooterGroup
            selectedReportSection={selectedReportSection}
            setSelectedReportSection={setSelectedReportSection}
          />
        </div>
      </div>
      <SnackBar
        message={successMsg?.text}
        visible={successMsg?.text?.length > 0}
        severity={successMsg?.msg}
      />
    </>
  );
}

// Props
const newReportPropTypes = {
  generateQuestionGroup: PropTypes.func, // Function: Generates the UI/UX for each question
  notMockUser: PropTypes.bool, // Boolean: Determines if the user is a mock user
};

NewReportComponent.propTypes = newReportPropTypes;
