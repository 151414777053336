// Functionality:  Input Fields & Submit Button for Forgot Password Page
import PropTypes from "prop-types";
import { authFields } from "../../constants/AuthConfig";
import Input from "../../components/Input";

// Refactor Forgot PW Fields to be in correct format for Inputs
const fields = authFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function ForgotPasswordFields({
  setLoginErrMsg,
  forgotPWState,
  setForgotPWState,
}) {
  // Function: Handle State Change of Input Fields
  const handleChange = (e) => {
    e.preventDefault();
    setLoginErrMsg("");
    setForgotPWState({ ...forgotPWState, [e.target.id]: e.target.value });
  };

  // Const: Develop Login Input Fields for email & password
  const forgotPWInputFields = fields
    ?.slice(0, 1)
    ?.map((field) => (
      <Input
        key={field.id}
        onChange={handleChange}
        value={forgotPWState[field.id]}
        labelFor={field.labelFor}
        id={field.id}
        type={field.type}
        isRequired={field.isRequired}
        placeholder={field.placeholder}
        label={<p>{field?.labelText}</p>}
        divClassName="forgotpw-input-main-container"
        className="forgotpw-input-element font-small-medium"
      />
    ));

  return <>{forgotPWInputFields}</>;
}

// Props
const forgotPWFieldsPropTypes = {
  setLoginErrMsg: PropTypes.func, // Function to set the error message
  showForgotPW: PropTypes.bool, // State of the forgot password form
  forgotPWState: PropTypes.object, // State of the forgot password fields
  setForgotPWState: PropTypes.func, // Function to set the state of the forgot password fields
};

ForgotPasswordFields.propTypes = forgotPWFieldsPropTypes;
