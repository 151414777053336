import { createContext, useState } from "react";
import useQueryParams from "../hooks/use-queryParams";

const DemoOnboardContext = createContext({});

// Provides access to token across whole application
export const DemoOnboardProvider = ({ children }) => {
  // Get the query parameters from the URL for the username
  const query = useQueryParams();
  const queryEmail = query.get("email");

  // State: Initialize State - Full Application
  const [demoSetupState, setDemoSetupState] = useState({
    email: queryEmail || "",
    user_name: queryEmail || "",
    password: "",
    confirm_password: "",
  });

  // State: Initialize State - Developer Services Checkbox
  const [developerServicesCheckbox, setDeveloperServicesCheckbox] = useState(
    []
  );

  // State: Initialize State - Demo Agree Terms
  const [demoAgreeTerms, setDemoAgreeTerms] = useState([]);

  const value = {
    demoSetupState,
    setDemoSetupState,
    developerServicesCheckbox,
    setDeveloperServicesCheckbox,
    demoAgreeTerms,
    setDemoAgreeTerms,
  };

  return (
    <DemoOnboardContext.Provider value={value}>
      {children}
    </DemoOnboardContext.Provider>
  );
};

export default DemoOnboardContext;
