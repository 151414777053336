// Functionality: This file exports the SustainableSolutionsMarker Card component which is used in the Reduction Page component.
import { useContext, useState, useEffect } from "react";
import ReductionContext from "../../context/ReductionProvider";
import DateContext from "../../context/DateProvider";
import SelectDropdown from "../../components/SelectDropdown";
import Table from "../../components/Table.js";
import Modal from "../../components/Modal";
import SustainableSolutionsNewEntryCard from "./SustinableSolutionsMarker/SustainableSolutionsNewEntryCard.js";
import { solutionsMarkerTableConfig } from "../../constants/ReductionConfig.js";
import SnackBar from "../../components/SnackBar";
import { postSustainableSolutionsMarkerTableAPI } from "../../api/Reduction/SolutionsMaker/SustainableSolutionsMarkerTableAPI";
import Skeleton from "react-loading-skeleton";
import { mockLoginData } from "../../constants/mockDataConfig";
import { sustainableSolutionsTile } from "../../constants/TileConfig";

export default function SustainableSolutionsMarkerCard() {
  // Const: Initialize Context
  const {
    sustainableSolutionsMarkerData,
    sustainableSolutionsSelectedYear,
    setSustainableSolutionsSelectedYear,
    sustainableSolutionsNewEntry,
    setSustainableSolutionsNewEntry,
    setSustainableSolutionsMarkerData,
  } = useContext(ReductionContext);

  // Const: Initialize Date Context
  const { yearValues } = useContext(DateContext);

  // State
  const [showModal, setShowModal] = useState(false); // Modal for adding a new solutions marker
  const [successMsg, setSuccessMsg] = useState({ text: "", msg: "" }); // Success message for adding a new solutions marker

  // Function: Show Modal
  const handleClick = () => {
    setShowModal(true);
  };
  // Function: Hide Modal
  const handleModalClose = () => {
    setShowModal(false);
  };

  // Const: Determine if Mock User is Logged in
  const notMockUser =
    localStorage.getItem("greenToken") !== mockLoginData?.token;

  // UE: Update the Table Data When New Entry is Added
  useEffect(() => {
    if (successMsg?.msg === "success" && notMockUser) {
      setTimeout(
        () =>
          postSustainableSolutionsMarkerTableAPI(
            setSustainableSolutionsMarkerData,
            sustainableSolutionsSelectedYear?.[0]?.value
          ),
        5000
      );
    }
  }, [
    successMsg,
    setSustainableSolutionsMarkerData,
    sustainableSolutionsSelectedYear,
    notMockUser,
  ]);

  // Const: Determine the body of the modal card
  const modalCardBody = (
    <Modal
      onClose={handleModalClose}
      children={
        <SustainableSolutionsNewEntryCard
          setShowModal={setShowModal}
          sustainableSolutionsNewEntry={sustainableSolutionsNewEntry}
          setSustainableSolutionsNewEntry={setSustainableSolutionsNewEntry}
          setSuccessMsg={setSuccessMsg}
          notMockUser={notMockUser}
        />
      }
    />
  );

  return (
    <>
      {!sustainableSolutionsMarkerData?.loaded ? (
        <Skeleton height={400} />
      ) : (
        <div className="solutions-manager-container">
          <div className="solutions-manager-title-group">
            <div className="card-title">
              Solution Marker
              {sustainableSolutionsTile?.blurb}
            </div>
            <div className="solution-manager-title-group-right">
              <div
                className="solution-manager-new-entry-container font-medium-large"
                onClick={() => handleClick()}
              >
                <p>+</p>
              </div>
              <SelectDropdown
                options={yearValues?.information || []}
                selectedOptions={sustainableSolutionsSelectedYear}
                setSelectedOptions={setSustainableSolutionsSelectedYear}
                divClassName={"select-dropdown-main-container font-color-gray"}
              />
            </div>
          </div>
          {sustainableSolutionsMarkerData?.data?.length > 0 ? (
            <div className="solutions-manager-table-container">
              <Table
                tableData={sustainableSolutionsMarkerData?.data}
                config={solutionsMarkerTableConfig()}
                tableRowClassName="primary-table-row solution-marker-table-row card-text"
              />
            </div>
          ) : (
            <div className="empty-entry-card" onClick={() => handleClick()}>
              <p>No historical sustainable solutions - Add new entry</p>
            </div>
          )}
        </div>
      )}
      {showModal && modalCardBody}
      <SnackBar
        message={successMsg?.text}
        visible={successMsg?.text?.length > 0}
        severity={successMsg?.msg}
      />
    </>
  );
}
