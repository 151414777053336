// Functionality: This file exports the FuelConsumptionCard component which is used in the OverviewPage component.
import SwitchCarousel from "../../components/SwitchCarousel";
import MetricTileGroupSimple from "../../components/MetricTileGroupSimple";
import Skeleton from "react-loading-skeleton";

export default function FuelConsumptionCard({
  fuelConsumption,
  dateValues,
  applicationDates,
  setApplicationDates,
  costOfCarbon,
}) {
  // Const: Fuel Consumption Date Values - This is used to set the date values for the Fuel Consumption Card when cost of carbon is 0
  const fuelConsumptionSwitchCarousel =
    costOfCarbon?.value === "-" ? (
      <div className="group-switch-carousel">
        <SwitchCarousel
          values={dateValues}
          applicationDates={applicationDates}
          setApplicationDates={setApplicationDates}
          applicationDatesLabel={"overviewRightTiles"}
          fillArrowColor={"#66C743"}
          switchCarouselValueClassName="group-switch-carousel-value"
        />
      </div>
    ) : (
      <></>
    );
  return (
    <>
      {!fuelConsumption?.loaded ? (
        <Skeleton height={"200px"} />
      ) : (
        <MetricTileGroupSimple
          tileGroupConfig={fuelConsumption}
          children={fuelConsumptionSwitchCarousel}
          containerClassName={`metric-tile-group-simple-container${
            costOfCarbon?.value === "-" ? "-no-carbon" : ""
          }`}
        />
      )}
    </>
  );
}
