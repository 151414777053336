// Functionality: Vin Decoder Output Edit Modal - Used to Edit the Output of the Vin Decoder on either a single scale, used both in singular vin decoder and csv vin decoder
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import Input from "../../../../components/Input";
import SearchBar from "../../../../components/SearchBar";
import { editVinFieldsConfig } from "../../../../constants/SettingsConfig";

export default function VinDecoderOutputEditModal({
  handleModalClose,
  selectedVehicleEdit,
  setSelectedVehicleEdit,
  showModal,
  yearValues,
  vinDecoderImportState,
  setVinDecoderImportState,
}) {
  // Function: Handle Update Button
  const handleUpdate = () => {
    // Update the data
    const newData = vinDecoderImportState?.data
      ?.filter((item) => {
        return item?.vehicle_id !== selectedVehicleEdit?.original?.vehicle_id;
      })
      .concat([selectedVehicleEdit?.edit]);

    // Check for any new / corrected errors in the data
    const errorData = newData?.filter((item) => {
      return Object.values(item).some(
        (val) => val === "" || val === 0 || val === null
      );
    });
    // Update the state
    setVinDecoderImportState({
      ...vinDecoderImportState,
      data: newData,
      errorData: errorData,
      length_data: newData?.length,
      length_error_data: errorData?.length,
    });
    handleModalClose();
  };

  // Function: Handle Field Change (Both Input and Dropdown)
  const handleFieldChange = (col, e, type) => {
    const val = type === "input" ? e?.target?.value : e?.value;
    setSelectedVehicleEdit({
      ...selectedVehicleEdit,
      edit: {
        ...selectedVehicleEdit?.edit,
        [col]: val,
      },
    });
  };

  // Const: Modal Header with Vehicle ID and VIN
  const modalHeader = (
    <div className="flex-row-space-between">
      <div>
        Editing Vehicle {selectedVehicleEdit?.original?.vehicle_id}{" "}
        Configuration
      </div>
      <div className="flex-row-space-between">
        <p>VIN:</p>
        <p> {""}</p>
        <p>{selectedVehicleEdit?.original?.vin}</p>
      </div>
    </div>
  );

  const editModalFieldsMapping = (config) => {
    return config.map((field, index) => {
      return (
        <div className="settings-update-vehicle-fields" key={index}>
          <p>{field.label}:</p>
          {field.type === "input" ? (
            <Input
              value={selectedVehicleEdit?.edit?.[field.valueName] || ""}
              onChange={(e) => handleFieldChange(field.valueName, e, "input")}
              placeholder={`Enter ${field.label}`}
              disabled={
                selectedVehicleEdit?.original?.[field.valueName]?.length > 0
              }
            />
          ) : (
            <SearchBar
              options={yearValues?.information || []}
              selectedOptions={
                [
                  {
                    label: selectedVehicleEdit?.edit?.[field.valueName],
                    value: selectedVehicleEdit?.edit?.[field.valueName],
                  },
                ] || []
              }
              placeHolderText={`Select a ${field.label}`}
              onChange={(e) =>
                handleFieldChange(field.valueName, e, "dropdown")
              }
              placeholder={"Search"}
              disabled={
                selectedVehicleEdit?.original?.[field.valueName]?.length > 0
              }
            />
          )}
        </div>
      );
    });
  };
  const editModalFields = (
    <div className="settings-update-vehicle-fields-group-pair">
      {editModalFieldsMapping(editVinFieldsConfig)}
    </div>
  );

  const editButtonGroup = (
    <div className="flex-end-button-group">
      <Button
        onClick={() => {
          handleModalClose();
        }}
        buttonName="Cancel"
        className="cancel-red-button"
      />
      <Button
        className="primary-blue-button"
        buttonName="Update"
        onClick={handleUpdate}
      />
    </div>
  );

  // Const: Determine the body of the modal card
  const editVinImportModal = (
    <Modal
      onClose={handleModalClose}
      modalDivClassName="large-modal-div"
      children={
        <>
          {modalHeader}
          {editModalFields}
          {editButtonGroup}
        </>
      }
    />
  );

  return <>{showModal && editVinImportModal}</>;
}
