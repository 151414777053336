// Functionality: Toggle between two options
import PropTypes from "prop-types";
import "./ToggleSwitch.css";

export default function ToggleSwitch({
  isToggled,
  onToggle,
  labelLeft = "Off",
  labelRight = "On",
  labelClassName = "toggle-switch",
  switchClassName = "switch",
  disabled = false,
}) {
  return (
    <label className={`${labelClassName} ${disabled ? "disabled" : ""}`}>
      <input
        type="checkbox"
        checked={isToggled}
        onChange={onToggle}
        disabled={disabled}
      />
      <span className={switchClassName}>
        <span className="switch-label switch-label-left">{labelLeft}</span>
        <span className="switch-label switch-label-right">{labelRight}</span>
      </span>
    </label>
  );
}

// Props
const toggleSwitchPropTypes = {
  isToggled: PropTypes.bool.isRequired, // Indicate if the toggle is on or off
  onToggle: PropTypes.func.isRequired, // Function: on change handler to change toggle
  labelLeft: PropTypes.string, // Label for the left side
  labelRight: PropTypes.string, // Label for the right side
  labelClassName: PropTypes.string, // Class name for the toggle switch
  switchClassName: PropTypes.string, // Class name for the toggle switch
};

ToggleSwitch.propTypes = toggleSwitchPropTypes;
