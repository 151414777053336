// Functionality: API Call to generate a new site location for a user
import axios from "../../loadBalancerAxios";
import { SCOPE_2_MANAGER_LOCATION_URL } from "../../paths";
import { isScope2ManagerAddressManager } from "../../../constants/initialDataConfig";

export const deleteSiteLocation = async (locationId, setMsg, setData) => {
  await axios
    .delete(`${SCOPE_2_MANAGER_LOCATION_URL}?site_id=${locationId}`)
    .then((response) => {
      if (response?.data?.error) {
        setMsg({
          text: "Error deleting the selected site location",
          msg: "warning",
        });
      } else {
        setMsg({
          text: "Successfully deleted Site Location and all associated Scope 2 submissions",
          msg: "success",
        });
        setData(isScope2ManagerAddressManager);
      }
    })
    .catch(() => {
      setMsg({
        text: "Error deleting the selected site location",
        msg: "warning",
      });
    });
};
