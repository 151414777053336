// Functionality:  Signup Submit for Signups Page
import Button from "../../components/Button";
import CheckBoxGroup from "../../components/CheckBox";
import { agreeTermsCheckboxOptions } from "../../constants/AuthConfig";
import { putCreatePassword } from "../../api/Auth/CreatePasswordAPI";
import useNavHook from "../../hooks/use-NavHook";

export default function SignupSubmit({
  signupState,
  signupAgreeTerms,
  setSignupAgreeTerms,
  setErrMsg,
}) {
  // Const: Length = 4 because there are 4 signup fields
  const isSignupFilledOut =
    Object.values(signupState)?.filter((item) => item !== "")?.length === 4;

  // Const: Passwords Equal
  const isPasswordEqual =
    signupState?.password === signupState?.confirm_password;

  // Const: Signup Agree Terms Checked
  const isSignupAgreeTermsChecked = signupAgreeTerms.length > 0;

  // Const: Navigation Properties to be used in the SignupSubmit component
  const { navigate, navTo } = useNavHook("/");

  // Function: Handle Submit of Login Form - Make API Call
  const handleSubmit = (e) => {
    e.preventDefault();
    putCreatePassword(
      { email: signupState?.email, password: signupState?.password },
      navigate,
      navTo,
      setErrMsg
    );
  };

  // Component: Submit Button
  const submitSignupButton = (
    <Button
      buttonName="Create Your Account"
      disabled={
        !isSignupFilledOut || !isPasswordEqual || !isSignupAgreeTermsChecked
      }
      onClick={handleSubmit}
    />
  );

  // Component: Terms & Conditions & Privacy Policy
  const agreeCondition = (
    <CheckBoxGroup
      options={agreeTermsCheckboxOptions}
      selectedOptions={signupAgreeTerms}
      setSelectedOptions={setSignupAgreeTerms}
      elementClassName="signup-checkbox-item-option"
    />
  );
  return (
    <div>
      {agreeCondition}
      <div className="signup-create-account-container">
        {submitSignupButton}
      </div>
    </div>
  );
}
