// Functionality: This file exports the Scope2DeleteEntry component which is used in the Scope2Manager component. The component is a card including the form to delete an existing Scope 2 emission entry
import Button from "../../../../components/Button";

export default function Scope2DeleteEntry({
  selectedEntriesEditDelete,
  handleEditDeleteEntry,
  handleModalClose,
}) {
  // Const: Label to Parse
  const labelToParse = selectedEntriesEditDelete?.selectedEntryLabel;
  return (
    <div className="scope2-delete-container">
      <div>Are you sure you want to delete this site entry?</div>
      <div className="scope2-delete-container-location">
        {labelToParse?.dates} - {labelToParse?.location} -{" "}
        {labelToParse?.electricity_purchased_kwh} kWh
      </div>
      <div>This action is irreversible.</div>
      <div className="flex-end-button-group">
        <Button
          buttonName="Cancel"
          className="cancel-red-button"
          onClick={() => handleModalClose()}
        />
        <Button
          buttonName="Delete Entry"
          className="cancel-solid-red-button"
          onClick={() => handleEditDeleteEntry("delete")}
        />
      </div>
    </div>
  );
}
