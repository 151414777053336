// Functionality: Linear & Ciruclar Progress Bar component for the application
import PropTypes from "prop-types";
import { IoMdCheckmark } from "react-icons/io";

export default function ProgressBar({
  percentageValue,
  progressBarClassName = "progress-bar",
  progressClassName = "progress",
  remainingProgressClassName = "remaining-progress",
  circular = false,
}) {
  // Const: Radius
  const radius = 40;

  // Const: Circumference
  const circumference = 2 * Math.PI * radius;

  // Const: Stroke dash offset
  const strokeDashoffset =
    circumference - (percentageValue / 100) * circumference;

  // Calculate remaining percentage value
  const remainingPercentageValue = 100 - percentageValue;

  // Render circular progress bar if circular prop is true, otherwise render linear progress bar
  if (circular) {
    return (
      <div
        className={progressBarClassName}
        style={{
          width: "30px",
          height: "30px",
          position: "relative",
          border: "none",
        }}
      >
        <svg
          viewBox="0 0 100 100"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <circle
            cx="50"
            cy="50"
            r={radius}
            fill="none"
            stroke="#d2d2d2"
            strokeWidth="10"
          />
          <circle
            cx="50"
            cy="50"
            r={radius}
            fill="none"
            stroke="#5aa140"
            strokeWidth="10"
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            transform="rotate(-90 50 50)"
          />
        </svg>

        <div
          style={{
            position: "absolute",
            top: "55%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <IoMdCheckmark
            size={20}
            color={parseInt(percentageValue) === 100 ? "#5aa140" : "#d2d2d2"}
          />
        </div>
      </div>
    );
  } else {
    // Render linear progress bar if circular prop is false
    return (
      <div className={progressBarClassName}>
        <div
          className={progressClassName}
          style={{ width: `${percentageValue}%` }}
        ></div>
        <div
          className={remainingProgressClassName}
          style={{ width: `${remainingPercentageValue}%` }}
        ></div>
      </div>
    );
  }
}

// Props
const progressBarPropTypes = {
  percentageValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Percentage value of the progress bar
  progressBarClassName: PropTypes.string, // Class name for the progress bar component
  progressClassName: PropTypes.string, // Class name for the progress bar fill color
  remainingProgressClassName: PropTypes.string, // Class name for the remaining progress bar fill color
  circular: PropTypes.bool, //  prop to determine circular progress bar
};

ProgressBar.propTypes = progressBarPropTypes;
