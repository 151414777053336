// Functionality: API Call for Industry Carbon Rating Tile
import axios from "../loadBalancerAxios";
import { INDUSTRY_CARBON_RATING_URL } from "../paths";

export const postIndustryCarbonRatingAPI = async (setData, selectedDate) => {
  await axios
    .post(INDUSTRY_CARBON_RATING_URL, {
      "Content-Type": "application/json",
      date: selectedDate,
    })
    .then((response) => {
      // Const: Set Industry Carbon Rating Data
      const responseData = response?.data?.[0]?.industry_rating;
      setData({ loaded: true, value: responseData });
    })
    .catch(() => {
      setData({ loaded: true, value: "-" });
    });
};
