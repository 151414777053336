// Functionality: This is the VehiclePerformanceDataTab component of VehiclePerformancePage.
import { useContext } from "react";
import VehiclePerformanceContext from "../../context/VehiclePerformanceProvider";
import VPDataFilter from "./Data/VPDataFilter";
import VPDataTable from "./Data/VPDataTable";

export default function VehiclePerformanceDataTab({
  setActiveTab,
  activeTab,
  pageName,
}) {
  // Context: Vehicle Performance
  const {
    filtersIsLoading,
    vehiclePerformanceFilterEmissions,
    setVehiclePerformanceFilterEmissions,
    vehiclePerformanceFilterIdleTime,
    setVehiclePerformanceFilterIdleTime,
    vehiclePerformanceFilterIdleTimeEmissions,
    setVehiclePerformanceFilterIdleTimeEmissions,
    vehiclePerformanceFilterVehicle,
    setVehiclePerformanceFilterVehicle,
    vehiclePerformanceFilterCarbonEmissions,
    setVehiclePerformanceFilterCarbonEmissions,
    vehiclePerformanceFilterCH4,
    setVehiclePerformanceFilterCH4,
    vehiclePerformanceFilterN2O,
    setVehiclePerformanceFilterN2O,
    clearButtonFilter,
    setClearButtonFilter,
    setFilterString,
    generateFilterString,
  } = useContext(VehiclePerformanceContext);

  const mockData = localStorage.getItem("greenToken") === "mockData";

  return (
    <>
      <div className="vp-data-container">
        <VPDataFilter
          page={"data"}
          filtersIsLoading={filtersIsLoading?.data}
          filterEmissions={vehiclePerformanceFilterEmissions}
          setFilterEmissions={setVehiclePerformanceFilterEmissions}
          filterIdleTime={vehiclePerformanceFilterIdleTime}
          setFilterIdleTime={setVehiclePerformanceFilterIdleTime}
          filterIdleTimeEmissions={vehiclePerformanceFilterIdleTimeEmissions}
          setFilterIdleTimeEmissions={
            setVehiclePerformanceFilterIdleTimeEmissions
          }
          filterVehicle={vehiclePerformanceFilterVehicle}
          setFilterVehicle={setVehiclePerformanceFilterVehicle}
          filterCarbonEmissions={vehiclePerformanceFilterCarbonEmissions}
          setFilterCarbonEmissions={setVehiclePerformanceFilterCarbonEmissions}
          filterCH4={vehiclePerformanceFilterCH4}
          setFilterCH4={setVehiclePerformanceFilterCH4}
          filterN2O={vehiclePerformanceFilterN2O}
          setFilterN2O={setVehiclePerformanceFilterN2O}
          clearButtonFilter={clearButtonFilter}
          setClearButtonFilter={setClearButtonFilter}
          setFilterString={setFilterString}
          generateFilterString={generateFilterString}
          mockData={mockData}
          setVehicleProfileDataPage={() => {}}
        />
        <VPDataTable
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          pageName={pageName}
          mockData={mockData}
        />
      </div>
    </>
  );
}
