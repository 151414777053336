// Functionality: API Call for getting previous entries in Sustainable Solutions Marker
import axios from "../../axios";
import { SOLUTIONS_MARKER_TABLE_URL } from "../../paths";

export const postSustainableSolutionsMarkerTableAPI = async (setData, year) => {
  await axios
    .post(SOLUTIONS_MARKER_TABLE_URL, { year: year })
    .then((response) => {
      // Const: Solutions Marker Data
      const responseData = response?.data;
      const formattedData = responseData?.dates.map((date, index) => ({
        date: new Date(responseData?.dates?.[index]).toLocaleDateString(
          "en-US",
          {
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        ),
        name: responseData?.names[index],
        description: responseData?.descriptions[index],
        impact: responseData?.impacts[index] || 0,
      }));

      setData({ loaded: true, data: formattedData });
    })
    .catch(() => {
      setData({ loaded: true, data: [] });
    });
};
