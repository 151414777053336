// Functionality: This file exports the VPDataFilters component which is used in the Vehicle Performance Page Data component.
import Accordion from "../../../components/Accordion";
import RangeSlider from "../../../components/RangeSlider";
import filter from "../../../assets/icons/filter.png";
import SearchBar from "../../../components/SearchBar";
import Button from "../../../components/Button";

export default function VPDataFilter({
  page,
  filtersIsLoading,
  filterEmissions,
  setFilterEmissions,
  filterIdleTime,
  setFilterIdleTime,
  filterIdleTimeEmissions,
  setFilterIdleTimeEmissions,
  filterVehicle,
  setFilterVehicle,
  filterCarbonEmissions,
  setFilterCarbonEmissions,
  filterCH4,
  setFilterCH4,
  filterN2O,
  setFilterN2O,
  vehicleProfileFilterEpaRating,
  setVehicleProfileFilterEpaRating,
  clearButtonFilter,
  setClearButtonFilter,
  setFilterString,
  generateFilterString,
  mockData,
  setVehicleProfileDataPage,
}) {
  // Const: Filter Header
  const filterHeader = (
    <div className="vp-filter-header-container">
      <p>Filter Vehicle Performance</p>
      <img src={filter} alt={"filter"} />
    </div>
  );

  // Function: Handle Dropdown Change
  const handleDropdownChange = (option) => {
    setFilterVehicle((prevSelectedOptions) => {
      const isSelected = prevSelectedOptions?.data?.some(
        (item) => item.value === option.value
      );
      if (isSelected) {
        return {
          ...prevSelectedOptions,
          data: prevSelectedOptions?.data?.filter(
            (item) => item.value !== option.value
          ),
        };
      } else {
        return {
          ...prevSelectedOptions,
          data: [...prevSelectedOptions?.data, option],
        };
      }
    });
  };
  // Const: Vehicle Filters
  const vehicleFilters = !filtersIsLoading && (
    <Accordion
      type="vehicleFilters"
      titleName="Vehicles"
      children={
        <div className="vp-data-filter-vehicles">
          <SearchBar
            options={filterVehicle?.options}
            isMultiSelect={true}
            selectedOptions={filterVehicle?.data}
            onChange={(e) => handleDropdownChange(e)}
          />
        </div>
      }
      titleClassName="accordion-section-group-vp"
    />
  );

  // Const: Emissions Filters
  const emissionsFilters = !filtersIsLoading && (
    <Accordion
      type="emissionFilters"
      titleName="Total CO2e Emissions"
      children={
        <>
          <RangeSlider
            values={filterEmissions}
            setValues={setFilterEmissions}
          />
        </>
      }
      titleClassName="accordion-section-group-vp"
    />
  );

  // Const: Carbon Emissions Filters
  const carbonEmissionsFilters = !filtersIsLoading && (
    <Accordion
      type="carbonFilters"
      titleName="Carbon Emissions"
      children={
        <>
          <RangeSlider
            values={filterCarbonEmissions}
            setValues={setFilterCarbonEmissions}
          />
        </>
      }
      titleClassName="accordion-section-group-vp"
    />
  );

  // Const: Idle Time Filters
  const idleTimeFilters = !filtersIsLoading && (
    <Accordion
      type="idleTimeFilters"
      titleName="Idle Time"
      children={
        <>
          <RangeSlider values={filterIdleTime} setValues={setFilterIdleTime} />
        </>
      }
      titleClassName="accordion-section-group-vp"
    />
  );

  // Const: Idle Time Emissions Filters
  const idleTimeEmissionsFilters = !filtersIsLoading && (
    <Accordion
      type="idleTimeEmissionsFilters"
      titleName="Idle Time Emissions"
      children={
        <>
          <RangeSlider
            values={filterIdleTimeEmissions}
            setValues={setFilterIdleTimeEmissions}
            stepValue={0.01}
          />
        </>
      }
      titleClassName="accordion-section-group-vp"
    />
  );

  // Const: N20 Filters
  const n2oFilters = !filtersIsLoading && (
    <Accordion
      type="n2oFilters"
      titleName="N2O"
      children={
        <>
          <RangeSlider
            values={filterN2O}
            setValues={setFilterN2O}
            stepValue={0.0001}
            disabled={filterN2O?.minMax?.[1] === 0 || false}
          />
        </>
      }
      titleClassName="accordion-section-group-vp"
    />
  );
  // Const: CH4 Filters
  const ch4Filters = !filtersIsLoading && (
    <Accordion
      type="ch4Filters"
      titleName="CH4"
      children={
        <>
          <RangeSlider
            values={filterCH4}
            setValues={setFilterCH4}
            stepValue={0.0001}
            disabled={filterCH4?.minMax?.[1] === 0 || false}
          />
        </>
      }
      titleClassName="accordion-section-group-vp"
    />
  );

  // Const: CH4 Filters
  const epaRatingFilter = !filtersIsLoading && (
    <Accordion
      type="epaRating"
      titleName="EPA Rating"
      children={
        <>
          <RangeSlider
            values={vehicleProfileFilterEpaRating}
            setValues={setVehicleProfileFilterEpaRating}
            stepValue={1}
          />
        </>
      }
      titleClassName="accordion-section-group-vp"
    />
  );

  // Const: Clear Button Filter
  const clearFiltersButton = (
    <div className="clear-button-filter-container">
      <Button
        buttonName="Clear"
        onClick={() => {
          setClearButtonFilter({
            ...clearButtonFilter,
            [page]: !clearButtonFilter?.[page],
          });
          setFilterString("");
          setVehicleProfileDataPage(1);
        }}
      />
    </div>
  );
  // Const: Submit Button Filter
  const submitFiltersButton = (
    <div className="submit-button-filter-container">
      <Button
        buttonName="Submit"
        onClick={() => {
          setFilterString(
            generateFilterString(
              [
                filterIdleTime,
                filterEmissions,
                filterIdleTimeEmissions,
                filterVehicle,
                filterCarbonEmissions,
                filterCH4,
                filterN2O,
                vehicleProfileFilterEpaRating,
              ],
              page
            )
          );
          setVehicleProfileDataPage(1);
        }}
      />
    </div>
  );

  return (
    <div className="vp-filter-container">
      <div className="vp-filters-scroll-group">
        {filterHeader}
        {vehicleFilters}
        {emissionsFilters}
        {carbonEmissionsFilters}
        {idleTimeFilters}
        {idleTimeEmissionsFilters}
        {n2oFilters}
        {ch4Filters}
        {page === "profile" && epaRatingFilter}
      </div>
      <div className="vp-button-filter-container-group">
        {clearFiltersButton}
        {mockData ? <div></div> : submitFiltersButton}
      </div>
    </div>
  );
}
