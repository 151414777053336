import { useContext } from "react";
import DateContext from "../context/DateProvider";
import OverviewContext from "../context/OverviewProvider";
import ApplicationHeader from "../layouts/ApplicationHeader";
import IndustryCarbonRatingCard from "./OverviewPage/IndustryCarbonRatingCard";
import FuelConsumptionCard from "./OverviewPage/FuelConsumption";
import VehicleEmissionsCard from "./OverviewPage/VehicleEmissionsCard";
import EmissionFootprintCard from "./OverviewPage/EmissionFootprintCard";
import CostofCarbonCard from "./OverviewPage/CostofCarbonCard";
import SwitchCarousel from "../components/SwitchCarousel";
import Skeleton from "react-loading-skeleton";

export default function OverviewPage() {
  // Const: Initialize Context - Dates
  const {
    dateValues,
    applicationDates,
    setApplicationDates,
    activeTab,
    setActiveTab,
  } = useContext(DateContext);

  // Const: Initialize Context - Overview
  const { costOfCarbon, fuelConsumption } = useContext(OverviewContext);

  return (
    <>
      <ApplicationHeader
        headerName={"Overview"}
        applicationDatesLabel={"overview"}
      />
      <>
        <div>
          <div id="overview-page-container">
            <div>
              <div className="overview-page-left-group">
                <EmissionFootprintCard
                  dateValues={dateValues}
                  applicationDates={applicationDates}
                  setApplicationDates={setApplicationDates}
                />
                <IndustryCarbonRatingCard
                  dateValues={dateValues}
                  applicationDates={applicationDates}
                  setApplicationDates={setApplicationDates}
                />
              </div>
              <VehicleEmissionsCard
                dateValues={dateValues}
                applicationDates={applicationDates}
                setApplicationDates={setApplicationDates}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
              />
            </div>
            {!costOfCarbon?.loaded && !fuelConsumption?.loaded ? (
              <Skeleton height={"500px"} />
            ) : (
              <div className="overview-page-right-group">
                <div className="overview-page-cost-of-carbon">
                  <CostofCarbonCard costOfCarbon={costOfCarbon} />
                  {costOfCarbon?.value !== "-" && (
                    <div className="group-switch-carousel overiew-page-right-switch-carousel">
                      <SwitchCarousel
                        values={dateValues}
                        applicationDates={applicationDates}
                        setApplicationDates={setApplicationDates}
                        applicationDatesLabel={"overviewRightTiles"}
                        fillArrowColor={"#66C743"}
                        switchCarouselValueClassName="group-switch-carousel-value"
                      />
                    </div>
                  )}
                  <FuelConsumptionCard
                    fuelConsumption={fuelConsumption}
                    dateValues={dateValues}
                    applicationDates={applicationDates}
                    setApplicationDates={setApplicationDates}
                    costOfCarbon={costOfCarbon}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
}
