// Functionality: This file exports the TargetReport component which is used in the Report Page component.
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import ReportContext from "../../../context/ReportsProvider";
import RadioItemGroup from "../../../components/RadioItemGroup";
import TextArea from "../../../components/TextArea";
import {
  reportQuestionConfig,
  emissionTargetRadioOptions,
  notActiveEmissionTargetRadioOptions,
} from "../../../constants/ReportsConfig";

export default function TargetReport({
  generateQuestionGroup,
  handleReportEventChange,
  reportInputClassName,
}) {
  // State: Emissions Target Replacement Radio Options
  const [emissionTargetOptions, setEmissionTargetOptions] = useState({
    options: [],
    loaded: false,
  });

  // Const: Initialize Report Context
  const { targetDataConfig, setTargetDataConfig, reductionTargetDefault } =
    useContext(ReportContext);

  // UE: Clear Board Level Oversight Input if Emissions Target is No or Other
  useEffect(() => {
    if (
      targetDataConfig?.emission_target_active?.radio !== "Other" &&
      targetDataConfig?.emission_target_active?.radio?.slice(0, 3) !== "Yes" &&
      targetDataConfig?.emission_target_active?.textArea !== ""
    ) {
      setTargetDataConfig((prevState) => ({
        ...prevState,
        emission_target_active: {
          radio: prevState.emission_target_active.radio,
          textArea: "",
        },
      }));
    } else if (
      targetDataConfig?.emission_target_active?.radio?.slice(0, 2) !== "No" &&
      targetDataConfig?.no_emission_target_active?.radio !== ""
    ) {
      setTargetDataConfig((prevState) => ({
        ...prevState,
        no_emission_target_active: {
          radio: "",
          textArea: "",
        },
      }));
    } else if (
      targetDataConfig?.no_emission_target_active?.radio !== "Other" &&
      targetDataConfig?.no_emission_target_active?.textArea !== ""
    ) {
      setTargetDataConfig((prevState) => ({
        ...prevState,
        no_emission_target_active: {
          radio: prevState.no_emission_target_active.radio,
          textArea: "",
        },
      }));
    }
  }, [
    targetDataConfig?.emission_target_active?.radio,
    targetDataConfig?.emission_target_active?.textArea,
    targetDataConfig?.no_emission_target_active?.radio,
    targetDataConfig?.no_emission_target_active?.textArea,
    setTargetDataConfig,
  ]);
  useEffect(() => {
    if (!emissionTargetOptions?.loaded) {
      const restOptions = emissionTargetRadioOptions?.slice(1);
      const replacementOption = emissionTargetRadioOptions?.[0]?.label
        ?.replace(
          "[x year]",
          reductionTargetDefault?.projection_year?.[0]?.label
        )
        ?.replace("[x year]", reductionTargetDefault?.base_year?.[0]?.label)
        ?.replace(
          "[x%]",
          reductionTargetDefault?.target_percentage?.[0]?.label
        );
      const replacementOptions = [
        { label: replacementOption, value: replacementOption },
      ];
      setEmissionTargetOptions({
        options: [...replacementOptions, ...restOptions],
        loaded: true,
      });
    }
  }, [emissionTargetOptions?.loaded]);

  useEffect(() => {
    if (
      emissionTargetOptions?.loaded &&
      targetDataConfig?.emission_target_active?.radio?.slice(0, 3) === "Yes"
    ) {
      setTargetDataConfig((prevState) => ({
        ...prevState,
        emission_target_active: {
          radio: emissionTargetOptions?.options?.[0]?.value,
          textArea: prevState.emission_target_active.textArea,
        },
      }));
    }
  }, [emissionTargetOptions?.loaded]);

  // Dictionary: UI/UX for each of the questions, in order
  const questionGroupConfig = {
    0: (
      <>
        <RadioItemGroup
          options={emissionTargetOptions?.options}
          onChange={(e) =>
            handleReportEventChange(
              e,
              "emission_target_active",
              "radio",
              setTargetDataConfig,
              targetDataConfig
            )
          }
          splitTextByLine={true}
          value={
            targetDataConfig?.emission_target_active?.radio ||
            emissionTargetOptions?.options?.[0]?.value
          }
          divClassName="div-radio-item-vertical"
        />
        {targetDataConfig?.emission_target_active?.radio === "Other" && (
          <TextArea
            value={targetDataConfig?.emission_target_active?.textArea}
            placeholder={"Explain"}
            onChange={(e) =>
              handleReportEventChange(
                e,
                "emission_target_active",
                "textArea",
                setTargetDataConfig,
                targetDataConfig
              )
            }
            className={reportInputClassName?.textArea}
          />
        )}
        {targetDataConfig?.emission_target_active?.radio?.slice(0, 3) ===
          "Yes" && (
          <>
            <div className="report-question-group-container">
              <p className="report-question-group-container-question">
                Why was this year selected as a base year?
              </p>
              <TextArea
                value={targetDataConfig?.emission_target_active?.textArea}
                placeholder={"Explain"}
                onChange={(e) =>
                  handleReportEventChange(
                    e,
                    "emission_target_active",
                    "textArea",
                    setTargetDataConfig,
                    targetDataConfig
                  )
                }
                className={reportInputClassName?.textArea}
              />
            </div>
          </>
        )}
        {targetDataConfig?.emission_target_active?.radio?.slice(0, 2) ===
          "No" && (
          <>
            <div className="report-question-group-container">
              <p className="report-question-group-container-question">
                {reportQuestionConfig?.targetsAndReduction?.slice(1)}
              </p>
              <RadioItemGroup
                options={notActiveEmissionTargetRadioOptions}
                onChange={(e) =>
                  handleReportEventChange(
                    e,
                    "no_emission_target_active",
                    "radio",
                    setTargetDataConfig,
                    targetDataConfig
                  )
                }
                value={targetDataConfig?.no_emission_target_active?.radio}
                divClassName="div-radio-item-vertical"
              />
              {targetDataConfig?.no_emission_target_active?.radio ===
                "Other" && (
                <TextArea
                  value={targetDataConfig?.no_emission_target_active?.textArea}
                  placeholder={"Explain"}
                  onChange={(e) =>
                    handleReportEventChange(
                      e,
                      "no_emission_target_active",
                      "textArea",
                      setTargetDataConfig,
                      targetDataConfig
                    )
                  }
                  className={reportInputClassName?.textArea}
                />
              )}
            </div>
          </>
        )}
      </>
    ),
  };
  return (
    <>
      {generateQuestionGroup(
        reportQuestionConfig?.targetsAndReduction.slice(0, 1),
        questionGroupConfig
      )}
    </>
  );
}

// Props
const targetReportPropTypes = {
  generateQuestionGroup: PropTypes.func, // Function: Generates the UI/UX for each question
  handleReportEventChange: PropTypes.func, // Function: Handles the change of each question
};

TargetReport.propTypes = targetReportPropTypes;
