// Functionality: API Call for Scope 2 Emissions Tile
import axios from "../loadBalancerAxios";
import { SCOPE_2_EMISSIONS_URL } from "../paths";
import { scope2EmissionsSVGConfig } from "../../constants/EmissionsConfig";

export const getScope2API = async (setData, date) => {
  await axios
    .get(`${SCOPE_2_EMISSIONS_URL}?date=${date}`)
    .then((response) => {
      // Const: Set Scope 1 Emissions Data
      const responseData = response?.data || "-";

      // Const: Determine if its increase or decrease
      const increase = responseData?.change < 0;
      setData({
        ...scope2EmissionsSVGConfig,
        value:
          responseData?.recentValue === 0
            ? "-"
            : responseData?.recentValue || "-",
        percentageChange: Math.abs(responseData?.change).toFixed(2) || "-",
        increase: increase,
        loaded: true,
      });
    })
    .catch(() => {
      setData({
        ...scope2EmissionsSVGConfig,
        value: "-",
        change: "-",
        increase: false,
        loaded: true,
      });
    });
};
