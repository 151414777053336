// Functionality: API Call for changing default Emission Reduction Target Entry
import axios from "../../loadBalancerAxios";
import { EMISSION_REDUCTION_BASE_VALUES_URL } from "../../paths";

export const getDefaultEmissionReductionTargetAPI = async (
  setData,
  setProjectYear,
  setTargetPercentage
) => {
  await axios
    .get(EMISSION_REDUCTION_BASE_VALUES_URL)
    .then((response) => {
      // Response Data: Gather the data from the response
      const responseData = response?.data;

      // Set Default Emission Reduction Target
      const baseYearData = JSON.stringify(responseData?.base_year);
      const percentageLabel = `${JSON.stringify(
        responseData?.base_target * 100
      )}%`;
      const percentageValue = JSON?.stringify(responseData?.base_target * 100);
      const projectionYearData = JSON.stringify(responseData?.target_year);

      setProjectYear([
        {
          label: projectionYearData,
          value: projectionYearData,
        },
      ]);
      setTargetPercentage([
        {
          label: percentageLabel,
          value: percentageValue,
        },
      ]);
      // Set Default Emission Reduction Target
      setData({
        loaded: true,
        base_year: [
          {
            label: baseYearData,
            value: baseYearData,
          },
        ],
        target_percentage: [
          {
            label: percentageLabel,
            value: percentageValue,
          },
        ],
        projection_year: [
          {
            label: projectionYearData,
            value: projectionYearData,
          },
        ],
      });
    })
    .catch(() => {});
};
