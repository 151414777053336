// Functionality: API Call for Specific Vehicle Profiles
import axios from "../loadBalancerAxios";
import { VEHICLE_PROFILES_URL } from "../paths";

export const getVehicleProfileAPI = async (
  selectedVehicle,
  applicationDates,
  setData
) => {
  await axios
    .get(
      `${VEHICLE_PROFILES_URL}?vehicleId=${selectedVehicle}&date=${applicationDates?.vehicleProfile}`
    )
    .then((response) => {
      // Get the response data
      const responseData = response?.data;
      const fillZero = 0;

      setData({
        data: {
          ...responseData,
          vehicle_id: selectedVehicle,
          total_drivetime:
            responseData?.total_drivetime?.toFixed(0) || fillZero?.toFixed(0),
          drive_to_idle_ratio:
            (responseData?.drive_to_idle_ratio * 100).toFixed(2) ||
            fillZero?.toFixed(2),
        },
        loaded: true,
      });
    })
    .catch(() => {});
};
