// Functionality: This file exports the Scope2EditEntry component which is used in the Scope2Manager component. The component is a card including the form to edit an existing Scope 2 emission entry
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import SearchBar from "../../../../components/SearchBar";
import DatePicker from "../../../../components/DatePicker";

export default function Scope2EditEntry({
  setScope2EditEntryFields,
  scope2EditEntryFields,
  handleEditDeleteEntry,
  scope2ManagerAddressOptions,
  handleModalClose,
  submitConditionHandler,
}) {
  // Function: Handle Selected Date
  const handleSelectedDate = (event) => {
    setScope2EditEntryFields({
      ...scope2EditEntryFields,
      bill_date: event.target.value,
    });
  };
  // Function: Handle Input Change
  const handleInputChange = (event, type) => {
    setScope2EditEntryFields({
      ...scope2EditEntryFields,
      [type]: event.target.value,
    });
  };

  // Function: Handle Dropdown Change
  const handleDropdownChange = (event, type) => {
    if (type === "siteLocation") {
      setScope2EditEntryFields((prevState) => ({
        ...prevState,
        site_id: event?.value?.site_id,
        [type]: [event],
      }));
    } else {
      setScope2EditEntryFields({
        ...scope2EditEntryFields,
        [type]: [event],
      });
    }
  };
  // Const: Input New Scope 2 Emission Container Group
  const editScope2EntryFields = (
    <div className="scope2-manage-address-group font-color-gray font-small-medium">
      <p className="card-title">Editing Electricity Usage Entry</p>
      <div className="scope2-entry-form-group">
        <div className="scope2-manage-grid-group">
          <div className="scope2-manage-input-group-address">
            <p>Invoice ID:</p>
            <Input
              className="input-element scope2-manage-input-element"
              onChange={(e) => handleInputChange(e, "invoice_id")}
              value={scope2EditEntryFields?.invoice_id}
            />
          </div>
          <div className="scope2-manage-input-group-address">
            <p>Date:</p>
            <DatePicker
              selectedDate={scope2EditEntryFields?.bill_date}
              handleSelectedDate={(e) => handleSelectedDate(e)}
              dateType={"date"}
              restrictFuture={true}
              dateClassName="secondary-datepicker font-small font-color-gray"
            />
          </div>
        </div>
        <div className="scope2-manage-input-group-address">
          <p>Site Location:</p>
          <SearchBar
            selectedOptions={scope2EditEntryFields?.siteLocation || []}
            options={scope2ManagerAddressOptions?.data}
            onChange={(e) => handleDropdownChange(e, "siteLocation")}
            placeholder="Search Site Location"
          />
          {/* TODO: ADD THIS */}
          {/* <p>Don't see a location? Visit Scope 2 Address Manager</p> */}
        </div>

        <div className="scope2-manage-input-group-address">
          <p>Electricity Purchased:</p>
          <div className="scope2-container-group">
            <Input
              onChange={(e) => handleInputChange(e, "wattage")}
              value={scope2EditEntryFields?.wattage}
            />
            <p>kWh</p>
          </div>
        </div>
      </div>
      <div className="scope2-submit-entry-button flex-end-button-group">
        <Button
          buttonName="Cancel"
          className="secondary-blue-button"
          onClick={() => handleModalClose()}
        />
        <Button
          buttonName="Edit Entry"
          onClick={() => handleEditDeleteEntry("edit")}
          disabled={!submitConditionHandler}
          className="primary-blue-button"
        />
      </div>
    </div>
  );

  return <>{editScope2EntryFields}</>;
}
