// Functionality:  Signup Fields for Signups Page
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import {
  signupInputFields,
  passwordRequirementsText,
} from "../../constants/AuthConfig";
import Input from "../../components/Input";
import { IoMdCheckmark } from "react-icons/io";

// Refactor signup Fields to be in correct format for Inputs
const fields = signupInputFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function SignupFields({
  signupState,
  setSignupState,
  setErrMsg,
}) {
  // State: Validation Message - Password
  const [pwValidationMessage, setPWValidationMessage] = useState("default");
  // State: Validation Message - Confirm Password
  const [cpwValidationMessage, setCPWValidationMessage] = useState("default");

  // Function: Handle State Change of Input Fields
  const handleChange = (e) => {
    e.preventDefault();
    setSignupState({ ...signupState, [e.target.id]: e.target.value });
    setErrMsg("");
  };

  // Function: Validate Password Meets Requirements
  const validatePassword = (password) => {
    // Define a regular expression for the password criteria
    const passwordRegex =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/;

    // Test the password against the regular expression
    const isValid = passwordRegex.test(password);

    // Return the validation result
    return isValid;
  };

  // Const: Passwords Equal
  const isPasswordEqual =
    signupState?.password === signupState?.confirm_password;

  // UE: Set Password Validation Message
  useEffect(() => {
    let timer;

    const handleValidation = () => {
      // Add your validation logic here
      if (validatePassword(signupState?.password)) {
        setPWValidationMessage("good");
      } else {
        setPWValidationMessage("bad");
      }
    };

    if (signupState.password.trim() !== "") {
      timer = setTimeout(handleValidation, 1500);
    } else {
      setPWValidationMessage("default");
      // Clear the validation message if the password is empty
    }

    return () => clearTimeout(timer);
  }, [signupState.password]);

  // UE: Set Confirm Password Validation Message
  useEffect(() => {
    let timer;

    const handleValidation = () => {
      // Add your validation logic here
      if (isPasswordEqual) {
        setCPWValidationMessage("good");
      } else {
        setCPWValidationMessage("bad");
      }
    };

    if (signupState.confirm_password.trim() !== "") {
      timer = setTimeout(handleValidation, 1500);
    } else {
      setCPWValidationMessage("default");
      // Clear the validation message if the password is empty
    }

    return () => clearTimeout(timer);
  }, [signupState.confirm_password]);

  // Const: Develop Login Input Fields for email & password
  const signupInputFieldsComponent = (slice1, slice2) => {
    return fields?.slice(slice1, slice2).map((field) => {
      // Const: Label Text for Confirm Password
      const determineLabelText = () => {
        // Handle Case For Confirm Password Validation
        if (field?.labelFor === "confirm_password") {
          if (cpwValidationMessage === "bad") {
            return (
              <div>
                <p>{field?.labelText}</p>
                <div className="signup-password-no-match">
                  Passwords do not match
                </div>
              </div>
            );
          } else if (cpwValidationMessage === "good") {
            return (
              <div className="signup-password-note-group">
                <p>{field?.labelText}</p>
                <IoMdCheckmark className="signup-password-note-checkmark" />
              </div>
            );
          } else {
            return <p>{field?.labelText}</p>;
          }
        }
        // Handle Case For Password Validation
        else if (field?.labelFor === "password") {
          if (pwValidationMessage === "default") {
            return (
              <div>
                <p>{field?.labelText}</p>
                <div className="signup-password-note">
                  {passwordRequirementsText}
                </div>
              </div>
            );
          } else if (pwValidationMessage === "good") {
            return (
              <div>
                <div className="signup-password-note-group">
                  <p>{field?.labelText}</p>
                  <IoMdCheckmark className="signup-password-note-checkmark" />
                </div>
                <div className="signup-password-note">
                  {passwordRequirementsText}
                </div>
              </div>
            );
          } else {
            return (
              <div>
                <div className="signup-password-note-group">
                  <p>{field?.labelText}</p>
                  <div className="signup-password-no-match">
                    Please enter a valid password
                  </div>
                </div>
                <div className="signup-password-note">
                  {passwordRequirementsText}
                </div>
              </div>
            );
          }
        } else {
          return <p>{field?.labelText}</p>;
        }
      };

      return (
        <Input
          key={field.id}
          onChange={handleChange}
          value={signupState[field.id]}
          labelFor={field.labelFor}
          id={field.id}
          type={field.type}
          isRequired={field.isRequired}
          disabled={field.disabled}
          placeholder={""}
          label={determineLabelText()}
          divClassName={field.divClassName}
          className="signup-input-element font-small-medium"
        />
      );
    });
  };

  const emailFields = signupInputFieldsComponent(0, 2);
  const passwordFields = signupInputFieldsComponent(2);
  return (
    <>
      <div className="signup-grid-container">
        <div className="signup-grid-container-group">{emailFields}</div>
        <div className="signup-grid-container-group">{passwordFields}</div>
      </div>
    </>
  );
}

// Props
const signupFieldsPropTypes = {
  signupState: PropTypes.object, // State of the signup fields
  setSignupState: PropTypes.func, // Function to set the state of the signup fields
};

SignupFields.propTypes = signupFieldsPropTypes;
