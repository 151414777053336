// Functionality: Button component for the application
import PropTypes from "prop-types";

export default function Button({ buttonName, ...props }) {
  return <button {...props}>{buttonName}</button>;
}

// Props
const buttonPropTypes = {
  buttonName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]), // Name of the button
};

Button.propTypes = buttonPropTypes;
