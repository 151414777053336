// Functionality: Modal Verification of Updating VIN Decoder Records, POST Call to update Vehicle Settings Information
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";

export default function VinDecoderImportModalVerify({
  handleModalClose,
  showModal,
  handlePostVehicleSettings,
  setSelectedEdit,
  setProcessStep,
}) {
  const modalContent = (
    <div>
      <div className="card-title">
        Are you sure you want to import these Vehicle records?
      </div>
      <p className="card-subtext">
        Advancing forward will override the corresponding vehicle settings data
        for the inputted vehicles within the platform.
      </p>
      <div className="flex-end-button-group">
        <Button
          buttonName="Cancel"
          className="secondary-blue-button"
          onClick={() => {
            handleModalClose();
          }}
        />
        <Button
          buttonName="Import Data"
          className="primary-blue-button"
          onClick={() => {
            handlePostVehicleSettings("CSV", "decode");
            handleModalClose();
            setSelectedEdit("Insite");
            setProcessStep(0);
          }}
        />
      </div>
    </div>
  );

  const verifyModal = (
    <Modal onClose={handleModalClose} children={modalContent} />
  );
  return <>{showModal && verifyModal}</>;
}
