// Functionality:  Check Input Fields, Submit Button for Forgot Password Page, API Call
import PropTypes from "prop-types";
import { useState } from "react";
import Button from "../../components/Button";
import useNavHook from "../../hooks/use-NavHook";
import { putForgotPasswordVerify } from "../../api/Auth/ForgotPasswordVerifyAPI";

export default function ResetPasswordSubmit({ forgotPWState }) {
  // State: ErrMsg
  const [errMsg, setErrMsg] = useState("");

  // Const: Reset PW Button Name
  const resetPWButtonName = "Reset Password";

  // Const: Length = 5 because there are 4 reset fields
  const isResetFilledOut =
    Object.values(forgotPWState)?.filter((item) => item !== "")?.length === 4;

  // Const: Passwords Equal
  const isPasswordEqual =
    forgotPWState?.password === forgotPWState?.confirm_password;

  // Const: Navigation Properties to be used in the ResetPasswordSubmit component
  const { navigate, navTo } = useNavHook("/");

  // Function: Handle Submit of Reset PW Form - Make API Call
  const handleSubmit = (e) => {
    e.preventDefault();
    putForgotPasswordVerify(forgotPWState, navigate, navTo, setErrMsg);
  };

  // Component: Login Submit Button
  const resetPasswordSubmitButton = (
    <Button
      onClick={handleSubmit}
      buttonName={resetPWButtonName}
      disabled={!isResetFilledOut || !isPasswordEqual}
      className={"font-medium"}
    />
  );
  return (
    <div className="forgotpw-submit-button-container">
      {resetPasswordSubmitButton}
    </div>
  );
}

// Props
const ResetPasswordSubmitProps = {
  loginState: PropTypes.object, // State of the login fields
  setLoginErrMsg: PropTypes.func, // Function to set the error message
};

ResetPasswordSubmit.propTypes = ResetPasswordSubmitProps;
