// Functionality: This file exports the EmissionsFootprintBreakdownCard component which is used in the OverviewPage component. The component is a card including a Doughnut chart that displays the breakdown of the total emissions footprint. This component will be shown within a modal.

// NOTE: Not Including Scope 3 in V1
import PropTypes from "prop-types";
import ProgressBar from "../../../components/ProgressBar";
import DoughnutChart from "../../../visualizations/DoughnutChart";
import { totalEmissionTitle } from "../../../constants/EmissionsConfig";
import useNavHook from "../../../hooks/use-NavHook";
import { mockLoginData } from "../../../constants/mockDataConfig";

export default function EmissionsFootprintBreakdownCard({
  emissionFootprintBreakdownData,
  totalEmissionFootprint,
}) {
  // Const: Initialize Hook - Navigation Hook to go to Emissions Page
  const { navigate, navTo } = useNavHook("/emissions?activeTab=1");

  // Const: Format Color dict to be supported in doughnut chart
  const formattedColorDict = Object.fromEntries(
    Object.entries(emissionFootprintBreakdownData).map(([key, value]) => [
      key.toLowerCase(),
      value,
    ])
  );

  // Const: Doughnut Chart Showing Breakdown of Emissions - Scope 1, 2, 3
  const doughnutChart = (
    <div className="emission-footprint-chart-container">
      <DoughnutChart
        groups={Object.values(emissionFootprintBreakdownData) || []}
        colorDict={formattedColorDict}
        labelMetricValue={totalEmissionFootprint?.value}
      />
    </div>
  );

  // Const: Determine if Mock User is Logged in
  const notMockUser =
    localStorage.getItem("greenToken") !== mockLoginData?.token;

  // Function: Generates the legend given the config
  const generateLegend = (config) => {
    return Object.keys(config)
      ?.slice(0, 2)
      .map((item, index) => {
        if (config?.[item]?.percentageValue !== 0) {
          return (
            <div key={index} className="emission-footprint-legend-group">
              <div className="emission-footprint-value-group">
                <div className="emission-footprint-legend-value font-metric-value">
                  {(config?.[item]?.percentageValue * 100)?.toFixed(2) || "-"}%
                </div>
                <ProgressBar
                  percentageValue={(
                    config?.[item]?.percentageValue * 100
                  )?.toFixed(2)}
                  progressClassName={`progress-${item}`}
                />
              </div>
              <div className="emission-footprint-label-group">
                <div className="emission-footprint-label font-small-medium">
                  {config?.[item]?.label}
                </div>
                <div className="emission-footprint-label-def font-small">
                  {config?.[item]?.legend}
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div
              id="emission-page-container-scope2-empty"
              className="chart-card-container-group font-medium"
              onClick={() => handleNavScope2Manager()}
            >
              <p>No Scope 2 data available</p>
              <p>for the selected month.</p>
              <p>Please visit Scope 2 Emissions Manager.</p>
            </div>
          );
        }
      });
  };

  // Function: Handle Navigation Scope 2 Manager
  const handleNavScope2Manager = () => {
    navigate(navTo, { state: { activeTab: 1 }, replace: true });
  };

  return (
    <>
      {!notMockUser ? (
        <div id="emission-footprint-breakdown-container">
          <div className="emission-footprint-chart-group">
            <p className="card-title">{totalEmissionTitle}</p>
            {doughnutChart}
          </div>
          <div>{generateLegend(emissionFootprintBreakdownData)}</div>
        </div>
      ) : (
        <>
          <div id="emission-footprint-breakdown-container">
            <div className="emission-footprint-chart-group">
              <p className="card-title">{totalEmissionTitle}</p>
              {doughnutChart}
            </div>
            <div>{generateLegend(emissionFootprintBreakdownData)}</div>
          </div>
        </>
      )}
    </>
  );
}

// Props
const emissionFootprintBreakdownPropTypes = {
  emissionFootprintBreakdownData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]), // Config for the Doughnut Chart
  totalEmissionFootprint: PropTypes.object, // Total Emission Footprint
};
EmissionsFootprintBreakdownCard.propTypes = emissionFootprintBreakdownPropTypes;
