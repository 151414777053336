// This file serves as a central location for all configuration variables related to Settings
import UniversalTableConfig from "./UniversalTableConfig.js";
import { PiPencilLight } from "react-icons/pi";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";

// TODO: ADD LAST UPDATED
// Vehicle Settings Data Table
// VP Data Table Columns
export const vehicleSettingsDataColumns = [
  "vehicle_id",
  "vin",
  "gvwr",
  "manufacturer_name",
  "make",
  "model",
  "year",
  "engine_manufacturer",
  "engine_type",
  "fuel_type",
];
const vehicleSettingsDataTableColumns = [
  "Vehicle",
  "VIN",
  "Class",
  "Manufacturer",
  "Make",
  "Model",
  "Model Year",
  "Engine Model",
  "Engine Manufacturer",
  "Primary Fuel Type",
];

export const vehicleSettingsDataColumnMapping =
  vehicleSettingsDataTableColumns.reduce((acc, key, index) => {
    acc[key] = vehicleSettingsDataColumns[index];
    return acc;
  }, {});

// VP Data Table Config
export const vehicleSettingsDataConfig = (
  handleEditClick,
  handleRouteVehicleProfile,
  editSingle,
  uploadCheck = false,
  handleUploadCheck = () => {}
) => {
  // Add the rest of the columns
  const columnsConfig = UniversalTableConfig(
    vehicleSettingsDataColumns,
    handleRouteVehicleProfile
  );

  // Add the column to edit the vehicle settings
  const editColumn = [
    {
      Header: "Edit",
      accessor: (universalData) => (
        <div className="table-edit-col-group">
          <PiPencilLight onClick={() => handleEditClick(universalData)} />
        </div>
      ),
      Cell: (universalData) => {
        return (
          <div className="table-edit-col-group">
            <PiPencilLight onClick={() => handleEditClick(universalData)} />
          </div>
        );
      },
      sortValue: (universalData) => universalData.vehicle_id,
    },
  ];

  const uploadCheckColumn = uploadCheck
    ? [
        {
          Header: "Approve Changes",
          accessor: (universalData) => (
            <div className="vehicle-csv-check-group">
              <div className="vehicle-csv-check">
                <IoMdCheckmark
                  onClick={() => handleUploadCheck(universalData, "approved")}
                />
              </div>
              <div className="vehicle-csv-x">
                <IoMdClose onClick={() => handleUploadCheck(universalData)} />
              </div>
            </div>
          ),
          Cell: (universalData) => {
            return (
              <div className="vehicle-csv-check-group">
                <div className="vehicle-csv-check">
                  <IoMdCheckmark
                    onClick={() => handleUploadCheck(universalData, "approved")}
                  />
                </div>
                <div className="vehicle-csv-x">
                  <IoMdClose onClick={() => handleUploadCheck(universalData)} />
                </div>
              </div>
            );
          },
          sortValue: (universalData) => universalData.vehicle_id,
        },
      ]
    : [];

  return editSingle
    ? columnsConfig?.concat(editColumn)?.concat(uploadCheckColumn)
    : columnsConfig?.concat(uploadCheckColumn);
};
export const vinDecoderLabelNames = [
  "Vehicle",
  "VIN",
  "Class",
  "Manufacturer",
  "Make",
  "Model",
  "Model Year",
  "Engine Manufacturer",
  "Engine Model",
  "Fuel Type",
];
const vinDecoderOutputCols = [
  "VehicleId",
  "Vin",
  "GVWR",
  "ManufacturerName",
  "Make",
  "Model",
  "ModelYear",
  "EngineManufacturer",
  "EngineModel",
  "FuelType",
];
export const vehSettingsOutputCols = [
  "vehicle_id",
  "vin",
  "gvwr",
  "manufacturer_name",
  "make",
  "model",
  "year",
  "engine_manufacturer",
  "engine_type",
  "fuel_type",
];
export const vinVehicleSettingsColumnMapping = vinDecoderOutputCols.reduce(
  (acc, key, index) => {
    acc[key] = vehSettingsOutputCols[index];
    return acc;
  },
  {}
);

export const fileUploadLineDict = {
  color: "#232c33",
  backgroundColor: "#232c33",
  height: 2,
  width: 75,
  border: "none",
};

export const intialVinDecoderDownloadLabeling = {
  label: "Vehicle Template",
  subText: `You can download the attached file containing your personalized
            vehicle information to use as a starting point for your own file.`,
  buttonName: "Download Template",
  fileName: "vehicle_settings",
  showIcon: true,
};
export const vinDecoderDownloadFailedEntriesLabeling = {
  label: "Failed Entries",
  subText:
    "Fix the entries in your local file, and re-upload the file to continue.",
  buttonName: "Download",
  fileName: "failed-entries",
};

export const vinDecoderDownloadPreviewLabeling = {
  label: "",
  subText: ``,
  buttonName: "Download a Copy",
  fileName: "vehicle_settings",
  showIcon: false,
};
// TODO: EDIT THIS TO UPDATE THE STATE OF THE VEHICLE SETTINGS
// Vehicle Settings Vin Upload Table
// Vehicle Settings Vin Upload Table Columns
export const vsVinUploadTableColumns = ["vehicle_id", "vin"];
// Vehicle Settings Vin Upload Table Config
export const vsVinUploadTableConfig = () => {
  // Add the rest of the columns
  const columnsConfig = UniversalTableConfig(vsVinUploadTableColumns);

  return columnsConfig;
};
// Vehicle Settings Initial Upload Failed Reasoning
export const failedVinDecoderReasonMapping = {
  null_vehicle: {
    message: "Automatically discarded - Null Vehicle ID.",
    summary:
      "Empty Vehicle ID: Null Vehicle ID entries have been automatically discarded.",
    count: 0,
    unique_ids: [],
  },
  duplicate_vehicle: {
    message: "Duplicate Vehicle ID.",
    summary:
      "Duplicate Vehicle ID: Duplicate Vehicle ID entries exist, please discard / accept duplicated entries.",
    count: 0,
    unique_ids: [],
  },
  duplicate_vin: {
    message: "Duplicate VIN.",
    summary:
      "Duplicate VIN: Duplicate VIN entries exist, please discard / accept duplicated entries.",
    count: 0,
    unique_ids: [],
  },
  vin_length: {
    message: "VIN must be 17 characters long.",
    summary: "VIN Length: Vehicle's VIN must be 17 characters long.",
    count: 0,
    unique_ids: [],
  },
  vin_invalid_chars: {
    message: "VIN must not contain special characters.",
    summary:
      "VIN Special Characters: Vehicle's VIN must not contain special characters.",
    count: 0,
    unique_ids: [],
  },
  vin_invalid_chars_ioq: {
    message: "VIN must not contain the letters I, O, or Q (case-insensitive).",
    summary:
      "VIN Invalid Characters: Vehicle's VIN must not contain the letters I, O, or Q (case-insensitive).",
    count: 0,
    unique_ids: [],
  },
  invalid_vehicle_id: {
    message: "Automatically discarded - Invalid Vehicle ID.",
    summary:
      "Invalid Vehicle ID: Incompatible Vehicle IDs have been automatically discarded.",
    count: 0,
    unique_ids: [],
  },
};

// Const: Vehicle Settings Single or Multi Edit
export const vehicleSettingsEditConfig = [
  { label: "Vehicle Configuration", value: "Insite" },
  { label: "CSV VIN Decoder", value: "CSV" },
];

// Const: Vehicle Settings Vin Upload Table
export const singularVehicleEditConfig = [
  { label: "VIN Decoder", value: "decoder" },
  { label: "In-Site Editing", value: "insite" },
];

// TODO: XYZ - this is compatible with initial insite editing, but not editing with the vin decoder output!!!! - need to change on the singular vin decoder editor and csv editor upload
export const editVinFieldsConfig = [
  {
    label: "Class",
    valueName: "gvwr",
    type: "input",
  },
  {
    label: "Manufacturer",
    valueName: "manufacturer_name",
    type: "input",
  },
  {
    label: "Make",
    valueName: "make",
    type: "input",
  },
  {
    label: "Model",
    valueName: "model",
    type: "input",
  },
  {
    label: "Model Year",
    valueName: "year",
    type: "searchbar",
  },
  {
    label: "Engine Manufacturer",
    valueName: "engine_manufacturer",
    type: "input",
  },
  {
    label: "Engine Model",
    valueName: "engine_type",
    type: "input",
  },
  {
    label: "Primary Fuel Type",
    valueName: "fuel_type",
    type: "searchbar",
  },
];
