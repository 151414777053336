// Functionality: React hook to sort a table by a column
import { useState, useEffect } from "react";

export default function useSort(data, config, forceFloat = true) {
  const [sortOrder, setSortOrder] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortedData, setSortedData] = useState([]);

  const setSortColumn = (label) => {
    if (sortBy && label !== sortBy) {
      setSortOrder("asc");
      setSortBy(label);
      return;
    }
    if (sortOrder === null) {
      setSortOrder("asc");
      setSortBy(label);
    } else if (sortOrder === "asc") {
      setSortOrder("desc");
      setSortBy(label);
    } else if (sortOrder === "desc") {
      setSortOrder(null);
      setSortBy(null);
    }
  };
  // Only sort data if sortOrder && sortBy are not null
  // Make a copy of the 'data' prop
  // Find the correct sortValue function and use it for sorting
  useEffect(() => {
    if (sortOrder && sortBy) {
      const { sortValue } = config.find((column) => column.Header === sortBy);
      const newSortedData = [...data].sort((a, b) => {
        const valueA = forceFloat ? parseFloat(sortValue(a)) : sortValue(a);
        const valueB = forceFloat ? parseFloat(sortValue(b)) : sortValue(b);
        const reverseOrder = sortOrder === "asc" ? 1 : -1;
        if (typeof valueA === "string") {
          return valueA.localeCompare(valueB) * reverseOrder;
        } else {
          return (valueA - valueB) * reverseOrder;
        }
      });
      setSortedData(newSortedData);
    } else {
      // If no sorting parameters are set, display the original data
      setSortedData(data);
    }
  }, [data, sortOrder, sortBy]);
  return {
    sortOrder,
    sortBy,
    sortedData,
    setSortColumn,
  };
}
