// Functionality: This file is the modal that pops up when the user clicks on the "Settings" button in the Emission Reduction Target section of the Reduction Page. It allows the user to change the settings of the Emission Reduction Target.
import { useState, useEffect } from "react";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import SelectDropdown from "../../components/SelectDropdown";
import SnackBar from "../../components/SnackBar";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { putEmissionReductionTargetAPI } from "../../api/Reduction/EmissionReductionTarget/putEmissionReductionTarget";
import { getDefaultEmissionReductionTargetAPI } from "../../api/Reduction/EmissionReductionTarget/getDefaultEmissionReductionTargetAPI";

export default function EmissionReductionTargetSettingsModal({
  reductionTargetPercentageDropdown,
  yearValues,
  reductionTargetDefault,
  setEmissionReductionProjectedYear,
  setSelectedEmissionTargetPercentage,
  setReductionTargetDefault,
}) {
  // State: Modal
  const [showModal, setShowModal] = useState(false);
  const [reviewState, setReviewState] = useState(true);
  const [selectedBaseYear, setSelectedBaseYear] = useState(
    reductionTargetDefault?.base_year
  );
  const [projectedYear, setProjectedYear] = useState(
    reductionTargetDefault?.projection_year
  );
  const [targetPercentage, setTargetPercentage] = useState(
    reductionTargetDefault?.target_percentage
  );
  const [successMsg, setSuccessMsg] = useState({ text: "", msg: "" });

  // Function: Show Modal
  const handleShowModal = () => {
    setShowModal(true);
  };
  // Function: Hide Modal
  const handleHideModal = () => {
    setShowModal(false);
    setReviewState(true);
  };

  // Function: Handle Update Entry - API Call
  const handleUpdateEntry = () => {
    putEmissionReductionTargetAPI(
      selectedBaseYear,
      projectedYear,
      targetPercentage,
      setSuccessMsg
    );
    handleHideModal();
    setTimeout(() => setSuccessMsg({ text: "", msg: "" }), 5000);
  };
  useEffect(() => {
    if (successMsg?.text?.length > 0) {
      getDefaultEmissionReductionTargetAPI(
        setReductionTargetDefault,
        setEmissionReductionProjectedYear,
        setSelectedEmissionTargetPercentage
      );
    }
  }, [successMsg]);
  // Const: Button
  const emissionsModalButton = (
    <IoMdSettings
      className="emission-settings-button"
      onClick={handleShowModal}
    />
  );

  const submitHandler =
    selectedBaseYear?.length > 0 &&
    projectedYear?.length > 0 &&
    targetPercentage?.length > 0;

  // Const: Base Year Handler - logic: if there is only 1 year available, return all years, otherwise slice most recent year as base year option
  const baseYearHandler = () => {
    if (yearValues?.information?.length === 1) {
      return yearValues?.information;
    } else {
      return yearValues?.information.slice(0, -1);
    }
  };

  // Const: Modal Content
  const modalContent = (
    <div>
      <div className="card-title emission-reduc-target-settings-title-group">
        <p>Emissions Reduction Target Settings</p>{" "}
        {!reviewState ? <FaLock /> : <FaLockOpen />}
      </div>
      <div className="emi-reduct-target-settings-sub-text">
        <p>
          You are attempting to update the default values for your emissions
          reduction target - this change will be reflected throughout the
          platform, including on reports.
        </p>

        <p className="card-title">
          Previous Emission Reduction Target:{" "}
          {reductionTargetDefault?.target_percentage?.[0]?.label} reduction by{" "}
          {reductionTargetDefault?.projection_year?.[0]?.label} assuming a base
          year of {reductionTargetDefault?.base_year?.[0]?.label}.
        </p>
      </div>
      <div className="emi-reduct-target-settings-container">
        <div className="emi-reduc-target-settings-group">
          <p>Base Year:</p>
          <SelectDropdown
            options={baseYearHandler() || []}
            selectedOptions={selectedBaseYear}
            setSelectedOptions={setSelectedBaseYear}
            disabled={!reviewState}
            headerClassName={`select-dropdown-header-container${
              !reviewState ? "-disabled" : ""
            } font-color-gray`}
          />
        </div>
        <div className="emi-reduc-target-settings-group">
          <p>Target Percentage:</p>
          <SelectDropdown
            options={reductionTargetPercentageDropdown}
            selectedOptions={targetPercentage}
            setSelectedOptions={setTargetPercentage}
            disabled={!reviewState}
            headerClassName={`select-dropdown-header-container${
              !reviewState ? "-disabled" : ""
            } font-color-gray`}
          />
        </div>
        <div className="emi-reduc-target-settings-group">
          <p>Target Year:</p>
          <SelectDropdown
            options={yearValues?.projection || []}
            selectedOptions={projectedYear}
            setSelectedOptions={setProjectedYear}
            disabled={!reviewState}
            headerClassName={`select-dropdown-header-container${
              !reviewState ? "-disabled" : ""
            } font-color-gray`}
          />
        </div>
      </div>
      <div className="flex-row-space-between">
        {!reviewState ? (
          <Button
            buttonName="Go Back"
            className="secondary-blue-button"
            onClick={() => {
              setReviewState(true);
            }}
            disabled={reviewState}
          />
        ) : (
          <div></div>
        )}
        <div className="flex-end-button-group">
          <Button
            buttonName="Cancel"
            className="secondary-blue-button"
            onClick={handleHideModal}
          />
          {reviewState && (
            <Button
              buttonName="Review"
              className="primary-blue-button"
              onClick={() => {
                setReviewState(false);
              }}
              disabled={!reviewState || !submitHandler}
            />
          )}
          {!reviewState && (
            <Button
              buttonName="Update"
              className="primary-blue-button"
              onClick={() => {
                handleUpdateEntry();
              }}
              disabled={reviewState}
            />
          )}
        </div>
      </div>
    </div>
  );

  // Const: Modal Component
  const emissionsModal = (
    <Modal
      onClose={handleHideModal}
      children={modalContent}
      modalDivClassName="large-modal-div"
    />
  );
  return (
    <>
      {emissionsModalButton}
      {showModal && emissionsModal}
      <SnackBar
        message={successMsg?.text}
        visible={successMsg?.text?.length > 0}
        severity={successMsg?.msg}
      />
    </>
  );
}
