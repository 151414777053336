import { useContext } from "react";
import { CustomRadioItemGroup } from "../../components/RadioItemGroup";
import ReportContext from "../../context/ReportsProvider";
import ProgressBar from "../../components/ProgressBar";
import { reportOptions } from "../../constants/ReportsConfig";

export default function ReportsSideBar({
  calculateCompletionPercentage,
  downloadReportSubmitButton,
}) {
  // Const: Initialize Report Context
  const {
    introDataConfig,
    riskDataConfig,
    strategyDataConfig,
    targetDataConfig,
    energyDataConfig,
    challengesDataConfig,
    selectedReportSection,
    setSelectedReportSection,
  } = useContext(ReportContext);

  const mappingDictionary = {
    Introduction: introDataConfig,
    "Risks & Opportunities": riskDataConfig,
    Strategy: strategyDataConfig,
    "Targets & Reduction": targetDataConfig,
    Energy: energyDataConfig,
    Challenges: challengesDataConfig,
  };

  const reportOptionsMapping = reportOptions?.map((item) => {
    const valuesToManipulate = ["Risks & Opportunities", "Targets & Reduction"];
    const riskTarget = valuesToManipulate?.includes(item) ? 1 : 0;
    const isStrategy = item === "Strategy" ? true : false;
    return {
      label: (
        <div className="reports-sidebar-label-group">
          {item}
          {item !== "Additional Features" && (
            <ProgressBar
              percentageValue={calculateCompletionPercentage(
                mappingDictionary?.[item],
                false,
                riskTarget,
                isStrategy
              )}
              remainingProgressClassName={"remaining-progress-report"}
              circular={true}
            />
          )}
        </div>
      ),
      value: item,
    };
  });

  return (
    <>
      <CustomRadioItemGroup
        options={reportOptionsMapping}
        selectedOption={selectedReportSection}
        setSelectedOption={setSelectedReportSection}
        divClassName="div-radio-item-sidebar"
        elementClassName="div-radio-item-sidebar-option"
      />
      {downloadReportSubmitButton}
    </>
  );
}
